import classNames from "classnames";

type Props = {
  count: number;
  total: number;
  percentage: number;
  collapse?: boolean;
};

const LearningOutcomesCount: React.FC<Props> = (props) => {
  return (
    <div
      className={classNames("flex flex-row items-end mt-1 mb-4", {
        "mb-0": props.collapse,
      })}
    >
      <div className="text-acc-34px font-acc-700 tracking-[-0.5] leading-none text-green-light-text">{props.count}</div>
      <div className="text-acc-15px font-acc-700 tracking-default leading-none text-grey-60 mb-1">
        &nbsp;{`/ ${props.total}`}
      </div>
      <div className="text-acc-15px font-acc-700 tracking-default leading-none text-grey-90 mb-1">
        &nbsp;{`(${props.percentage}%)`}
      </div>
    </div>
  );
};

export default LearningOutcomesCount;
