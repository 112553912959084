import { ChangeEvent, useEffect, useRef, useState } from "react";
import { EvidencePart } from "../../../Interfaces";
import { Text, View } from "@react-pdf/renderer";
import { IonInput, IonTextarea } from "@ionic/react";
import Textarea from "@mui/joy/Textarea";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  definition: EvidencePart;
  onValueChanged?: (value: any, isInput?: boolean) => void;
  onInputBlurred: () => void;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  isLocked?: boolean;
  pdfStyles: any;
  initialValue?: any;
  id: string;
  multiline: boolean;
};

const TextBox: React.FC<Props> = ({
  onValueChanged,
  onInputBlurred,
  data,
  definition,
  isLocked,
  isDisplay,
  isPDF,
  pdfStyles,
  initialValue,
  id,
  multiline,
}) => {
  const [value, setValue] = useState<string>(initialValue || "");
  const [initialValueSet, setInitialValueSet] = useState<boolean>(false);

  useEffect(() => {
    if (isLocked && !initialValueSet) {
      setValue(initialValue);
      setInitialValueSet(true);
      onValueChanged && onValueChanged(initialValue, true);
    }
  }, [initialValue, initialValueSet, isLocked, onValueChanged]);

  useEffect(() => {
    if (!initialValue?.length) {
      setValue("");
    }
  }, [id]);

  const inputChanged = (event: any): void => {
    const _value = (event.target as HTMLTextAreaElement).value;

    setValue(_value);
    onValueChanged && onValueChanged(_value, true);
  };

  const keyChanged = (event: any) => {
    // prevent user from adding new lines in single line textboxes
    if (event.key === "Enter" && !multiline) {
      event.preventDefault();
    }
  };

  const inputBlurred = (): void => {
    onInputBlurred && onInputBlurred();
  };

  if (isPDF) {
    if (definition["Display Title"] === "Title") {
      return null;
    }

    return (
      <View>
        <Text style={pdfStyles.reflectionHeader}>{(definition["Display Title"] || definition.Name) + ":"}</Text>
        <Text style={pdfStyles.evidenceDate}>{data}</Text>
      </View>
    );
  }

  if (isDisplay) {
    if (definition["Display Title"] === "Title") {
      return null;
      // return (
      //   <div className="-mt-5">
      //     <div className="readEvidenceTitle">{data}</div>
      //     <div className="h-[1px] w-full bg-grey-30 mt-4" />
      //   </div>
      // );
    }

    return (
      <div className="readEvidenceBlock">
        <div className="readEvidenceHeader">{definition["Display Title"] || definition.Name}</div>
        <div className={`readEvidenceText ${multiline && "whitespace-pre-wrap"}`}>{data}</div>
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return (
    <>
      {multiline ? (
        <Textarea
          placeholder={definition?.["Placeholder Text"] || "Enter a value..."}
          disabled={isLocked}
          value={value}
          onChange={(e) => inputChanged(e)}
          variant="plain"
          sx={{
            "--Textarea-placeholderOpacity": 1,
            backgroundColor: "white",
            minHeight: "28px",
            color: value.length ? "black" : "var(--grey-60)", // different color for placeholder text
            padding: 0,
            fontSize: "20px",
            fontWeight: "600",
            "@media (max-width: 500px)": {
              fontSize: "17px",
            },
            ":hover": {
              color: value.length ? "black" : "var(--grey-60)", // different color for placeholder text
            },
          }}
        />
      ) : (
        <input
          className="reflectionTagsInput"
          placeholder={definition?.["Placeholder Text"] || "Enter a value..."}
          autoComplete="off"
          disabled={isLocked}
          value={value}
          onChange={inputChanged}
          onBlur={inputBlurred}
        />
      )}
    </>
  );
};

export default TextBox;
