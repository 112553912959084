import { IonButton, IonSpinner } from "@ionic/react";
import { ProgramData } from "../../Interfaces";
import * as ProgramUtils from "../../utils/programUtils";
import { TRUST_BACKGROUND_COLOUR_VARIANTS } from "../../Constants";

type Props = {
  program?: ProgramData;
  startProgram: () => void;
  loading: boolean;
};

const GetStartedCard: React.FC<Props> = (props) => {
  return (
    <div className="programPlaceholderCard">
      <div
        className={`programPlaceholderCardBackground ${ProgramUtils.getProgramCardBackground(props.program?.Name!)}`}
      />
      <div className="programPlaceholderCardInner">
        <div className="overviewHeader" style={{ marginTop: -3 }}>
          <div className="flex justify-between">
            {props.program?.Trust && (
              <div
                className={`programTrustPill programTrustPillStarted ${TRUST_BACKGROUND_COLOUR_VARIANTS[props.program.TrustName!]}`}
              >
                {props.program.Trust}
              </div>
            )}
            {props.program?.showBetaLabel && (
              <div className="text-white bg-black px-1 rounded-[2px] font-semibold text-13px flex justify-center items-center">
                {"BETA"}
              </div>
            )}
          </div>
          <div className="programCardTitle" style={{ color: "#FFFFFF" }}>
            {`Set up your ${props.program?.Title}`}
          </div>
          <div className="programCardSubtitle">{props.program?.DashboardDescription}</div>
        </div>
        <IonButton onClick={() => props.startProgram()} className="programGetStartedButton">
          {props.loading ? <IonSpinner class="text-orange-700 h-6 w-6" /> : <div>{"Get started"}</div>}
        </IonButton>
      </div>
    </div>
  );
};

export default GetStartedCard;
