import { useRecoilValueLoadable } from "recoil";
import { IEvidence, LearningObjective, ProgramData } from "../../Interfaces";
import LearningOutcomesCount from "./LearningOutcomesCount";
import { evidenceAtom } from "../../state/State";
import * as ProgramUtils from "../../utils/programUtils";
import * as NQPUtils from "../../utils/nqpUtils";

type Props = {
  program?: ProgramData;
};

const NQPLearningOutcomesDisplay: React.FC<Props> = (props) => {
  const allEvidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);

  if (!props.program) {
    return null;
  }

  const learningObjectives = ProgramUtils.getAllLearningObjectivesForProgram(props.program);

  let evidenceForProgram: IEvidence[] = [];

  if (allEvidence.state === "hasValue" && allEvidence.contents) {
    evidenceForProgram = allEvidence.contents
      .filter((evidence: IEvidence) => {
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
        const id = evidenceJSON?.programInfo?.programID || "";

        return id === props.program?.ID;
      })
      .map((evidence) => ({ ...evidence, evidenceJSON: JSON.parse(evidence.evidenceJSON) }));
  }

  const count = NQPUtils.getLearningOutcomesAchieved(learningObjectives, evidenceForProgram);
  const total = learningObjectives.length;
  const percentage = Math.trunc((count / total) * 100);

  return (
    <div>
      <LearningOutcomesCount count={count} total={learningObjectives.length} percentage={percentage} collapse />
    </div>
  );
};

export default NQPLearningOutcomesDisplay;
