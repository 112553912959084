import { useHistory, useParams } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperInterface } from "swiper";
import { useCallback, useEffect, useRef, useState } from "react";
import { IonButton, IonSpinner } from "@ionic/react";
import {
  ActivityPage,
  IEvidence,
  IUser,
  IWellbeingContainer,
  WellbeingInfoCardButton,
  WellbeingPart,
  WellbeingPartType,
} from "../../Interfaces";
import { CreateWellbeingActivityPart } from "./wellbeingParts/WellbeingComponentWrapper";
import { App } from "@capacitor/app";
import _ from "lodash";

import "swiper/css";
import DataController from "../../controllers/DataController";
import { getDefaultEvidenceJSONForWellbeingActivity } from "../../utils/wellbeingUtils";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { evidenceAtom, userAtom } from "../../state/State";
import { DatabaseService } from "../../controllers/DatabaseService";
import WellbeingConfirmModal from "./WellbeingConfirmModal";

let backButtonListener: any;

const WellbeingContainer: React.FC<IWellbeingContainer> = (props) => {
  const params = useParams<{ programId: string; progressCheckId: string; competenceId: string }>();
  const history = useHistory();

  const position = useRef<number>(0);
  const swiperInstance = useRef<SwiperInterface>();

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const setEvidence = useSetRecoilState<IEvidence[] | null>(evidenceAtom);

  const progressCheck = props.program.ProgressChecks?.find((item) => item.ID === params.progressCheckId)!;
  const progressCheckCompetence = progressCheck["Competence/Activity"].find((item) => item.ID === params.competenceId)!;

  const numberOfPages = props.wellbeingActivity["Activity Pages"].length;

  const [saving, setSaving] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const defaultEvidenceJSON = getDefaultEvidenceJSONForWellbeingActivity(
    props.wellbeingActivity["Activity Pages"],
    props.program,
    progressCheck,
    progressCheckCompetence
  );

  // const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();
  const [slidePosition, setSlidePosition] = useState<number>(0);
  const [evidenceToAdd, setEvidenceToAdd] = useState<IEvidence>({
    title: "Wellbeing Activity",
    date: new Date().toISOString(),
    id: DataController.generateUniqueID(),
    private: true,
    evidenceJSON: defaultEvidenceJSON,
  });

  useEffect(() => {
    const setupPageBackButtonListener = (): void => {
      try {
        if (!backButtonListener) {
          let backButtonArray = document.getElementsByClassName("wellbeing-activity-back-button");

          if (backButtonArray.length > 0) {
            let backButton = backButtonArray[0];

            backButtonListener = backButton.addEventListener("click", (event: Event) => {
              event.stopImmediatePropagation();
              event.preventDefault();

              if (position.current === 0) {
                history.goBack();
              } else {
                swiperInstance.current?.slideTo(position.current - 1, undefined);
              }
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    setupPageBackButtonListener();

    return () => {
      backButtonListener?.remove();
    };
  }, [swiperInstance]);

  useEffect(() => {
    App.addListener("backButton", (event) => {
      if (slidePosition === 0) {
        history.goBack();
      } else {
        changeSlideIndex(slidePosition - 1, true);
      }
    });
  }, []);

  useEffect(() => {
    const scrollToTop = (): void => {
      try {
        const element: HTMLIonContentElement = document.getElementById(
          "wellbeing-content-container"
        ) as HTMLIonContentElement;

        element?.scrollToTop(0);
      } catch (error) {
        console.log(error);
      }
    };

    scrollToTop();
  }, [slidePosition]);

  const onSlideChange = (): void => {
    position.current = swiperInstance.current?.realIndex ?? 0;
    setSlidePosition(swiperInstance.current?.realIndex ?? 0);
  };

  const changeSlideIndex = useCallback(
    (value: number, skip?: boolean): void => {
      swiperInstance.current?.slideTo(value, skip ? 0 : undefined);
    },
    [swiperInstance]
  );

  const getButtonText = (): string => {
    if (slidePosition === 0) {
      return "Start Activity";
    } else if (slidePosition === numberOfPages - 1) {
      return "Confirm";
    } else {
      return "Next";
    }
  };

  const showSkipButton = (): boolean => {
    const currentPage = props.wellbeingActivity["Activity Pages"][slidePosition];

    return currentPage["Show Skip Button"] ?? false;
  };

  const buttonClicked = (): void => {
    if (slidePosition !== numberOfPages - 1) {
      changeSlideIndex(slidePosition + 1);
    } else {
      setShowConfirmModal(true);
    }
  };

  const skipButtonClicked = (): void => {
    changeSlideIndex(numberOfPages - 1, true);
  };

  const saveEvidence = async (): Promise<void> => {
    try {
      setSaving(true);
      const object = _.cloneDeep(evidenceToAdd);

      if (progressCheckCompetence?.["LOs Automatic"]) {
        const selectedOutcomes = progressCheckCompetence["Learning Objective"]?.map((item) => ({
          id: item.ID,
          name: item.Name,
        }));

        object.evidenceJSON = {
          LearningObjectivesTickboxes: {
            selectedOutcomes,
          },
          ...object.evidenceJSON,
        };
      }

      let addToDB = await DatabaseService.addProgramEvidence(user.contents, object);

      if (!addToDB) {
        addToDB = await DatabaseService.updateProgramEvidence(user.contents, object);
      }

      if (addToDB) {
        let allEvidence = [];
        allEvidence = await DatabaseService.getEvidence(user.contents);
        await DataController.saveEvidence(allEvidence);
        setEvidence(allEvidence);

        setShowConfirmModal(false);
        setSaving(false);
        history.goBack();
      } else {
        setSaving(false);
        window.alert("An error occurred when saving wellbeing activity");
      }
    } catch (error) {
      console.log(error);
      setSaving(false);
      window.alert("An error occurred when saving wellbeing activity");
    }
  };

  const onValueChanged = (part: WellbeingPart, page: ActivityPage, value: any, isInput?: boolean): void => {
    const object = _.cloneDeep(evidenceToAdd.evidenceJSON) ?? {};
    const key = part.Name;

    let newObject: any;

    if (part["Part type"] === WellbeingPartType.WellbeingRange) {
      newObject = _.extend(object, { [key]: { ...value, page: page.ID } });
    } else {
      newObject = _.extend(object, { [key]: value });
    }

    setEvidenceToAdd((e: any) => ({ ...e, evidenceJSON: newObject }));
  };

  const getInitialValue = (part: WellbeingPart): any => {
    const evidenceJSON = _.cloneDeep(evidenceToAdd.evidenceJSON);

    if (part["Part type"] === WellbeingPartType.RadarGraph || part["Part type"] === WellbeingPartType.BarGraph) {
      const keys = Object.keys(evidenceJSON).filter((key) => evidenceJSON[key].page !== undefined);

      let groups = keys.map((key) => evidenceJSON[key].page);
      groups = _.uniq(groups);

      const data = groups.map((group) => {
        const keys = Object.keys(evidenceJSON).filter((key) => evidenceJSON[key].page === group);
        const activities = props.wellbeingActivity["Activity Pages"];
        const activity = activities.find((page) => page.ID === evidenceJSON[keys[0]]?.page);

        return keys.map((key) => ({
          ...evidenceJSON[key],
          label: key,
          header: activity?.["Chart Header"],
          total: part["Max value"],
        }));
      });

      return {
        data,
      };
    }

    return evidenceJSON[part.Name];
  };

  const infoCardButtonClicked = (buttonType: string): void => {
    if (buttonType === WellbeingInfoCardButton.ViewSupportOptions) {
      changeSlideIndex(slidePosition - 1);
    } else if (buttonType === WellbeingInfoCardButton.SeeResults) {
      console.log(WellbeingInfoCardButton.SeeResults);
    }
  };

  return (
    <div className=" w-full flex flex-col flex-1 justify-between min-h-dvh-nav">
      {props.wellbeingActivity["Confirm Text"] && (
        <WellbeingConfirmModal
          disclaimerText={props.wellbeingActivity["Confirm Text"] ?? ""}
          visible={showConfirmModal}
          dismiss={() => setShowConfirmModal(false)}
          confirm={() => saveEvidence()}
          saving={saving}
        />
      )}
      <Swiper
        threshold={0}
        mousewheel={false}
        // onSwiper={(swiper) => setSwiperInstance(swiper)}
        onSwiper={(swiper) => (swiperInstance.current = swiper)}
        onSlideChange={onSlideChange}
        allowTouchMove={false}
        simulateTouch={false}
        className="!max-w-app-column !w-full h-auto relative"
        autoHeight={true}
        observer={true}
        observeParents={true}
        id="wellbeing-swiper"
        noSwiping={true}
        noSwipingClass="swiper-no-swiping"
        preventClicks={false}
        preventClicksPropagation={false}
      >
        {props.wellbeingActivity["Activity Pages"].map((page) => (
          <SwiperSlide key={page.ID} draggable={false}>
            <div className="w-full flex flex-col h-auto swiper-no-swiping">
              {page["Wellbeing Parts"].map((part) => {
                if (page["Show in Results View"] && part["Hide in Activity Page"]) {
                  return null;
                }

                return (
                  <div key={part.ID}>
                    {CreateWellbeingActivityPart(part["Part type"], {
                      wellbeingPart: part,
                      onValueChanged: (value: any, isInput?: boolean) => onValueChanged(part, page, value),
                      initialValue: getInitialValue(part),
                      page,
                      evidenceJSON: _.cloneDeep(evidenceToAdd.evidenceJSON),
                      buttonClicked: (buttonType: string) => infoCardButtonClicked(buttonType),
                    })}
                  </div>
                );
              })}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="flex flex-col justify-center items-center flex-grow-1 mt-4 pb-h-dvh-nav">
        {showSkipButton() && (
          <IonButton
            mode="ios"
            className="white-button w-full h-[56px] max-w-[374px] mb-2"
            onClick={() => skipButtonClicked()}
          >
            <div className="text-[16px] font-bold tracking-default text-center text-grey-90">{"Skip"}</div>
          </IonButton>
        )}
        <IonButton mode="ios" className="primary-button w-full h-[56px] max-w-[374px]" onClick={() => buttonClicked()}>
          {!props.wellbeingActivity["Confirm Text"] && saving ? (
            <IonSpinner className="h-5 w-5 text-white" />
          ) : (
            <div className="text-[16px] font-bold tracking-default text-center text-white">{getButtonText()}</div>
          )}
        </IonButton>
      </div>
    </div>
  );
};

export default WellbeingContainer;
