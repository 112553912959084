import { IonButton } from "@ionic/react";
import { IWellbeingInfoCard } from "../../../types/Components";
import sanitizeHTML from "./helpers/sanitizeHTML";

const WellbeingInfoCard: React.FC<IWellbeingInfoCard> = (props) => {
  const isSmallerCard =
    props.wellbeingPart.isSmallerCard ||
    (props.isResultsPage && props.wellbeingPart["Hide in Activity Page"] && props.index > 1);

  const hideInResultsView = props.isResultsPage && props.wellbeingPart["Hide in Results Page"];

  if (hideInResultsView) {
    return null;
  }

  return (
    <div className="flex justify-center">
      <div
        className={`relative max-w-app-column w-full p-4 bg-white mb-2 pl-safe-area-l pr-safe-area-r ${isSmallerCard ? "!mx-4 !mt-1.5 !mb-1.5 rounded-[5px]" : ""}`}
      >
        <div className="text-acc-17px font-extrabold tracking-default text-grey-80 text-start">
          {props.wellbeingPart["Display Title"]}
        </div>
        {props.wellbeingPart["List of values"] && (
          <div
            className="text-start text-acc-14px [&>p]:!text-acc-14px [&>ul]:!text-acc-14px [&>ul]:list-disc [&>ul]:list-outside [&>ul]:pl-[revert] mt-2 text-grey-70"
            dangerouslySetInnerHTML={sanitizeHTML(props.wellbeingPart["List of values"])}
          />
        )}
        {props.wellbeingPart["Info Block Button"] && (
          <IonButton
            mode="ios"
            className="grey-button w-full h-[56px] max-w-[374px] mt-4"
            onClick={() => props.buttonClicked?.(props.wellbeingPart["Info Block Button"]!)}
          >
            <div className="text-[16px] font-bold tracking-default text-center text-cta-blue">
              {props.wellbeingPart["Info Block Button"]}
            </div>
          </IonButton>
        )}
      </div>
    </div>
  );
};

export default WellbeingInfoCard;
