/* eslint-disable @typescript-eslint/no-explicit-any */
import { format, subYears } from "date-fns";
import React from "react";
import { EventRegister } from "react-native-event-listeners";
import { useRecoilValueLoadable } from "recoil";
import { useProgramData } from "../../hooks/data/useProgramData";
import {
  AllProgressCheckStatuses,
  CompetenceActivityClass,
  EvidenceCustomTag,
  EvidenceStandardTag,
  FilledInBy,
  IEvidence,
  IUser,
  ProgressCheck,
} from "../../Interfaces";
import { evidenceAtom, progressCheckDataAtom, userAtom } from "../../state/State";
import { IProgramEvidenceCard } from "../../types/Components";
import * as ProgramUtils from "../../utils/programUtils";
import { useProgramModuleStatus } from "../../hooks/programs/useProgramModuleStatus";
import * as EvidenceUtils from "../../utils/evidenceUtils";
import EvidenceCard from "../evidence/EvidenceCard";

const ProgramEvidenceCard: React.FC<IProgramEvidenceCard> = (props) => {
  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const allEvidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);
  const { userPrograms } = useProgramData();

  const allProgressCheckData = useRecoilValueLoadable<AllProgressCheckStatuses[] | null>(progressCheckDataAtom);
  const { pauseAndHold } = useProgramModuleStatus(props.program?.ID);

  const evidenceJSON = props.programEvidence.evidenceJSON && JSON.parse(props.programEvidence.evidenceJSON);
  const title = props.programEvidence.title || evidenceJSON?.Title || "";

  const activityTypeKey = ProgramUtils.getEvidenceActivityTypeKey(props.programEvidence);

  const activityType = activityTypeKey && evidenceJSON?.[activityTypeKey];
  const taskingType = evidenceJSON?.["Tasking type or training"];

  const customTagsKey = ProgramUtils.getEvidenceCustomTagsKey(props.programEvidence);
  const customTags: EvidenceCustomTag[] = customTagsKey && evidenceJSON?.[customTagsKey];

  const progressCheck: ProgressCheck | null =
    evidenceJSON?.programInfo?.progressCheckID && ProgramUtils.getProgressCheckForEvidence(evidenceJSON, props.program);

  const hasSeparator =
    typeof activityType !== "undefined" ||
    (typeof progressCheck !== "undefined" && typeof props.program !== "undefined");

  const onHoldReset = evidenceJSON?.OnHoldReset === 1;

  const getLabel = () => {
    if (props.program && ProgramUtils.showStandardsLabelForEvidenceCard(props.program.ID)) {
      try {
        const standards: string[] = [];
        const parsedEvidence = JSON.parse(props.programEvidence.evidenceJSON);
        const comps: any[] = Array.from(parsedEvidence.Comps);
        comps.forEach((comp) => standards.push(comp.Name));
        return standards.join(", ");
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  const label = getLabel();

  const expired = new Date(props.programEvidence.date) < subYears(new Date(), 2);

  const editEvidence = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    const parsedEvidence = JSON.parse(props.programEvidence.evidenceJSON);

    const program = userPrograms.find((program) => program.ID === parsedEvidence.programInfo.programID);
    const pc = program?.ProgressChecks?.find(
      (progressCheck) => progressCheck.ID === parsedEvidence.programInfo.progressCheckID
    );

    let competences: CompetenceActivityClass[] = [];
    let progressCheckCompetences: CompetenceActivityClass[] = [];
    let progressCheckCompetence: any;
    let mandatoryStandards: CompetenceActivityClass[] = [];
    let optionalStandards: CompetenceActivityClass[] = [];
    if (program) {
      if (ProgramUtils.shouldGetProgressCheckInfo(program.ID)) {
        progressCheckCompetences = pc?.["Competence/Activity"] ?? [];
        progressCheckCompetence = progressCheckCompetences.find(
          (item: any): item is CompetenceActivityClass =>
            item.ID === parsedEvidence.programInfo?.progressCheckCompetenceID
        );
      } else {
        competences = program.CompetenceActivity ?? [];
      }

      if (user.state === "hasValue" && user.contents) {
        mandatoryStandards = ProgramUtils.getMandatoryStandards(user.contents, program!);
        optionalStandards = ProgramUtils.getOptionalStandards(user.contents, program!);
      }
    }
    const competence = competences.find(
      (item: any): item is CompetenceActivityClass => item.Name === parsedEvidence.programInfo?.competence
    );

    const evidenceDefinitions = program?.["Evidence Definitions"] || [];
    const progressChecks: any = program?.ProgressChecks ?? [];

    const data = {
      program: program,
      ...(competences.length > 0 && { competences }),
      ...(evidenceDefinitions.length > 0 && {
        evidenceDefinitions: evidenceDefinitions[0],
      }),
      competence: competence,
      evidence: props.programEvidence,
      ...(progressCheckCompetence && {
        progressCompetence: progressCheckCompetence,
      }),
      progressCheck: pc,
      ...(progressChecks.length > 0 && { progressChecks }),
      ...(mandatoryStandards.length > 0 && { mandatoryStandards }),
      ...(optionalStandards.length > 0 && { optionalStandards }),
      evidenceJSON: JSON.parse(props.programEvidence.evidenceJSON),
      skill: null,
      id: props.programEvidence.id,
      learningOutcomes: progressCheckCompetence?.["Learning Objective"] ?? [],
      progressCheckCompetence: progressCheckCompetence,
      currentProgressCheckId: pc?.ID ?? "",
    };

    EventRegister.emit("evidence/add-program-evidence", data);
  };

  const canEdit = () => {
    if (pauseAndHold) {
      return false;
    }

    const parsedEvidence = JSON.parse(props.programEvidence.evidenceJSON);
    const program = userPrograms.find((program) => program.ID === parsedEvidence.programInfo.programID);
    const pc = program?.ProgressChecks?.find(
      (progressCheck) => progressCheck.ID === parsedEvidence.programInfo.progressCheckID
    );
    let competences: CompetenceActivityClass[] = [];
    if (program) {
      if (ProgramUtils.shouldGetProgressCheckInfo(program.ID)) {
        competences = pc?.["Competence/Activity"] ?? [];
      } else {
        competences = program.CompetenceActivity ?? [];
      }
    }
    const competence = competences.find(
      (item: CompetenceActivityClass) => item.ID === parsedEvidence.programInfo?.progressCheckCompetenceID
    );

    const dataForProgram: AllProgressCheckStatuses = allProgressCheckData.contents?.find(
      (item: any): item is AllProgressCheckStatuses => item.programID === program?.ID
    );
    const dataForProgressCheck = dataForProgram?.pCs.find((item) => item.pCId === pc?.ID);

    if (program && ProgramUtils.isCSM(program.ID)) {
      return (
        (!competence?.NoEdit || (competence?.NoEdit && props.programEvidence.draft)) && parsedEvidence.OnHoldReset !== 1
      );
    } else if (program && ProgramUtils.isNQPProgram(program.ID)) {
      return (
        !competence?.isWellbeingActivity &&
        competence?.FilledInBy !== FilledInBy.Mentor &&
        (!competence?.NoEdit || (competence?.NoEdit && props.programEvidence.draft)) &&
        parsedEvidence.OnHoldReset !== 1 &&
        ProgramUtils.canAddOrEditProgressCheckEvidence(dataForProgressCheck?.submissions) &&
        !ProgramUtils.isProgramCompleteNQP(
          program?.ProgressChecks,
          allProgressCheckData.contents,
          allEvidence.contents,
          program?.ID
        )
      );
    } else {
      return (
        !competence?.isWellbeingActivity &&
        competence?.FilledInBy !== FilledInBy.Mentor &&
        (!competence?.NoEdit || (competence?.NoEdit && props.programEvidence.draft)) &&
        parsedEvidence.OnHoldReset !== 1 &&
        ProgramUtils.canAddOrEditProgressCheckEvidence(dataForProgressCheck?.submissions)
      );
    }
  };

  return (
    <div>
      <div className="flex justify-center">
        <EvidenceCard
          onClick={props.buttonPressed}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 12,
            paddingBottom: 12,
          }}
        >
          <div className="flex flex-col py-3 !px-0 w-full">
            <div className="flex flex-row flex-wrap items-center">
              {expired && (
                <div className="flex items-center justify-center self-start h-[16px] px-[6px] rounded-[13px] mr-[8px] bg-orange-30 ">
                  <div className="text-[12px] font-semibold text-[#9C4221]">{"Expired"}</div>
                </div>
              )}
              {props.programEvidence.private && (
                <div className="flex items-center justify-center self-start h-[16px] w-[52px] px-[6px] rounded-[13px] mr-[8px] bg-[#DEE2E6] ">
                  <div className="text-[12px] font-semibold text-[#868E96]">{"Private"}</div>
                </div>
              )}
              {props.programEvidence.draft && (
                <div className="flex items-center justify-center self-start h-[16px] w-[42px] px-[6px] rounded-[13px] mr-[8px] bg-orange-30 ">
                  <div className="text-[12px] font-semibold text-[#9C4221]">{"Draft"}</div>
                </div>
              )}
              <div className="text-acc-12px font-acc-500 leading-[1.33] tracking-[-0.1px] text-grey-70">
                {format(new Date(props.programEvidence.date), "d MMM yyyy")}
              </div>
              {hasSeparator && <div className="evidenceCardDotSeparator" />}
              {activityType && <div className="evidenceCardProgramActivity">{activityType}</div>}
              {progressCheck && (
                <div className="text-acc-12px font-acc-600 leading-acc-lh text-program-evidence-header">
                  {progressCheck.Name}
                </div>
              )}
              {onHoldReset && (
                <div className="flex flex-row">
                  <div className="evidenceCardDotSeparator" />
                  <div className="text-acc-12px font-acc-500 leading-[1.33] tracking-[-0.1px] text-cta-red whitespace-break-spaces">
                    {"Submission Reset"}
                  </div>
                </div>
              )}
            </div>
            <div className="flex items-center">
              <div
                className={`flex-1 acc-underline text-acc-20px font-semibold leading-acc-lh tracking-[-0.1px] text-grey-90 mt-[4px] evidence-m:text-acc-17px evidence-m:leading-default ${label.length === 0 && "mb-[8px]"}`}
              >
                {title}
              </div>
              {EvidenceUtils.canEdit(props.programEvidence, userPrograms, allProgressCheckData) && (
                <button onClick={(e) => editEvidence(e)} className="text-cta-blue text-acc-14px p-1">
                  Edit
                </button>
              )}
            </div>
            <span className="text-acc-12px font-acc-500 leading-default tracking-[-0.1px] text-grey-70 overflow-visible acc-underline">
              {taskingType && <div>{taskingType}</div>}
              {props.programEvidence.standardTags && props.programEvidence.standardTags?.length > 0 && (
                <span className="text-acc-12px font-acc-500 leading-default tracking-[-0.1px] text-[#2F855A] overflow-visible">
                  {props.programEvidence.standardTags.map((tag: EvidenceStandardTag) => tag.tag).join(", ")}
                </span>
              )}
              {props.programEvidence.standardTags &&
                props.programEvidence.standardTags.length > 0 &&
                props.programEvidence.customTags &&
                props.programEvidence.customTags.length > 0 && (
                  <span className="inline-block self-center w-[2px] h-[2px] rounded-[4px] mx-[5px] my-[3.5px] bg-grey-70" />
                )}
              {props.programEvidence.customTags && props.programEvidence.customTags.length > 0 && (
                <span className="text-acc-12px font-acc-500 leading-default tracking-[-0.1px] text-grey-70 overflow-visible">
                  {props.programEvidence.customTags.map((tag: EvidenceCustomTag) => tag.tag).join(", ")}
                </span>
              )}
              {customTags && customTags.length > 0 && (
                <span className="text-acc-12px font-acc-500 leading-default tracking-[-0.1px] text-grey-70 overflow-visible">
                  {customTags.map((tag: EvidenceCustomTag) => tag.tag).join(", ")}
                </span>
              )}
              {label && label.length > 0 && (
                <span className="text-acc-12px font-acc-500 leading-default tracking-[-0.1px] text-grey-60 overflow-visible">
                  {label}
                </span>
              )}
            </span>
            {props.programEvidence.addToHCPCAudit && <div className="evidenceHCPCPill">{"Audit"}</div>}
          </div>
        </EvidenceCard>
      </div>
    </div>
  );
};

export default ProgramEvidenceCard;
