import { IEvidence, LearningObjective } from "../Interfaces";

/**
 * Calculates the number of unique learning outcomes achieved based on the provided evidence.
 *
 * This function iterates over the given evidence and checks for learning outcomes
 * that are present in the "LearningObjectivesTickboxes" section of the evidence JSON.
 * It matches these outcomes with the list of learning outcomes provided and counts
 * the number of unique matches.
 *
 * @param learningOutcomes - An array of LearningObjective objects representing the
 *                           desired learning outcomes to check against.
 * @param evidence - An array of IEvidence objects containing evidence data with
 *                   potential learning outcome information.
 * @returns The number of unique learning outcomes achieved based on the evidence.
 */

export function getLearningOutcomesAchieved(learningOutcomes: LearningObjective[], evidence: IEvidence[]): number {
  const outcomesIds = new Set<string>();

  evidence.forEach((evidence) => {
    if (evidence.evidenceJSON["LearningObjectivesTickboxes"]) {
      Array.from(evidence.evidenceJSON["LearningObjectivesTickboxes"].selectedOutcomes).forEach((outcome: any) => {
        if (learningOutcomes.find((lo) => lo.ID === outcome.id)) {
          outcomesIds.add(outcome.id);
        }
      });
    }
  });

  return outcomesIds.size;
}

export function getOccurrencesOfLearningOutcome(learningOutcome: LearningObjective, evidence: IEvidence[]): number {
  let sum = 0;

  evidence.forEach((evidence) => {
    if (evidence.evidenceJSON["LearningObjectivesTickboxes"]) {
      Array.from(evidence.evidenceJSON["LearningObjectivesTickboxes"].selectedOutcomes).forEach((outcome: any) => {
        if (outcome.id === learningOutcome.ID) {
          sum += 1;
        }
      });
    }
  });

  return sum;
}
