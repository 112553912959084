import { useRecoilValue, useSetRecoilState } from "recoil";
import { plusDataAtom, userAtom } from "../../state/State";
import { IPlusUsageData, IUser } from "../../Interfaces";
import { useCallback, useEffect } from "react";
import { DatabaseService } from "../../controllers/DatabaseService";
import DataController from "../../controllers/DataController";
import { USAGE_DATA_REFRESH_INTERVAL } from "../../Constants";
import { useLoggedIn } from "../auth/useLoggedIn";

type RefreshJRCALCPlusData = {
  refreshJRCALCPlusData: (user: IUser) => Promise<void>;
};

export const useRefreshJRCALCPlusData = (shouldLoad?: boolean): RefreshJRCALCPlusData => {
  const user = useRecoilValue(userAtom);
  const setPlusData = useSetRecoilState<IPlusUsageData[] | null>(plusDataAtom);
  const loggedIn = useLoggedIn();

  const refreshJRCALCPlusData = useCallback(async (_user: IUser): Promise<void> => {
    try {
      let data = [];
      data = await DatabaseService.getJRCALCPLusTimelineData(_user);
      await DataController.saveJRCALCPlusTimelineData(data);
      setPlusData(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const getJRCALCPlusData = async (): Promise<void> => {
      try {
        if (loggedIn && user && shouldLoad) {
          let data = [];
          data = await DatabaseService.getJRCALCPLusTimelineData(user);
          await DataController.saveJRCALCPlusTimelineData(data);
          setPlusData(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const interval = setInterval(() => {
      getJRCALCPlusData();
    }, USAGE_DATA_REFRESH_INTERVAL);

    return () => {
      try {
        clearInterval(interval);
      } catch (error) {
        console.log(error);
      }
    };
  }, [loggedIn, setPlusData, user, shouldLoad]);

  useEffect(() => {
    const getJRCALCPlusData = async (): Promise<void> => {
      try {
        let data = [];
        data = await DatabaseService.getJRCALCPLusTimelineData(user);
        await DataController.saveJRCALCPlusTimelineData(data);
        setPlusData(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (loggedIn && user && shouldLoad) {
      getJRCALCPlusData();
    }
  }, [loggedIn, setPlusData, user, shouldLoad]);

  return {
    refreshJRCALCPlusData,
  };
};
