const PersonalEvidenceDisclaimer: React.FC = () => {
  return (
    <div className="p-3.5 rounded-md border border-solid border-grey-40 mb-4 bg-grey-20">
      <div className="text-15px font-bold leading-default tracking-default text-grey-70">
        {
          "This is Personal CPD evidence. It is private to you until you chose to share it. Remember not to add patient identifiable information."
        }
      </div>
    </div>
  );
};

export default PersonalEvidenceDisclaimer;
