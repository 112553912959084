import { IonPicker, IonPopover, IonModal, createAnimation } from "@ionic/react";
import { IDurationPicker } from "../../types/Components";
import React, { useState } from "react";

const DurationPicker: React.FC<IDurationPicker> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hours, minutes] = props.hours === "" ? ["0000", "00"] : props.hours.split(":");
  const time = `${hours} hr : ${minutes.padStart(2, "0")} min`;

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="durationPickerText"
        style={{ color: props.hours === "" ? "var(--grey-60)" : "black" }}
      >
        {time}
      </button>
      <IonPicker
        animated={true}
        enterAnimation={(baseEl: HTMLElement) => {
          const pickerWrapper = baseEl.querySelector(".picker-wrapper")!;
          return createAnimation()
            .addElement(pickerWrapper)
            .duration(250)
            .easing("ease-in-out")
            .keyframes([
              { offset: 0, transform: "translateY(100%)", opacity: 0 },
              { offset: 1, transform: "translateY(0)", opacity: 1 },
            ]);
        }}
        leaveAnimation={(baseEl: HTMLElement) => {
          const el = baseEl.querySelector(".picker-wrapper")!;
          return createAnimation()
            .addElement(el)
            .duration(250)
            .easing("ease-in-out")
            .keyframes([
              { offset: 0, transform: "translateY(0)", opacity: 1 },
              { offset: 1, transform: "translateY(100%)", opacity: 0 },
            ]);
        }}
        isOpen={isOpen}
        onWillDismiss={() => setIsOpen(false)}
        mode="ios"
        columns={[
          {
            cssClass: "pickerHighlight",
            selectedIndex: parseInt(hours),
            name: "hours",
            suffix: "hr",
            options: Array.from({ length: 1001 }, (_, i) => i).map((it) => ({
              text: it.toString(),
              value: it.toString(),
            })),
          },
          {
            cssClass: "pickerHighlight",
            selectedIndex: parseInt(minutes),
            name: "minutes",
            suffix: "min",
            options: Array.from({ length: 60 }, (_, i) => i).map((it) => ({
              text: it.toString(),
              value: it.toString(),
            })),
          },
        ]}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "Confirm",
            handler: (value) => props.onChange(`${value.hours.value}:${value.minutes.value}`),
          },
        ]}
      />
    </>
  );
};

export default React.memo(DurationPicker);
