import { IonItem } from "@ionic/react";
import {
  CompetenceActivityClass,
  EvidencePart,
  INOSSelectCompetence,
  INOSSelectLearningObjectives,
  LearningObjective,
} from "../../../Interfaces";
import { useState } from "react";
import {
  Button_Checkbox_Active,
  Button_Checkbox_Inactive,
  Button_Collapse,
  Button_Expand,
} from "../../../assets/images";
import NOSSelectContainer from "../../common/NOSSelectContainer";
import _ from "lodash";
import LearningObjectiveSelector from "./LearningObjectiveSelector";
import { Text, View } from "@react-pdf/renderer";
import * as ProgramUtils from "../../../utils/programUtils";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  definition: EvidencePart;
  mandatoryStandards?: CompetenceActivityClass[];
  optionalStandards?: CompetenceActivityClass[];
  onValueChanged?: (value: any) => void;
  setCollapsibleOpen: (value: boolean) => void;
  toggleCollapsible: () => void;
  collapsibleOpen: boolean;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue?: any;
};

const NOSSelect: React.FC<Props> = (props) => {
  const [collapsibleOpen, setCollapsibleOpen] = useState<string[]>([]);
  const [selectedStandards, setSelectedStandards] = useState<INOSSelectCompetence[]>(props.initialValue?.Comps ?? []);
  const [selectedLearningObjectives, setSelectedLearningObjectives] = useState<INOSSelectLearningObjectives[]>(
    props.initialValue?.LOs ?? []
  );
  const [showDescriptions, setShowDescriptions] = useState<boolean>(true);

  if (props.isPDF) {
    return (
      <View>
        {props.data.Comps?.length > 0 && (
          <View>
            {props.data.Comps.map((item: INOSSelectCompetence) => {
              const learningObjectives = ProgramUtils.getLearningObjectivesForStandard(
                props.mandatoryStandards,
                props.optionalStandards,
                item,
                props.data.LOs
              );

              return (
                <View key={item.id}>
                  <View>
                    <Text style={props.pdfStyles.reflectionHeader}>{"Standard"}</Text>
                    <Text style={props.pdfStyles.evidenceDate}>{item.Name}</Text>
                  </View>
                  <View>
                    <Text style={props.pdfStyles.reflectionHeader}>{"PCKUs"}</Text>
                    <Text style={props.pdfStyles.evidenceDate}>
                      {learningObjectives && learningObjectives?.length > 0
                        ? learningObjectives
                            .map((item) => item.displayName)
                            .sort()
                            .join(", ")
                        : "None"}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        )}
      </View>
    );
  }

  if (props.isDisplay) {
    return (
      <div>
        {props.data.Comps?.length > 0 && (
          <div>
            {props.data.Comps.map((item: INOSSelectCompetence) => {
              const learningObjectives = ProgramUtils.getLearningObjectivesForStandard(
                props.mandatoryStandards,
                props.optionalStandards,
                item,
                props.data.LOs
              );

              return (
                <div key={item.id} className="readEvidenceBlock">
                  <div>
                    <div className="readEvidenceHeader">{"Standard"}</div>
                    <div className="readEvidenceText">{item.Name}</div>
                    <div className="h-[1px] w-full bg-grey-30 mt-4" />
                  </div>
                  <div>
                    <div className="readEvidenceHeader mt-5">{"PCKUs"}</div>
                    <div>
                      {learningObjectives && learningObjectives?.length > 0
                        ? learningObjectives
                            .map((item) => item.displayName)
                            .sort()
                            .join(", ")
                        : "None"}
                    </div>
                    <div className="h-[1px] w-full bg-grey-30 mt-4" />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  const toggleSelectedStandard = (mandatoryStandard: CompetenceActivityClass): void => {
    let array = _.cloneDeep(selectedStandards);

    let index = array.findIndex((item) => item.id === mandatoryStandard.ID);

    if (index > -1) {
      array.splice(index, 1);
      handleCollapsibleToggle(mandatoryStandard.ID, true);
      const deleted = removeLearningObjectivesFromStandard(mandatoryStandard);

      const value = {
        Comps: [...array],
        LOs: [...deleted],
      };

      props.onValueChanged?.(value);
    } else {
      array.push({ id: mandatoryStandard.ID, Name: mandatoryStandard.Name });
      handleCollapsibleToggle(mandatoryStandard.ID);

      const value = {
        Comps: [...array],
        LOs: [...selectedLearningObjectives],
      };

      props.onValueChanged?.(value);
    }

    setSelectedStandards(array);
  };

  const handleCollapsibleToggle = (id: string, close?: boolean): void => {
    let array = _.cloneDeep(collapsibleOpen);
    let index = array.findIndex((item) => item === id);

    if (index > -1) {
      array.splice(index, 1);
    } else {
      !close && array.push(id);
    }

    setCollapsibleOpen(array);
  };

  const toggleLearningObjective = (learningObjective: LearningObjective): void => {
    let array = _.cloneDeep(selectedLearningObjectives);
    let index = array.findIndex((item) => item.id === learningObjective.ID);

    if (index > -1) {
      array.splice(index, 1);
    } else {
      array.push({ id: learningObjective.ID, displayName: learningObjective["Display Name"]! });
    }

    const value = {
      Comps: [...selectedStandards],
      LOs: [...array],
    };

    props.onValueChanged?.(value);

    setSelectedLearningObjectives(array);
  };

  const removeLearningObjectivesFromStandard = (
    mandatoryStandard: CompetenceActivityClass
  ): INOSSelectLearningObjectives[] => {
    let array = _.cloneDeep(selectedLearningObjectives);

    const learningObjectives: string[] =
      mandatoryStandard["Learning Objective"]?.map((learningObjective) => learningObjective.ID) ?? [];

    if (learningObjectives.length > 0) {
      array = array.filter((learningObjective) => !learningObjectives.includes(learningObjective.id));
    }

    array = _.uniqBy(array, "id");

    setSelectedLearningObjectives(array);
    return array;
  };

  const selectAllLearningObjectives = (mandatoryStandard: CompetenceActivityClass): void => {
    let array = _.cloneDeep(selectedLearningObjectives);

    const learningObjectives: INOSSelectLearningObjectives[] =
      mandatoryStandard["Learning Objective"]?.map((learningObjective) => ({
        id: learningObjective.ID,
        displayName: learningObjective["Display Name"]!,
      })) ?? [];

    if (learningObjectives.length > 0) {
      array.push(...learningObjectives);
    }

    array = _.uniqBy(array, "id");

    setSelectedLearningObjectives(array);

    const value = {
      Comps: [...selectedStandards],
      LOs: [...array],
    };

    props.onValueChanged?.(value);
  };

  const deselectAllLearningObjectives = (mandatoryStandard: CompetenceActivityClass): void => {
    let array = _.cloneDeep(selectedLearningObjectives);

    const learningObjectives: string[] =
      mandatoryStandard["Learning Objective"]?.map((learningObjective) => learningObjective.ID) ?? [];

    if (learningObjectives.length > 0) {
      array = array.filter((learningObjective) => !learningObjectives.includes(learningObjective.id));
    }

    array = _.uniqBy(array, "id");

    setSelectedLearningObjectives(array);

    const value = {
      Comps: [...selectedStandards],
      LOs: [...array],
    };

    props.onValueChanged?.(value);
  };

  return (
    <div>
      <div className="reflectionContentCard bg-grey-20">
        <div className="reflectionContentCardTitle !mb-0">
          {`Select the relevant NOS for this evidence`}
          {(props.definition.options === "Mandatory" || props.definition["option type"] === "Mandatory") && (
            <span className="text-cta-red"> (Mandatory)</span>
          )}
        </div>
      </div>
      {props.mandatoryStandards?.map((mandatoryStandard: CompetenceActivityClass) => {
        const selected = selectedStandards.findIndex((item) => item.id === mandatoryStandard.ID) > -1;

        return (
          <NOSSelectContainer key={mandatoryStandard.ID}>
            <div className="relative">
              <IonItem
                button
                detail={false}
                className="evidencePartsCheckboxContainer"
                onClick={() =>
                  selected ? handleCollapsibleToggle(mandatoryStandard.ID) : toggleSelectedStandard(mandatoryStandard)
                }
              >
                <div className="w-full flex flex-row items-center !justify-between">
                  <div className="my-1">
                    <div className="text-[17px] font-acc-600 leading-default tracking-[-0.3px] text-grey-90 whitespace-nowrap overflow-hidden text-ellipsis acc-underline decoration-current">
                      {mandatoryStandard.Name}
                    </div>
                    <div className="text-[11px] font-acc-400 leading-default tracking-[-0.3px] text-grey-90 overflow-hidden text-ellipsis acc-underline decoration-current mt-[2px] line-clamp-2">
                      {mandatoryStandard.Description}
                    </div>
                  </div>
                  <button
                    onClick={(event?: any) => {
                      event?.stopPropagation();
                      toggleSelectedStandard(mandatoryStandard);
                    }}
                  >
                    <img
                      alt=""
                      className="h-icon w-icon img-no-select"
                      src={selected ? Button_Checkbox_Active : Button_Checkbox_Inactive}
                    />
                  </button>
                </div>
              </IonItem>
              {selected && (
                <button
                  className="z-50 absolute right-11 top-0 bottom-0"
                  onClick={() => handleCollapsibleToggle(mandatoryStandard.ID)}
                >
                  <img
                    alt=""
                    className="h-icon w-icon img-no-select"
                    src={collapsibleOpen.includes(mandatoryStandard.ID) ? Button_Collapse : Button_Expand}
                  />
                </button>
              )}
            </div>
            {mandatoryStandard["Learning Objective"] && (
              <LearningObjectiveSelector
                learningObjectives={mandatoryStandard["Learning Objective"]}
                collapsibleOpen={collapsibleOpen.includes(mandatoryStandard.ID)}
                onValueChanged={(learningObjective) => toggleLearningObjective(learningObjective)}
                selectedLearningObjectives={selectedLearningObjectives}
                showDescription={showDescriptions}
                toggleDescriptions={() => setShowDescriptions(!showDescriptions)}
                selectAllLearningObjectives={() => selectAllLearningObjectives(mandatoryStandard)}
                deselectAllLearningObjectives={() => deselectAllLearningObjectives(mandatoryStandard)}
              />
            )}
          </NOSSelectContainer>
        );
      })}
      {props.optionalStandards?.map((optionalStandard: CompetenceActivityClass) => {
        const selected = selectedStandards.findIndex((item) => item.id === optionalStandard.ID) > -1;

        return (
          <NOSSelectContainer key={optionalStandard.ID}>
            <div className="relative">
              <IonItem
                button
                detail={false}
                className="evidencePartsCheckboxContainer"
                onClick={() =>
                  selected ? handleCollapsibleToggle(optionalStandard.ID) : toggleSelectedStandard(optionalStandard)
                }
              >
                <div className="w-full flex flex-row items-center !justify-between">
                  <div className="my-1">
                    <div className="text-[17px] font-acc-600 leading-default tracking-[-0.3px] text-grey-90 whitespace-nowrap overflow-hidden text-ellipsis acc-underline decoration-current">
                      {optionalStandard.Name}
                    </div>
                    <div className="text-[11px] font-acc-400 leading-default tracking-[-0.3px] text-grey-90 overflow-hidden text-ellipsis acc-underline decoration-current mt-[2px] line-clamp-2">
                      {optionalStandard.Description}
                    </div>
                  </div>
                  <button
                    onClick={(event?: any) => {
                      event?.stopPropagation();
                      toggleSelectedStandard(optionalStandard);
                    }}
                  >
                    <img
                      alt=""
                      className="h-icon w-icon img-no-select"
                      src={selected ? Button_Checkbox_Active : Button_Checkbox_Inactive}
                    />
                  </button>
                </div>
              </IonItem>
              {selected && (
                <button
                  className="z-50 absolute right-11 top-0 bottom-0"
                  onClick={() => handleCollapsibleToggle(optionalStandard.ID)}
                >
                  <img
                    alt=""
                    className="h-icon w-icon img-no-select"
                    src={collapsibleOpen.includes(optionalStandard.ID) ? Button_Collapse : Button_Expand}
                  />
                </button>
              )}
            </div>
            {optionalStandard["Learning Objective"] && (
              <LearningObjectiveSelector
                learningObjectives={optionalStandard["Learning Objective"]}
                collapsibleOpen={collapsibleOpen.includes(optionalStandard.ID)}
                onValueChanged={(learningObjective) => toggleLearningObjective(learningObjective)}
                selectedLearningObjectives={selectedLearningObjectives}
                showDescription={showDescriptions}
                toggleDescriptions={() => setShowDescriptions(!showDescriptions)}
                selectAllLearningObjectives={() => selectAllLearningObjectives(optionalStandard)}
                deselectAllLearningObjectives={() => deselectAllLearningObjectives(optionalStandard)}
              />
            )}
          </NOSSelectContainer>
        );
      })}
    </div>
  );
};

export default NOSSelect;
