import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonImg, IonPage, IonToolbar } from "@ionic/react";
import { useHistory } from "react-router";
import DataController from "../../controllers/DataController";
import { Capacitor } from "@capacitor/core";
import { AppInfoContainer, ContentContainer, HeaderTitle } from "parafolio-components";
import { useTextToSpeech } from "../../hooks/accessibility/useTextToSpeech";
import { useCallback, useEffect } from "react";
import { useHidingTabBar } from "../../hooks/tabs/useHidingTabBar";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { IAccessibilitySettings, IUser } from "../../Interfaces";
import { accessibilityAtom, appInfoAtom, deviceInfoAtom, userAtom } from "../../state/State";
import { Button_Audio_Play_White, Button_Audio_Stop } from "../../assets/images";
import { FirebaseService } from "../../controllers/FirebaseService";
import { DeviceInfo } from "@capacitor/device";
import { AppInfo } from "@capacitor/app";
import { version } from "../../environment/version";

const ContactUs: React.FC = (props) => {
  const history = useHistory();
  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const deviceInfo = useRecoilValue<DeviceInfo | null>(deviceInfoAtom);
  const appInfo = useRecoilValue<AppInfo | null>(appInfoAtom);
  const accessibilitySettings = useRecoilValueLoadable<IAccessibilitySettings | null>(accessibilityAtom);

  const { handleTTS, playing, stopTTS } = useTextToSpeech("contactUsContainer");
  useHidingTabBar(history.location.pathname === "/dashboard/help-centre/contact-us");

  useEffect(() => {
    return () => {
      stopTTS();
    };
  }, []);

  const sendSupportEmail = useCallback((): void => {
    if (user.state === "hasValue" && user.contents) {
      const url = "mailto:apps@class.co.uk";
      const subject = "ParaFolio - Help Centre Support";

      const body = DataController.supportEmailBody(user.contents.userData, deviceInfo, appInfo);

      window.open(`${url}?subject=${subject}&body=${body}`, "_blank");
    }
  }, []);

  const contactSupport = async (event: MouseEvent | any): Promise<boolean> => {
    console.log(event);

    await FirebaseService.logEvent("support_link_pressed", {
      link: "contact_support",
      type: "help_centre",
    });

    sendSupportEmail();

    return false;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref={
                history.location.pathname === "/dashboard/help-centre/contact-us"
                  ? "/dashboard/help-centre"
                  : "/dashboard"
              }
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{"Contact Us"}</HeaderTitle>
          {accessibilitySettings.contents?.textToSpeech && (
            <IonButtons slot="end">
              <IonButton className="header-button" mode="ios" onClick={() => handleTTS()}>
                <IonImg src={playing ? Button_Audio_Stop : Button_Audio_Play_White} className="headerIcon" />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background whiteBackground">
        <ContentContainer tabBarDisabled id="contactUsContainer">
          <div className="max-w-app-column w-full relative pl-ml-safe-area-l pr-safe-area-r">
            <AppInfoContainer title="Login & subscription help">
              <p>
                {
                  "For specific troubleshooting help regarding subscriptions, accounts and signing up/login, please send us "
                }
                <button type="button" onClick={(event) => contactSupport(event)}>
                  <div className="text-cta-blue font-acc-normal underline">{"this formatted email"}</div>
                </button>
                {" with a description of the issue."}
              </p>
              <p>
                {"If the formatted email does not work for you, please use this email: "}
                <a href="mailto:apps@class.co.uk?subject=ParaFolio - Support">{"apps@class.co.uk"}</a>
              </p>
            </AppInfoContainer>
            <AppInfoContainer title="Email or call">
              <p>
                {
                  "Let us know if you have an issue, suggestion or any feedback. We're always trying to improve the app. Email us at "
                }
                <a href="mailto:apps@class.co.uk?subject=ParaFolio - Feedback" target="_blank" rel="noreferrer">
                  {"apps@class.co.uk"}
                </a>
                {" or call us on "}
                <a href="tel:01278427800" target="_blank" rel="noreferrer">
                  {"01278 427 800"}
                </a>
              </p>
              <br />
              <p>
                {Capacitor.getPlatform() === "web" ? (
                  <>{`App Version - ${version}`}</>
                ) : (
                  <>{`App Version - ${appInfo?.version} (${appInfo?.build})`}</>
                )}
              </p>
            </AppInfoContainer>
          </div>
        </ContentContainer>
      </IonContent>
    </IonPage>
  );
};

export default ContactUs;
