import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonPopover,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { userAtom } from "../../state/State";
import { DatabaseService } from "../../controllers/DatabaseService";
import _ from "lodash";
import { format, isToday } from "date-fns";
import DataController from "../../controllers/DataController";
import "./ProgramComponents.css";
import { useRecoilState } from "recoil";
import HeaderTitle from "../common/HeaderTitle";
import {
  AWSAccessParams,
  CompetenceActivityClass,
  ICertificateInfo,
  IUser,
  IUserProgramCertificate,
  ProgramCertificate,
  ProgramData,
} from "../../Interfaces";
import { Capacitor } from "@capacitor/core";
import { useFilePicker } from "use-file-picker";
import RemoteDataController from "../../controllers/RemoteDataController";
import AWSService from "../../controllers/AWSService";
import { FirebaseService } from "../../controllers/FirebaseService";
import { CERTIFICATE_ACCEPTED_FILE_TYPES, CERTIFICATE_MAX_FILE_SIZE } from "../../Constants";
import { DayPicker, getDefaultClassNames } from "react-day-picker";
import WeekDayRenderer from "./evidenceParts/WeekdayRenderer";

const defaultClassNames = getDefaultClassNames();
type Props = {
  visible: boolean;
  closeModal: (value: boolean) => void;
  certificate: ProgramCertificate | any;
  program: ProgramData | any;
  competence: CompetenceActivityClass | any;
};

const CertificateModal: React.FC<Props> = (props) => {
  const [openFileSelector, { filesContent, clear, plainFiles, errors }] = useFilePicker({
    accept: CERTIFICATE_ACCEPTED_FILE_TYPES,
    maxFileSize: CERTIFICATE_MAX_FILE_SIZE,
    readAs: "DataURL",
    multiple: false,
    limitFilesConfig: { max: 1 },
  });

  const [user, setUser] = useRecoilState<IUser | null>(userAtom);

  const [date, setDate] = useState<Date>(new Date());
  const [uploadedCertificate, setUploadedCertificate] = useState<any>(null);
  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [upload, setUpload] = useState<ICertificateInfo | null>(null);

  useEffect(() => {
    if (plainFiles.length > 0) {
      const _certificate: ICertificateInfo = {
        name: plainFiles[0].name,
        type: plainFiles[0].type,
        size: plainFiles[0].size,
        content: filesContent[0].content,
        lastModified: plainFiles[0].lastModified,
        certificateType: props.certificate.Name,
      };

      setUpload(_certificate);
      clear();
    }
  }, [props.certificate, clear, filesContent, plainFiles]);

  const resetModal = (): void => {
    setDate(new Date());
    setUploadedCertificate(null);
    setDatePickerVisible(false);
    setUploading(false);
    setUpload(null);
    props.closeModal(false);
  };

  const openDatePicker = (): void => {
    setDatePickerVisible(true);
  };

  const onDateChanged = (event: any): void => {
    setDate(new Date(event.detail.value));
  };

  const addCertificate = async (): Promise<void> => {
    try {
      if (user && upload) {
        setUploading(true);
        const accessParams: AWSAccessParams = await AWSService.generateSTSToken(user);

        let response = await AWSService.uploadCertificate(user, accessParams, upload);

        if (response && response.networkIssue) {
          response = await AWSService.uploadCertificateFromServer(user, accessParams, upload);
        }

        if (response) {
          const userToSave = _.cloneDeep(user);
          const _certificates = _.cloneDeep(userToSave.programCertificates) || [];

          const _certificate: IUserProgramCertificate = {
            certificate: {
              ...upload,
              content: undefined,
              url: response.Location,
            },
            issueDate: date.toISOString(),
            programData: {
              programName: props.program.Name,
              competenceName: props.competence.Name,
            },
          };

          const index = _certificates?.findIndex(
            (item) => item.certificate?.certificateType === _certificate.certificate?.certificateType
          );

          if (index > -1) {
            _certificates.splice(index, 1, _certificate);
          } else {
            _certificates.push(_certificate);
          }

          userToSave.programCertificates = _certificates;

          console.log(userToSave);

          await DatabaseService.updateUserDetails(userToSave, {
            programCertificates: userToSave.programCertificates,
          });

          FirebaseService.logEvent("program_certificate_uploaded", {
            program: props.program?.Name,
            certificate: props.certificate?.Name,
          });

          setUpload(null);
          setUser(userToSave);
          setUploadedCertificate(_certificate);
          setUploading(false);
          resetModal();
        }
      }
    } catch (error: any) {
      console.log(error);
      RemoteDataController.logError(error);
      setUploading(false);
    }
  };

  return (
    <IonModal
      id="certificateModal"
      isOpen={props.visible}
      onIonModalDidDismiss={() => resetModal()}
      backdropDismiss={true}
      canDismiss={true}
      className={"fullscreen"}
    >
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <HeaderTitle>{"Add certificate"}</HeaderTitle>
          <IonButtons slot="end">
            <IonButton mode="ios" className="cancelButton" onClick={() => resetModal()}>
              {"Cancel"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background whiteBackground">
        {props.certificate && (
          <div className="editRolesContent" style={{ paddingTop: 16 }}>
            <div className="programAddCertificateTitle">{`${props.certificate.Name} certificate`}</div>
            <div className="programAddCertificateDescription">
              {"Please upload a valid certificate that you hold to practise this enhanced skill."}
            </div>
            <div>
              <div className="programAddCertificate">{"Certificate"}</div>
              <div className="onboardingDateContainer" style={{ marginBottom: 12 }} onClick={() => openFileSelector()}>
                <div className="programAddCertificateName">
                  {uploadedCertificate?.certificate.name || upload?.name || "Tap to add certificate"}
                </div>
              </div>
            </div>
            <div>
              <div className="programAddCertificate">{"Issue date"}</div>
              <div
                id="certificateDatePicker"
                className="onboardingDateContainer"
                style={{ marginBottom: 12 }}
                onClick={() => openDatePicker()}
              >
                <div className="onboardingDateText">{format(date, "dd / MM / yyyy")}</div>
                <IonPopover
                  trigger="certificateDatePicker"
                  id="certificate-date-picker"
                  isOpen={datePickerVisible}
                  onDidDismiss={() => setDatePickerVisible(false)}
                >
                  <div className="pt-2 p-5">
                    <DayPicker
                      mode="single"
                      weekStartsOn={1}
                      selected={date}
                      defaultMonth={date}
                      onSelect={(data: any) => setDate(data)}
                      endMonth={new Date()}
                      disabled={{ after: new Date() }}
                      classNames={{
                        selected: `bg-cta-blue text-white rounded-[10px]`,
                        today: isToday(date) ? "text-white" : getDefaultClassNames().today,
                        month_caption: `${defaultClassNames.month_caption} text-[#595959] font-normal`,
                      }}
                      components={{
                        Weekday: (el) => <WeekDayRenderer element={el} />,
                      }}
                    />
                  </div>
                </IonPopover>
              </div>
            </div>
            <div className="programAddCertificateButtonContainer">
              <IonButton
                mode="ios"
                onClick={() => addCertificate()}
                disabled={!upload || uploading}
                className="programAddCertificateButton"
              >
                <div>{uploading ? <IonSpinner className="reflectionSaveSpinner" /> : <>{"Confirm"}</>}</div>
              </IonButton>
            </div>
          </div>
        )}
      </IonContent>
    </IonModal>
  );
};

export default CertificateModal;
