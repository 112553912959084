import React from "react";
import { IonApp, IonSpinner, setupIonicReact } from "@ionic/react";
import { ErrorBoundary } from "react-error-boundary";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Parafolio component library required CSS */
import "parafolio-components/dist/style.css";

/* Theme variables */
import "./theme/variables.css";

import "./App.css";
import { RecoilRoot } from "recoil";
import { Suspense } from "react";
import ParaFolio from "./ParaFolio";
import ErrorBoundaryFallback from "./pages/ErrorBoundaryFallback";
import { useFirebaseSetup } from "./hooks/app/useFirebaseSetup";
import { useNotificationSetup } from "./hooks/app/useNotificationSetup";
import { useGoogleVerification } from "./hooks/subscriptions/useGoogleVerification";
import { useAppLaunchSetup } from "./hooks/app/useAppLaunchSetup";

setupIonicReact({});

const App: React.FC = () => {
  useFirebaseSetup();
  useNotificationSetup();
  useGoogleVerification();
  useAppLaunchSetup();

  return (
    <RecoilRoot>
      <IonApp>
        <Suspense
          fallback={
            <div className="loadingScreen">
              <IonSpinner className="loadingScreenSpinner" />
            </div>
          }
        >
          <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
            <ParaFolio />
          </ErrorBoundary>
        </Suspense>
      </IonApp>
    </RecoilRoot>
  );
};

export default App;
