import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonToolbar } from "@ionic/react";
import { useCallback, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { appInfoAtom, deviceInfoAtom } from "../../state/State";
import { ILoginModal } from "../../Interfaces";
import { FirebaseService } from "../../controllers/FirebaseService";
import DataController from "../../controllers/DataController";
import { DeviceInfo } from "@capacitor/device";
import { AppInfo } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import HeaderTitle from "../common/HeaderTitle";
import { CLASS_APPS_URL } from "../../Constants";
import "./LoginModal.css";

const LearnMoreModal: React.FC<ILoginModal> = (props) => {
  const deviceInfo = useRecoilValue<DeviceInfo | null>(deviceInfoAtom);
  const appInfo = useRecoilValue<AppInfo | null>(appInfoAtom);

  const resetModal = (): void => {
    props.closeModal(false);
  };

  const contactSupport = useCallback(async (): Promise<void> => {
    await FirebaseService.logEvent("support_link_pressed", {
      link: "contact_support",
      type: "subscription_support",
    });

    const url = "mailto:apps@class.co.uk";
    const cc = "";
    const subject = `ParaFolio - ${props.page} support`;

    const body = DataController.supportEmailBody(null, deviceInfo, appInfo);

    FirebaseService.logEvent("support_link", { page: "login" });

    window.open(`${url}?subject=${subject}&body=${body}`, "_blank");
  }, [appInfo, deviceInfo, props.page]);

  const handleClick = useCallback(
    async (event: Event): Promise<void> => {
      const element = event.target as HTMLAnchorElement;
      event.preventDefault();
      event.stopImmediatePropagation();

      if (element.href && element.href?.includes("loginSupport")) {
        event.preventDefault();
        await FirebaseService.logEvent("support_link_pressed", {
          link: "contact_support",
          type: "app_support",
        });

        contactSupport();
      } else if (element.href && element.href?.includes("classWebsite")) {
        event.preventDefault();
        await FirebaseService.logEvent("support_link_pressed", {
          link: "class_website",
        });

        window.open(CLASS_APPS_URL, "_blank");
      }
    },
    [contactSupport]
  );

  const handleAuxClick = useCallback(
    async (event: MouseEvent): Promise<void> => {
      const element = event.target as HTMLAnchorElement;

      if (event.button === 1) {
        if (element.href?.includes("loginSupport")) {
          event.preventDefault();
          await FirebaseService.logEvent("support_link_pressed", {
            link: "contact_support",
            type: "app_info_feedback",
          });

          contactSupport();
        } else if (element.href && element.href?.includes("classWebsite")) {
          event.preventDefault();
          await FirebaseService.logEvent("support_link_pressed", {
            link: "class_website",
          });

          window.open(CLASS_APPS_URL, "_blank");
        }
      }
    },
    [contactSupport]
  );

  useEffect(() => {
    if (props.visible) {
      document.addEventListener("click", handleClick);
      document.addEventListener("auxclick", handleAuxClick);
    } else {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("auxclick", handleAuxClick);
    }
  }, [handleAuxClick, handleClick, props.visible]);

  return (
    <IonModal
      id="onboardingLoginModal"
      isOpen={props.visible}
      onDidDismiss={() => resetModal()}
      canDismiss={true}
      className="fullscreen"
    >
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonButton mode="ios" className="cancelButton" onClick={() => resetModal()}>
              {"Close"}
            </IonButton>
          </IonButtons>
          <HeaderTitle>{"ParaFolio"}</HeaderTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="onboardingLoginModalPage fullHeight">
        <div className="onboardingLoginContentNoPadding onboardingLearnMoreContent pl-safe-area-l pr-safe-area-r">
          <div className="onboardingLoginHeader">{"Your login details"}</div>
          <div className="onboardingLearnMoreText">
            {"ParaFolio is powerful when used alongside the other apps published by "}
            <a className="onboardingLoginLink" href="classWebsite" target="_blank" rel="noreferrer">
              {"Class Professional Publishing"}
            </a>
            {". These apps include JRCALC Plus, iCPG, Responder Plus and ParaPass."}
            <br />
            <br />
            {`If you use one or more of these apps, log into ParaFolio using the email and password you use for those apps. Using your login from one of these apps means your usage and activity appears inside the Timeline, where you can reflect on any activity listed.`}
            <br />
            <br />
            {`Users of JRCALC Plus, iCPG and Responder Plus will see their activity such as Clinical Notices, guidelines and medicines viewed.`}
            <br />
            <br />
            {`Users of ParaPass will see quizzes taken, quiz results, case studies read and Standby CPDs.`}
            <br />
            <br />
            {
              "Please note that users of ParaPass who have access through their trusts will get free full access to ParaFolio. If you would like to learn more, either speak to a member of your trust or "
            }
            <a href="loginSupport" target="_blank" rel="noreferrer">
              {"contact us"}
            </a>
            {" directly."}
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default LearnMoreModal;
