import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonModal,
  IonPopover,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  AWSAccessParams,
  AllProgressCheckStatuses,
  CPDActivityType,
  CompetenceActivityClass,
  EvidenceAttachment,
  EvidenceCustomTag,
  EvidencePart,
  EvidenceStandardTag,
  FieldType,
  IAppFeatures,
  IDateRange,
  IEvidence,
  IEvidenceModal,
  INewDeepLinkEvidence,
  INewGuidelineEvidence,
  INewTimelineEvidence,
  IUser,
  ProgramData,
  ProgramSkill,
  ProgressCheck,
  ProgressCheckApproval,
} from "../../Interfaces";
import { useRecoilStateLoadable, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { appFeaturesAtom, evidenceAtom, progressCheckDataAtom, userAtom } from "../../state/State";
import ContentContainer from "./ContentContainer";
import "./CommonComponents.css";
import EvidenceContentCard from "./EvidenceContentCard";
import {
  Button_Dropdown,
  Chevron_Next_Active,
  Chevron_Next_Inactive,
  Chevron_Prev_Active,
  Chevron_Prev_Inactive,
  Icon_Attachment_Delete,
} from "../../assets/images";
import { format, isAfter, isBefore, isSameDay, isToday, isValid } from "date-fns";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import _ from "lodash";
import { EventRegister } from "react-native-event-listeners";
import roles from "../../assets/json/roles.json";
import standards from "../../assets/json/standards.json";
import DataController from "../../controllers/DataController";
import { useFilePicker } from "use-file-picker";
import { DatabaseService } from "../../controllers/DatabaseService";
import AWSService from "../../controllers/AWSService";
import { FirebaseService } from "../../controllers/FirebaseService";
import { Capacitor } from "@capacitor/core";
import { Keyboard, KeyboardInfo } from "@capacitor/keyboard";
import EvidenceHeader from "./EvidenceHeader";
import * as EvidenceUtils from "../../utils/evidenceUtils";
import * as ProgramUtils from "../../utils/programUtils";
import SaveDraftModal from "../evidence/SaveDraftModal";
import EmptyTagModal from "../evidence/EmptyTagModal";
import ExistingTagModal from "../evidence/ExistingTagModal";
import * as EditorUtils from "../../utils/editorUtils";
import { FileUtils } from "../../utils/fileUtils";
import { ProgressCheckService } from "../../controllers/ProgressCheckService";
import DeleteEvidenceModal from "../evidence/DeleteEvidenceModal";
import AttachmentProgressIndicator from "../evidence/AttachmentProgressIndicator";
import UnsavedChangesModal from "../evidence/UnsavedChangesModal";
import { EVIDENCE_ACCEPTED_FILE_TYPES, EVIDENCE_MAX_FILE_SIZE } from "../../Constants";
import SaveEvidenceButton from "../evidence/SaveEvidenceButton";
import AuditTickbox from "../evidence/AuditTickbox";
import AttachmentInput from "../evidence/AttachmentInput";
import ProgramModuleEvidencePart from "../evidence/ProgramModuleEvidencePart";
import { StringUtils } from "../../utils/stringUtils";
import { useRefreshProgressCheckData } from "../../hooks/data/useRefreshProgressCheckData";
import { useProgramModuleStatus } from "../../hooks/programs/useProgramModuleStatus";
import DurationPicker from "./DurationPicker";
import CustomTagInput from "./CustomTagInput";
import EvidencePartWrapper from "./EvidencePartWrapper";
import AIDictationBanner from "../evidence/AIDictationBanner";
import { useEvidenceDictation } from "../../hooks/ai/useEvidenceDictation";
import AIFeedbackToast from "../evidence/AIFeedbackToast";
import AIDictationInfoModal from "../evidence/AIDictationInfoModal";
import { DayPicker, getDefaultClassNames } from "react-day-picker";
import WeekDayRenderer from "../programs/evidenceParts/WeekdayRenderer";
import PersonalEvidenceDisclaimer from "../evidence/PersonalEvidenceDisclaimer";
import Textarea from "@mui/joy/Textarea";

const defaultClassNames = getDefaultClassNames();

const EvidenceModal = ({ visible, closeModal }: IEvidenceModal) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openFileSelector, { filesContent, clear, plainFiles, errors }] = useFilePicker({
    accept: EVIDENCE_ACCEPTED_FILE_TYPES,
    maxFileSize: EVIDENCE_MAX_FILE_SIZE,
    readAs: "DataURL",
    multiple: true,
  });

  const { refreshProgressCheckData } = useRefreshProgressCheckData();
  const {
    state: dictationState,
    processingState,
    resetProcessingState,
    handleAIDictation,
    isAvailable: isAIDictationAvailable,
    processEvidenceGeneration,
    processedEvidence,
    errorMessage: dictationErrorMessage,
    transcriptHistory,
    resetDictation,
  } = useEvidenceDictation();

  const titleRef = useRef<HTMLInputElement>(null);
  const standardTagsRef = useRef<HTMLInputElement>(null);
  const activityDescriptionRef = useRef<HTMLTextAreaElement>(null);
  const reflectionsEditorRef = useRef<TinyMCEEditor | null>(null);
  const benefitsEditorRef = useRef<TinyMCEEditor | null>(null);
  const objectivesEditorRef = useRef<TinyMCEEditor | null>(null);
  const dictationBannerRef = useRef<HTMLDivElement>(null);

  const activityTypes: CPDActivityType[] = EvidenceUtils.getDefaultActivityTypes();

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const [_e, setEvidence] = useRecoilStateLoadable<IEvidence[] | null>(evidenceAtom);
  const allProgressCheckData = useRecoilValueLoadable<AllProgressCheckStatuses[]>(progressCheckDataAtom);

  const evidenceRef = useRef<IEvidence[]>(_e.contents ?? []);
  const allProgressCheckDataRef = useRef<AllProgressCheckStatuses[]>(allProgressCheckData.contents ?? []);

  const appFeatures = useRecoilValue<IAppFeatures[] | string[]>(appFeaturesAtom);

  const [initialEvidence, setInitialEvidence] = useState<IEvidence | null>(null);
  const [timelineReference, setTimelineReference] = useState<string>("");

  const [isNewEvidence, setIsNewEvidence] = useState<boolean>(true);
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(visible);

  // Personal evidence inputs
  const [title, setTitle] = useState<string>("");
  const [date, setDate] = useState<Date>(new Date());
  const [activity, setActivity] = useState<CPDActivityType | null>(null);
  const [numberOfHours, setNumberOfHours] = useState<string>("");
  const [customTags, setCustomTags] = useState<EvidenceCustomTag[]>([]);
  const [attachments, setAttachments] = useState<EvidenceAttachment[]>([]);
  const [activityDescription, setActivityDescription] = useState<string>("");
  const [reflections, setReflection] = useState<string>("");
  const [benefits, setBenefits] = useState<string>("");
  const [objectives, setObjectives] = useState<string>("");
  const [addToHCPCAudit, setAddToHCPCAudit] = useState<boolean>(false);
  const [dictationInput, setDictationInput] = useState<string>("");
  const [id, setId] = useState<string>("");
  // Activity Date range
  const [activityDateMode, setActivityDateMode] = useState<"single" | "range">("single");
  const [activitySingleDate, setActivitySingleDate] = useState<Date>(new Date());
  const [activityDateRange, setActivityDateRange] = useState<IDateRange | undefined>(undefined);

  // SWAST MIC inputs
  const [optionalStandards, setOptionalStandards] = useState<any[] | undefined>([]);
  const [optionalStandardsFiltered, setOptionalStandardsFiltered] = useState<any[] | undefined>([]);
  const [mandatoryStandards, setMandatoryStandards] = useState<any[] | undefined>([]);
  const [mandatoryStandardsFiltered, setMandatoryStandardsFiltered] = useState<any[] | undefined>([]);
  const [standardTags, setStandardTags] = useState<EvidenceStandardTag[]>([]);
  const [standardTag, setStandardTag] = useState<string>("");

  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);
  const [activityDatePickerVisible, setActivityDatePickerVisible] = useState<boolean>(false);
  const [showStandardTags, setShowStandardTags] = useState<boolean>(false);

  const [attachmentsToAdd, setAttachmentsToAdd] = useState<EvidenceAttachment[]>([]);
  const [attachmentsToDelete, setAttachmentsToDelete] = useState<EvidenceAttachment[]>([]);
  const [handlingAttachments, setHandlingAttachments] = useState<boolean>(false);
  const [viewingAttachment, setViewingAttachment] = useState<boolean>(false);
  const [viewingAttachmentFilename, setViewingAttachmentFilename] = useState<string>("");

  const [reflectionsFocused, setReflectionsFocused] = useState<boolean>(false);
  const [benefitsFocused, setBenefitsFocused] = useState<boolean>(false);
  const [objectivesFocused, setObjectivesFocused] = useState<boolean>(false);
  const [richTextBoxFocused, setRichTextBoxFocused] = useState<boolean>(false);

  const [saving, setSaving] = useState<boolean>(false);
  const [savingDraft, setSavingDraft] = useState<boolean>(false);

  const [canSaveDraft, setCanSaveDraft] = useState<boolean>(false);
  const [saveDraftModalVisible, setSaveDraftModalVisible] = useState<boolean>(false);
  const [deleteEvidenceModalVisible, setDeleteEvidenceModalVisible] = useState<boolean>(false);
  const [emptyTagModalVisible, setEmptyTagModalVisible] = useState<boolean>(false);
  const [existingTagModalVisible, setExistingTagModalVisible] = useState<boolean>(false);
  const [unsavedChangesModalVisible, setUnsavedChangesModalVisible] = useState<boolean>(false);
  const [dictationInfoModalVisible, setDictationInfoModalVisible] = useState<boolean>(false);

  const [keyboardActive, setKeyboardActive] = useState<boolean>(false);
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);

  // Program evidence
  const [program, setProgram] = useState<ProgramData | null>(null);
  const [competence, setCompetence] = useState<CompetenceActivityClass | null>(null);
  const [competences, setCompetences] = useState<CompetenceActivityClass[] | any[]>([]);
  const [enhancedSkill, setEnhancedSkill] = useState<ProgramSkill | null>(null);
  const [evidenceParts, setEvidenceParts] = useState<EvidencePart[] | any[]>([]);
  const [progressCheck, setProgressCheck] = useState<ProgressCheck | null>(null);
  const [progressChecks, setProgressChecks] = useState<ProgressCheck[] | any[]>([]);
  const [progressCheckCompetences, setProgressCheckCompetences] = useState<CompetenceActivityClass[] | any[]>([]);
  const [progressCheckCompetence, setProgressCheckCompetence] = useState<CompetenceActivityClass | null>(null);
  const [programMandatoryStandards, setProgramMandatoryStandards] = useState<CompetenceActivityClass[]>([]);
  const [programOptionalStandards, setProgramOptionalStandards] = useState<CompetenceActivityClass[]>([]);

  const [evidenceJSON, setEvidenceJSON] = useState<any>({});
  const [initialEvidenceJSON, setInitialEvidenceJSON] = useState<any>({});

  // Confirmation modal for changing enhanced skill
  const [changeTaskingTypeModalVisible, setChangeTaskingTypeModalVisible] = useState<boolean>(false);
  const [tempTaskingType, setTempTaskingType] = useState<string>("");

  // Confirmation modal for changing progress check type
  const [changeProgressCheckModal, setChangeProgressCheckModalVisible] = useState<boolean>(false);
  const [tempProgressCheck, setTempProgressCheck] = useState<string>("");

  // Confirmation modal for changing progress check competence
  const [changeProgressCheckCompetenceModal, setChangeProgressCheckCompetenceModalVisible] = useState<boolean>(false);
  const [tempProgressCheckCompetence, setTempProgressCheckCompetence] = useState<string>("");

  const [tempInput, setTempInput] = useState<string>("");

  // Mandatory field handling
  const errorBannerRef = useRef<HTMLDivElement>(null);
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);
  const mandatoryFieldRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [incompleteMandatoryFields, setIncompleteMandatoryFields] = useState<string[]>([]);
  const [activeMandatoryFieldIndex, setActiveMandatoryFieldIndex] = useState(0);
  const disableNextChevron = incompleteMandatoryFields.length === activeMandatoryFieldIndex + 1;
  const disablePrevChevron = activeMandatoryFieldIndex === 0;

  const { fastTrackActive, pauseAndHold } = useProgramModuleStatus(program?.ID);

  useEffect(() => {
    evidenceRef.current = _e.contents;
  }, [_e.contents]);

  useEffect(() => {
    allProgressCheckDataRef.current = allProgressCheckData.contents;
  }, [allProgressCheckData.contents]);

  useEffect(() => {
    if (Capacitor.getPlatform() === "ios") {
      Keyboard.addListener("keyboardWillShow", (info: KeyboardInfo) => {
        setKeyboardActive(true);
        setKeyboardHeight(info.keyboardHeight);
      });

      Keyboard.addListener("keyboardWillHide", () => {
        setKeyboardActive(false);
      });
    }
  }, []);

  useEffect(() => {
    if (progressCheck) {
      getProgressCheckCompetences(progressCheck);
    }
  }, [progressCheck]);

  useEffect(() => {
    if (plainFiles.length > 0) {
      const result = EvidenceUtils.addAttachmentToUploadArray(plainFiles, filesContent, attachments, attachmentsToAdd);

      setAttachments(result.attachments);
      setAttachmentsToAdd(_.uniqBy(result.toAdd, "name"));
      clear();
    }
  }, [attachments, attachmentsToAdd, attachmentsToDelete, clear, filesContent, plainFiles]);

  useEffect(() => {
    const checkAttachments = () => {
      if (attachments.length > 0) {
        const toAdd = _.cloneDeep(attachmentsToAdd);

        for (let i = 0; i < attachments.length; i++) {
          const attachment = attachments[i];
          const includeAdd = toAdd.findIndex((item) => item.name === attachment.name);
          const includeDelete = attachmentsToDelete.findIndex((item) => item.name === attachment.name);

          if (includeAdd === -1 && includeDelete === -1 && attachment.content) {
            toAdd.push(attachment);
          }
        }

        setAttachmentsToAdd(_.uniqBy(toAdd, "name"));
      }
    };

    const checkAttachmentsNeedUploading = () => {
      let _attachments = _.cloneDeep(attachments);
      _attachments = _attachments.filter((item) => item.content);
      return _attachments.length > 0;
    };

    if (attachmentsToAdd.length === 0 && checkAttachmentsNeedUploading()) {
      checkAttachments();
    }
  }, [attachments, attachmentsToAdd, attachmentsToDelete]);

  useEffect(() => {
    const checkAttachments = () => {
      if (attachments.length > 0) {
        const toAdd = _.cloneDeep(attachmentsToAdd);

        for (let i = 0; i < attachments.length; i++) {
          const attachment = attachments[i];
          const includeAdd = toAdd.findIndex((item) => item.name === attachment.name);
          const includeDelete = attachmentsToDelete.findIndex((item) => item.name === attachment.name);

          if (includeAdd === -1 && includeDelete === -1 && attachment.content) {
            toAdd.push(attachment);
          }
        }

        setAttachmentsToAdd(_.uniqBy(toAdd, "name"));
      }
    };

    const checkAttachmentsNeedUploading = () => {
      let _attachments = _.cloneDeep(attachments);
      _attachments = _attachments.filter((item) => item.content);
      return _attachments.length > 0;
    };

    if (attachmentsToAdd.length === 0 && checkAttachmentsNeedUploading()) {
      checkAttachments();
    }
  }, [attachments, attachmentsToAdd, attachmentsToDelete]);

  useEffect(() => {
    const handleClick = (event: Event): void => {
      const element: any = document.getElementById("reflectionsStandardTags");
      const target = event.target as HTMLInputElement;
      const classList = event.target && target.classList;

      // if (!classList?.contains('standardTagButton')) {
      if (!element?.contains(event.target) && !classList?.contains("standardTagButton")) {
        setShowStandardTags(false);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const getProgressCheckCompetences = useCallback(
    (_progressCheck: ProgressCheck): void => {
      if (program) {
        let _competences = _.cloneDeep(_progressCheck["Competence/Activity"]);
        _competences = ProgramUtils.removeProgressCheckCompetences(
          _competences,
          _progressCheck,
          evidenceRef.current,
          program.ID,
          progressCheckCompetence
        );

        setProgressCheckCompetences(_competences);
      }
    },
    [program?.ID]
  );

  const getIncompleteProgressChecks = useCallback(
    (_progressChecks: ProgressCheck[], _program: ProgramData, _progressCheck?: ProgressCheck | null): void => {
      let array = _.cloneDeep(_progressChecks);
      array = ProgramUtils.removeProgressChecks(
        array,
        evidenceRef.current,
        _program,
        allProgressCheckDataRef.current,
        fastTrackActive,
        pauseAndHold,
        _progressCheck
      );

      setProgressChecks(array);
    },
    [fastTrackActive, pauseAndHold]
  );

  useEffect(() => {
    EventRegister.addEventListener("evidence-modal/add-evidence-guideline", (object: INewGuidelineEvidence) => {
      setTitle(object.title || "");
    });

    EventRegister.addEventListener("evidence-modal/add-evidence-timeline", (object: INewTimelineEvidence) => {
      setTitle(object.title);
      setDate(object.date);
      setActivitySingleDate(object.activityDate);
      setActivityDateMode("single");
      setActivityDescription(object.activityDescription);
      setTimelineReference(object.timelineReference);
    });

    EventRegister.addEventListener("evidence-modal/add-evidence-deeplink", (object: INewDeepLinkEvidence) => {
      setTitle(object.title ?? "");
      setDate(object.date ? new Date(object.date) : new Date());
      setActivitySingleDate(object.activityDate ? new Date(object.activityDate) : new Date());
      setActivityDateMode("single");
      setActivityDescription(object.activityDescription ?? "");
      setTimelineReference(object.timelineReference ?? "");
      setNumberOfHours(object.activityDuration ? DataController.convertToHoursMinutes(object.activityDuration) : "");
      setActivity(object.activityType ? { type: object.activityType } : null);
      setReflection(object.reflection ?? "");
      setBenefits(object.benefits ?? "");
      setObjectives(object.learningObjectives ?? "");
    });

    EventRegister.addEventListener("timeline/edit-evidence-clicked", (object: IEvidence) => {
      setTitle(object.title || "");
      setDate(new Date(object.date));
      setActivity(!_.isEmpty(object.activity) ? object.activity : null);
      setNumberOfHours((object.numberOfHours && DataController.convertToHoursMinutes(object.numberOfHours)) || "");
      object.standardTags && setStandardTags(object.standardTags);
      object.customTags && setCustomTags(object.customTags);
      object.attachments && setAttachments(object.attachments);
      setActivityDescription(object.activityDescription || "");
      setReflection(object.reflections || "");
      setBenefits(object.benefits || "");
      setObjectives(object.objectives || "");
      setDictationInput(object.dictationInput ?? "");
      setId(object.id);
      object.addToHCPCAudit !== undefined && setAddToHCPCAudit(object.addToHCPCAudit);
      object.timelineReference && setTimelineReference(object.timelineReference);
      setIsDraft(object.draft === true);
      if (object.activityDate && object.activityDateEnd) {
        setActivityDateRange({ from: new Date(object.activityDate), to: new Date(object.activityDateEnd) });
        setActivityDateMode("range");
      } else if (object.activityDate) {
        setActivitySingleDate(new Date(object.activityDate));
        setActivityDateMode("single");
      }

      setInitialEvidence(object);
      setIsNewEvidence(false);
    });

    EventRegister.addEventListener("evidence/evidence-card-clicked", (object: IEvidence) => {
      setTitle(object.title || "");
      setDate(new Date(object.date));
      setActivity(!_.isEmpty(object.activity) ? object.activity : null);
      setNumberOfHours((object.numberOfHours && DataController.convertToHoursMinutes(object.numberOfHours)) || "");
      object.standardTags && setStandardTags(object.standardTags);
      object.customTags && setCustomTags(object.customTags);
      object.attachments && setAttachments(object.attachments);
      setActivityDescription(object.activityDescription || "");
      setReflection(object.reflections || "");
      setBenefits(object.benefits || "");
      setObjectives(object.objectives || "");
      setDictationInput(object.dictationInput ?? "");
      setId(object.id);
      object.addToHCPCAudit !== undefined && setAddToHCPCAudit(object.addToHCPCAudit);
      object.timelineReference && setTimelineReference(object.timelineReference);
      if (object.activityDate && object.activityDateEnd) {
        setActivityDateRange({ from: new Date(object.activityDate), to: new Date(object.activityDateEnd) });
        setActivityDateMode("range");
      } else if (object.activityDate) {
        setActivitySingleDate(new Date(object.activityDate));
        setActivityDateMode("single");
      }

      setInitialEvidence(object);
      setIsDraft(object.draft === true);
      setIsNewEvidence(false);
    });

    EventRegister.addEventListener("evidence/add-program-evidence", (data) => {
      data.program && setProgram(data.program);
      data.skill && setEnhancedSkill(data.skill);
      data.competences && setCompetences(data.competences);
      data.progressCompetences && setProgressCheckCompetences(data.progressCompetences);

      if (data.evidenceJSON) {
        setDate(DataController.getDefaultDateFromProgramEvidence(data.evidenceJSON));
        setEvidenceJSON(data.evidenceJSON);
        setInitialEvidenceJSON(data.evidenceJSON);
        setIsNewEvidence(false);
        setIsDraft(data?.evidence?.draft === true);
        setNumberOfHours(
          (data?.evidence?.numberOfHours && DataController.convertToHoursMinutes(data?.evidence?.numberOfHours)) || ""
        );
        data?.evidence?.addToHCPCAudit !== undefined && setAddToHCPCAudit(data.evidence.addToHCPCAudit);
        setId(data.id);
      }

      if (data.competence) {
        setCompetence(data.competence);
        getEvidenceParts(data.competence);
      }

      if (data.progressCheck && data.progressCheck["Competence/Activity"]) {
        setProgressCheck(data.progressCheck);
        getProgressCheckCompetences(data.progressCheck);
      }

      if (ProgramUtils.isCSM(data.program.ID) && data.progressChecks) {
        setProgressChecks(data.progressChecks);
      } else if (data.progressChecks) {
        getIncompleteProgressChecks(data.progressChecks, data.program, data.progressCheck);
      }

      if (data.progressCompetence) {
        setProgressCheckCompetence(data.progressCompetence);
        getEvidenceParts(data.progressCompetence);
      }

      if (data.mandatoryStandards) {
        setProgramMandatoryStandards(data.mandatoryStandards);
      }

      if (data.optionalStandards) {
        setProgramOptionalStandards(data.optionalStandards);
      }

      if (data.evidenceDefinitions) {
        setEvidenceParts(data.evidenceDefinitions?.["Evidence parts"]);
      }

      if (data.selectedStandard) {
        const newEvidenceObject = EvidenceUtils.addSelectedStandard(data.evidenceJSON ?? {}, data.selectedStandard);
        setEvidenceJSON(newEvidenceObject);
      }
    });

    EventRegister.addEventListener("evidence/add-progress-check-evidence", (data) => {
      data.program && setProgram(data.program);
      data.progressCheck && setProgressCheck(data.progressCheck);
      data.progressChecks && setProgressChecks(data.progressChecks);
      data.progressCompetences && setProgressCheckCompetences(data.progressCompetences);

      if (data.progressChecks && !ProgramUtils.isCSM(data.program.ID)) {
        getIncompleteProgressChecks(data.progressChecks, data.program, data.progressCheck);
      }

      if (data.progressCompetence) {
        setProgressCheckCompetence(data.progressCompetence);
        getEvidenceParts(data.progressCompetence);
        getProgressCheckCompetences(data.progressCheck);
      } else if (data.progressCheck && data.progressCheck["Competence/Activity"]) {
        getProgressCheckCompetences(data.progressCheck);
      }
    });

    return () => {
      EventRegister.removeEventListener("evidence-modal/add-evidence-guideline");
      EventRegister.removeEventListener("evidence-modal/add-evidence-timeline");
      EventRegister.removeEventListener("evidence-modal/add-evidence-deeplink");
      EventRegister.removeEventListener("evidence/evidence-card-clicked");
      EventRegister.removeEventListener("evidence/add-program-evidence");
      EventRegister.removeEventListener("evidence/add-progress-check-evidence");
      EventRegister.removeEventListener("timeline/edit-evidence-clicked");
    };
  }, []);

  useEffect(() => {
    EventRegister.addEventListener("evidence/add-skill-evidence", (data) => {
      data.program && setProgram(data.program);
      data.skill && setEnhancedSkill(data.skill);
      data.competences && setCompetences(data.competences);

      if (data.competence) {
        setCompetence(data.competence);
        getEvidenceParts(data.competence);

        const newEvidenceObject = EvidenceUtils.addRequiredSkill({}, data.skill, data.competence);
        setEvidenceJSON(newEvidenceObject);
      }
    });

    return () => {
      EventRegister.removeEventListener("evidence/add-skill-evidence");
    };
  }, []);

  useEffect(() => {
    setModalVisible(visible);
  }, [visible]);

  useEffect(() => {
    if (processingState === "processed" && processedEvidence) {
      console.log('[useEffect] "processed"', processedEvidence);
      processedEvidence.title && setTitle(processedEvidence.title);
      processedEvidence.date && setDate(new Date(processedEvidence.date));
      processedEvidence.activity && setActivity(processedEvidence.activity);
      processedEvidence.numberOfHours &&
        setNumberOfHours(DataController.convertToHoursMinutes(processedEvidence.numberOfHours));

      processedEvidence.activityDescription && setActivityDescription(processedEvidence.activityDescription);
      processedEvidence.reflections && setReflection(processedEvidence.reflections);
      processedEvidence.benefits && setBenefits(processedEvidence.benefits);
      processedEvidence.objectives && setObjectives(processedEvidence.objectives);
      processedEvidence.addToHCPCAudit != null && setAddToHCPCAudit(processedEvidence.addToHCPCAudit);

      if (processedEvidence.activityDate && processedEvidence.activityDateEnd) {
        setActivityDateRange({
          from: new Date(processedEvidence.activityDate),
          to: new Date(processedEvidence.activityDateEnd),
        });
        setActivityDateMode("range");
      } else if (processedEvidence.activityDate) {
        setActivitySingleDate(new Date(processedEvidence.activityDate));
        setActivityDateMode("single");
      }

      if (processedEvidence.customTags) {
        const array = _.uniqBy([...processedEvidence.customTags, ...customTags], "tag");
        setCustomTags(array);
      }

      if (dictationInput.length > 0) {
        setDictationInput((prev) => `${prev};${transcriptHistory.join(";")}`);
      } else {
        setDictationInput(transcriptHistory.join(";"));
      }

      EventRegister.emit("evidence/show-ai-feedback");
      isNewEvidence && setCanSaveDraft(true);
      resetProcessingState();
    }
  }, [dictationState, processedEvidence, transcriptHistory]);

  useEffect(() => {
    const getStandardTagsForRole = (): void => {
      if (user.state === "hasValue" && user.contents?.role) {
        const result = EvidenceUtils.getStandardTagsForRole(roles, user.contents.role, standards, initialEvidence);

        setOptionalStandards(_.orderBy(result.optionalRoles, ["Code"], ["asc"]) || []);
        setOptionalStandardsFiltered(_.orderBy(result.filteredOptional, ["Code"], ["asc"]) || []);
        setMandatoryStandards(_.orderBy(result.mandatoryRoles, ["Code"], ["asc"]) || []);
        setMandatoryStandardsFiltered(_.orderBy(result.filteredMandatory, ["Code"], ["asc"]) || []);
      }
    };

    getStandardTagsForRole();
  }, [initialEvidence, user, visible]);

  const deleteEvidence = async (): Promise<void> => {
    if (user.state === "hasValue" && user.contents) {
      try {
        if (id) {
          if (attachments.length > 0) {
            const deleted = await deleteAttachments(attachments, id);
          }

          let deleteFromDB = await DatabaseService.deleteEvidence(user.contents, id);

          if (deleteFromDB) {
            let allEvidence = [];
            allEvidence = await DatabaseService.getEvidence(user.contents);
            await DataController.saveEvidence(allEvidence);

            setEvidence(allEvidence);

            EventRegister.emit("evidence/evidence-deleted");
            dismissModal();
          }
        }
      } catch (error) {
        window.alert("An error occurred when deleting evidence");
      }
    }
  };

  const scrollToMandatoryField = (index: number, incompleteFields: string[]) => {
    // scroll to div
    const key = incompleteFields[index];
    const mandatoryField = mandatoryFieldRefs.current[key];
    if (mandatoryField) {
      // TODO: Add height of AI banner to calculation
      mandatoryField.style.scrollMargin =
        16 + (errorBannerRef.current?.clientHeight || 0) + (dictationBannerRef.current?.clientHeight || 0) + "px";
      mandatoryField.scrollIntoView({ behavior: "smooth" });
    }

    // animate div border
    const firstDivChild = mandatoryField?.querySelector("div:first-child");
    firstDivChild?.classList.add("border-pulse-animation");
    setTimeout(() => {
      firstDivChild?.classList.remove("border-pulse-animation");
    }, 1500); // Duration of the animation
  };

  const uploadAttachments = async (_attachments: EvidenceAttachment[], id: string): Promise<EvidenceAttachment[]> => {
    const toUpload = _.cloneDeep(_attachments);
    const accessParams: AWSAccessParams = await AWSService.generateSTSToken(user.contents);

    const uploaded = [];

    for (let i = 0; i < toUpload.length; i++) {
      let file = await AWSService.uploadAttachment(user.contents, accessParams, id, toUpload[i]);

      if (file && file.networkIssue) {
        file = await AWSService.uploadAttachmentFromServer(user.contents, accessParams, id, toUpload[i]);
      }

      const updatedFile: EvidenceAttachment = {
        ...toUpload[i],
        content: undefined,
        url: file.Location,
      };

      delete updatedFile.content;

      uploaded.push(updatedFile);
    }

    return uploaded;
  };

  const deleteAttachments = async (_attachments: EvidenceAttachment[], id: string): Promise<string[]> => {
    const toDelete = _.cloneDeep(_attachments);
    const accessParams: AWSAccessParams = await AWSService.generateSTSToken(user.contents);

    const deleted = [];

    for (let i = 0; i < toDelete.length; i++) {
      const fileDeleted = await AWSService.deleteAttachment(user.contents, accessParams, id, toDelete[i]);

      if (fileDeleted) {
        deleted.push(toDelete[i].name);
      }
    }

    return deleted;
  };

  const saveEvidence = async (isDraftEvidence?: boolean): Promise<void> => {
    try {
      isDraftEvidence ? setSavingDraft(true) : setSaving(true);

      if (user.state === "hasValue" && user.contents) {
        if (program) {
          let evidence: IEvidence = {
            date: date.toISOString(),
            ...(addToHCPCAudit !== undefined && { addToHCPCAudit }),
            ...(numberOfHours !== "" && {
              numberOfHours: DataController.convertToNumberOfHours(numberOfHours),
            }),
            evidenceJSON: {
              ...evidenceJSON,
              programInfo: {
                program: program.Name,
                programID: program.ID,
                competence: competence?.Name,
                competenceID: competence?.ID,
                progressCheck: progressCheck?.Name,
                progressCheckID: progressCheck?.ID,
                progressCheckCompetence: progressCheckCompetence?.Name,
                progressCheckCompetenceID: progressCheckCompetence?.ID,
              },
            },
            id: id || DataController.generateUniqueID(),
            draft: isDraftEvidence ?? false,
          };

          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          evidence.evidenceJSON = EvidenceUtils.deleteNonRequiredEvidencePart(evidence.evidenceJSON, evidenceParts);

          if (progressCheckCompetence && !EvidenceUtils.checkEvidenceJSONHasTitle(evidence.evidenceJSON)) {
            evidence.evidenceJSON = EvidenceUtils.addTitleToProgressCheck(
              evidence.evidenceJSON,
              progressCheckCompetence
            );
          } else if (isDraftEvidence && !EvidenceUtils.checkEvidenceJSONHasTitle(evidence.evidenceJSON)) {
            const draftsLength = EvidenceUtils.getDraftsCount(_e.contents);

            evidence.evidenceJSON.Title = `My Draft #${draftsLength ? draftsLength + 1 : "1"}`;
          }

          try {
            // Find if there are any Evidence Parts that can hold attachments
            const attachmentPartKey = ProgramUtils.getEvidenceAttachmentsKey(evidenceParts);

            if (attachmentPartKey) {
              // Check if user has added attachments
              const hasAttachments = typeof evidence.evidenceJSON[attachmentPartKey] !== "undefined";

              if (hasAttachments) {
                // There are attachments present so these can be processed

                if (evidence.evidenceJSON[attachmentPartKey].attachmentsToAdd?.length > 0) {
                  setHandlingAttachments(true);
                  let _attachments = _.clone(evidence.evidenceJSON[attachmentPartKey].attachments) || [];

                  const uploaded = await uploadAttachments(
                    evidence.evidenceJSON[attachmentPartKey].attachmentsToAdd,
                    evidence.id
                  );

                  for (let i = 0; i < uploaded.length; i++) {
                    let index = _attachments?.findIndex((item: EvidenceAttachment) => item.name === uploaded[i].name);

                    if (index > -1) {
                      _attachments[index] = uploaded[i];
                    } else {
                      _attachments.push(uploaded[i]);
                    }
                  }

                  evidence.evidenceJSON[attachmentPartKey].attachments = _attachments;
                  delete evidence.evidenceJSON[attachmentPartKey].attachmentsToAdd;
                } else {
                  delete evidence.evidenceJSON[attachmentPartKey].attachmentsToAdd;
                }

                if (evidence.evidenceJSON[attachmentPartKey].attachmentsToDelete?.length > 0) {
                  setHandlingAttachments(true);
                  let _attachments = _.clone(evidence.evidenceJSON[attachmentPartKey].attachments) || [];

                  const deleted = await deleteAttachments(
                    evidence.evidenceJSON[attachmentPartKey].attachmentsToDelete,
                    evidence.id
                  );

                  for (let i = 0; i < deleted.length; i++) {
                    let index = _attachments?.findIndex((item: EvidenceAttachment) => item.name === deleted[i]);

                    if (typeof index === "number" && index > -1) {
                      _attachments?.splice(index, 1);
                    }
                  }

                  evidence.evidenceJSON[attachmentPartKey].attachments = _attachments;
                  delete evidence.evidenceJSON[attachmentPartKey].attachmentsToDelete;
                } else {
                  delete evidence.evidenceJSON[attachmentPartKey].attachmentsToDelete;
                }
              }
            }
          } catch (error) {
            console.log(error);
          }

          setHandlingAttachments(false);

          try {
            await FirebaseService.logEvent("program_evidence_added", {
              program: program?.Name,
              competence: competence?.Name,
              includedInAudit: addToHCPCAudit,
              draft: isDraftEvidence ?? false,
            });
          } catch (error) {
            console.log(error);
          }

          // TODO: Add automatic LOs for NQP2 competences

          if (progressCheckCompetence?.["LOs Automatic"]) {
            const selectedOutcomes = progressCheckCompetence["Learning Objective"]?.map((item) => ({
              id: item.ID,
              name: item.Name,
            }));

            evidence.evidenceJSON = {
              LearningObjectivesTickboxes: {
                selectedOutcomes,
              },
              ...evidence.evidenceJSON,
            };
          }

          try {
            let addToDB = await DatabaseService.addProgramEvidence(user.contents, evidence);

            if (!addToDB) {
              addToDB = await DatabaseService.updateProgramEvidence(user.contents, evidence);
            }

            if (addToDB) {
              let allEvidence = [];
              allEvidence = await DatabaseService.getEvidence(user.contents);
              await DataController.saveEvidence(allEvidence);

              if (evidence.evidenceJSON.programInfo.progressCheckID) {
                const progressCheckStatus = ProgramUtils.getProgressCheckStatusFromEvidence(
                  evidence.evidenceJSON.programInfo.progressCheckID,
                  allProgressCheckData.contents,
                  program
                );

                if (!isDraftEvidence) {
                  if (!progressCheckStatus) {
                    // add new progress check

                    let progressCheckCreated;

                    if (
                      ProgramUtils.autoCompleteProgressCheck(
                        evidence.evidenceJSON.programInfo.progressCheckCompetenceID
                      )
                    ) {
                      progressCheckCreated = await ProgressCheckService.completeProgressCheck(
                        user.contents,
                        program.ID,
                        evidence.evidenceJSON.programInfo.progressCheckID
                      );
                    } else {
                      if (EvidenceUtils.evidencePartTriggerFound(evidenceParts)) {
                        const operation = EvidenceUtils.evidencePartTriggerUsed(evidenceParts, evidenceJSON)
                          ? "Completed"
                          : "OnHoldReset";
                        await ProgressCheckService.createProgressCheck(
                          user.contents,
                          program.ID,
                          evidence.evidenceJSON.programInfo.progressCheckID,
                          operation
                        );
                      } else {
                        progressCheckCreated = await ProgressCheckService.createProgressCheck(
                          user.contents,
                          program.ID,
                          evidence.evidenceJSON.programInfo.progressCheckID,
                          "In-Progress"
                        );
                      }
                    }

                    progressCheckCreated && (await refreshProgressCheckData(user.contents));
                  } else {
                    // update existing progress check

                    // Do special check for CSM because we want to avoid email spam from server (informing users of complete progress checks).
                    // CSM is the only module that can receive evidence after the review is done
                    if (!ProgramUtils.isCSM(program.ID) || progressCheckStatus.submissions.status !== "Completed") {
                      if (ProgramUtils.autoCompleteProgressCheckDependsOnTrigger(program.ID)) {
                        if (EvidenceUtils.evidencePartTriggerFound(evidenceParts) && !isDraftEvidence) {
                          const operation = EvidenceUtils.evidencePartTriggerUsed(evidenceParts, evidenceJSON)
                            ? "Completed"
                            : "OnHoldReset";
                          await ProgressCheckService.createProgressCheck(
                            user.contents,
                            program.ID,
                            evidence.evidenceJSON.programInfo.progressCheckID,
                            operation
                          );
                        } else {
                          let operation = progressCheckStatus.submissions.status;
                          if (!isDraftEvidence && operation === "OnHoldReset") {
                            operation = "In-Progress";
                          }
                          await ProgressCheckService.createProgressCheck(
                            user.contents,
                            program.ID,
                            evidence.evidenceJSON.programInfo.progressCheckID,
                            operation
                          );
                        }
                        await refreshProgressCheckData(user.contents);
                      } else if (progressCheck?.Approval === ProgressCheckApproval.NotRequired) {
                        const evidenceForProgressCheck = ProgramUtils.getEvidenceForProgressCheck(
                          allEvidence,
                          evidence.evidenceJSON.programInfo.progressCheckID
                        );

                        if (
                          ProgramUtils.isAllEvidenceAddedForProgressCheck(
                            evidenceForProgressCheck,
                            progressCheck["Competence/Activity"]
                          )
                        ) {
                          const progressCheckCompleted = await ProgressCheckService.completeProgressCheck(
                            user.contents,
                            program.ID,
                            evidence.evidenceJSON.programInfo.progressCheckID
                          );

                          progressCheckCompleted && (await refreshProgressCheckData(user.contents));
                        }
                      }
                    }
                  }
                }
              }

              if (isDraftEvidence) {
                setId(evidence.id);
                setSavingDraft(false);
                setIsDraft(true);
                setIsNewEvidence(false);
                setEvidence(allEvidence);
                EventRegister.emit("evidence/draft-saved");
              } else {
                setSaving(false);
                setEvidence(allEvidence);
                dismissModal();
              }
            } else {
              if (isDraftEvidence) {
                setSavingDraft(false);
              } else {
                setSaving(false);
              }

              window.alert("An error occurred when saving evidence");
            }
          } catch (error) {
            if (isDraftEvidence) {
              setSavingDraft(false);
            } else {
              setSaving(false);
            }
            console.log(error);
            window.alert("An error occurred when saving evidence");
          }
        } else {
          let evidence: IEvidence = {
            title: title.replace(/'/gim, "''"),
            date: date.toISOString(),
            ...(activity && { activity }),
            ...(numberOfHours !== "" && {
              numberOfHours: DataController.convertToNumberOfHours(numberOfHours),
            }),
            ...(standardTags.length > 0 && { standardTags }),
            ...(customTags.length > 0 && {
              customTags: customTags.map((item) => ({
                tag: item.tag.replace(/'/gim, "''"),
              })),
            }),
            ...(attachments.length > 0 && { attachments }),
            ...(activityDescription !== "" && {
              activityDescription: activityDescription.replace(/'/gim, "''"),
            }),
            benefits: benefits.replace(/'/gim, "''"),
            reflections: reflections.replace(/'/gim, "''"),
            ...(objectives !== "" && {
              objectives: objectives.replace(/'/gim, "''"),
            }),
            ...(addToHCPCAudit !== undefined && { addToHCPCAudit }),
            ...(timelineReference !== "" && { timelineReference }),
            id: id || DataController.generateUniqueID(),
            draft: isDraftEvidence ?? false,
            ...(dictationInput.length > 0 && { dictationInput }),
          };

          if (activityDateMode === "single") {
            evidence.activityDate = activitySingleDate.toISOString();
          } else if (activityDateMode === "range") {
            if (activityDateRange?.from) {
              evidence.activityDate = activityDateRange.from.toISOString();
            }

            if (activityDateRange?.to) {
              evidence.activityDateEnd = activityDateRange.to.toISOString();
            }
          }

          if (isDraftEvidence && EvidenceUtils.isEvidenceTitleBlank(evidence.title)) {
            const draftsLength = EvidenceUtils.getDraftsCount(_e.contents);

            evidence.title = `My Draft #${draftsLength ? draftsLength + 1 : "1"}`;
            setTitle(evidence.title);
          }

          try {
            if (attachmentsToAdd.length > 0) {
              let _attachments = evidence.attachments && _.clone(evidence.attachments);
              setHandlingAttachments(true);

              const uploaded = await uploadAttachments(attachmentsToAdd, evidence.id);

              for (let i = 0; i < uploaded.length; i++) {
                let index = _attachments?.findIndex((item: EvidenceAttachment) => item.name === uploaded[i].name);

                if (_attachments && typeof index === "number" && index > -1) {
                  _attachments[index] = uploaded[i];
                }
              }

              evidence = {
                ...evidence,
                attachments: _attachments,
              };
            }
          } catch (error) {
            console.log(error);
          }

          try {
            if (attachmentsToDelete.length > 0) {
              let _attachments = evidence.attachments && _.clone(evidence.attachments);
              setHandlingAttachments(true);

              const deleted = await deleteAttachments(attachmentsToDelete, evidence.id);

              for (let i = 0; i < deleted.length; i++) {
                let index = _attachments?.findIndex((item: EvidenceAttachment) => item.name === deleted[i]);

                if (typeof index === "number" && index > -1) {
                  _attachments = _attachments?.splice(index, 1);
                }
              }

              evidence = {
                ...evidence,
                attachments: _attachments,
              };
            }
          } catch (error) {
            console.log(error);
          }

          try {
            await FirebaseService.logEvent("evidence_added", {
              activity: activity?.type,
              ...(evidence.attachments && {
                has_attachments: true,
                attachments: evidence.attachments.length,
              }),
              ...(evidence.standardTags &&
                evidence.standardTags.length > 0 && {
                  standards: JSON.stringify(evidence.standardTags.map((item) => item.tag)),
                }),
              ...(evidence.customTags &&
                evidence.customTags.length > 0 && {
                  tags: JSON.stringify(evidence.customTags.map((item) => item.tag)),
                }),
              tags_added: evidence.customTags && evidence.customTags?.length > 0,
              hasReflection: reflections.length > 0,
              hasBenefits: benefits.length > 0,
              hasLearningObjectives: objectives.length > 0,
              includedInAudit: addToHCPCAudit,
              userTrust: user.contents?.userData.CFRTrust,
              timelineReference: evidence.timelineReference,
              draft: isDraftEvidence ?? false,
            });
          } catch (error) {
            console.log(error);
          }

          setHandlingAttachments(false);

          try {
            let addToDB = await DatabaseService.addEvidence(user.contents, evidence);

            if (!addToDB) {
              addToDB = await DatabaseService.updateEvidence(user.contents, evidence);
            }

            if (addToDB) {
              let allEvidence = [];
              allEvidence = await DatabaseService.getEvidence(user.contents);
              await DataController.saveEvidence(allEvidence);
              resetDictation();

              if (isDraftEvidence) {
                setId(evidence.id);
                setSavingDraft(false);
                setIsDraft(true);
                setIsNewEvidence(false);
                setEvidence(allEvidence);
                EventRegister.emit("evidence/draft-saved");
              } else {
                setSaving(false);
                setEvidence(allEvidence);
                dismissModal();
              }
            } else {
              if (isDraftEvidence) {
                setSavingDraft(false);
              } else {
                setSaving(false);
              }
              window.alert("An error occurred when saving evidence");
            }
          } catch (error) {
            if (isDraftEvidence) {
              setSavingDraft(false);
            } else {
              setSaving(false);
            }
            window.alert("An error occurred when saving evidence");
          }
        }
      }
    } catch (error) {
      if (isDraftEvidence) {
        setSavingDraft(false);
      } else {
        setSaving(false);
      }
      console.log(error);
      window.alert("An error occurred when saving evidence");
    }
  };

  const deleteClicked = async (): Promise<void> => {
    setDeleteEvidenceModalVisible(true);
  };

  const saveDraftClicked = async (): Promise<void> => {
    try {
      await saveEvidence(true);
    } catch (error) {
      console.log(error);
    }
  };

  const saveDraftIfChanged = async (): Promise<void> => {
    if (isDraft) {
      await saveEvidence(true);
    } else if (isNewEvidence) {
      setCanSaveDraft(true);
    }
  };

  const saveEvidenceAndClose = async (): Promise<void> => {
    const mandatoryFieldsCheck = areAllMandatoryFieldsComplete();
    if (mandatoryFieldsCheck.complete) {
      await saveEvidence();
      dismissModal();
    } else {
      setUnsavedChangesModalVisible(false);
      setShowErrorBanner(true);
      setIncompleteMandatoryFields([...mandatoryFieldsCheck.incompleteFields]);
      setActiveMandatoryFieldIndex(0);
      scrollToMandatoryField(0, mandatoryFieldsCheck.incompleteFields);
    }
  };

  const saveDraftAndClose = async (): Promise<void> => {
    await saveDraftClicked();
    dismissModal();
  };

  const deleteEvidenceAndClose = async (): Promise<void> => {
    await deleteEvidence();
    dismissModal();
  };

  const draftCloseClicked = async (): Promise<void> => {
    await saveDraftAndClose();
  };

  const newEvidenceCloseClicked = (): void => {
    if (canSaveDraft) {
      setSaveDraftModalVisible(true);
    } else {
      dismissModal();
    }
  };

  const cancelClicked = (): void => {
    if (program) {
      if (_.isEmpty(evidenceJSON)) {
        dismissModal();
      } else {
        if (!_.isEmpty(initialEvidenceJSON)) {
          let tempJSON = _.cloneDeep(evidenceJSON);

          // Find values that are dates and convert to ISO string
          // that database holds before checking equality
          let dates = Object.keys(tempJSON).filter((key) => tempJSON[key] instanceof Date);

          for (let i = 0; i < dates.length; i++) {
            const key = dates[i];
            tempJSON[key] = tempJSON[key].toISOString();

            if (isSameDay(new Date(tempJSON[key]), new Date(initialEvidenceJSON[key]))) {
              tempJSON[key] = initialEvidenceJSON[key];
            }
          }

          if (_.isEqual(initialEvidenceJSON, tempJSON)) {
            dismissModal();
          } else {
            setUnsavedChangesModalVisible(true);
          }
        } else {
          dismissModal();
        }
      }
    } else {
      // initial evidence should exist at this point

      const evidence: IEvidence = {
        title,
        date: date.toISOString(),
        ...(activity && { activity }),
        ...(numberOfHours !== "" && {
          numberOfHours: DataController.convertToNumberOfHours(numberOfHours),
        }),
        standardTags: standardTags.length > 0 ? standardTags : [],
        customTags: customTags.length > 0 ? customTags : [],
        attachments: attachments.length > 0 ? attachments : [],
        reflections,
        ...(activityDescription !== "" && {
          activityDescription: activityDescription.replace(/'/gim, "''"),
        }),
        benefits,
        ...(objectives && { objectives }),
        ...(addToHCPCAudit !== undefined && { addToHCPCAudit }),
        ...(timelineReference !== "" && { timelineReference }),
        id: id || DataController.generateUniqueID(),
      };

      if (activityDateMode === "single") {
        evidence.activityDate = activitySingleDate.toISOString();
      } else if (activityDateMode === "range") {
        const activityDateArray = [];

        if (activityDateRange?.from) {
          activityDateArray.push(activityDateRange.from.toISOString());
        }

        if (activityDateRange?.to) {
          activityDateArray.push(activityDateRange.to.toISOString());
        }

        if (activityDateArray.length > 0) {
          evidence.activityDate = activityDateArray.join(";");
        }
      }

      if (typeof initialEvidence!.attachments === "undefined" && evidence.attachments?.length === 0) {
        delete evidence.attachments;
      }

      if (typeof initialEvidence!.standardTags === "undefined" && evidence.standardTags?.length === 0) {
        delete evidence.standardTags;
      }

      if (typeof initialEvidence!.addToHCPCAudit === "undefined" && !evidence.addToHCPCAudit) {
        delete evidence.addToHCPCAudit;
      }

      if (typeof initialEvidence!.customTags === "undefined" && evidence.customTags?.length === 0) {
        delete evidence.customTags;
      }

      const noChanges = _.isEqual(initialEvidence!, evidence);

      if (!noChanges) {
        // changes have been made to a piece of personal evidence
        setUnsavedChangesModalVisible(true);
      } else {
        dismissModal();
      }
    }
  };

  const dismissModal = (): void => {
    closeInternalDialogs();
    closeModal(false);
  };

  const closeInternalDialogs = (): void => {
    setSaveDraftModalVisible(false);
    setEmptyTagModalVisible(false);
    setExistingTagModalVisible(false);
    setUnsavedChangesModalVisible(false);
    setDeleteEvidenceModalVisible(false);
  };

  const resetModalState = (): void => {
    setTitle("");
    setDate(new Date());
    setActivitySingleDate(new Date());
    setActivityDateRange(undefined);
    setActivityDateMode("single");
    setActivity(null);
    setNumberOfHours("");
    setStandardTags([]);
    setStandardTag("");
    setCustomTags([]);
    setAttachments([]);
    setAttachmentsToAdd([]);
    setAttachmentsToDelete([]);
    setActivityDescription("");
    setReflection("");
    setBenefits("");
    setObjectives("");
    setDictationInput("");
    setAddToHCPCAudit(false);
    setTimelineReference("");
    setId("");
    setInitialEvidence(null);
    setSaving(false);
    setSavingDraft(false);
    setCanSaveDraft(false);
    setIsNewEvidence(true);
    setIsDraft(false);
    setTempInput("");
    setSaveDraftModalVisible(false);
    setDeleteEvidenceModalVisible(false);
    setEmptyTagModalVisible(false);
    setExistingTagModalVisible(false);
    setProgram(null);
    setCompetence(null);
    setEnhancedSkill(null);
    setCompetences([]);
    setEvidenceParts([]);
    setProgressCheck(null);
    setProgressChecks([]);
    setProgressCheckCompetences([]);
    setProgressCheckCompetence(null);
    setProgramMandatoryStandards([]);
    setProgramOptionalStandards([]);
    setEvidenceJSON({});
    setInitialEvidenceJSON({});
    setShowErrorBanner(false);
    mandatoryFieldRefs.current = {};
    setIncompleteMandatoryFields([]);
    setActiveMandatoryFieldIndex(0);
    closeModal(false);
  };

  const discardDraft = () => {
    FirebaseService.logEvent("draft_evidence_discarded", {});
    dismissModal();
  };

  const openDatePicker = (): void => {
    setDatePickerVisible(true);
  };

  const openActivityDatePicker = (): void => {
    setActivityDatePickerVisible(true);
  };

  const handleDateRangeModeChange = (newMode: "single" | "range"): void => {
    if (newMode === activityDateMode) return;

    if (newMode === "single") {
      if (activityDateRange?.from) {
        setActivitySingleDate(activityDateRange.from);
      }
      setActivityDateMode("single");
    }

    if (newMode === "range") {
      if (activitySingleDate) {
        setActivityDateRange({ from: activitySingleDate });
      }
      setActivityDateMode("range");
    }
  };

  const handleDateRangeChange = (triggerDate: Date): void => {
    if (!activityDateRange) {
      setActivityDateRange({ from: triggerDate });
      return;
    }

    const copy = { ...activityDateRange };
    const triggerIsBeforeFromDate = isBefore(triggerDate, copy.from);
    const triggerIsAfterFromDate = isAfter(triggerDate, copy.from);
    if (copy.to || triggerIsBeforeFromDate) {
      copy.from = triggerDate;
      copy.to = undefined;
    } else if (triggerIsAfterFromDate) {
      copy.to = triggerDate;
    }

    setActivityDateRange(copy);
  };

  const viewAttachment = async (attachment: EvidenceAttachment): Promise<void> => {
    try {
      if (attachment.url && user.contents) {
        setViewingAttachment(true);
        setViewingAttachmentFilename(attachment.name);

        await FileUtils.viewAttachment(user.contents, id, attachment);

        setViewingAttachment(false);
        setViewingAttachmentFilename("");
      }
    } catch (error) {
      console.log(error);
      setViewingAttachment(false);
      setViewingAttachmentFilename("");
      window.alert("An error occurred when opening attachment");
    }
  };

  const deleteAttachment = (name: string): void => {
    const index = attachments.findIndex((attachment: EvidenceAttachment) => attachment?.name === name);

    if (index > -1) {
      const attachment = attachments[index];

      if (attachment.url) {
        const toDelete = _.cloneDeep(attachmentsToDelete);
        toDelete.push(attachment);

        setAttachmentsToDelete(toDelete);
      } else if (attachment.content) {
        const toAdd = _.cloneDeep(attachmentsToAdd);
        const addedIndex = _.findIndex(toAdd, (item) => item.name === name);
        addedIndex > -1 && toAdd.splice(addedIndex);

        setAttachmentsToAdd(toAdd);
      }
    }

    index !== -1 && setAttachments(attachments.filter((_, i) => i !== index));
  };

  const addStandardTag = (code: string, type: string): void => {
    const tag: EvidenceStandardTag = { tag: code };
    setStandardTags((tags) => [...tags, tag]);
    setStandardTag("");
    standardTagsRef.current?.focus();
    saveDraftIfChanged();
    removeStandardTagFromList(code);
  };

  const deleteStandardTag = (value: string): void => {
    const index = standardTags.findIndex((tag: EvidenceStandardTag) => tag.tag === value);

    index !== -1 && setStandardTags(standardTags.filter((_, i) => i !== index));
    addStandardTagToList(value);
    saveDraftIfChanged();
  };

  const deleteCustomTag = (value: string): void => {
    const index = customTags.findIndex((tag: EvidenceCustomTag) => tag.tag === value);

    index !== -1 && setCustomTags(customTags.filter((_, i) => i !== index));
    saveDraftIfChanged();
  };

  const reflectionsEditorFocused = (): void => {
    setReflectionsFocused(true);
    setTempInput(reflections);
    const editor = document.getElementById("reflectionsEditorRef");
    editor && editor.scrollIntoView();
  };

  const reflectionsEditorBlurred = (): void => {
    setReflectionsFocused(false);

    if (tempInput !== reflections) {
      saveDraftIfChanged();
    }

    setTempInput("");
  };

  const benefitsEditorFocused = (): void => {
    setBenefitsFocused(true);
    setTempInput(benefits);
    const editor = document.getElementById("benefitsEditorRef");
    editor && editor.scrollIntoView();
  };

  const benefitsEditorBlurred = (): void => {
    setBenefitsFocused(false);

    if (tempInput !== reflections) {
      saveDraftIfChanged();
    }

    setTempInput("");
  };

  const objectivesEditorFocused = (): void => {
    setObjectivesFocused(true);
    setTempInput(objectives);
    const editor = document.getElementById("objectivesEditorRef");
    editor && editor.scrollIntoView();
  };

  const objectivesEditorBlurred = (): void => {
    setObjectivesFocused(false);

    if (tempInput !== objectives) {
      saveDraftIfChanged();
    }

    setTempInput("");
  };

  const titleChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(event.target.value);
    isNewEvidence && setCanSaveDraft(true);
  };

  const titleFocused = (event: React.FocusEvent<HTMLInputElement>): void => {
    setTempInput(event.target.value);
  };

  const titleBlurred = (): void => {
    if (tempInput !== title) {
      saveDraftIfChanged();
    }

    setTempInput("");
  };

  const activityChanged = (event: any): void => {
    event.detail.value && setActivity({ type: event.detail.value });
    saveDraftIfChanged();
  };

  const standardTagChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setStandardTag(event.target.value);
    isNewEvidence && setCanSaveDraft(true);
    filterStandardTags(event.target.value);
  };

  const filterStandardTags = useCallback(
    (search: string): void => {
      const result = EvidenceUtils.filterStandardTags(search, standardTags, mandatoryStandards, optionalStandards);

      result.mandatory && setMandatoryStandardsFiltered(_.orderBy(result.mandatory, ["Code"], ["asc"]));
      result.optional && setOptionalStandardsFiltered(_.orderBy(result.optional, ["Code"], ["asc"]));
    },
    [mandatoryStandards, optionalStandards, standardTags]
  );

  const removeStandardTagFromList = useCallback(
    (code: string): void => {
      let addedTags = standardTags.map((item) => item.tag);

      let mandatory = mandatoryStandards?.filter((item) => item.Code !== code && !addedTags.includes(item.Code));
      let optional = optionalStandards?.filter((item) => item.Code !== code && !addedTags.includes(item.Code));

      mandatory && setMandatoryStandardsFiltered(_.orderBy(mandatory, ["Code"], ["asc"]));
      optional && setOptionalStandardsFiltered(_.orderBy(optional, ["Code"], ["asc"]));
    },
    [mandatoryStandards, optionalStandards, standardTags]
  );

  const addStandardTagToList = useCallback(
    (code: string): void => {
      let addedTags = standardTags.map((item) => item.tag);
      addedTags = addedTags.filter((item) => item !== code);

      let mandatory = mandatoryStandards?.filter((item) => !addedTags.includes(item.Code));
      let optional = optionalStandards?.filter((item) => !addedTags.includes(item.Code));

      mandatory && setMandatoryStandardsFiltered(_.orderBy(mandatory, ["Code"], ["asc"]));
      optional && setOptionalStandardsFiltered(_.orderBy(optional, ["Code"], ["asc"]));
    },
    [mandatoryStandards, optionalStandards, standardTags]
  );

  const standardTagKeyPressed = (event: React.KeyboardEvent): void => {
    event.preventDefault();

    if (event.key === "Enter") {
    } else if (event.key === "Escape") {
      standardTagsRef.current?.blur();
      setShowStandardTags(false);
    }
  };

  const onDurationPickerChange = useCallback((newTime: string) => {
    setNumberOfHours(newTime);
    saveDraftIfChanged();
  }, []);

  const activityDescriptionChanged = (event: any): void => {
    setActivityDescription(event.target.value);
    isNewEvidence && setCanSaveDraft(true);
  };

  const activityDescriptionFocused = (event: any) => {
    setTempInput(event.target.value);
  };

  const activityDescriptionBlurred = (): void => {
    if (tempInput !== activityDescription) {
      saveDraftIfChanged();
    }

    setTempInput("");
  };

  const standardTagFocused = (event: React.FocusEvent<HTMLInputElement>): void => {
    setShowStandardTags(true);
  };

  const saveNewTag = (newTag: string): void => {
    if (newTag.trim() === "") {
      setEmptyTagModalVisible(true);
    } else if (!customTags.find((tag) => tag.tag.toUpperCase() === newTag.toUpperCase().trim())) {
      setCustomTags((tags) => [...tags, { tag: newTag.trim() }]);
      saveDraftIfChanged();
    } else {
      setExistingTagModalVisible(true);
    }
  };

  const areAllMandatoryFieldsComplete = (): { complete: boolean; incompleteFields: string[] } => {
    if (program) {
      let mandatory: EvidencePart[] = _.cloneDeep(evidenceParts) || [];
      mandatory = mandatory.filter((item) => item?.options === "Mandatory" || item?.["option type"] === "Mandatory");
      mandatory = mandatory.filter((item) => EvidenceUtils.checkIfEvidencePartIsRequired(item, evidenceJSON));

      let array: { done: boolean; partName: string }[] = [];

      for (let i = 0; i < mandatory.length; i++) {
        let part = mandatory[i];
        let value = evidenceJSON[`${part.Name}`];

        let done = false;
        if (part["Field type"] === FieldType.NOSSelect) {
          done = EvidenceUtils.checkNOSSelectIsFilled(evidenceJSON);
          array.push({ done, partName: part.Name });
        } else if (
          part["Field type"] === FieldType.LearningOutcomesList ||
          part["Field type"] === FieldType.LearningOutcomesListSegmented
        ) {
          done = EvidenceUtils.checkLearningOutcomesIsFilled(evidenceJSON);
          array.push({ done, partName: part.Name });
        } else if (part["Field type"] === FieldType.LOsMultiSelectForNQP) {
          done = EvidenceUtils.checkLOsMultiSelectForNQPIsFilled(evidenceJSON);
          array.push({ done, partName: part.Name });
        } else {
          done = DataController.checkEvidencePartIsFilled(part["Field type"] ?? "", value);
          array.push({ done, partName: part.Name });
        }
      }

      const complete = array.length > 0 ? array.every((item) => item.done) : false;
      return { complete, incompleteFields: array.filter((item) => !item.done).map((item) => item.partName) };
    } else {
      const incompleteFields: string[] = [];
      if (!title.length) incompleteFields.push("Title");
      if (!date) incompleteFields.push("Date of reflection");
      if (activityDateMode === "single" && !activitySingleDate) incompleteFields.push("Date of activity");
      if (activityDateMode === "range" && !activityDateRange) incompleteFields.push("Date of activity");
      if (!activity || _.isEmpty(activity)) incompleteFields.push("Activity");
      if (reflections === "") incompleteFields.push("Reflections");
      if (benefits === "") incompleteFields.push("Benefits");

      return { complete: incompleteFields.length === 0, incompleteFields: incompleteFields };
    }
  };

  const isSaveButtonDisabled = (): boolean => {
    if (!program || progressChecks.length === 0) return saving;

    return !progressCheck || !progressCheckCompetence;
  };

  const disableFocusTrap = (): boolean => {
    return reflectionsFocused || benefitsFocused || objectivesFocused || richTextBoxFocused;
  };

  const canDismiss = (): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      if (program) {
        if (_.isEmpty(evidenceJSON)) {
          resolve(true);
        } else {
          if (!_.isEmpty(initialEvidenceJSON)) {
            let tempJSON = _.cloneDeep(evidenceJSON);

            // Find values that are dates and convert to ISO string
            // that database holds before checking equality
            let dates = Object.keys(tempJSON).filter((key) => tempJSON[key] instanceof Date);

            for (let i = 0; i < dates.length; i++) {
              const key = dates[i];
              tempJSON[key] = tempJSON[key].toISOString();

              if (isSameDay(new Date(tempJSON[key]), new Date(initialEvidenceJSON[key]))) {
                tempJSON[key] = initialEvidenceJSON[key];
              }
            }

            resolve(true);
          } else {
            resolve(true);
          }
        }
      } else {
        if (initialEvidence) {
          const evidence: IEvidence = {
            title,
            date: date.toISOString(),
            ...(activity && { activity }),
            ...(numberOfHours !== "" && {
              numberOfHours: DataController.convertToHoursMinutes(numberOfHours),
            }),
            standardTags: standardTags.length > 0 ? standardTags : [],
            customTags: customTags.length > 0 ? customTags : [],
            attachments: attachments.length > 0 ? attachments : [],
            reflections,
            ...(activityDescription !== "" && {
              activityDescription: activityDescription.replace(/'/gim, "''"),
            }),
            benefits,
            ...(objectives && { objectives }),
            ...(addToHCPCAudit !== undefined && { addToHCPCAudit }),
            ...(timelineReference !== "" && { timelineReference }),
            id: id || DataController.generateUniqueID(),
          };

          if (activityDateMode === "single") {
            evidence.activityDate = activitySingleDate.toISOString();
          } else if (activityDateMode === "range") {
            if (activityDateRange?.from) {
              evidence.activityDate = activityDateRange.from.toISOString();
            }

            if (activityDateRange?.to) {
              evidence.activityDateEnd = activityDateRange.to.toISOString();
            }
          }

          if (typeof initialEvidence.attachments === "undefined" && evidence.attachments?.length === 0) {
            delete evidence.attachments;
          }

          if (typeof initialEvidence.standardTags === "undefined" && evidence.standardTags?.length === 0) {
            delete evidence.standardTags;
          }

          if (typeof initialEvidence.addToHCPCAudit === "undefined" && !evidence.addToHCPCAudit) {
            delete evidence.addToHCPCAudit;
          }

          if (typeof initialEvidence.customTags === "undefined" && evidence.customTags?.length === 0) {
            delete evidence.customTags;
          }

          const noChanges = _.isEqual(initialEvidence, evidence);

          if (!noChanges) {
            resolve(true);
          } else {
            resolve(true);
          }
        } else {
          const fieldsFilled =
            title.trim().length > 0 ||
            (activity?.type !== undefined && !_.isEmpty(activity)) ||
            standardTags.length > 0 ||
            customTags.length > 0 ||
            attachments.length > 0 ||
            reflections !== "" ||
            benefits !== "" ||
            objectives !== "";

          if (fieldsFilled) {
            resolve(true);
          } else {
            resolve(true);
          }
        }
      }
    });
  };

  // For Bespoke programs
  const enhancedSkillChanged = (item: any): void => {
    const name = item.detail.value;

    const _competence: CompetenceActivityClass = competences.find(
      (item: CompetenceActivityClass) => item.Name === name
    );

    if (_competence) {
      if (competence && competence.Name !== _competence.Name) {
        setChangeTaskingTypeModalVisible(true);

        // Set tasking type name for confirmation;
        setTempTaskingType(name);
      } else {
        setCompetence(_competence);
        isNewEvidence && setCanSaveDraft(true);
        _competence["Evidence Definitions"] ? getEvidenceParts(_competence) : setEvidenceParts([]);
      }
    }
  };

  const progressCheckChanged = (item: any): void => {
    const name = item.detail.value;

    const _progressCheck: ProgressCheck = progressChecks.find((item: ProgressCheck) => item.Name === name);

    if (_progressCheck) {
      if (progressCheck && progressCheck.Name !== _progressCheck.Name) {
        setChangeProgressCheckModalVisible(true);

        setTempProgressCheck(name);
      } else {
        setProgressCheck(_progressCheck);
        setCanSaveDraft(false);
        _progressCheck["Competence/Activity"]
          ? getProgressCheckCompetences(_progressCheck)
          : setProgressCheckCompetences([]);
      }
    }
  };

  const getOldTags = () => {
    const oldTags: string[] = [];
    if (_e.contents) {
      Array.from(_e.contents).forEach((item: any) => {
        if (item.customTags) {
          item.customTags.forEach((item: any) => {
            if (!oldTags.includes(item.tag)) {
              oldTags.push(item.tag);
            }
          });
        }
      });
    }

    return oldTags;
  };

  const progressCheckCompetenceChanged = (item: any): any => {
    const name = item.detail.value;

    const _progressCheckCompetence: CompetenceActivityClass = progressCheckCompetences.find(
      (item: CompetenceActivityClass) => item.Name === name
    );

    if (_progressCheckCompetence) {
      if (progressCheckCompetence && progressCheckCompetence.Name !== _progressCheckCompetence.Name) {
        setChangeProgressCheckCompetenceModalVisible(true);

        setTempProgressCheckCompetence(name);
      } else {
        setProgressCheckCompetence(_progressCheckCompetence);
        isNewEvidence && setCanSaveDraft(true);
        getEvidenceParts(_progressCheckCompetence);
      }
    }
  };

  const confirmChangeProgressCheck = (name: string): void => {
    if (name.length > 0) {
      const _progressCheck: ProgressCheck = progressChecks.find((item: ProgressCheck) => item.Name === name);

      setCanSaveDraft(false);
      setProgressCheck(_progressCheck);
      setEvidenceParts([]);
      setProgressCheckCompetence(null);
      resetEvidenceAfterTypeChanged();
      _progressCheck["Competence/Activity"]
        ? getProgressCheckCompetences(_progressCheck)
        : setProgressCheckCompetences([]);
    }
  };

  const confirmChangeProgressCheckCompetence = (name: string): void => {
    if (name.length > 0) {
      const _progressCheckCompetence: CompetenceActivityClass = progressCheckCompetences.find(
        (item: CompetenceActivityClass) => item.Name === name
      );

      setProgressCheckCompetence(_progressCheckCompetence);
      resetEvidenceAfterTypeChanged();
      getEvidenceParts(_progressCheckCompetence);
    }
  };

  const confirmChangeTaskType = (name: string) => {
    if (name.length > 0) {
      const _competence: CompetenceActivityClass = competences.find(
        (item: CompetenceActivityClass) => item.Name === name
      );

      setCompetence(_competence);
      resetEvidenceAfterTypeChanged();
      _competence["Evidence Definitions"] ? getEvidenceParts(_competence) : setEvidenceParts([]);
    }
  };

  const resetEvidenceAfterTypeChanged = (): void => {
    setEvidenceJSON({});
    setNumberOfHours("");
    setAddToHCPCAudit(false);
    setChangeProgressCheckModalVisible(false);
    setChangeProgressCheckCompetenceModalVisible(false);
    setChangeTaskingTypeModalVisible(false);
    setShowErrorBanner(false);
    setIncompleteMandatoryFields([]);
    setActiveMandatoryFieldIndex(0);
  };

  const getEvidenceParts = (_competence: CompetenceActivityClass): void => {
    const evidenceDefinitions = _competence?.["Evidence Definitions"][0];
    const _evidenceParts = EvidenceUtils.getEvidenceParts(evidenceDefinitions);

    setEvidenceParts(_evidenceParts);
  };

  const toggleAuditTickbox = (): void => {
    setAddToHCPCAudit(!addToHCPCAudit);
    saveDraftIfChanged();
  };

  const blurRichText = (): void => {
    setRichTextBoxFocused(false);
    saveDraftIfChanged();
  };

  const evidencePartChanged = (evidencePart: EvidencePart, value: any, isInput?: boolean): void => {
    let object = _.cloneDeep(evidenceJSON);
    let key = evidencePart.Name;

    let newObject: any;

    if (evidencePart["Field type"] === FieldType.Attachments) {
      newObject = _.extend(object, {
        [key]: {
          ...object[key],
          ...value,
        },
      });
    } else if (evidencePart["Field type"] === FieldType.NOSSelect) {
      newObject = _.extend(object, value);
    } else if (evidencePart["Field type"] === FieldType.CompMultiSelect) {
      newObject = _.extend(object, { "Comp Selector": value });
    } else if (evidencePart["Field type"] === FieldType.LearningOutcomesList) {
      newObject = _.extend(object, { LearningOutcomes: value });
    } else {
      newObject = _.extend(object, { [key]: value });
    }

    if (evidencePart["Linked Part"] && evidencePart["Linked Part"][0]) {
      const linkedPart = evidencePart["Linked Part"][0];
      const triggers = linkedPart["Linked Part trigger"] ? linkedPart["Linked Part trigger"].split("\n") : [];
      if (!triggers.includes(value)) {
        // change newObj so that the key of first.Name is removed
        newObject = _.omit(newObject, linkedPart.Name);
      }
    }

    setEvidenceJSON((e: any) => ({ ...e, ...newObject }));

    if (evidencePart["Field type"] === FieldType.ActivityDuration) {
      setNumberOfHours(value);
    }

    if (isValid(value) && DataController.isDefaultDate(evidencePart.Name, program)) {
      setDate(value);
    }

    if (!isInput) {
      saveDraftIfChanged();
    }
  };

  const addRefToMandatorySet = (div: HTMLDivElement, key: string) => {
    if (modalVisible) {
      const copy = { ...mandatoryFieldRefs.current };
      if (div) {
        copy[key] = div;
      } else {
        delete copy[key]; // need to delete references that no longer exist when switching progress checks
      }
      mandatoryFieldRefs.current = copy;
    }
  };

  return (
    <IonModal
      id="evidenceModal"
      isOpen={modalVisible}
      onDidDismiss={() => resetModalState()}
      backdropDismiss={true}
      canDismiss={canDismiss}
      className={`fullscreen ${disableFocusTrap() ? "ion-disable-focus-trap" : ""}`}
    >
      <IonHeader
        style={{
          height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
        }}
      >
        {isNewEvidence ? (
          <IonToolbar mode="ios" className="navBar" style={{ maxWidth: DataController.isWeb() ? 980 : undefined }}>
            <IonButtons slot="start">
              <IonButton mode="ios" className="cancelButton" onClick={() => newEvidenceCloseClicked()}>
                {"Close"}
              </IonButton>
            </IonButtons>
            <IonTitle>{"New Evidence"}</IonTitle>
            {canSaveDraft && (
              <IonButtons slot="end">
                {!savingDraft ? (
                  <IonButton mode="ios" className="cancelButton" onClick={() => saveDraftClicked()}>
                    <div className="font-semibold">{"Save draft"}</div>
                  </IonButton>
                ) : (
                  <div className="flex justify-center items-center">
                    <IonSpinner className="w-[20px] h-[20px] text-white" />
                  </div>
                )}
              </IonButtons>
            )}
          </IonToolbar>
        ) : isDraft ? (
          <IonToolbar mode="ios" className="navBar" style={{ maxWidth: DataController.isWeb() ? 980 : undefined }}>
            <IonButtons slot="start">
              <IonButton mode="ios" className="cancelButton" onClick={() => draftCloseClicked()}>
                {"Close"}
              </IonButton>
            </IonButtons>
            <IonTitle>{"Draft"}</IonTitle>
            <IonButtons slot="end">
              <IonButton mode="ios" className="cancelButton" onClick={() => deleteClicked()}>
                {"Delete"}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        ) : (
          <IonToolbar mode="ios" className="navBar" style={{ maxWidth: DataController.isWeb() ? 980 : undefined }}>
            <IonButtons slot="start">
              <IonButton mode="ios" className="cancelButton" onClick={() => cancelClicked()}>
                {"Close"}
              </IonButton>
            </IonButtons>
            <IonTitle>{"Edit Evidence"}</IonTitle>
            <IonButtons slot="end">
              <IonButton mode="ios" className="cancelButton" onClick={() => deleteClicked()}>
                {"Delete"}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent>
        <div className="flex flex-col sticky top-0 z-50">
          {isAIDictationAvailable && !program && (
            <>
              <AIDictationBanner
                ref={dictationBannerRef}
                dictationState={dictationState}
                processingState={processingState}
                microphoneButtonPressed={handleAIDictation}
                createButtonPressed={(input) => processEvidenceGeneration(input)}
                infoButtonPressed={() => setDictationInfoModalVisible(true)}
                errorMessage={dictationErrorMessage}
              />
              <AIDictationInfoModal
                visible={dictationInfoModalVisible}
                closeModal={() => setDictationInfoModalVisible(false)}
              />
              <AIFeedbackToast />
            </>
          )}
          {showErrorBanner && (
            <div
              ref={errorBannerRef}
              className="flex justify-center bg-cta-red py-4 text-white text-acc-13px font-semibold"
            >
              <div className="max-w-app-column w-full ml-safe-area-l mr-safe-area-r flex justify-between items-center">
                <div className="flex-1">
                  <div>{incompleteMandatoryFields.length} mandatory fields</div>
                  <div>have not been completed</div>
                </div>
                <div className="flex items-center">
                  <div className="mr-3">{`${activeMandatoryFieldIndex + 1}-${incompleteMandatoryFields.length}`}</div>
                  <div className="flex items-center">
                    <button
                      className="p-1"
                      onClick={() => {
                        if (!disablePrevChevron) {
                          setActiveMandatoryFieldIndex(activeMandatoryFieldIndex - 1);
                          scrollToMandatoryField(activeMandatoryFieldIndex - 1, incompleteMandatoryFields);
                        }
                      }}
                    >
                      <img src={disablePrevChevron ? Chevron_Prev_Inactive : Chevron_Prev_Active} />
                    </button>
                    <button
                      className="p-1"
                      onClick={() => {
                        if (!disableNextChevron) {
                          setActiveMandatoryFieldIndex(activeMandatoryFieldIndex + 1);
                          scrollToMandatoryField(activeMandatoryFieldIndex + 1, incompleteMandatoryFields);
                        }
                      }}
                    >
                      <img src={disableNextChevron ? Chevron_Next_Inactive : Chevron_Next_Active} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <ContentContainer
          style={{
            paddingBottom: keyboardActive && disableFocusTrap() ? keyboardHeight : 12,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SaveDraftModal
            visible={saveDraftModalVisible}
            cancelPressed={() => setSaveDraftModalVisible(false)}
            deletePressed={() => discardDraft()}
            savePressed={() => saveDraftAndClose()}
          />
          <DeleteEvidenceModal
            visible={deleteEvidenceModalVisible}
            isDraft={isDraft}
            cancelPressed={() => setDeleteEvidenceModalVisible(false)}
            deletePressed={() => deleteEvidenceAndClose()}
          />
          <EmptyTagModal visible={emptyTagModalVisible} dismissPressed={() => setEmptyTagModalVisible(false)} />
          <ExistingTagModal
            visible={existingTagModalVisible}
            dismissPressed={() => setExistingTagModalVisible(false)}
          />
          <UnsavedChangesModal
            visible={unsavedChangesModalVisible}
            cancelPressed={() => setUnsavedChangesModalVisible(false)}
            discardPressed={() => dismissModal()}
            savePressed={() => saveEvidenceAndClose()}
          />
          {program ? (
            <>
              {/* <pre>{JSON.stringify(evidenceJSON, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(progressCheck, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(changeProgressCheckModal, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(changeTaskingTypeModalVisible, null, 2)}</pre> */}
              {/* <pre>evidenceParts: {JSON.stringify(evidenceParts, null, 2)}</pre> */}
              {/* <pre>progressChecks: {JSON.stringify(progressChecks, null, 2)}</pre> */}
              {/* <pre>program: {JSON.stringify(program, null, 2)}</pre> */}
              {/* <pre>competences: {JSON.stringify(competences, null, 2)}</pre> */}
              {/* <pre>progressCheckCompetences: {JSON.stringify(progressCheckCompetence, null, 2)}</pre> */}
              {/* <pre>isDraft: {JSON.stringify(isDraft, null, 2)}</pre> */}
              <IonModal
                id="changeProgressCheckModal"
                isOpen={changeProgressCheckModal}
                backdropDismiss={true}
                canDismiss={true}
                className="fullscreen"
                onDidDismiss={() => setChangeProgressCheckModalVisible(false)}
              >
                <IonContent className="changeTaskingTypeModal">
                  <div className="changeTaskingTypeModalContainer">
                    <div>
                      <div className="changeTaskingTypeModalTitle">{"Are you sure you want to change the type?"}</div>
                      <div className="changeTaskingTypeModalText mx-[8px]">
                        {"Changing type will lose any information added for that type"}
                      </div>
                    </div>
                    <div className="tipBottomButtonContainer" style={{ marginTop: 20 }}>
                      <button className="tipBottomButton" onClick={() => confirmChangeProgressCheck(tempProgressCheck)}>
                        {"Change type"}
                      </button>
                    </div>
                    <div className="tipBottomButtonContainer">
                      <button className="tipBottomButton" onClick={() => setChangeProgressCheckModalVisible(false)}>
                        {"Keep the current type"}
                      </button>
                    </div>
                  </div>
                </IonContent>
              </IonModal>
              <IonModal
                id="changeProgressCheckModal"
                isOpen={changeProgressCheckCompetenceModal}
                backdropDismiss={true}
                canDismiss={true}
                className="fullscreen"
                onDidDismiss={() => setChangeProgressCheckCompetenceModalVisible(false)}
              >
                <IonContent className="changeTaskingTypeModal">
                  <div className="changeTaskingTypeModalContainer">
                    <div>
                      <div className="changeTaskingTypeModalTitle">{"Are you sure you want to change the type?"}</div>
                      <div className="changeTaskingTypeModalText mx-[8px]">
                        {"Changing type will lose any information added for that type"}
                      </div>
                    </div>
                    <div className="tipBottomButtonContainer" style={{ marginTop: 20 }}>
                      <button
                        className="tipBottomButton"
                        onClick={() => confirmChangeProgressCheckCompetence(tempProgressCheckCompetence)}
                      >
                        {"Change type"}
                      </button>
                    </div>
                    <div className="tipBottomButtonContainer">
                      <button
                        className="tipBottomButton"
                        onClick={() => setChangeProgressCheckCompetenceModalVisible(false)}
                      >
                        {"Keep the current type"}
                      </button>
                    </div>
                  </div>
                </IonContent>
              </IonModal>
              <IonModal
                id="changeTaskingTypeModal"
                isOpen={changeTaskingTypeModalVisible}
                backdropDismiss={true}
                canDismiss={true}
                className="fullscreen"
                onDidDismiss={() => setChangeTaskingTypeModalVisible(false)}
              >
                <IonContent className="changeTaskingTypeModal">
                  <div className="changeTaskingTypeModalContainer">
                    <div>
                      <div className="changeTaskingTypeModalTitle">
                        {"Are you sure you want to change the task type?"}
                      </div>
                      <div className="changeTaskingTypeModalText">
                        {"Changing task type will lose any information added for that task"}
                      </div>
                    </div>
                    <div className="tipBottomButtonContainer" style={{ marginTop: 20 }}>
                      <button className="tipBottomButton" onClick={() => confirmChangeTaskType(tempTaskingType)}>
                        {"Change task type"}
                      </button>
                    </div>
                    <div className="tipBottomButtonContainer">
                      <button className="tipBottomButton" onClick={() => setChangeTaskingTypeModalVisible(false)}>
                        {"Keep the current task type"}
                      </button>
                    </div>
                  </div>
                </IonContent>
              </IonModal>
              <EvidenceHeader programName={program.Title} competenceName={competence?.Name} />
              {progressChecks?.length > 0 && (
                <EvidenceContentCard title="Progress check">
                  <div className="reflectionActivityTypeContainer">
                    <IonList mode="md" className="reflectionActivityList" color="white">
                      <IonItem mode="md" className="reflectionActivityItem" color="white">
                        <IonSelect
                          color="white"
                          mode="md"
                          className="reflectionActivitySelectItem w-full"
                          interface="popover"
                          justify="space-between"
                          interfaceOptions={{
                            size: "auto",
                            alignment: "start",
                          }}
                          placeholder="Select progress check"
                          value={progressCheck?.Name || undefined}
                          onIonChange={progressCheckChanged}
                        >
                          {progressChecks.map((item: ProgressCheck) => (
                            <IonSelectOption color="white" key={item.Name} value={item.Name}>
                              {item.Name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonList>
                  </div>
                </EvidenceContentCard>
              )}
              {progressCheckCompetences?.length > 0 && (
                <EvidenceContentCard title={"Evidence type"}>
                  <div className="reflectionActivityTypeContainer">
                    <IonList mode="md" className="reflectionActivityList" color="white">
                      <IonItem className="reflectionActivityItem" color="white" mode="md">
                        <IonSelect
                          aria-label={StringUtils.getCompetenceCardTitle(program.ID)}
                          color="white"
                          mode="md"
                          className="reflectionActivitySelectItem"
                          interface="popover"
                          justify="space-between"
                          interfaceOptions={{
                            size: "auto",
                            alignment: "start",
                          }}
                          placeholder="Select evidence"
                          value={progressCheckCompetence?.Name || undefined}
                          onIonChange={progressCheckCompetenceChanged}
                        >
                          {progressCheckCompetences.map((item: CompetenceActivityClass) => (
                            <IonSelectOption color="white" key={item.Name} value={item.Name}>
                              {item.Name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonList>
                  </div>
                </EvidenceContentCard>
              )}
              {competences?.length > 0 && !ProgramUtils.isTelephoneTriageModule(program.ID) && (
                <EvidenceContentCard title="Enhanced Skill undertaken">
                  <div className="reflectionActivityTypeContainer">
                    <IonList mode="md" className="reflectionActivityList" color="white">
                      <IonItem mode="md" className="reflectionActivityItem" color="white">
                        <IonSelect
                          color="white"
                          mode="md"
                          className="reflectionActivitySelectItem"
                          interface="popover"
                          justify="space-between"
                          interfaceOptions={{
                            size: "auto",
                            alignment: "start",
                          }}
                          placeholder="Select enhanced skill"
                          value={competence?.Name || undefined}
                          onIonChange={enhancedSkillChanged}
                        >
                          {competences.map((item: CompetenceActivityClass) => (
                            <IonSelectOption color="white" key={item.Name} value={item.Name}>
                              {item.Name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonList>
                  </div>
                </EvidenceContentCard>
              )}
              {evidenceParts?.map((evidencePart: EvidencePart, index) => {
                if (evidencePart["Linked Part type"] && evidencePart["Linked Part type"] === "Sibling") {
                  return null;
                }
                return (
                  <EvidencePartWrapper
                    key={evidencePart.ID}
                    isErrorBannerVisible={showErrorBanner}
                    handleElementReference={(ref) => addRefToMandatorySet(ref, evidencePart.Name)}
                    isMandatory={evidencePart.options === "Mandatory" || evidencePart["option type"] === "Mandatory"}
                    isPartIncomplete={incompleteMandatoryFields.includes(evidencePart.Name)}
                  >
                    <ProgramModuleEvidencePart
                      evidencePart={evidencePart}
                      blurRichText={() => blurRichText()}
                      focusRichText={() => setRichTextBoxFocused(true)}
                      evidenceJSON={evidenceJSON}
                      index={index}
                      onInputBlurred={() => saveDraftIfChanged()}
                      onValueChanged={(value: any, isInput?: boolean) =>
                        evidencePartChanged(evidencePart, value, isInput)
                      }
                      onChildValueChanged={(part: EvidencePart, value: any, isInput?: boolean) =>
                        evidencePartChanged(part, value, isInput)
                      }
                      key={evidencePart.ID}
                      program={program}
                      progressCheckCompetence={progressCheckCompetence}
                      mandatoryStandards={programMandatoryStandards}
                      optionalStandards={programOptionalStandards}
                      competence={competence}
                      setExistingTagModalVisible={() => setExistingTagModalVisible(true)}
                      setEmptyTagModalVisible={() => setEmptyTagModalVisible(true)}
                      learningOutcomes={progressCheckCompetence ? progressCheckCompetence["Learning Objective"] : []}
                      currentProgressCheckId={progressCheck?.ID ?? ""}
                    />
                  </EvidencePartWrapper>
                );
              })}
              <AuditTickbox active={addToHCPCAudit} toggleValue={() => toggleAuditTickbox()} />
            </>
          ) : (
            <>
              <EvidenceHeader programName="Personal Evidence" />
              <PersonalEvidenceDisclaimer />
              <EvidencePartWrapper
                isErrorBannerVisible={showErrorBanner}
                handleElementReference={(ref) => addRefToMandatorySet(ref, "Title")}
                isMandatory={true}
                isPartIncomplete={incompleteMandatoryFields.includes("Title")}
              >
                <EvidenceContentCard title="Title" mandatory={true}>
                  <input
                    ref={titleRef}
                    className="reflectionTitleInput"
                    placeholder="Give your evidence a title..."
                    autoComplete="off"
                    spellCheck
                    autoCapitalize="words"
                    id="reflectionTitleInput"
                    value={title}
                    onFocus={titleFocused}
                    onChange={titleChanged}
                    onBlur={titleBlurred}
                  />
                </EvidenceContentCard>
              </EvidencePartWrapper>
              <EvidenceContentCard title="Activity description">
                <div className="bg-white overflow-hidden ml-[-2.5px]">
                  <Textarea
                    placeholder="Provide a brief description..."
                    id="reflection-activity-description-input"
                    spellCheck
                    value={activityDescription}
                    onChange={activityDescriptionChanged}
                    onFocus={activityDescriptionFocused}
                    onBlur={activityDescriptionBlurred}
                    variant="plain"
                    slotProps={{
                      textarea: {
                        ref: activityDescriptionRef,
                      },
                    }}
                    sx={{
                      "--Textarea-placeholderOpacity": 1,
                      backgroundColor: "white",
                      minHeight: "28px",
                      color: activityDescription.length ? "black" : "var(--grey-60)", // different color for placeholder text
                      padding: 0,
                      fontSize: "20px",
                      fontWeight: "600",
                      "@media (max-width: 500px)": {
                        fontSize: "17px",
                      },
                      ":hover": {
                        color: activityDescription.length ? "black" : "var(--grey-60)", // different color for placeholder text
                      },
                    }}
                  />
                </div>
              </EvidenceContentCard>
              <EvidencePartWrapper
                isErrorBannerVisible={showErrorBanner}
                handleElementReference={(ref) => addRefToMandatorySet(ref, "Date of reflection")}
                isMandatory={true}
                isPartIncomplete={incompleteMandatoryFields.includes("Date of reflection")}
              >
                <EvidenceContentCard title="Date of reflection" mandatory={true}>
                  <div id="reflectionDatePicker" className="reflectionDateContainer" onClick={() => openDatePicker()}>
                    <div className="reflectionDateText">{format(date, "dd / MM / yyyy")}</div>
                    <img src={Button_Dropdown} className="reflectionsHeaderDropdown" alt="" />
                    <IonPopover
                      trigger="reflectionDatePicker"
                      id="date-range-picker"
                      isOpen={datePickerVisible}
                      arrow
                      onDidDismiss={() => {
                        setDatePickerVisible(false);
                        saveDraftIfChanged();
                      }}
                    >
                      <div className="pt-2 p-5">
                        <DayPicker
                          mode={"single"}
                          weekStartsOn={1}
                          selected={date}
                          defaultMonth={date}
                          onSelect={(data: any) => {
                            setDate(data);
                          }}
                          endMonth={new Date()}
                          disabled={{ after: new Date() }}
                          classNames={{
                            selected: `bg-cta-blue text-white rounded-[10px]`,
                            today: isToday(date) ? "text-white" : getDefaultClassNames().today,
                            month_caption: `${defaultClassNames.month_caption} text-[#595959] font-normal`,
                          }}
                          components={{
                            Weekday: (el) => <WeekDayRenderer element={el} />,
                          }}
                        />
                      </div>
                    </IonPopover>
                  </div>
                </EvidenceContentCard>
              </EvidencePartWrapper>
              <EvidencePartWrapper
                isErrorBannerVisible={showErrorBanner}
                handleElementReference={(ref) => addRefToMandatorySet(ref, "Activity")}
                isMandatory={true}
                isPartIncomplete={incompleteMandatoryFields.includes("Activity")}
              >
                <EvidenceContentCard title="CPD Activity" mandatory={true}>
                  <div className="reflectionActivityTypeContainer w-full overlay-hidden" onClick={() => {}}>
                    <IonList mode="md" className="reflectionActivityList w-full" color="white">
                      <IonItem mode="md" className="reflectionActivityItem" color="white">
                        <IonSelect
                          color="white"
                          mode="md"
                          className="reflectionActivitySelectItem w-full"
                          interface="popover"
                          justify="space-between"
                          interfaceOptions={{
                            size: "auto",
                            alignment: "start",
                          }}
                          placeholder="What type of CPD activity are you reflecting on"
                          value={activity?.type || undefined}
                          onIonChange={activityChanged}
                        >
                          {activityTypes.map((item, index) => {
                            return (
                              <IonSelectOption color="white" key={item.type} value={item.type}>
                                {item.type}
                              </IonSelectOption>
                            );
                          })}
                        </IonSelect>
                      </IonItem>
                    </IonList>
                  </div>
                </EvidenceContentCard>
              </EvidencePartWrapper>
              <EvidencePartWrapper
                isErrorBannerVisible={showErrorBanner}
                handleElementReference={(ref) => addRefToMandatorySet(ref, "Date of activity")}
                isMandatory={true}
                isPartIncomplete={incompleteMandatoryFields.includes("Date of activity")}
              >
                <EvidenceContentCard title="Date of activity" mandatory={true}>
                  <div
                    id="activityDatePicker"
                    className="reflectionDateContainer max-w-date-rage-picker"
                    onClick={() => openActivityDatePicker()}
                  >
                    {activityDateMode === "single" ? (
                      <div className="reflectionDateText">{format(activitySingleDate, "dd / MM / yyyy")}</div>
                    ) : (
                      <div className="reflectionDateText flex">
                        <div>
                          {activityDateRange ? format(activityDateRange.from, "dd / MM / yyyy") : "DD / MM / YYYY"}
                        </div>
                        {activityDateRange && activityDateRange.to && (
                          <div className="whitespace-pre-wrap">{" - "}</div>
                        )}
                        <div>
                          {activityDateRange && activityDateRange.to
                            ? format(activityDateRange.to, "dd / MM / yyyy")
                            : ""}
                        </div>
                      </div>
                    )}
                    <img src={Button_Dropdown} className="reflectionsHeaderDropdown" alt="" />
                    <IonPopover
                      trigger="activityDatePicker"
                      id="activity-date-range-picker"
                      isOpen={activityDatePickerVisible}
                      onDidDismiss={() => {
                        setActivityDatePickerVisible(false);
                        saveDraftIfChanged();
                      }}
                    >
                      <div className="p-5 rounded-xl">
                        <div className="flex gap-2 mb-3">
                          <button
                            className={`flex-1 border border-solid rounded-md py-2 ${activityDateMode === "single" ? "bg-cta-blue border-cta-blue text-white" : "bg-transparent border-[#D5D3D5] text-[#BEBEBE]"}`}
                            onClick={() => handleDateRangeModeChange("single")}
                          >
                            {"Single Date"}
                          </button>
                          <button
                            className={`flex-1 border border-solid rounded-md py-2 ${activityDateMode === "range" ? "bg-cta-blue border-cta-blue text-white" : "bg-transparent border-[#D5D3D5] text-[#BEBEBE]"}`}
                            onClick={() => handleDateRangeModeChange("range")}
                          >
                            {"Date Range"}
                          </button>
                        </div>
                        {activityDateMode === "single" ? (
                          <DayPicker
                            mode={"single"}
                            weekStartsOn={1}
                            selected={activitySingleDate}
                            defaultMonth={activitySingleDate}
                            onSelect={(data: any) => {
                              setActivitySingleDate(data);
                            }}
                            endMonth={new Date()}
                            disabled={{ after: new Date() }}
                            classNames={{
                              selected: `bg-cta-blue text-white rounded-[10px]`,
                              today: isToday(activitySingleDate) ? "text-white" : getDefaultClassNames().today,
                              month_caption: `${defaultClassNames.month_caption} text-[#595959] font-normal`,
                            }}
                            components={{
                              Weekday: (el) => <WeekDayRenderer element={el} />,
                            }}
                          />
                        ) : (
                          <DayPicker
                            mode={"range"}
                            selected={activityDateRange}
                            defaultMonth={activityDateRange?.from}
                            weekStartsOn={1}
                            onSelect={(_: any, triggerDate: Date) => handleDateRangeChange(triggerDate)}
                            endMonth={new Date()}
                            disabled={{ after: new Date() }}
                            classNames={{
                              selected:
                                activityDateRange?.from && !activityDateRange.to
                                  ? `bg-cta-blue text-white rounded-[10px]`
                                  : "",
                              today:
                                activityDateRange?.from && isToday(activityDateRange?.from)
                                  ? "text-white"
                                  : getDefaultClassNames().today,
                              month_caption: `${defaultClassNames.month_caption} text-[#595959] font-normal`,
                            }}
                            components={{
                              Weekday: (el) => <WeekDayRenderer element={el} />,
                            }}
                          />
                        )}
                      </div>
                    </IonPopover>
                  </div>
                </EvidenceContentCard>
              </EvidencePartWrapper>
              <EvidenceContentCard title="Activity duration">
                <DurationPicker hours={numberOfHours} onChange={onDurationPickerChange} />
              </EvidenceContentCard>
              {appFeatures.includes(IAppFeatures.SWAST_MIC) && (
                <EvidenceContentCard id="reflectionsStandardTags" title="National Occupational Standards Tags">
                  <div style={{ position: "relative" }}>
                    <div className="reflectionsStandardTagsContainer">
                      {standardTags.map((tag, index) => {
                        return (
                          <div
                            className="reflectionTagContainer"
                            key={tag.tag}
                            onClick={() => deleteStandardTag(tag.tag)}
                          >
                            <div className="reflectionTagText">{tag.tag}</div>
                            <img src={Icon_Attachment_Delete} className="attachmentIconClose" alt="" />
                          </div>
                        );
                      })}
                      <div>
                        <input
                          ref={standardTagsRef}
                          className="reflectionTagsInput"
                          placeholder="Start typing to find tags..."
                          id="reflectionStandardTagsInput"
                          autoComplete="off"
                          spellCheck
                          value={standardTag}
                          onChange={standardTagChanged}
                          onKeyUp={standardTagKeyPressed}
                          onFocus={standardTagFocused}
                        />
                      </div>
                    </div>
                    {showStandardTags && (
                      <div className="standardTagsPopover">
                        {mandatoryStandardsFiltered?.length === 0 && optionalStandardsFiltered?.length === 0 && (
                          <div className="standardTagPopoverEmpty">
                            <div className="standardTagPopoverEmptyText">{"No tags found"}</div>
                          </div>
                        )}
                        {mandatoryStandardsFiltered &&
                          mandatoryStandardsFiltered.map((item, index) => {
                            const isStart = index === 0;
                            const isLast = index === mandatoryStandardsFiltered.length - 1;

                            return (
                              <div key={item.id}>
                                {isStart && <div className="standardTagHeader">{"Mandatory"}</div>}
                                <div
                                  className={`standardTagButton ${isLast ? "standardTagButtonLast" : ""}`}
                                  onClick={() => addStandardTag(item.Code, "mandatory")}
                                >
                                  {`${item.Code}${item.Description ? ` - ${item.Description}` : ""}`}
                                </div>
                              </div>
                            );
                          })}
                        {optionalStandardsFiltered &&
                          optionalStandardsFiltered.map((item, index) => {
                            const isStart = index === 0;
                            const isLast = index === optionalStandardsFiltered.length - 1;

                            return (
                              <div key={item.id}>
                                {isStart && <div className="standardTagHeader">{"Optional"}</div>}
                                <div
                                  className={`standardTagButton ${isLast ? "standardTagButtonLast" : ""}`}
                                  onClick={() => addStandardTag(item.Code, "optional")}
                                >
                                  {`${item.Code}${item.Description && ` - ${item.Description}`}`}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </EvidenceContentCard>
              )}
              <EvidenceContentCard title="Custom tags">
                {customTags.length > 0 && (
                  <div className={`relative flex flex-row flex-wrap items-center gap-2 mb-2`}>
                    {customTags.map((tag, index) => {
                      return (
                        <div className="reflectionTagContainer" key={tag.tag} onClick={() => deleteCustomTag(tag.tag)}>
                          <div className="reflectionTagText">{tag.tag}</div>
                          <img src={Icon_Attachment_Delete} className="attachmentIconClose" alt="" />
                        </div>
                      );
                    })}
                  </div>
                )}
                <CustomTagInput oldTags={getOldTags()} handleAddTag={(newTag) => saveNewTag(newTag)} />
              </EvidenceContentCard>
              <EvidenceContentCard title="Relevant documents (85MB limit / file)">
                <AttachmentInput
                  attachments={attachments}
                  viewingAttachment={viewingAttachment}
                  viewingAttachmentFilename={viewingAttachmentFilename}
                  viewAttachment={(attachment) => viewAttachment(attachment)}
                  deleteAttachment={(filename) => deleteAttachment(filename)}
                  openFileSelector={() => openFileSelector()}
                />
              </EvidenceContentCard>
              <EvidencePartWrapper
                isErrorBannerVisible={showErrorBanner}
                handleElementReference={(ref) => addRefToMandatorySet(ref, "Reflections")}
                isMandatory={true}
                isPartIncomplete={incompleteMandatoryFields.includes("Reflections")}
              >
                <EvidenceContentCard title="Reflection" mandatory={true}>
                  <Editor
                    init={EditorUtils.ReflectionsEditorConfig}
                    tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                    id="reflectionsEditorRef"
                    onInit={(event, editor) => (reflectionsEditorRef.current = editor)}
                    value={reflections}
                    onEditorChange={(a, editor) => {
                      a.length > 0 && editor.getBody().setAttribute("data-mce-placeholder", "");
                      setReflection(a);
                      isNewEvidence && setCanSaveDraft(true);
                    }}
                    onFocus={reflectionsEditorFocused}
                    onBlur={reflectionsEditorBlurred}
                  />
                </EvidenceContentCard>
              </EvidencePartWrapper>
              <EvidencePartWrapper
                isErrorBannerVisible={showErrorBanner}
                handleElementReference={(ref) => addRefToMandatorySet(ref, "Benefits")}
                isMandatory={true}
                isPartIncomplete={incompleteMandatoryFields.includes("Benefits")}
              >
                <EvidenceContentCard title="Benefits" mandatory={true}>
                  <Editor
                    init={EditorUtils.BenefitsEditorConfig}
                    tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                    id="benefitsEditorRef"
                    onInit={(event, editor) => (benefitsEditorRef.current = editor)}
                    value={benefits}
                    onEditorChange={(a, editor) => {
                      a.length > 0 && editor.getBody().setAttribute("data-mce-placeholder", "");
                      setBenefits(a);
                      isNewEvidence && setCanSaveDraft(true);
                    }}
                    onFocus={benefitsEditorFocused}
                    onBlur={benefitsEditorBlurred}
                  />
                </EvidenceContentCard>
              </EvidencePartWrapper>
              <EvidenceContentCard title="Learning / Exercise Objectives">
                <Editor
                  init={EditorUtils.ObjectivesEditorConfig}
                  tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                  id="objectivesEditorRef"
                  onInit={(event, editor) => (objectivesEditorRef.current = editor)}
                  value={objectives}
                  onEditorChange={(a, editor) => {
                    a.length > 0 && editor.getBody().setAttribute("data-mce-placeholder", "");
                    setObjectives(a);
                    isNewEvidence && setCanSaveDraft(true);
                  }}
                  onFocus={objectivesEditorFocused}
                  onBlur={objectivesEditorBlurred}
                />
              </EvidenceContentCard>
              <AuditTickbox active={addToHCPCAudit} toggleValue={() => toggleAuditTickbox()} />
            </>
          )}
          <AttachmentProgressIndicator visible={handlingAttachments} />
          {/* <pre>{JSON.stringify(evidenceJSON, null, 2)}</pre> */}
          <SaveEvidenceButton
            disabled={isSaveButtonDisabled()}
            saving={saving}
            isDraft={isDraft}
            isNewEvidence={isNewEvidence}
            saveEvidence={() => {
              const mandatoryFieldsCheck = areAllMandatoryFieldsComplete();
              if (mandatoryFieldsCheck.complete) {
                saveEvidence();
              } else {
                setShowErrorBanner(true);
                setIncompleteMandatoryFields([...mandatoryFieldsCheck.incompleteFields]);
                setActiveMandatoryFieldIndex(0);
                scrollToMandatoryField(0, mandatoryFieldsCheck.incompleteFields);
              }
            }}
          />
        </ContentContainer>
      </IonContent>
    </IonModal>
  );
};

export default EvidenceModal;
