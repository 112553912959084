import { useRecoilValueLoadable } from "recoil";
import { AllProgressCheckStatuses, ProgramModuleStatus } from "../../Interfaces";
import { progressCheckDataAtom } from "../../state/State";
import { useState } from "react";

export const useProgramModuleStatus = (programID: string = "") => {
  const allProgressCheckData = useRecoilValueLoadable<AllProgressCheckStatuses[]>(progressCheckDataAtom);

  let programModuleStatus: ProgramModuleStatus | undefined;

  if (allProgressCheckData.state === "hasValue") {
    const dataForProgram: AllProgressCheckStatuses | undefined = allProgressCheckData.contents?.find(
      (item: any): item is AllProgressCheckStatuses => item.programID === programID
    );

    const data = dataForProgram?.pCs.find((item) => item.pCId === "pMData");

    programModuleStatus = data?.pMData;
  }

  const getProgramModulePauseStatus = (program: string) => {
    if (allProgressCheckData.state === "hasValue") {
      const dataForProgram: AllProgressCheckStatuses | undefined = allProgressCheckData.contents?.find(
        (item: any): item is AllProgressCheckStatuses => item.programID === program
      );

      const data = dataForProgram?.pCs.find((item) => item.pCId === "pMData");

      return data?.pMData?.data.Pause === "PauseAndHold" || undefined;
    }

    return undefined;
  };

  const getProgramModuleFastTrackStatus = (program: string) => {
    if (allProgressCheckData.state === "hasValue") {
      const dataForProgram: AllProgressCheckStatuses | undefined = allProgressCheckData.contents?.find(
        (item: any): item is AllProgressCheckStatuses => item.programID === program
      );

      const data = dataForProgram?.pCs.find((item) => item.pCId === "pMData");

      return data?.pMData?.data.FastTrack === "Enabled" || undefined;
    }

    return undefined;
  };

  return {
    programModuleStatus,
    fastTrackActive: programModuleStatus?.data.FastTrack === "Enabled",
    pauseAndHold: programModuleStatus?.data.Pause === "PauseAndHold",
    getProgramModulePauseStatus,
    getProgramModuleFastTrackStatus,
  };
};
