import { PushNotifications } from "@capacitor/push-notifications";
import { useEffect } from "react";
import RemoteDataController from "../../controllers/RemoteDataController";
import DataController from "../../controllers/DataController";
import { EventRegister } from "react-native-event-listeners";
import { FirebaseService } from "../../controllers/FirebaseService";
import { Browser } from "@capacitor/browser";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../state/State";

export const useNotificationSetup = () => {
  const history = useHistory();

  useEffect(() => {
    const notificationListenerSetup = async (): Promise<void> => {
      await PushNotifications.addListener("registration", (token) => {
        console.log("FCM token: ", token.value);
      });

      await PushNotifications.addListener("registrationError", (error) => {
        console.log("Registration error: ", error.error);
        RemoteDataController.logError(error.error);
      });

      await PushNotifications.addListener("pushNotificationReceived", (notification) => {
        console.log("Push notification received: ", notification);
      });

      await PushNotifications.addListener("pushNotificationActionPerformed", (notification) => {
        console.log("Push notification action performed: ", notification);

        if (notification?.notification && notification.notification.data) {
          if (notification.notification.data.deepLinkUrl) {
            location.href = notification.notification.data.deepLinkUrl;
          }
        }
      });
    };

    const notificationSetup = async (): Promise<void> => {
      if (!(await DataController.isWebAsync())) {
        try {
          let permStatus = await PushNotifications.checkPermissions();

          if (permStatus.receive === "prompt") {
            permStatus = await PushNotifications.requestPermissions();
          }

          if (permStatus.receive !== "granted") {
            throw new Error("User denied notification permissions!");
          }

          await notificationListenerSetup();
          await PushNotifications.register();
        } catch (error: any) {
          console.log(error);
          RemoteDataController.logError(error);
        }
      }
    };

    notificationSetup();
  }, []);
};
