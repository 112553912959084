/* eslint-disable @typescript-eslint/no-explicit-any */
// [key: string]: any // for any additional properties

export interface IUser {
  token?: string;
  userData?: any;
  subscription?: any;
  email?: string;
  name?: string;
  role?: string;
  certificate?: IUserCertificate;
  certificates?: IUserCertificate[];
  personalStatement?: string;
  personalStatementTimestamp?: string;
  summaryOfWork?: string;
  summaryOfWorkTimestamp?: string;
  HCPCNumber?: string;
  usageData?: boolean;
  programCertificates?: IUserProgramCertificate[] | undefined;
  programRoles?: IUserProgramRoles[];
}

export interface IAccessibilitySettings {
  fontSizeChange?: number;
  lineHeightChange?: number;
  boldText?: boolean;
  fontFamily?: string;
  textToSpeech?: boolean;
  underlineButtons?: boolean;
}

export interface IPersonalStatement {
  lastUpdated: string;
  text: string;
}

export interface ISummaryOfWork {
  lastUpdated: string;
  text: string;
}

export interface OnboardingCertificateIssueDate {
  date: Date;
  type: string;
}

export interface OnboardingDatePickerType {
  isIssueOrExpiry: string;
}

export interface IProgressCheckInfo {
  progressCheck: ProgressCheck;
  progressCheckStatus?: ProgressCheckStatus;
  progressCheckEvidence: IEvidence[];
  status: "Not started" | "In progress" | "Submitted" | "Ready for submission";
}

export interface IProgressCheckLabel {
  label: string;
  status: "Not started" | "In progress" | "Completed" | "Attention" | "Open";
}

export interface IUserCertificate {
  certificate: ICertificateInfo | undefined;
  issueDate: string;
}

export interface IUserProgramCertificate {
  certificate: ICertificateInfo | undefined;
  issueDate?: string;
  expiryDate?: string;
  programData: ICertificateProgramData;
}

export interface IUserProgramRoles {
  programName: string;
  programRoles: any[]; // should be string[] or {roleID: string; roleName: string; optionalStandards: any[]}[] for NWAS IC
  programID?: string;
  area?: string;
  team?: string;
  declarationTickDate?: string;
}

export interface ICertificateProgramData {
  programName: string;
  competenceName?: string;
  certificateType?: string;
}

export interface ICertificateInfo {
  name: string;
  type: string;
  size: number;
  content?: string;
  url?: string;
  lastModified: number;
  certificateType: string;
}

export interface ILoginModal {
  page?: string;
  visible: boolean;
  closeModal: (value: boolean) => void;
}

export interface IEvidenceModal {
  visible: boolean;
  closeModal: (value: boolean) => void;
  body?: string | null;
}

export interface IInfoModal {
  visible: boolean;
  closeModal: () => void;
  body?: string | null;
  program: ProgramData;
}

export interface IDashboardCard {
  title: string;
  subtitle?: string;
  isProfile?: boolean;
  inProgress?: boolean;
  comingSoon?: boolean;
  newFeature?: boolean;
  sideButton?: boolean;
  sideButtonText?: string;
  buttonClicked?: () => void;
  children?: React.ReactNode;
}

export interface IOverviewCard {
  userObject: IUser | null;
  openByDefault: boolean;
  profilePage?: boolean;
}

export interface INOSOverviewCard {
  userObject: IUser | null;
}

export interface IHARTCard {
  userObject: IUser | null;
}

export interface IGuidelineCard {
  title: string;
  GLID: string;
  addEvidenceClicked?: () => void;
}

export interface IEvidenceHeader {
  id?: string;
  programName?: string;
  competenceName?: string;
}

export interface IEvidenceCard {
  id?: string;
  title: string;
  mandatory?: boolean;
  children?: React.ReactNode;
}

export interface IEvidencePartWrapper {
  isErrorBannerVisible: boolean;
  handleElementReference: (ref: HTMLDivElement) => void;
  isMandatory: boolean;
  isPartIncomplete: boolean;
  children?: React.ReactNode;
}

export interface IDateRange {
  from: Date;
  to?: Date;
}

export interface IProfileSettingsCard {
  title: string;
  children?: React.ReactNode;
}

export interface IProfileSettingsButton {
  icon: string;
  title: string;
  largeIcon?: boolean;
  inProgress?: boolean;
  comingSoon?: boolean;
  syncingData?: boolean;
  showDisclosure?: boolean;
  buttonClicked?: () => void;
  logout?: boolean;
}

export interface IMenuButton {
  icon: string;
  title: string;
  syncingData?: boolean;
  showDisclosure?: boolean;
  buttonClicked?: () => void;
}

export interface IInformationCardButton {
  icon: string;
  title: string;
  subtitle: string;
  disabled?: boolean;
  buttonClicked?: () => void;
}

export interface CPDActivityType {
  type: string;
}

export interface StandardTag {
  id: string;
  Code: string;
  Roles?: string[] | undefined;
  "Roles 2"?: string[] | undefined;
  Description?: string | undefined;
}

export interface EvidenceAttachment {
  name: string;
  type: string;
  size: number;
  content?: string;
  url?: string;
  id?: number;
}

export interface EvidenceStandardTag {
  tag: string;
  id?: number;
}

export interface EvidenceCustomTag {
  tag: string;
  id?: number;
}

export interface CompMultiCollapsibleItem {
  compId: string;
  compName: string;
  isSelected: boolean;
  isOpen: boolean;
  selectedOutcomes: { id: string; name: string }[];
  selectedOutcomesLimit: number;
}

export interface INewGuidelineEvidence {
  title: string;
}

export interface INewTimelineEvidence {
  title: string;
  date: Date;
  activityDate: Date;
  activityDescription: string;
  timelineReference: string;
}

export interface INewDeepLinkEvidence {
  title?: string;
  date?: Date;
  activityDate?: Date;
  activityDescription?: string;
  timelineReference?: string;
  activityType?: string;
  activityDuration?: string;
  reflection?: string;
  benefits?: string;
  learningObjectives?: string;
}

export interface IEvidence {
  title?: string | undefined;
  date: string;
  activityDate?: string | undefined;
  activityDateEnd?: string | undefined;
  activity?: CPDActivityType | undefined;
  numberOfHours?: string | undefined;
  standardTags?: any[] | undefined;
  customTags?: any[] | undefined;
  attachments?: any[] | undefined;
  activityDescription?: string | undefined;
  reflections?: string | undefined;
  benefits?: string | undefined;
  objectives?: string | undefined;
  addToHCPCAudit?: boolean | undefined;
  draft?: boolean | undefined;
  timelineReference?: string | undefined;
  program?: string | undefined;
  id: string;
  private?: boolean | undefined;
  dictationInput?: string | undefined;
  programInfo?: any;
  evidenceJSON?: any;
}

export interface IEvidenceDraft {
  evidence: IEvidence;
  date: string;
}

export interface UsageData {
  CompName: string;
  compId: string;
  percentage: string;
}

export interface INewsArticle {
  Name: string;
  "Thumbnail Image": any;
  HTML: string;
  Date: any;
  Title: string;
  Subtitle: string;
  "URL Slug": string;
  Status: any;
  "Last Modified": any;
}

export interface AWSAccessParams {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  region: string;
  bucket: string;
}

export interface IClassSubscription {
  App_Data: string;
  CustomFieldList: CustomFieldList[];
  Description: string;
  DisplayExpiryDate: string;
  ExpiryDate: string;
  ExpiryDateStr: string | null;
  ESR?: string | null;
  FreeTrial?: boolean;
  OTA_DEBUG: string;
  Status: number;
  SubscriberID: string;
  SubscriptionID: number;
  ParaFolioPrograms?: string[];
  BetaFeatures?: string[];
  Title: string;
  TrustName: string;
  region: any;
  staffGroup: any[];
}

export interface CustomFieldList {
  Key: string;
  Value: string;
}

export interface AttachmentImageMetadata {
  width: number;
  height: number;
}

export interface IPlusUsageData {
  GLID: string;
  id: number;
  product: string;
  timestamp: string;
  title: string;
  trust: string;
  versionNumber: string;
  wyvernID: string;
  count?: number;
  sortableDate?: Date;
  filterType?: string;
}

export interface IParapassUsageData {
  date: number;
  quizSet: string;
  score: IParapassQuizScore;
  title: string;
  type: string;
  filterType?: string;
  sortableDate?: Date;
}

export interface IParapassQuizScore {
  correct: number;
  incorrect: number;
  unanswered: number;
}

export enum IAppVersion {
  SWAST = "SWAST",
  GENERAL = "GENERAL",
}

export enum IAppFeatures {
  SWAST_MIC = "SWAST_MIC",
  SWAST_NQP = "SWAST_NQP",
  SWAST_HART = "SWAST_HART",
  GENERAL = "GENERAL",
}

export enum IEvidenceView {
  EVIDENCE = "EVIDENCE",
  DRAFTS = "DRAFTS",
}

export enum IExportType {
  PROGRAM = "PROGRAM",
  MAJOR_INCIDENT = "MAJOR_INCIDENT",
  HCPC = "HCPC",
  GENERAL = "GENERAL",
}

export enum ISegmentOptions {
  ONE_MONTH = 1,
  SIX_MONTHS = 6,
  ONE_YEAR = 12,
  TWO_YEARS = 24,
}

export interface IAppEntitlementsResponse {
  success: boolean;
  subs: ISubscriptionPurchase[];
}

export interface ISubscriptionProduct {
  productId: string;
  price: string;
  displayName: string;
  description: string;
}

export interface ISubscriptionPurchase {
  id?: string;
  productId?: string;
  transactionId?: string;
  startDate?: string;
  endDate?: string;
}

export enum IAppAccess {
  SWAST = "SWAST",
  GROUP = "GROUP",
  INDIVIDUAL = "INDIVIDUAL",
}

export enum ISubscriptionWindow {
  WITHIN_TRIAL = "WITHIN_TRIAL",
  TRIAL_EXPIRED = "TRIAL_EXPIRED",
  NO_SUBSCRIPTION = "NO_SUBSCRIPTION",
  HAS_SUBSCRIPTION = "HAS_SUBSCRIPTION",
}

export enum ISubscriptionModalType {
  ONBOARDING = "ONBOARDING",
  PROFILE = "PROFILE",
}

export enum IFontFamily {
  INTER = "Inter",
  OPEN_DYSLEXIC = "Open Dyslexic",
}

export enum IDatePickerType {
  ISSUE = "ISSUE",
  EXPIRY = "EXPIRY",
}

export interface ProgramData {
  Name: string;
  CompetenceActivity?: CompetenceActivityClass[];
  ProgressChecks?: ProgressCheck[];
  Title?: string;
  "Short Title"?: string;
  Trust?: string;
  PopupDisclaimer?: string;
  MentorPopupDisclaimer?: string;
  Subscription?: string[];
  ID: string;
  Roles?: ProgramRole[];
  Certificates?: ProgramCertificate[];
  TrustName?: string;
  DashboardDescription?: string;
  DetailsDescription?: string;
  "Evidence Definitions"?: EvidenceDefinition[];
  EvidenceButtonText?: string | null;
  Teams?: string[];
  BaseID?: null;
  showBetaLabel: boolean;
  version: number;
  id: string;
  Areas?: string[];
  WellbeingModules?: WellbeingModule[];
  MentorNamingConvention?: string;
  MentorTitle?: string;
  MenteeTitle?: string;
  ProgressCheckListSubtitle?: string;
  CompletionText?: string;
  DeclarationOfObligationsText?: string;
  PauseDescription?: string;
  PauseAndHoldDescription?: string;
  FastTrackDescription?: string;
  ConfidenceLevelGraphLabels?: string[];
}

export interface WellbeingModule {
  Name: string;
  ID: string;
  "Activity Pages": ActivityPage[];
  "Popup Text"?: string;
  "Confirm Text"?: string;
  Program: string[];
  CompetenceID: string;
  id: string;
}

export interface ActivityPage {
  Name: string;
  ID: string;
  "Chart Header"?: string;
  "Show in Results View"?: boolean;
  "Show Skip Button"?: boolean;
  "Wellbeing Parts": WellbeingPart[];
  "Wellbeing Modules": string[];
  id: string;
}

export interface WellbeingPart {
  Name: string;
  ID: string;
  "Part type": string;
  "Display Title": null | string;
  "List of values": null | string;
  "Placeholder Text": null | string;
  "Max value": number | null;
  options: null;
  "Wellbeing Activity": string[];
  "Contact Link"?: string;
  "Support Image"?: string;
  id: string;
  "Linked Key"?: string;
  "Hide in Activity Page"?: boolean;
  "Hide in Results Page"?: boolean;
  "Info Block Button"?: string;
  isSmallerCard?: boolean;
}

export enum WellbeingPartType {
  InfoBlock = "InfoBlock",
  StartButton = "StartButton",
  WellbeingRange = "WellbeingRange",
  NextButton = "NextButton",
  TextBox = "TextBox",
  SkipNextButton = "SkipNextButton",
  SupportBlock = "SupportBlock",
  RadarGraph = "RadarGraph",
  BarGraph = "BarGraph",
  TextDisplay = "TextDisplay",
}

export enum WellbeingInfoCardButton {
  SeeResults = "See results",
  ViewSupportOptions = "View support options",
}

export interface CompetenceActivityClass {
  Certificates: ProgramCertificate[];
  Description?: string;
  "Evidence Definitions": EvidenceDefinition[];
  "Expire Notice"?: string;
  ID: string;
  Name: string;
  Program?: string[];
  SkillName?: string;
  Skills?: ProgramSkill[];
  id: string;
  Mentor?: string;
  "Number Evidences required"?: number;
  Periods: any[];
  "Learning Objective"?: LearningObjective[];
  "LOs Automatic"?: boolean;
  MultipleAllowed?: boolean;
  isFinalReview?: boolean;
  NoEdit?: boolean;
  FilledInBy?: string;
  isWellbeingActivity?: boolean;
  isLockedByRequiredEvidence?: boolean;
  canUnlockSubmission?: boolean;
  FastTrackEvidence?: boolean;
}

export interface ProgramCertificate {
  id: string;
  Name: string;
  Duration?: string;
  Type: string;
  Required: string;
  ID: string;
  Program?: string[];
  Description?: string;
  "Competence/Activity": string[];
  Roles?: string[];
}

export enum FilledInBy {
  Mentor = "Mentor",
}

export enum Required {
  Mandatory = "Mandatory",
  Optional = "Optional",
}

export interface EvidenceDefinition {
  Competence: string[];
  "Evidence parts"?: EvidencePart[];
  "Evidence parts Mandatory"?: any[];
  "Evidence parts Optional"?: any[];
  Name: string;
  id: string;
  ID: string;
}

export interface EvidencePart {
  "Display Title"?: string;
  "Evidence Definitions"?: string[];
  "Field type"?: string;
  "Banner color"?: string;
  "Banner colour"?: string;
  Name: string;
  "Placeholder Text"?: string;
  id: string;
  ID: string;
  "option type"?: string;
  options?: string;
  "List of values"?: string;
  "Max value"?: number;
  Skills?: ProgramSkill[];
  "Linked Part"?: EvidencePart[];
  "Evidence Definitions 2"?: string[];
  "Linked Part trigger"?: string;
  "Linked Part type"?: string;
  "notes "?: string;
  "Learning Objective": any[];
  "Progress Check Submission Trigger"?: string;
  "Progress Check Submission Unlock Trigger"?: string;
}

export enum FieldType {
  ActivityDuration = "Activity duration",
  Attachments = "Attachments",
  CustomTags = "Custom tags",
  Date = "Date",
  Number = "Number",
  PullDownList = "Pull down list",
  RadioButton = "RadioButton",
  RadioYesNoWithComments = "Radio yes/no with comments",
  RolesSelect = "Roles select",
  Scale15 = "Scale 1-5",
  SingleLineText = "SingleLine text",
  SingleTick = "SingleTick",
  TextBox = "TextBox",
  TickBoxList = "Tick box list",
  Time = "Time",
  NOSSelect = "NOS Select",
  LearningOutcomesList = "LearningOutcomesList",
  CompMultiSelect = "CompMultiSelectForLOs",
  LearningOutcomesListSegmented = "LearningOutcomesListSegmented",
  LOsMultiSelectForNQP = "LOsMultiSelectForNQP",
  MentorInfo = "MentorInfo",
}

export enum LinkedPartType {
  Child = "Child",
  Sibling = "Sibling",
}

export interface IOptionalStandard {
  id: string;
  name: string;
  description: string;
  learningObjectives: LearningObjective[];
}

export interface IMandatoryStandard {
  id: string;
  name: string;
  description: string;
  learningObjectives: LearningObjective[];
}

export interface LearningObjective {
  id: string;
  Competence: string[] | null;
  ID: string;
  "Display Name": string | null;
  Name: string;
  Description?: string | null;
  Section: string | null;
  NumberRequired?: number | null;
  ProgressChecks: string[] | null;
  "Top Section"?: string;
  IncludeInMonthlyEvidence: boolean;
  TopSection?: string;
}

export interface ProgramSkill {
  id: string;
  Name: string;
  duration: string;
  numberRequiredInDuration: string;
  "Competence/Activity": string[];
  "Evidence Parts": EvidencePart[];
  ID: string;
  durationDescription?: string;
  Description?: string;
}

export interface ProgramPeriod {
  Name: string;
  Notes?: string;
  Program: string[];
  id: string;
  ID: string;
  "Competence/Activity"?: string[];
}

export interface ProgressCheck {
  id: string;
  ID: string;
  Name: string;
  Program: string[];
  Approval?: string;
  "Competence/Activity": CompetenceActivityClass[];
  Description?: string;
  SequentiallyLocked?: string;
  UnlockedByID?: string;
  CommentsSupported?: boolean;
  SubmissionModalHeader?: string;
  SubmissionModalBody?: string;
  SubmissionHeaderText?: string;
  SubmissionWaitingText?: string;
  SubmissionRejectionText?: string;
  SubmissionRejectionResetText?: string;
  SubmissionDateText?: string;
  SubmissionApprovedText?: string;
  "LOs Automatic"?: LearningObjective[] | null;
}

export enum SequentiallyLocked {
  Locked = "Locked",
  LockedIgnore = "Locked Ignore",
  Unlocked = "Unlocked",
  UnlockedIgnore = "Unlocked Ignore",
}

export enum ProgressCheckApproval {
  NotRequired = "Not Required",
  Required = "Approval Required",
  RequiredExtension = "Approval Required Extension Allowed",
  ApprovalOO = "Approval Required OO",
}

export interface ProgramRole {
  id: string;
  Name: string;
  Program?: string[];
  ID: string;
  CompetenceActivity?: CompetenceActivityClass[] | string[];
  OptionalCompetenceActivity?: CompetenceActivityClass[] | string[];
  Certificates?: ProgramCertificate[];
  Description?: string;
}

export interface IComment {
  comment: string;
  contactId: string;
  date: string;
  displayName: string;
  role: string;
  mentorTitle?: string;
}

export interface ConfidenceLevelData {
  area1: {
    areaName?: string;
    data?: number[];
  };
  area2: {
    areaName?: string;
    data?: number[];
  };
  general: {
    areaName: "General";
    data: number[];
  };
}

export interface AllProgressCheckStatuses {
  programID?: string;
  pCs: ProgressCheckStatus[];
  mentors: Mentor[];
}

export interface Mentor {
  name: string;
  contactId: string;
  email: string;
  role: string;
}

export interface ProgressCheckStatus {
  iD: string;
  comments: IComment[];
  commentReadTimes: CommentReadTime[];
  menteeContactId: number;
  pCId: string;
  pMId: string;
  submissions: ProgressCheckSubmission;
  pMData?: ProgramModuleStatus;
}

export interface ProgramModuleStatus {
  data: ProgramModuleMetadata;
  history: ProgramModuleStatusHistory[];
}

export interface ProgramModuleStatusHistory {
  date: string;
  pmData: ProgramModuleMetadata;
  byContactID: string;
  displayName: string;
}

export interface ProgramModuleMetadata {
  Pause?: "Pause" | "PauseAndHold";
  FastTrack?: "Enabled" | "Disabled";
  PauseComment?: string;
  FastTrackComment?: string;
}

export interface PauseLabel {
  label: string;
  status: "Paused" | "Pause & Hold";
}

export interface FastTrackLabel {
  label: string;
  status: "Fast Track";
}

export interface CommentReadTime {
  Date: string;
  ContactID: string | number;
}

export interface ProgressCheckSubmission {
  status: string;
  history: ProgressCheckSubmissionHistory[];
}

export enum SubmissionButtonState {
  Submit = "Submit",
  WaitingForApproval = "Waiting for approval",
  Resubmit = "Resubmit",
  Approved = "Approved",
}

export enum SubmissionState {
  Submitted = "Submitted",
  Approved = "Approved",
  OnHold = "OnHold",
  OnHoldReset = "OnHoldReset",
  InProgress = "In-Progress",
  Completed = "Completed",
}

export enum ProgressState {
  NotStarted = "Not started",
  InProgress = "In progress",
  ReadyForSubmission = "Ready for submission",
  Submitted = "Submitted",
}

export interface ProgressCheckSubmissionHistory {
  date: string;
  role: string;
  comment: string;
  operation: string;
  byContactID: string;
  displayName: string;
}

export interface INOSSelectCompetence {
  id: string;
  Name: string;
}

export interface INOSSelectLearningObjectives {
  id: string;
  displayName: string;
}

export interface ProgramDataVersionInfo {
  Name: string;
  Trust?: string;
  ID: string;
  version: number;
}

export interface ProgramDataVersions {
  type: "Debug" | "Release" | "User";
  versionInfo: ProgramDataVersionInfo[];
}

export interface IWellbeingContainer {
  program: ProgramData;
  wellbeingActivity: WellbeingModule;
}

export interface IWellbeingResultsContainer {
  program: ProgramData;
  wellbeingActivity: WellbeingModule;
}

export interface HelpCentreContent {
  Title: null | string;
  HTML: string;
  Image: HelpCentreImage | null;
  "Last Modified": Date;
  VideoID: null | string;
  Enabled: boolean | null;
  Section: string;
  ID: string;
}

export interface HelpCentreImage {
  url: string;
  filename: string;
  width: number;
  height: number;
  size: number;
  type: string;
}

export interface HelpCentreTimestamp {
  lastUpdatedTime: number;
}
