import { IonItem } from "@ionic/react";
import { CompetenceActivityClass, ProgressCheckStatus } from "../../Interfaces";
import { Button_Disclosure_Right_Grey } from "../../assets/images";
import * as ProgramUtils from "../../utils/programUtils";

export type CompetenceStatus = keyof typeof CompetenceStatusStyles;

// In Progress - Some evidence added, OR some LOs added
// Added  - All mandatory evidence AND all mandatory LO's have been added. This means that optional evidence with optional LOs will NEVER display Added.
// Open - No evidence added, and adding evidence is available
// Locked -
// - For non-review competencies: evidence can't be added because the progress check is done (e.g. approved), or not yet unlocked (requires completetion of previous progress check).
// - For 'End of Course Review' competency: evidence can't be added because some mandatory evidence is missing
// - For 'End of Mentoring Review' competency: evidence can't be added because some mandatory evidence OR some mandatory LOs are missing
// Mentor to Add - // TODO Only mentor can add
const CompetenceStatusStyles = {
  "In Progress": "text-[#92472A] bg-orange-20",
  Added: "text-[#235136] bg-green-background",
  Open: "text-[#007aff] bg-open-label",
  Locked: "text-[#878E95] bg-grey-30",
  "Mentor to Add": "text-[#878E95] bg-grey-30", // TODO using this properly??
};

interface Props {
  competence: CompetenceActivityClass;
  evidenceLabel?: string;
  learningOutcomesLabel?: string;
  competenceStatus: CompetenceStatus; // "In Progress" or "Added" or "Open" etc.
  onPress?: (e: any) => void;
  programId: string;
  progressCheckData?: ProgressCheckStatus | null;
}

const ProgressCheckEvidenceButton = (props: Props) => {
  const showDisclosureIcon = shouldShowDisclosureIcon();

  function shouldShowDisclosureIcon(): boolean {
    if (ProgramUtils.canHandleMultiplePiecesOfEvidencePerEvidenceType(props.programId)) {
      if (props.competence.isWellbeingActivity) {
        if (props.competenceStatus === "Locked") {
          return false;
        } else if (props.competenceStatus === "Added") {
          return true;
        }
        return ProgramUtils.canAddOrEditProgressCheckEvidence(props.progressCheckData?.submissions);
      } else if (props.competence["Learning Objective"] && props.competence["Learning Objective"].length) {
        return true;
      } else {
        const status = props.competenceStatus;
        if (props.competence.MultipleAllowed) {
          if (status === "Open" || status === "In Progress" || status === "Added") {
            return true;
          }
        } else {
          if (status === "Open" || status === "In Progress") {
            return true;
          }
        }
      }
    } else {
      const status = props.competenceStatus;
      if (status === "Open" || status === "In Progress") {
        return true;
      }
    }

    return false;
  }

  return (
    // TODO replace preceptee-item-button class
    <IonItem
      button={showDisclosureIcon}
      detail={false}
      onClick={props.onPress}
      className="flex preceptee-item-button"
      style={{ "--inner-padding-top": "4px", "--inner-padding-bottom": "4px" }}
    >
      <div className="flex-1 py-[4px]">
        <div className="text-[15px] leading-[1.33] tracking-[-0.3px] text-grey-90">{props.competence.Name}</div>
        {props.evidenceLabel && (
          <div className={`text-[11px] leading-[1.273] text-grey-60 ${props.learningOutcomesLabel && "pb-px"}`}>
            {props.evidenceLabel}
          </div>
        )}
        {props.learningOutcomesLabel && (
          <div className="text-[11px] leading-[1.273] text-grey-60">{props.learningOutcomesLabel}</div>
        )}
      </div>
      <div className="flex items-center">
        <div
          className={`flex shrink-0 max-h-5 items-center justify-center py-[2.25px] px-2 rounded-[12px] text-[13px] font-semibold ${CompetenceStatusStyles[props.competenceStatus]}`}
        >
          {props.competenceStatus}
        </div>
        {showDisclosureIcon ? (
          <img src={Button_Disclosure_Right_Grey} className="h-[24px] w-[24px] img-no-select" />
        ) : (
          <div className="w-[24px] h-[24px]" />
        )}
      </div>
    </IonItem>
  );
};

export default ProgressCheckEvidenceButton;
