import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonSpinner,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import DataController from "../../controllers/DataController";
import "../onboarding/LoginModal.css";
import {
  AllProgressCheckStatuses,
  IAccessibilitySettings,
  IClassSubscription,
  IEvidence,
  IEvidenceDraft,
  IParapassUsageData,
  IPlusUsageData,
  ISubscriptionModalType,
  ISubscriptionProduct,
  ISubscriptionPurchase,
  ISubscriptionWindow,
  IUser,
} from "../../Interfaces";
import ContentContainer from "../common/ContentContainer";
import "../onboarding/LoginModal.css";
import {
  Button_Checkbox_Inactive,
  Button_Close,
  Button_Tickbox_Active,
  Image_IAP_Apps,
  Image_IAP_Graph,
  Image_IAP_Tag,
  Image_IAP_Ticks,
} from "../../assets/images";
import { Capacitor } from "@capacitor/core";
import {
  APPLE_PRODUCT_IDS,
  CLASS_PARAFOLIO_URL,
  CLASS_TERMS_URL,
  GOOGLE_PRODUCT_IDS,
  PF_FEATURES_URL,
} from "../../Constants";
import { Subscriptions } from "capacitor-subscriptions";
import _ from "lodash";
import { FirebaseService } from "../../controllers/FirebaseService";
import { useRecoilStateLoadable, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
  accessibilityAtom,
  appInfoAtom,
  deviceInfoAtom,
  draftsHintDismissedAtom,
  evidenceAtom,
  evidenceDraftsAtom,
  guidelinesCardDismissedAtom,
  parapassDataAtom,
  plusDataAtom,
  programInfoDismissedAtom,
  progressCheckDataAtom,
  userAtom,
  welcomeDismissedAtom,
} from "../../state/State";
import { DeviceInfo } from "@capacitor/device";
import { AppInfo } from "@capacitor/app";
import IAPService from "../../controllers/IAPService";
import { addDays, differenceInDays, format, isAfter, isSameDay, startOfDay } from "date-fns";
import AuthenticationController from "../../controllers/AuthenticationController";
import { EventRegister } from "react-native-event-listeners";
import { useHistory } from "react-router";
import HeaderTitle from "../common/HeaderTitle";
import { SubscriptionUtils } from "../../utils/subscriptionUtils";

type Props = {
  visible: boolean;
  tempUser?: IUser | null;
  closeModal: (value: boolean) => void;
  continueWithTrial: () => void;
  type: ISubscriptionModalType;
};

const SubscriptionsModal: React.FC<Props> = ({ visible, closeModal, continueWithTrial, tempUser, type }) => {
  const [presentOverlay, dismissOverlay] = useIonLoading();
  const history = useHistory();

  const setEvidence = useSetRecoilState<IEvidence[] | null>(evidenceAtom);
  const setGuidelinesCardDismissed = useSetRecoilState<boolean>(guidelinesCardDismissedAtom);
  const setWelcomeDismissed = useSetRecoilState<boolean>(welcomeDismissedAtom);
  const setPlusData = useSetRecoilState<IPlusUsageData[] | null>(plusDataAtom);
  const setParapassData = useSetRecoilState<IParapassUsageData[] | null>(parapassDataAtom);
  const setAccessibilitySettings = useSetRecoilState<IAccessibilitySettings | null>(accessibilityAtom);
  const setInfoDismissed = useSetRecoilState<any[]>(programInfoDismissedAtom);
  const setDraftsHintDismissed = useSetRecoilState<boolean>(draftsHintDismissedAtom);
  const setDrafts = useSetRecoilState<IEvidenceDraft[] | null>(evidenceDraftsAtom);
  const setAllProgressCheckData = useSetRecoilState<AllProgressCheckStatuses[]>(progressCheckDataAtom);

  const resetUser = useResetRecoilState(userAtom);
  const resetAllProgressCheckData = useResetRecoilState(progressCheckDataAtom);

  const [user, setUser] = useRecoilStateLoadable<IUser | null>(userAtom);
  const deviceInfo = useRecoilValue<DeviceInfo | null>(deviceInfoAtom);
  const appInfo = useRecoilValue<AppInfo | null>(appInfoAtom);

  const [modalVisible, setModalVisible] = useState<boolean>(visible);
  const [canDismiss, setCanDismiss] = useState<boolean>(true);

  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  const [loadingProductsError, setLoadingProductsError] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<ISubscriptionProduct[]>([]);
  const [subscriptionSelected, setSubscriptionSelected] = useState<ISubscriptionProduct | null>(null);
  const [subscriptionWindow, setSubscriptionWindow] = useState<ISubscriptionWindow[]>([]);
  const [refreshingWebSubscription, setRefreshingWebSubscription] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [latestClassSubscription, setLatestClassSubscription] = useState<IClassSubscription | null>(null);
  const [daysLeft, setDaysLeft] = useState<number>(0);

  const [modalType, setModalType] = useState<ISubscriptionModalType>(type);
  const [storedUser, setStoredUser] = useState<IUser | null>(tempUser || null);

  const contactSupport = useCallback(async (): Promise<void> => {
    await FirebaseService.logEvent("support_link_pressed", {
      link: "contact_support",
      type: "subscription_support",
    });

    const url = "mailto:apps@class.co.uk";
    const subject = `ParaFolio - Subscription support`;

    let body: string = "";

    if (user.contents) {
      body = DataController.supportEmailBody(user.contents.userData, deviceInfo, appInfo);
    } else if (storedUser) {
      body = DataController.supportEmailBody(storedUser.userData, deviceInfo, appInfo);
    } else {
      body = DataController.supportEmailBody(null, deviceInfo, appInfo);
    }

    FirebaseService.logEvent("support_link", { page: "subscription" });

    window.open(`${url}?subject=${subject}&body=${body}`, "_blank");
  }, [appInfo, deviceInfo, storedUser, user.contents]);

  const handleClick = useCallback(
    async (event: Event): Promise<void> => {
      const element = event.target as HTMLAnchorElement;

      if (element.href && element.href?.includes("subscriptionContactSupport")) {
        event.preventDefault();
        await FirebaseService.logEvent("support_link_pressed", {
          link: "contact_support",
          type: "app_support",
        });

        contactSupport();
      } else if (element.href && element.href?.includes(CLASS_TERMS_URL)) {
        await FirebaseService.logEvent("support_link_pressed", {
          link: "terms_and_conditions",
        });
      }
    },
    [contactSupport]
  );

  const handleAuxClick = useCallback(
    async (event: MouseEvent): Promise<void> => {
      const element = event.target as HTMLAnchorElement;

      if (event.button === 1) {
        if (element.href && element.href?.includes("subscriptionContactSupport")) {
          event.preventDefault();
          await FirebaseService.logEvent("support_link_pressed", {
            link: "contact_support",
            type: "app_support",
          });

          contactSupport();
        } else if (element.href && element.href?.includes(CLASS_TERMS_URL)) {
          await FirebaseService.logEvent("support_link_pressed", {
            link: "terms_and_conditions",
          });
        }
      }
    },
    [contactSupport]
  );

  useEffect(() => {
    if (modalVisible) {
      document.addEventListener("click", handleClick);
      document.addEventListener("auxclick", handleAuxClick);
    } else {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("auxclick", handleAuxClick);
    }
  }, [handleAuxClick, handleClick, modalVisible]);

  useEffect(() => {
    setModalType(type);
  }, [type]);

  useEffect(() => {
    // if (!DataController.isWeb()) {
    getIAPProducts();
    // }
  }, [visible]);

  useEffect(() => {
    tempUser && setStoredUser(tempUser);
  }, [tempUser]);

  useEffect(() => {
    const getTempUser = async (): Promise<void> => {
      const _user = await AuthenticationController.getUser();
      // console.log(_user);
      setStoredUser(_user);
    };

    if (visible && !tempUser) {
      // console.log('getTempUser');
      getTempUser();
    }
  }, [tempUser, visible]);

  const getIAPProducts = async (): Promise<void> => {
    // console.log('getProducts');
    setLoadingProducts(true);
    setLoadingProductsError(false);

    let products: ISubscriptionProduct[] = [];
    const productIDs: string[] = Capacitor.getPlatform() === "ios" ? APPLE_PRODUCT_IDS : GOOGLE_PRODUCT_IDS;

    for (let i = 0; i < productIDs.length; i++) {
      try {
        let productIdentifier = productIDs[i];

        const response = await Subscriptions.getProductDetails({
          productIdentifier,
        });
        // console.log(`IAP product found: ${productIdentifier}`, response.data);

        if (response.data) {
          products.push({
            productId: productIdentifier,
            displayName: response.data.displayName,
            description: response.data.description,
            price: response.data.price,
          });
        }
      } catch (error) {
        console.log(error);
        setLoadingProductsError(true);
      }
    }

    setSubscriptions(_.orderBy(products, ["price"], ["desc"]));
    setLoadingProducts(false);
  };

  const getClassSubscriptionCallback = useCallback((): void => {
    try {
      setRefreshingWebSubscription(true);
      const _user = user.contents || storedUser;
      const allSubscriptions = _user?.userData ? _user.userData?.subscriptions : [];

      if (allSubscriptions && allSubscriptions.length > 0) {
        const latestSubscription = DataController.getLatestPortfolioSubscription(allSubscriptions);

        if (latestSubscription) {
          let valid = false;
          const dateSeconds = parseInt(latestSubscription.ExpiryDate.replace("/Date(", "").replace(")/", ""), 10);
          const withGrace = startOfDay(addDays(new Date(dateSeconds), 2));

          if (dateSeconds === 0) {
            valid = true;
          } else if (isAfter(withGrace, new Date())) {
            valid = true;
          } else if (isSameDay(new Date(), withGrace)) {
            valid = true;
          }

          const _daysLeft = differenceInDays(withGrace, new Date());

          setLatestClassSubscription(latestSubscription);
          setDaysLeft(_daysLeft <= 30 ? _daysLeft : 30);

          if (valid) {
            if (latestSubscription.FreeTrial === true) {
              // Within trial - has subscription
              setSubscriptionWindow([ISubscriptionWindow.WITHIN_TRIAL, ISubscriptionWindow.NO_SUBSCRIPTION]);
              setCanDismiss(true);
            } else {
              // Outside trial - has subscription
              setSubscriptionWindow([ISubscriptionWindow.TRIAL_EXPIRED, ISubscriptionWindow.HAS_SUBSCRIPTION]);
              setCanDismiss(true);
            }
          } else {
            // Invalid subscription
            setSubscriptionWindow([ISubscriptionWindow.TRIAL_EXPIRED, ISubscriptionWindow.NO_SUBSCRIPTION]);
            setCanDismiss(false);
          }
        } else {
          setSubscriptionWindow([ISubscriptionWindow.TRIAL_EXPIRED, ISubscriptionWindow.NO_SUBSCRIPTION]);
          setCanDismiss(false);
        }
      } else {
        setSubscriptionWindow([ISubscriptionWindow.WITHIN_TRIAL, ISubscriptionWindow.NO_SUBSCRIPTION]);
        setDaysLeft(30);
        setCanDismiss(true);
      }

      setRefreshingWebSubscription(false);
    } catch (error: any) {
      console.log(error);
      setRefreshingWebSubscription(false);
    }
  }, [user, storedUser]);

  useEffect(() => {
    const getClassSubscription = (): void => {
      const _user = user.contents || storedUser;
      const allSubscriptions = _user?.userData ? _user.userData?.subscriptions : [];

      if (allSubscriptions && allSubscriptions.length > 0) {
        const latestSubscription = DataController.getLatestPortfolioSubscription(allSubscriptions);

        if (latestSubscription) {
          let valid = false;
          const dateSeconds = parseInt(latestSubscription.ExpiryDate.replace("/Date(", "").replace(")/", ""), 10);
          const withGrace = startOfDay(addDays(new Date(dateSeconds), 2));

          if (dateSeconds === 0) {
            valid = true;
          } else if (isAfter(withGrace, new Date())) {
            valid = true;
          } else if (isSameDay(new Date(), withGrace)) {
            valid = true;
          }

          const _daysLeft = differenceInDays(withGrace, new Date());

          setLatestClassSubscription(latestSubscription);
          setDaysLeft(_daysLeft <= 30 ? _daysLeft : 30);

          if (valid) {
            if (latestSubscription.FreeTrial === true) {
              // Within trial - has subscription
              setSubscriptionWindow([ISubscriptionWindow.WITHIN_TRIAL, ISubscriptionWindow.NO_SUBSCRIPTION]);
              setCanDismiss(true);
            } else {
              // Outside trial - has subscription
              setSubscriptionWindow([ISubscriptionWindow.TRIAL_EXPIRED, ISubscriptionWindow.HAS_SUBSCRIPTION]);
              setCanDismiss(true);
            }
          } else {
            // Invalid subscription
            setSubscriptionWindow([ISubscriptionWindow.TRIAL_EXPIRED, ISubscriptionWindow.NO_SUBSCRIPTION]);
            setCanDismiss(false);
          }
        } else {
          setSubscriptionWindow([ISubscriptionWindow.TRIAL_EXPIRED, ISubscriptionWindow.NO_SUBSCRIPTION]);
          setCanDismiss(false);
        }
      } else {
        setSubscriptionWindow([ISubscriptionWindow.WITHIN_TRIAL, ISubscriptionWindow.NO_SUBSCRIPTION]);
        setDaysLeft(30);
        setCanDismiss(true);
      }
    };

    if (visible) {
      getClassSubscription();
    }
  }, [storedUser, user, visible]);

  useEffect(() => {
    setModalVisible(visible);

    try {
      const element = document.getElementById("dummyButton");

      if (element) {
        // console.log(element);
        setTimeout(() => {
          element.click();
        }, 250);
      }
    } catch (error) {
      console.log(error);
    }
  }, [visible]);

  const resetModal = (): void => {
    closeModal(false);
  };

  const refreshSubscriptions = async (): Promise<void> => {
    await getIAPProducts();
  };

  const getSubscriptionPeriod = (productID: string): string => {
    if (productID.includes("1month")) {
      return "month";
    } else if (productID.includes("12month")) {
      return "year";
    } else {
      return "month";
    }
  };

  const faqPressed = (): void => {
    window.open(PF_FEATURES_URL, "_blank");
  };

  const logoutPressed = async (): Promise<void> => {
    setCanDismiss(true);
    resetModal();

    await AuthenticationController.logout();
    setEvidence(null);
    setPlusData(null);
    setParapassData(null);
    setAccessibilitySettings(null);
    setWelcomeDismissed(false);
    setGuidelinesCardDismissed(false);
    setDraftsHintDismissed(false);
    setGuidelinesCardDismissed(false);
    setInfoDismissed([]);
    setDrafts(null);
    resetUser();
    resetAllProgressCheckData();

    if (modalType === ISubscriptionModalType.PROFILE) {
      history.replace("/onboarding/login");
    } else if (modalType === ISubscriptionModalType.ONBOARDING) {
      history.replace("/onboarding");
    }
  };

  const subscriptionPressed = (subscription: ISubscriptionProduct): void => {
    if (subscriptionSelected?.productId === subscription.productId) {
      setSubscriptionSelected(null);
    } else {
      setSubscriptionSelected(subscription);
    }
  };

  const purchaseSubscription = async (subscription: ISubscriptionProduct): Promise<void> => {
    await presentOverlay({ message: "Processing subscription purchase..." });

    if (Capacitor.getPlatform() === "ios") {
      await purchaseSubscriptionApple(subscription);
    } else if (Capacitor.getPlatform() === "android") {
      await purchaseSubscriptionGoogle(subscription);
    }
  };

  const purchaseSubscriptionApple = async (subscription: ISubscriptionProduct): Promise<void> => {
    const response = await Subscriptions.purchaseProduct({
      productIdentifier: subscription.productId,
    });
    let shouldClose;

    /**
     * response codes and matching messages:
     * -1: Incompatible with web
     * 0: Successfully purchased product. Successful new purchase OR the user clicked the button despite already owning the product.
     * 1: Could not find a product matching the given productIdentifier
     * 2: Product seems to have been purchased but the transaction failed verification
     * 3: User closed the native popover before purchasing
     * 4: Product request made but is currently pending - likely due to parental restrictions
     * 5: An unknown error occurred whilst in the purchasing process
     */

    if (response.responseCode === 0) {
      const latestTransactionResponse = await Subscriptions.getLatestTransaction({
        productIdentifier: subscription.productId,
      });
      const { productIdentifier, transactionId, expiryDate, originalStartDate } = latestTransactionResponse.data!;

      const purchaseInfo: ISubscriptionPurchase = {
        productId: productIdentifier,
        transactionId,
        endDate: expiryDate,
        startDate: originalStartDate,
      };

      shouldClose = await updatePurchase(productIdentifier, purchaseInfo);
    }

    await dismissOverlay();

    // console.log('SubscriptionModal', modalType, shouldClose);

    if (modalType === ISubscriptionModalType.ONBOARDING && shouldClose) {
      continueWithTrial();
    } else if (modalType === ISubscriptionModalType.PROFILE && shouldClose) {
      setCanDismiss(true);
      resetModal();
    }
  };

  const purchaseSubscriptionGoogle = async (subscription: ISubscriptionProduct): Promise<void> => {
    Subscriptions.addListener("ANDROID-PURCHASE-RESPONSE", async (response: any) => {
      let shouldClose;

      if (response.successful) {
        let subs: any[] = [];

        try {
          const entitlements = await Subscriptions.getCurrentEntitlements();

          if (entitlements.responseCode === 0 && entitlements.data) {
            // Parse subscriptions to JSON
            subs = JSON.parse(entitlements.data.toString());
          }

          if (subs.length === 0) {
            throw new Error("Can't get latest subscription from Google Play");
          }

          const purchased = subs.find((item) => item.productIdentifier === subscription.productId);

          if (purchased) {
            const purchaseInfo = DataController.mapGoogleSub(purchased);

            shouldClose = await updatePurchase(subscription.productId, purchaseInfo);
          }
        } catch (error) {
          console.log("Error getting subscriptions from Google Play: ", error);
          subs = [];
        }
      }

      await dismissOverlay();

      if (modalType === ISubscriptionModalType.ONBOARDING && shouldClose) {
        continueWithTrial();
      } else if (modalType === ISubscriptionModalType.PROFILE && shouldClose) {
        setCanDismiss(true);
        resetModal();
      }
    });

    await Subscriptions.purchaseProduct({
      productIdentifier: subscription.productId,
    });
  };

  useEffect(() => {
    // console.log(user.contents);
  }, [user]);

  const restoreSubscription = async (): Promise<void> => {
    await presentOverlay({ message: "Restoring purchases..." });

    try {
      const entitlements = await IAPService.restoreSubscription();

      if (entitlements.success) {
        // console.log(entitlements.subs);
        if (entitlements.subs.length > 0) {
          const latest = entitlements.subs[0];

          if (latest) {
            // console.log('latest', latest);

            const success = await updatePurchase(latest.productId!, latest);

            if (success) {
              await dismissOverlay();

              setCanDismiss(true);
              resetModal();

              window.alert("Successfully restored purchases");
            } else {
              await dismissOverlay();

              window.alert("An error occurred when restored purchases");
            }
          } else {
            await dismissOverlay();

            window.alert("No purchases to restore");
          }
        } else {
          await dismissOverlay();

          window.alert("No purchases to restore");
        }
      } else {
        await dismissOverlay();
        window.alert("An error occurred when restored purchases");
      }
    } catch (error) {
      await dismissOverlay();
      window.alert("An error occurred when restored purchases");
    }
  };

  const openWebSubscription = (): void => {
    window.open(CLASS_PARAFOLIO_URL, "_blank");
  };

  const refreshWebSubscription = async (): Promise<void> => {
    try {
      EventRegister.emit("subscriptions/refresh-user");

      setTimeout(() => {
        getClassSubscriptionCallback();
      }, 1000);
    } catch (error: any) {
      console.log(error);
    }
  };

  const updatePurchase = async (
    productID: string,
    subscription: ISubscriptionPurchase
  ): Promise<boolean | undefined> => {
    try {
      if (user.state === "hasValue" && user.contents && subscription) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const receipt = await Subscriptions.getCurrentEntitlements();

        let response;

        if (!_.isEmpty(user.contents)) {
          response = await IAPService.sendPurchaseInfo(
            `${user.contents?.userData.contactID}`,
            format(new Date(subscription.startDate!), "yyyy-MM-dd"),
            subscription.transactionId!,
            productID,
            Capacitor.getPlatform() === "ios" ? "iOS" : "Android",
            user.contents?.token!,
            format(new Date(subscription.endDate!), "yyyy-MM-dd")
          );
        } else if (storedUser) {
          response = await IAPService.sendPurchaseInfo(
            `${storedUser.userData.contactID}`,
            subscription.startDate!,
            subscription.transactionId!,
            productID,
            Capacitor.getPlatform() === "ios" ? "iOS" : "Android",
            storedUser?.token!,
            format(new Date(subscription.endDate!), "yyyy-MM-dd")
          );
        }

        if (response) {
          let userToSave = await AuthenticationController.getUserData(
            !_.isEmpty(user.contents) ? user.contents : storedUser!
          );

          if (userToSave) {
            setUser(userToSave);

            return true;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <IonModal
      id="onboardingSubscriptionsModal"
      isOpen={modalVisible}
      onDidDismiss={() => resetModal()}
      canDismiss={canDismiss}
      className="fullscreen"
    >
      {canDismiss && (
        <IonHeader className="ion-no-border">
          <IonToolbar
            mode="ios"
            className="navBar"
            style={{
              maxWidth: DataController.isWeb() ? 980 : undefined,
              height: Capacitor.getPlatform() === "android" ? "54px" : "44px",
            }}
          >
            <HeaderTitle>{""}</HeaderTitle>
            {canDismiss && (
              <IonButtons slot="end">
                <IonButton mode="ios" className="cancelButton" onClick={() => resetModal()}>
                  <img src={Button_Close} className="closeIcon" alt="" />
                </IonButton>
              </IonButtons>
            )}
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent className="onboardingSubscriptionModalPage fullHeight">
        <ContentContainer tabBarDisabled>
          <div className="subscriptionContentContainer">
            <div>
              <div className="onboardingSubscriptionHeader">
                {subscriptionWindow.includes(ISubscriptionWindow.TRIAL_EXPIRED) &&
                  subscriptionWindow.includes(ISubscriptionWindow.HAS_SUBSCRIPTION) && (
                    <>{"You've already full access to ParaFolio"}</>
                  )}
                {subscriptionWindow.includes(ISubscriptionWindow.WITHIN_TRIAL) &&
                  subscriptionWindow.includes(ISubscriptionWindow.NO_SUBSCRIPTION) && (
                    <>
                      {daysLeft === 0 ? (
                        <>{"Your free trial ends today"}</>
                      ) : (
                        <>
                          {"You have "}
                          {`${daysLeft}`}
                          {` day${daysLeft !== 1 ? "s" : ""} left in your trial`}
                        </>
                      )}
                    </>
                  )}
                {subscriptionWindow.includes(ISubscriptionWindow.TRIAL_EXPIRED) &&
                  subscriptionWindow.includes(ISubscriptionWindow.NO_SUBSCRIPTION) && (
                    <>{"Get full access to ParaFolio"}</>
                  )}
              </div>
              <div className="onboardingSubscriptionSubheader">
                {subscriptionWindow.includes(ISubscriptionWindow.TRIAL_EXPIRED) &&
                  subscriptionWindow.includes(ISubscriptionWindow.HAS_SUBSCRIPTION) && (
                    <>{"You can change your subscription option for ParaFolio below"}</>
                  )}
                {subscriptionWindow.includes(ISubscriptionWindow.WITHIN_TRIAL) &&
                  subscriptionWindow.includes(ISubscriptionWindow.NO_SUBSCRIPTION) && (
                    <>
                      {
                        "After your free trial is over, you will need to choose a subscription option below to continue full access to ParaFolio."
                      }
                    </>
                  )}
                {subscriptionWindow.includes(ISubscriptionWindow.TRIAL_EXPIRED) &&
                  subscriptionWindow.includes(ISubscriptionWindow.NO_SUBSCRIPTION) && (
                    <>
                      {
                        "Your free trial has ended, please select a subscription option to continue full access to ParaFolio"
                      }
                    </>
                  )}
              </div>
            </div>
            {Capacitor.getPlatform() === "web" ? (
              <div className="subscriptionsLoadingContainer">
                <IonButton
                  mode="ios"
                  onClick={() => openWebSubscription()}
                  style={{ marginTop: 24 }}
                  className="subscriptionRefreshButton"
                >
                  <div>{"Purchase subscription"}</div>
                </IonButton>
                <IonButton
                  mode="ios"
                  onClick={() => refreshWebSubscription()}
                  disabled={refreshingWebSubscription}
                  style={{ marginTop: 20 }}
                  className="subscriptionRefreshButton"
                >
                  {refreshingWebSubscription ? (
                    <div className="subscriptionWebRefreshSpinnerContainer">
                      <IonSpinner className="subscriptionWebRefreshSpinner" />
                    </div>
                  ) : (
                    <div>{"Refresh subscriptions"}</div>
                  )}
                </IonButton>
              </div>
            ) : (
              <div className="subscriptionsLoadingContainer">
                {loadingProducts && (
                  <div className="subscriptionSpinnerContainer">
                    <IonSpinner className="subscriptionSpinner" />
                  </div>
                )}
                {loadingProductsError && (
                  <div className="subscriptionErrorContainer">
                    {"Failed to load subscription options"}
                    <IonButton
                      mode="ios"
                      disabled={loadingProducts}
                      onClick={() => refreshSubscriptions()}
                      className="subscriptionRefreshButton"
                    >
                      <div>{"Retry"}</div>
                    </IonButton>
                  </div>
                )}
                {subscriptions.length === 0 ? (
                  <div className="subscriptionErrorContainer">
                    {"Subscription options not found"}
                    <IonButton
                      mode="ios"
                      disabled={loadingProducts}
                      onClick={() => refreshSubscriptions()}
                      className="subscriptionRefreshButton"
                    >
                      <div>{"Retry"}</div>
                    </IonButton>
                  </div>
                ) : (
                  <div style={{ marginTop: 24 }}>
                    {subscriptions.map((subscription) => {
                      const subscriptionPeriod = getSubscriptionPeriod(subscription.productId);
                      const selected = subscription.productId === subscriptionSelected?.productId;

                      return (
                        <div
                          key={subscription.productId}
                          className="subscriptionContainer"
                          onClick={() => subscriptionPressed(subscription)}
                        >
                          <div className="subscriptionDescriptionContainer">
                            <div className="subscriptionName">{subscription.displayName}</div>
                            <div className="subscriptionPrice">
                              {subscription.price}
                              <span className="subscriptionPeriod">{` / ${subscriptionPeriod}`}</span>
                            </div>
                            <div className="subscriptionDescription">{subscription.description}</div>
                          </div>
                          <div>
                            <img
                              className={`${selected ? "subscriptionTickBoxActive" : "subscriptionTickBox"}`}
                              src={selected ? Button_Tickbox_Active : Button_Checkbox_Inactive}
                              alt=""
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="onboardingSubscriptionSubheader" style={{ marginTop: 16 }}>
                  {
                    "If you already have a subscription and cannot see it, try restoring your subscription through the button below."
                  }
                </div>
                <IonButton mode="ios" onClick={() => restoreSubscription()} className="subscriptionRefreshButton">
                  <div>{"Restore existing purchase"}</div>
                </IonButton>
              </div>
            )}
            <div className="subscriptionsBenefitsContainer">
              <div className="subscriptionsBenefits">
                <img className="subscriptionsBenefitsIcon" alt="" src={Image_IAP_Graph} />
                <div className="subscriptionsBenefitsInnerContainer">
                  <div className="subscriptionsBenefitsHeader">{"Track your CPD"}</div>
                  <div className="subscriptionsBenefitsText">
                    {"See your evidence added and hours spent doing CPD on the Dashboard tab"}
                  </div>
                </div>
              </div>
              <div className="subscriptionsBenefits">
                <img className="subscriptionsBenefitsIcon" alt="" src={Image_IAP_Tag} />
                <div className="subscriptionsBenefitsInnerContainer">
                  <div className="subscriptionsBenefitsHeader">{"Organise your evidence"}</div>
                  <div className="subscriptionsBenefitsText">
                    {"Add custom tags to your Evidence, and tag what you need for audit"}
                  </div>
                </div>
              </div>
              <div className="subscriptionsBenefits">
                <img className="subscriptionsBenefitsIcon" alt="" src={Image_IAP_Apps} />
                <div className="subscriptionsBenefitsInnerContainer">
                  <div className="subscriptionsBenefitsHeader">{"Reflect on your activity"}</div>
                  <div className="subscriptionsBenefitsText">
                    {
                      "Use your login for JRCALC Plus, iCPG, ParaPass or Responder Plus and view your activity in your Timeline"
                    }
                  </div>
                </div>
              </div>
              <div className="subscriptionsBenefits">
                <img className="subscriptionsBenefitsIcon" alt="" src={Image_IAP_Ticks} />
                <div className="subscriptionsBenefitsInnerContainer">
                  <div className="subscriptionsBenefitsHeader">{"Get ready for audit"}</div>
                  <div className="subscriptionsBenefitsText">
                    {"Keep all you need for HCPC audit in one place in Profile"}
                  </div>
                </div>
              </div>
            </div>
            <div className="subscriptionsFAQ">
              <div className="subscriptionsFAQHeader">{"Want more information?"}</div>
              <IonButton
                mode="ios"
                disabled={loadingProducts}
                onClick={() => faqPressed()}
                className="subscriptionRefreshButton"
              >
                <div>{"Read our FAQs"}</div>
              </IonButton>
              <div className="subscriptionFAQText">
                {"Can’t find an answer to your question? "}
                <a href="subscriptionContactSupport" className="subscriptionFAQLink">
                  {"Contact support"}
                </a>
                {"."}
              </div>
            </div>
            <>
              <div className="subscriptionInformation">
                <div className="subscriptionInformationHeader">{"Subscription information"}</div>
                <div className="subscriptionInformationText">
                  {"For app subscription related help request please use this "}
                  <a href="subscriptionContactSupport" className="subscriptionFAQLink">
                    {"link"}
                  </a>
                  {"."}
                  <br />
                  <br />
                  {
                    "ParaFolio is free to download and includes a free 30 day trial. After the trial has finished there are 2 types of subscription available; a recurring monthly subscription or a recurring annual subscription."
                  }
                  <br />
                  <br />
                  {
                    "Both subscriptions are auto-renewing but can be cancelled at any time using the manage subscription button found higher up this page. Auto-renew must be turned off at least 24- hours before the end of the current renewal period in order to not be charged."
                  }
                  <br />
                  <br />
                  {`Subscription payments will be charged to your ${Capacitor.getPlatform() === "ios" ? "Apple ID" : "Google Play account"} at confirmation of purchase.`}
                  <br />
                  <br />
                  {
                    "Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication, where applicable."
                  }
                  <br />
                  <br />
                  {"Please read the "}
                  <a href={CLASS_TERMS_URL} target="_blank" rel="noreferrer" className="subscriptionFAQLink">
                    {"Privacy Policy"}
                  </a>
                  {" and "}
                  <a href={CLASS_TERMS_URL} target="_blank" rel="noreferrer" className="subscriptionFAQLink">
                    {"Terms of Use"}
                  </a>
                  {"."}
                  <br />
                  <br />
                  {""}
                </div>
              </div>
            </>
            <div className="subscriptionsFAQ" style={{ marginTop: 36 }}>
              <div className="subscriptionInformationHeader">{"User information"}</div>
              <div className="subscriptionInformationText">
                {`Name: ${(user.contents && user.contents.name) || (storedUser && storedUser.name) || "Unknown"}`}
              </div>
              <div className="subscriptionInformationText" style={{ marginTop: 8 }}>
                {`Email: ${(user.contents && user.contents.email) || (storedUser && storedUser.email) || "Unknown"}`}
              </div>
              {modalType === ISubscriptionModalType.PROFILE && (
                <IonButton
                  mode="ios"
                  disabled={loadingProducts}
                  onClick={() => logoutPressed()}
                  className="subscriptionRefreshButton"
                >
                  <div>{"Sign out"}</div>
                </IonButton>
              )}
            </div>
          </div>
        </ContentContainer>
      </IonContent>
      {Capacitor.getPlatform() !== "web" && (
        <div className="subscriptionFooter" id="subscriptionFooter">
          <div className="subscriptionFooterContainer">
            {!subscriptionSelected &&
              modalType === ISubscriptionModalType.ONBOARDING &&
              subscriptionWindow.includes(ISubscriptionWindow.WITHIN_TRIAL) &&
              subscriptionWindow.includes(ISubscriptionWindow.NO_SUBSCRIPTION) && (
                <IonButton mode="ios" className="subscriptionContinueButton" onClick={() => continueWithTrial()}>
                  <div className="">
                    <div className="subscriptionContinueButtonText">{"Continue with free trial"}</div>
                    <div className="subscriptionContinueButtonSubtext">{"Select a subscription after your trial"}</div>
                  </div>
                </IonButton>
              )}
            {((!subscriptionSelected &&
              subscriptionWindow.includes(ISubscriptionWindow.TRIAL_EXPIRED) &&
              subscriptionWindow.includes(ISubscriptionWindow.NO_SUBSCRIPTION)) ||
              (!subscriptionSelected && modalType === ISubscriptionModalType.PROFILE)) && (
              <IonButton mode="ios" disabled={true} className="subscriptionContinueButton">
                <div className="">
                  <div className="subscriptionContinueButtonText">{"Select a subscription option"}</div>
                </div>
              </IonButton>
            )}
            {subscriptionSelected && (
              <IonButton
                mode="ios"
                className="subscriptionContinueButton"
                onClick={() => purchaseSubscription(subscriptionSelected)}
              >
                <div className="">
                  <div className="subscriptionContinueButtonText">
                    {`Purchase ${SubscriptionUtils.getSubscriptionPurchaseText(subscriptionSelected.productId)}`}
                  </div>
                </div>
              </IonButton>
            )}
            <IonButton id="dummyButton" className="dummyButton" onClick={() => console.log("dummy")}></IonButton>
          </div>
        </div>
      )}
    </IonModal>
  );
};

export default SubscriptionsModal;
