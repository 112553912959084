import { useEffect } from "react";
import { useRefreshProgressCheckData } from "../../hooks/data/useRefreshProgressCheckData";
import { EventRegister } from "react-native-event-listeners";
import { useRefreshUserEvidence } from "../../hooks/data/useRefreshUserEvidence";
import { ISubscriptionModalType } from "../../Interfaces";
import { useRefreshUserDetails } from "../../hooks/data/useRefreshUserDetails";
import { useRefreshJRCALCPlusData } from "../../hooks/data/useRefreshJRCALCPlusData";
import { useRefreshParapassData } from "../../hooks/data/useRefreshParapassData";
import _ from "lodash";

type Props = {
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setModalType: React.Dispatch<React.SetStateAction<ISubscriptionModalType>>;
};

const AppEventListener: React.FC<Props> = (props) => {
  const { refreshUserDetails } = useRefreshUserDetails();
  const { refreshProgressCheckData } = useRefreshProgressCheckData();
  const { refreshUserEvidence } = useRefreshUserEvidence();
  const { refreshJRCALCPlusData } = useRefreshJRCALCPlusData();
  const { refreshParapassData } = useRefreshParapassData();

  useEffect(() => {
    EventRegister.addEventListener("subscriptions/show-modal", (modalType: ISubscriptionModalType) => {
      props.setModalVisible(true);
      props.setModalType(modalType);
    });

    return () => {
      EventRegister.removeEventListener("subscriptions/show-modal");
    };
  }, []);

  useEffect(() => {
    EventRegister.addEventListener("subscriptions/refresh-user", (_user) => refreshUserDetails(_user));

    return () => {
      EventRegister.removeEventListener("subscriptions/refresh-user");
    };
  }, [refreshUserDetails]);

  useEffect(() => {
    EventRegister.addEventListener("comments/comment-sent", (_user) => refreshProgressCheckData(_user));
    EventRegister.addEventListener("comments/read-time-updated", (_user) => refreshProgressCheckData(_user));
    EventRegister.addEventListener("progress-check/submitted", (_user) => refreshProgressCheckData(_user));
    EventRegister.addEventListener("progress-check/created", (_user) => refreshProgressCheckData(_user));
    EventRegister.addEventListener(
      "program/refresh-button",
      _.debounce(async (_user) => {
        await refreshUserDetails(_user);
        await refreshUserEvidence(_user);
        await refreshProgressCheckData(_user);
        await refreshJRCALCPlusData(_user);
        await refreshParapassData(_user);
        EventRegister.emit("program/data-refreshed");
      }, 100)
    );

    return () => {
      EventRegister.removeEventListener("comments/comment-sent");
      EventRegister.removeEventListener("comments/read-time-updated");
      EventRegister.removeEventListener("progress-check/submitted");
      EventRegister.removeEventListener("progress-check/created");
      EventRegister.removeEventListener("program/refresh-button");
    };
  }, [refreshProgressCheckData, refreshUserEvidence]);

  return null;
};

export default AppEventListener;
