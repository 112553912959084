import { useRecoilValue, useSetRecoilState } from "recoil";
import { parapassDataAtom, userAtom } from "../../state/State";
import { IParapassUsageData, IUser } from "../../Interfaces";
import { useCallback, useEffect, useState } from "react";
import { DatabaseService } from "../../controllers/DatabaseService";
import DataController from "../../controllers/DataController";
import _ from "lodash";
import { USAGE_DATA_REFRESH_INTERVAL } from "../../Constants";
import { useLoggedIn } from "../auth/useLoggedIn";

type RefreshParapassData = {
  refreshParapassData: (user: IUser) => Promise<void>;
};

export const useRefreshParapassData = (shouldLoad?: boolean): RefreshParapassData => {
  const user = useRecoilValue(userAtom);
  const setParapassData = useSetRecoilState<IParapassUsageData | null>(parapassDataAtom);
  const loggedIn = useLoggedIn();

  const refreshParapassData = useCallback(async (user: IUser): Promise<void> => {
    try {
      let data = [];
      data = await DatabaseService.getParapassUsageData(user);
      await DataController.saveParapassUsageData(data);
      setParapassData(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const getParapassData = async (): Promise<void> => {
      try {
        if (loggedIn && user && shouldLoad) {
          let data = [];
          data = await DatabaseService.getParapassUsageData(user);
          await DataController.saveParapassUsageData(data);
          setParapassData(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const interval = setInterval(() => {
      getParapassData();
    }, USAGE_DATA_REFRESH_INTERVAL);

    return () => {
      try {
        clearInterval(interval);
      } catch (error) {
        console.log(error);
      }
    };
  }, [loggedIn, setParapassData, user, shouldLoad]);

  useEffect(() => {
    const getParapassData = async (): Promise<void> => {
      try {
        let data = [];
        data = await DatabaseService.getParapassUsageData(user);
        await DataController.saveParapassUsageData(data);
        setParapassData(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (loggedIn && user && shouldLoad) {
      getParapassData();
    }
  }, [loggedIn, setParapassData, user, shouldLoad]);

  return {
    refreshParapassData,
  };
};
