import { IonButton } from "@ionic/react";
import { IWellbeingSupportCard } from "../../../types/Components";
import DOMPurify from "dompurify";
import classNames from "classnames";

const WellbeingSupportCard: React.FC<IWellbeingSupportCard> = (props) => {
  const sanitizeData = (data: string) => ({
    __html: DOMPurify.sanitize(data, {
      ALLOWED_ATTR: ["rel", "href", "target", "referrerPolicy"],
    }),
  });

  const openLink = (url: string): void => {
    try {
      window.open(url, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="rounded-[5px] p-4 bg-white mx-4 my-1.5">
      {props.wellbeingPart["Support Image"] && (
        <img
          alt=""
          className={classNames("!max-w-36 !max-h-24 !block !w-auto !h-auto", {
            "mb-2": props.wellbeingPart["Display Title"],
          })}
          src={props.wellbeingPart["Support Image"]}
        />
      )}
      {props.wellbeingPart["Display Title"] && (
        <div className="text-acc-17px font-extrabold tracking-default text-gray-800 text-start">
          {props.wellbeingPart["Display Title"]}
        </div>
      )}
      <div
        className="text-acc-14px text-start mt-3 [&>ul]:!text-acc-14px [&>ul]:list-disc [&>ul]:list-outside [&>ul]:pl-[revert] [&>a]:!underline [&>a]:!text-cta-blue [&>ul]:[&>li]:!text-cta-blue blue-list-item"
        dangerouslySetInnerHTML={sanitizeData(props.wellbeingPart["List of values"] ?? "")}
      />
      {props.wellbeingPart["Contact Link"] && (
        <IonButton
          mode="ios"
          className="grey-button w-full h-[56px] max-w-[374px] mt-4"
          onClick={() => openLink(props.wellbeingPart["Contact Link"]!)}
        >
          <div className="text-[16px] font-bold tracking-default text-center text-cta-blue">{"Contact"}</div>
        </IonButton>
      )}
    </div>
  );
};

export default WellbeingSupportCard;
