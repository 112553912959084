import { AppInfo } from "@capacitor/app";
import { DeviceInfo } from "@capacitor/device";
import { Preferences } from "@capacitor/preferences";
import { atom, selector } from "recoil";
import {
  AllProgressCheckStatuses,
  HelpCentreContent,
  HelpCentreTimestamp,
  IAppFeatures,
  IAppVersion,
  ProgramData,
  ProgramDataVersions,
} from "../Interfaces";
import AuthenticationController from "../controllers/AuthenticationController";
import _ from "lodash";
import localforage from "localforage";

export const appVersionSelector = selector({
  key: "appVersionSelector",
  get: async () => {
    const appVersion = await Preferences.get({ key: "appVersion" });
    return appVersion?.value ? appVersion.value : IAppVersion.GENERAL;
  },
});

export const appFeaturesSelector = selector({
  key: "appFeaturesSelector",
  get: async () => {
    const appVersion = await Preferences.get({ key: "appFeatures" });
    return appVersion?.value ? JSON.parse(appVersion.value) : [IAppFeatures.GENERAL];
  },
});

export const userSelector = selector({
  key: "userSelector",
  get: async () => {
    const user = await Preferences.get({ key: "user" });
    return user?.value ? JSON.parse(user.value) : {};
  },
});

export const evidenceSelector = selector({
  key: "evidenceSelector",
  get: async ({ get }) => {
    const evidence = await Preferences.get({ key: "evidence" });
    return evidence?.value ? JSON.parse(evidence.value) : [];
  },
  set: ({ set }, newValue) => {
    set(evidenceAtom, newValue);
  },
});

export const evidenceDraftsSelector = selector({
  key: "evidenceDraftsSelector",
  get: async ({ get }) => {
    const evidence = await Preferences.get({ key: "evidenceDrafts" });
    return evidence?.value ? JSON.parse(evidence.value) : [];
  },
  set: ({ set }, newValue) => {
    set(evidenceDraftsAtom, newValue);
  },
});

// export const progressCheckDataSelector = selector({
//   key: "progressCheckDataSelector",
//   get: async ({ get }) => {
//     console.log("progressCheckDataSelector get");
//     const progressChecks = await Preferences.get({ key: "progressCheckData" });
//     return progressChecks?.value ? JSON.parse(progressChecks.value) : [];
//   },
//   set: async ({ set }, newValue) => {
//     set(progressCheckDataAtom, newValue);
//   },
// });

export const newsSelector = selector({
  key: "newsSelector",
  get: async ({ get }) => {
    const news = await Preferences.get({ key: "news" });
    return news?.value ? JSON.parse(news.value) : [];
  },
  set: ({ set }, newValue) => {
    set(newsAtom, newValue);
  },
});

export const plusDataSelector = selector({
  key: "plusDataSelector",
  get: async ({ get }) => {
    const plusData = await Preferences.get({ key: "plusData" });
    return plusData?.value ? JSON.parse(plusData.value) : [];
  },
  set: ({ set }, newValue) => {
    set(plusDataAtom, newValue);
  },
});

export const parapassDataSelector = selector({
  key: "parapassDataSelector",
  get: async ({ get }) => {
    const parapassData = await Preferences.get({ key: "parapassData" });
    return parapassData?.value ? JSON.parse(parapassData.value) : [];
  },
  set: ({ set }, newValue) => {
    set(parapassDataAtom, newValue);
  },
});

export const welcomeDismissedSelector = selector({
  key: "welcomeDismissedSelector",
  get: async ({ get }) => {
    const value = await Preferences.get({ key: "welcomeDismissed" });
    return value.value ? true : false;
  },
  set: ({ set }, newValue) => {
    set(welcomeDismissedAtom, newValue);
  },
});

export const accessibilitySelector = selector({
  key: "accessibilitySelector",
  get: async ({ get }) => {
    const value = await Preferences.get({ key: "accessibilitySettings" });
    return value.value ? JSON.parse(value.value) : {};
  },
  set: ({ set }, newValue) => {
    set(accessibilityAtom, newValue);
  },
});

export const guidelinesCardDismissedSelector = selector({
  key: "guidelinesCardDismissedSelector",
  get: async ({ get }) => {
    const value = await Preferences.get({ key: "guidelinesCardDismissed" });
    return value.value ? true : false;
  },
  set: ({ set }, newValue) => {
    set(guidelinesCardDismissedAtom, newValue);
  },
});

export const programInfoDismissedSelector = selector({
  key: "programInfoDismissedSelector",
  get: async ({ get }) => {
    const value = await Preferences.get({ key: "programInfoDismissed" });
    return value?.value ? JSON.parse(value.value) : [];
  },
  set: ({ set }, newValue) => {
    set(programInfoDismissedAtom, newValue);
  },
});

export const summaryOfWorkTipDismissedSelector = selector({
  key: "summaryOfWorkTipDismissedSelector",
  get: async ({ get }) => {
    const value = await Preferences.get({ key: "summaryOfWorkTipDismissed" });
    return value.value ? true : false;
  },
  set: ({ set }, newValue) => {
    set(summaryOfWorkTipDismissedAtom, newValue);
  },
});

export const draftsHintDismissedSelector = selector({
  key: "draftsHintDismissedSelector",
  get: async ({ get }) => {
    const value = await Preferences.get({ key: "draftsHintDismissed" });
    return value.value ? true : false;
  },
  set: ({ set }, newValue) => {
    set(draftsHintDismissedAtom, newValue);
  },
});

export const appVersionAtom = atom<string>({
  key: "appVersionState",
  default: appVersionSelector,
});

export const appFeaturesAtom = atom<string[]>({
  key: "appFeaturesState",
  default: appFeaturesSelector,
});

export const userAtom = atom<any>({
  key: "userState",
  default: null,
  effects: [
    ({ onSet, setSelf, trigger }) => {
      const loadState = async () => {
        const user = await Preferences.get({ key: "user" });
        user?.value ? setSelf(JSON.parse(user.value)) : setSelf(null);
      };

      if (trigger === "get") {
        loadState();
      }

      onSet(async (newValue, oldValue, isReset) => {
        if (isReset) {
          await Preferences.remove({ key: "user" });
        } else if (newValue && Object.keys(newValue).length !== 0) {
          await Preferences.set({ key: "user", value: JSON.stringify(newValue) });
        } else {
          await AuthenticationController.logout();
        }
      });
    },
  ],
});

export const welcomeDismissedAtom = atom({
  key: "welcomeDismissedState",
  default: welcomeDismissedSelector,
});

export const guidelinesCardDismissedAtom = atom({
  key: "guidelinesCardDismissedState",
  default: guidelinesCardDismissedSelector,
});

export const programInfoDismissedAtom = atom({
  key: "ProgramInfoDismissedState",
  default: programInfoDismissedSelector,
});

export const summaryOfWorkTipDismissedAtom = atom({
  key: "summaryOfWorkTipDismissedState",
  default: summaryOfWorkTipDismissedSelector,
});

export const evidenceAtom = atom({
  key: "evidenceState",
  default: evidenceSelector,
});

export const evidenceDraftsAtom = atom({
  key: "evidenceDraftsState",
  default: evidenceDraftsSelector,
});

export const userProgramsAtom = atom<ProgramData[]>({
  key: "userProgramsState",
  default: [],
  effects: [
    ({ onSet, setSelf, trigger }) => {
      const loadState = async () => {
        const oldUserPrograms = await Preferences.get({ key: "userPrograms" });

        // Migrate to localforage
        if (oldUserPrograms?.value) {
          await localforage.setItem("userPrograms", JSON.parse(oldUserPrograms.value));
          // Remove old userPrograms
          await Preferences.remove({ key: "userPrograms" });
        }

        const userPrograms: ProgramData[] | null = await localforage.getItem("userPrograms");

        userPrograms ? setSelf(userPrograms) : setSelf([]);
      };

      if (trigger === "get") {
        loadState();
      }

      onSet(async (newValue, oldValue, isReset) => {
        if (isReset) {
          try {
            await Preferences.remove({ key: "userPrograms" });
            await localforage.removeItem("userPrograms");
          } catch (error) {
            console.log(error);
          }
        } else if (newValue !== undefined) {
          await localforage.setItem("userPrograms", newValue);
        }
      });
    },
  ],
});

export const progressCheckDataAtom = atom<AllProgressCheckStatuses[]>({
  key: "progressCheckDataState",
  default: [],
  effects: [
    ({ onSet, setSelf, trigger }) => {
      const loadState = async () => {
        const progressChecks = await Preferences.get({ key: "progressCheckData" });
        progressChecks?.value ? setSelf(JSON.parse(progressChecks.value)) : setSelf([]);
      };

      if (trigger === "get") {
        loadState();
      }

      onSet(async (newValue, oldValue, isReset) => {
        if (isReset) {
          await Preferences.remove({ key: "progressCheckData" });
        } else if (newValue !== undefined) {
          await Preferences.set({ key: "progressCheckData", value: JSON.stringify(newValue) });
        }
      });
    },
  ],
});

export const debugUserAtom = atom<boolean>({
  key: "debugUserState",
  effects: [
    ({ onSet, setSelf, trigger }) => {
      const loadState = async () => {
        const data: boolean | null = await localforage.getItem("debugUser");
        data ? setSelf(data) : setSelf(false);
      };

      if (trigger === "get") {
        loadState();
      }

      onSet(async (newValue, oldValue, isReset) => {
        if (isReset) {
          await localforage.removeItem("debugUser");
        } else if (newValue !== undefined) {
          await localforage.setItem("debugUser", newValue);
        }
      });
    },
  ],
});

export const programDataAtom = atom<ProgramData[]>({
  key: "programDataState",
  effects: [
    ({ onSet, setSelf, trigger }) => {
      const loadState = async () => {
        const data: ProgramData[] | null = await localforage.getItem("programData");
        data ? setSelf(data) : setSelf([]);
      };

      if (trigger === "get") {
        loadState();
      }

      onSet(async (newValue, oldValue, isReset) => {
        if (isReset) {
          await localforage.removeItem("programData");
        } else if (newValue !== undefined) {
          await localforage.setItem("programData", newValue);
        }
      });
    },
  ],
});

export const programDataVersionInfoAtom = atom<ProgramDataVersions[]>({
  key: "programDataVersionInfoState",
  effects: [
    ({ onSet, setSelf, trigger }) => {
      const loadState = async () => {
        const data: ProgramDataVersions[] | null = await localforage.getItem("programDataVersionInfo");
        data ? setSelf(data) : setSelf([]);
      };

      if (trigger === "get") {
        loadState();
      }

      onSet(async (newValue, oldValue, isReset) => {
        if (isReset) {
          try {
            const a = await localforage.removeItem("programDataVersionInfo");
            console.log(a);
          } catch (error) {
            console.log(error);
          }
        } else if (newValue !== undefined) {
          await localforage.setItem("programDataVersionInfo", newValue);
        }
      });
    },
  ],
});

export const draftsHintDismissedAtom = atom({
  key: "draftsHintDismissedState",
  default: draftsHintDismissedSelector,
});

export const newsAtom = atom({
  key: "newsState",
  default: newsSelector,
});

export const plusDataAtom = atom({
  key: "plusDataState",
  default: plusDataSelector,
});

export const parapassDataAtom = atom({
  key: "parapassDataState",
  default: parapassDataSelector,
});

export const deviceInfoAtom = atom<DeviceInfo | null>({
  key: "deviceInfoState",
  default: null,
});

export const appInfoAtom = atom<AppInfo | null>({
  key: "appInfoState",
  default: null,
});

export const accessibilityAtom = atom({
  key: "accessibilityState",
  default: accessibilitySelector,
});

export const helpCentreTimestampAtom = atom<HelpCentreTimestamp>({
  key: "helpCentreTimestampState",
  effects: [
    ({ onSet, setSelf, trigger }) => {
      const loadState = async () => {
        const data: HelpCentreTimestamp | null = await localforage.getItem("helpCentreTimestamp");
        data ? setSelf(data) : setSelf({ lastUpdatedTime: 0 });
      };

      if (trigger === "get") {
        loadState();
      }

      onSet(async (newValue, oldValue, isReset) => {
        if (isReset) {
          try {
            await localforage.removeItem("helpCentreTimestamp");
          } catch (error) {
            console.log(error);
          }
        } else if (newValue !== undefined) {
          await localforage.setItem("helpCentreTimestamp", newValue);
        }
      });
    },
  ],
});

export const helpCentreContentAtom = atom<HelpCentreContent[]>({
  key: "helpCenterContentState",
  effects: [
    ({ onSet, setSelf, trigger }) => {
      const loadState = async () => {
        const data: HelpCentreContent[] | null = await localforage.getItem("helpCentreContent");
        data ? setSelf(data) : setSelf([]);
      };

      if (trigger === "get") {
        loadState();
      }

      onSet(async (newValue, oldValue, isReset) => {
        if (isReset) {
          try {
            await localforage.removeItem("helpCentreContent");
          } catch (error) {
            console.log(error);
          }
        } else if (newValue !== undefined) {
          await localforage.setItem("helpCentreContent", newValue);
        }
      });
    },
  ],
});
