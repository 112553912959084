import { Capacitor } from "@capacitor/core";
import { useRecoilState } from "recoil";
import { userAtom } from "../../state/State";
import IAPService from "../../controllers/IAPService";
import DataController from "../../controllers/DataController";
import { format, isAfter, isSameDay } from "date-fns";
import { useEffect, useState } from "react";
import AuthenticationController from "../../controllers/AuthenticationController";
import _ from "lodash";
import { useLoggedIn } from "../auth/useLoggedIn";

export const useSubscriptionRenewal = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const loggedIn = useLoggedIn();

  useEffect(() => {
    const checkSubscriptionRenewal = async () => {
      let entitlements = await IAPService.restoreSubscription();
      const allSubscriptions = user?.userData ? user.userData?.subscriptions : [];
      const latestSubscription = DataController.getLatestPortfolioSubscription(allSubscriptions);

      if (entitlements.success && entitlements.subs.length > 0) {
        const latest = entitlements.subs[0];

        if (latest && latestSubscription) {
          const dateSeconds = parseInt(latestSubscription.ExpiryDate.replace("/Date(", "").replace(")/", ""), 10);
          const dateCurrent = new Date(dateSeconds);
          const dateRenewal = new Date(latest.endDate!);

          if (!isSameDay(dateCurrent, dateRenewal) && isAfter(dateRenewal, dateCurrent)) {
            let response = await IAPService.sendPurchaseInfo(
              `${user.userData.contactID}`,
              format(new Date(latest.startDate!), "yyyy-MM-dd"),
              latest.transactionId!,
              latest.productId!,
              Capacitor.getPlatform() === "ios" ? "iOS" : "Android",
              user.token!,
              format(new Date(latest.endDate!), "yyyy-MM-dd")
            );

            if (response) {
              let userToSave = await AuthenticationController.getUserData(user);

              if (userToSave) {
                setUser(userToSave);
              }
            }
          }
        }
      }
    };

    if (loggedIn && user) {
      if (Capacitor.getPlatform() !== "web") {
        checkSubscriptionRenewal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
