import { useRecoilStateLoadable, useRecoilValue, useSetRecoilState } from "recoil";
import { evidenceAtom, userAtom } from "../../state/State";
import { IEvidence, IUser } from "../../Interfaces";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { DATA_REFRESH_INTERVAL } from "../../Constants";
import { DatabaseService } from "../../controllers/DatabaseService";
import DataController from "../../controllers/DataController";
import { useLoggedIn } from "../auth/useLoggedIn";

type RefreshUserEvidence = {
  refreshUserEvidence: (user: IUser) => Promise<void>;
};

export const useRefreshUserEvidence = (shouldLoad?: boolean): RefreshUserEvidence => {
  const user = useRecoilValue(userAtom);
  const [evidence, setEvidence] = useRecoilStateLoadable<IEvidence[] | null>(evidenceAtom);
  const loggedIn = useLoggedIn();

  const refreshUserEvidence = useCallback(async (_user: IUser): Promise<void> => {
    try {
      let allEvidence = [];
      allEvidence = await DatabaseService.getEvidence(_user);

      // Check if evidence from server is equal to current evidence
      if (!_.isEqual(evidence.contents, allEvidence)) {
        await DataController.saveEvidence(allEvidence);
        setEvidence(allEvidence);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const getUserEvidence = async (): Promise<void> => {
      try {
        if (loggedIn && user && shouldLoad) {
          let allEvidence = [];
          allEvidence = await DatabaseService.getEvidence(user);

          // Check if evidence from server is equal to current evidence
          if (!_.isEqual(evidence.contents, allEvidence)) {
            await DataController.saveEvidence(allEvidence);
            setEvidence(allEvidence);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const interval = setInterval(() => {
      getUserEvidence();
    }, DATA_REFRESH_INTERVAL);

    return () => {
      try {
        clearInterval(interval);
      } catch (error) {
        console.log(error);
      }
    };
  }, [evidence, loggedIn, setEvidence, user, shouldLoad]);

  useEffect(() => {
    const getUserEvidence = async (): Promise<void> => {
      try {
        if (user) {
          let allEvidence = [];
          allEvidence = await DatabaseService.getEvidence(user);
          await DataController.saveEvidence(allEvidence);
          setEvidence(allEvidence);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (loggedIn && user && shouldLoad) {
      getUserEvidence();
    }
  }, [loggedIn, setEvidence, user, shouldLoad]);

  return {
    refreshUserEvidence,
  };
};
