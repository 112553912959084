/* eslint-disable @typescript-eslint/no-explicit-any */
import { RawEditorOptions } from "tinymce";

export function getInitialHeight(): number {
  const width = window.innerWidth;

  if (width <= 600) {
    return 400;
  } else if (width <= 960) {
    return 375;
  }

  return 250;
}

export const editorContentStyle: string = `
body {
  font-size: 14pt;
  margin: 16px;
}

body::before {
  white-space: pre-wrap !important;
  pointer-events: none;
}
`;

export const BenefitsEditorConfig: RawEditorOptions | any = {
  height: getInitialHeight(),
  placeholder: "How did this activity benefit the service user...",
  browser_spellcheck: true,
  branding: false,
  menubar: false,
  paste_data_images: false,
  content_style: "body {font-size: 14pt;}",
  plugins: ["autolink", "wordcount", "lists", "code"],
  invalid_styles: {
    "*": "color line-height mso-outline-level background font-family",
  },
  style_formats: [
    {
      title: "Headings",
      items: [
        { title: "Heading 1", block: "h1" },
        { title: "Heading 2", block: "h2" },
        { title: "Heading 3", block: "h3" },
      ],
    },
    {
      title: "Inline",
      items: [
        { title: "Bold", block: "strong" },
        { title: "Italic", block: "em" },
        { title: "Underline", block: "u" },
        { title: "Strikethrough", block: "s" },
      ],
    },
    {
      title: "Blocks",
      items: [
        { title: "Paragraph", block: "p" },
        { title: "Blockquote", block: "blockquote" },
      ],
    },
  ],
  fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
  toolbar: ["undo redo | styles fontsize | bold italic underline | bullist numlist | alignleft aligncenter alignright"],
  mobile: {
    toolbar_mode: "sliding",
  },
};

export const ReflectionsEditorConfig: RawEditorOptions | any = {
  height: getInitialHeight(),
  placeholder: "What do you want to say about this piece of evidence...",
  browser_spellcheck: true,
  branding: false,
  menubar: false,
  paste_data_images: false,
  content_style: "body {font-size: 14pt;}",
  plugins: ["autolink", "wordcount", "lists"],
  invalid_styles: {
    "*": "color line-height mso-outline-level background font-family",
  },
  style_formats: [
    {
      title: "Headings",
      items: [
        { title: "Heading 1", block: "h1" },
        { title: "Heading 2", block: "h2" },
        { title: "Heading 3", block: "h3" },
      ],
    },
    {
      title: "Inline",
      items: [
        { title: "Bold", block: "strong" },
        { title: "Italic", block: "em" },
        { title: "Underline", block: "u" },
        { title: "Strikethrough", block: "s" },
      ],
    },
    {
      title: "Blocks",
      items: [
        { title: "Paragraph", block: "p" },
        { title: "Blockquote", block: "blockquote" },
      ],
    },
  ],
  fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
  toolbar: ["undo redo | styles fontsize | bold italic underline | bullist numlist | alignleft aligncenter alignright"],
  mobile: {
    toolbar_mode: "sliding",
  },
};

export const ObjectivesEditorConfig: RawEditorOptions | any = {
  height: getInitialHeight(),
  placeholder: "What were the learning / exercise objectives...",
  browser_spellcheck: true,
  branding: false,
  menubar: false,
  paste_data_images: false,
  content_style: "body {font-size: 14pt;}",
  plugins: ["autolink", "wordcount", "lists", "code"],
  invalid_styles: {
    "*": "color line-height mso-outline-level background font-family",
  },
  style_formats: [
    {
      title: "Headings",
      items: [
        { title: "Heading 1", block: "h1" },
        { title: "Heading 2", block: "h2" },
        { title: "Heading 3", block: "h3" },
      ],
    },
    {
      title: "Inline",
      items: [
        { title: "Bold", block: "strong" },
        { title: "Italic", block: "em" },
        { title: "Underline", block: "u" },
        { title: "Strikethrough", block: "s" },
      ],
    },
    {
      title: "Blocks",
      items: [
        { title: "Paragraph", block: "p" },
        { title: "Blockquote", block: "blockquote" },
      ],
    },
  ],
  fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
  toolbar: ["undo redo | styles fontsize | bold italic underline | bullist numlist | alignleft aligncenter alignright"],
  mobile: {
    toolbar_mode: "sliding",
  },
};
