import { Capacitor } from "@capacitor/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonFooter,
  IonHeader,
  IonItem,
  IonPage,
  IonPopover,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { addYears, isSameDay, isValid, subYears } from "date-fns";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { EventRegister } from "react-native-event-listeners";
import { useHistory, useLocation, useParams, withRouter } from "react-router";
import { useRecoilStateLoadable } from "recoil";
import { useFilePicker } from "use-file-picker";
import {
  AWSAccessParams,
  ICertificateInfo,
  IDatePickerType,
  IUser,
  IUserProgramCertificate,
  IUserProgramRoles,
  OnboardingCertificateIssueDate,
  OnboardingDatePickerType,
  ProgramCertificate,
  ProgramData,
  ProgramRole,
} from "../../Interfaces";
import {
  Button_Checkbox_Active,
  Button_Checkbox_Inactive,
  Icon_Radio_Active,
  Icon_Radio_Grey,
} from "../../assets/images";
import HeaderTitle from "../../components/common/HeaderTitle";
import CertificatesList from "../../components/programs/CertificatesList";
import AWSService from "../../controllers/AWSService";
import AuthenticationController from "../../controllers/AuthenticationController";
import DataController from "../../controllers/DataController";
import { DatabaseService } from "../../controllers/DatabaseService";
import { FirebaseService } from "../../controllers/FirebaseService";
import RemoteDataController from "../../controllers/RemoteDataController";
import { userAtom } from "../../state/State";
import * as ProgramUtils from "../../utils/programUtils";
import OptionalStandardCheckbox from "../../components/programs/OptionalStandardCheckbox";
import { IOptionalStandard } from "../../Interfaces";
import { CERTIFICATE_ACCEPTED_FILE_TYPES, CERTIFICATE_MAX_FILE_SIZE } from "../../Constants";
import { useProgramData } from "../../hooks/data/useProgramData";

const ProgramDetails: React.FC = (props) => {
  const history = useHistory();
  const location = useLocation();

  const params = useParams<{ programId: string }>();

  const { userPrograms } = useProgramData();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openFileSelector, { filesContent, clear, plainFiles, errors }] = useFilePicker({
    accept: CERTIFICATE_ACCEPTED_FILE_TYPES,
    maxFileSize: CERTIFICATE_MAX_FILE_SIZE,
    readAs: "DataURL",
    multiple: false,
    limitFilesConfig: { max: 1 },
  });

  const [user, setUser] = useRecoilStateLoadable<IUser | null>(userAtom);

  const program = userPrograms.find((item) => item.ID === params.programId)!;
  // const [program, setProgram] = useState<ProgramData | any | null>(null);
  const [allCertificates, setAllCertificates] = useState<any[]>([]);
  const [allRoles, setAllRoles] = useState<any[]>([]);

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  const [selectedStandards, setSelectedStandards] = useState<IOptionalStandard[]>([]);

  const [declarationChecked, setDeclarationChecked] = useState<boolean>(false);

  const [selectedArea, setSelectedArea] = useState<string>("");

  const [uploads, setUploads] = useState<ICertificateInfo[]>([]);
  const [deletions, setDeletions] = useState<ICertificateInfo[]>([]);

  const [existingCertificates, setExistingCertificates] = useState<IUserProgramCertificate[]>([]);

  const [certificateType, setCertificateType] = useState<string>("");
  const [selectingCertificate, setSelectingCertificate] = useState<boolean>(false);
  const [deletingCertificate, setDeletingCertificate] = useState<boolean>(false);
  const [issueDates, setIssueDates] = useState<OnboardingCertificateIssueDate[]>([]);
  const [expiryDates, setExpiryDates] = useState<OnboardingCertificateIssueDate[]>([]);

  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);
  const [datePickerType, setDatePickerType] = useState<string>(IDatePickerType.ISSUE);
  const [datePickerValue, setDatePickerValue] = useState<Date>(new Date());

  const [newDateInfo, setNewDateInfo] = useState<(OnboardingCertificateIssueDate & OnboardingDatePickerType) | null>(
    null
  );
  const [changingDate, setChangingDate] = useState<boolean>(false);

  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    EventRegister.emit("tab-bar/visibility-changed", false);

    return () => EventRegister.emit("tab-bar/visibility-changed", true);
  });

  useEffect(() => {
    const getProgramData = (): void => {
      if (program) {
        if (program.Roles) {
          setAllRoles(_.orderBy(program.Roles, ["Name"], ["asc"]));
        }

        if (program.Certificates) {
          setAllCertificates(_.orderBy(program.Certificates, ["Required", "Name"], ["asc", "asc"]));
        }
      }
    };

    getProgramData();
  }, [program?.version]);

  useEffect(() => {
    if (plainFiles.length > 0) {
      const _certificate: ICertificateInfo = {
        name: plainFiles[0].name,
        type: plainFiles[0].type,
        size: plainFiles[0].size,
        content: filesContent[0].content,
        lastModified: plainFiles[0].lastModified,
        certificateType: certificateType,
      };

      let array = _.cloneDeep(uploads);

      if (!Array.isArray(array)) {
        array = [array];
      }

      const index = _.findIndex(array, (item: ICertificateInfo) => item.certificateType === certificateType);

      if (index > -1) {
        array.splice(index, 1);
        array.push(_certificate);
      } else {
        array.push(_certificate);
      }

      setUploads(array);
      clear();
    }
  }, [certificateType, clear, filesContent, plainFiles, uploads]);

  useEffect(() => {
    const getUserProgramCertificates = (): void => {
      let _programCertificates = user.contents?.programCertificates || [];
      _programCertificates = _programCertificates.filter(
        (item: IUserProgramCertificate) => item.programData.programName === program?.Name
      );

      // console.log('Existing certificates: ', _programCertificates);

      setExistingCertificates(_programCertificates);
    };

    if (user.state === "hasValue" && program) {
      getUserProgramCertificates();
    }
  }, [user, program]);

  useEffect(() => {
    const setInitialRoles = (): void => {
      if (user.contents) {
        const roles: IUserProgramRoles[] = _.cloneDeep(user.contents?.programRoles);

        if (roles) {
          const programRoles = roles.find((item) => item.programName === program.Name);

          if (programRoles) {
            // TODO replace this check with with something better
            if (ProgramUtils.isEPRR(programRoles.programID!)) {
              setSelectedRoles(programRoles.programRoles.map((item) => item.roleName));

              // set initial standards
              if (programRoles.programRoles.length) {
                const matchedRole = program.Roles?.find(
                  (role: any) => role.ID === programRoles.programRoles[0].roleID
                )!;
                const ids: string[] = programRoles.programRoles[0].optionalStandards.map(
                  (standard: any) => standard.id
                );
                const preSelectedStandards = matchedRole.OptionalCompetenceActivity?.filter(
                  (item: any) => "ID" in item && ids.includes(item.ID)
                );

                const mapped =
                  preSelectedStandards?.map((item: any) => ({
                    id: item.ID,
                    name: item.Name,
                    description: item.Description,
                    learningObjectives: [],
                  })) ?? [];
                setSelectedStandards(mapped);
              }
            } else {
              setSelectedRoles(programRoles.programRoles);
            }
          }
        }
      }
    };

    if (user.state === "hasValue" && program) {
      setInitialRoles();
    }
  }, [user, program]);

  useEffect(() => {
    const setInitialArea = (): void => {
      if (user.contents) {
        const roles: IUserProgramRoles[] = _.cloneDeep(user.contents?.programRoles);

        if (roles) {
          const programRoles = roles.find((item) => item.programName === program.Name);

          if (programRoles) {
            if (programRoles.area) {
              setSelectedArea(programRoles.area!);
            }
          }
        }
      }
    };

    if (user.state === "hasValue" && program) {
      setInitialArea();
    }
  }, [user, program]);

  const getOptionalStandardsForSelectedRole = () => {
    if (!selectedRoles.length) return [];

    const standards: IOptionalStandard[] = [];
    selectedRoles.forEach((role) => {
      const matchedRole = program.Roles?.find((item: any) => item.Name === role);
      if (matchedRole) {
        const newStandards = matchedRole.OptionalCompetenceActivity?.map((item: any) => ({
          id: item.ID,
          name: item.Name,
          description: item.Description,
        }));
        newStandards?.map((newStandard: any) => standards.push(newStandard));
      }
    });

    return standards;
  };

  const addCertificate = async (type: string): Promise<void> => {
    try {
      setCertificateType(type);
      setSelectingCertificate(true);
      openFileSelector();
    } catch (error) {
      console.log(error);
      setSelectingCertificate(false);
      window.alert("Failed to add certificate. Please try again");
    }
  };

  const canSaveDetails = (): boolean => {
    if (ProgramUtils.validateDetails(program?.ID)) {
      let optionalStandardsIsValid = false;
      const optionalStandards = getOptionalStandardsForSelectedRole();
      if (optionalStandards.length === 0) {
        optionalStandardsIsValid = true;
      } else if (optionalStandards.length < 2) {
        optionalStandardsIsValid = selectedStandards.length === 1;
      } else {
        optionalStandardsIsValid = selectedStandards.length === 2;
      }
      let declarationIsValid = true;
      if (ProgramUtils.showDeclarationOfObligationsTickbox(program.ID)) {
        declarationIsValid = declarationChecked;
      }

      if (program.Certificates?.length) {
        let uploadIsOk = false;
        if ((uploads.length > 0 && issueDates.length > 0) || existingCertificates.length > 0) {
          uploadIsOk = true;
        }
        const canSave =
          uploadIsOk &&
          selectedRoles.length > 0 &&
          selectedArea.length > 0 &&
          optionalStandardsIsValid &&
          declarationIsValid;
        return canSave;
      } else {
        const canSave =
          selectedRoles.length > 0 && selectedArea.length > 0 && optionalStandardsIsValid && declarationIsValid;
        return canSave;
      }
    } else {
      const hasSelectedRoles = selectedRoles.length > 0;
      const hasCertificatesToUpload = uploads.length > 0;
      const hasCertificatesToDelete = deletions.length > 0;
      const hasExistingCertificates = existingCertificates.length > 0;

      return hasCertificatesToUpload || hasCertificatesToDelete || hasExistingCertificates || hasSelectedRoles;
    }
  };

  const deleteCertificate = async (user: IUser, deletions: ICertificateInfo[]): Promise<boolean | void> => {
    const toDelete = _.cloneDeep(deletions);
    const accessParams: AWSAccessParams = await AWSService.generateSTSToken(user);

    for (let i = 0; i < toDelete.length; i++) {
      const file = toDelete[i];

      if (accessParams) {
        await AWSService.deleteCertificate(user, accessParams, file);
      }
    }

    return true;
  };

  const uploadCertificates = async (user: IUser, uploads: ICertificateInfo[]): Promise<ICertificateInfo[]> => {
    const toUpload = _.cloneDeep(uploads);
    const accessParams: AWSAccessParams = await AWSService.generateSTSToken(user);

    const uploaded = [];

    for (let i = 0; i < toUpload.length; i++) {
      let file = await AWSService.uploadCertificate(user, accessParams, toUpload[i]);

      if (file && file.networkIssue) {
        file = await AWSService.uploadCertificateFromServer(user, accessParams, toUpload[i]);
      }

      const updatedFile: ICertificateInfo = {
        ...toUpload[i],
        content: undefined,
        url: file.Location,
      };

      delete updatedFile.content;

      uploaded.push(updatedFile);
    }

    return uploaded;
  };

  const saveDetails = async (): Promise<void> => {
    setSaving(true);

    try {
      if (user.state === "hasValue" && user.contents) {
        const userToSave = _.cloneDeep(user.contents);

        const userProgramCertificates = _.cloneDeep(userToSave.programCertificates) || [];

        if (deletions.length > 0) {
          const deleted = await deleteCertificate(user.contents, deletions);

          if (deleted) {
            for (let i = 0; i < deletions.length; i++) {
              const certificate = deletions[i];

              const index = userProgramCertificates?.findIndex(
                (item) => item.certificate?.certificateType === certificate.certificateType
              );

              if (index > -1) {
                userProgramCertificates.splice(index, 1);
              }
            }

            userToSave.programCertificates = userProgramCertificates;
          }
        }

        if (uploads.length > 0) {
          const uploaded = await uploadCertificates(user.contents, uploads);

          await FirebaseService.logEvent("program_certificates_uploaded", {
            certificates_uploaded: uploaded.length,
            program: program.Name,
          });

          if (uploaded) {
            for (let i = 0; i < uploaded.length; i++) {
              const certificate = uploaded[i];
              const issueDate = issueDates.find((item) => item.type === certificate.certificateType);
              const expiryDate = expiryDates.find((item) => item.type === certificate.certificateType);

              const _certificate: IUserProgramCertificate = {
                certificate,
                ...(issueDate && { issueDate: issueDate.date.toISOString() }),
                ...(expiryDate && {
                  expiryDate: expiryDate.date.toISOString(),
                }),
                programData: {
                  programName: program.Name,
                  certificateType: certificate.certificateType,
                },
              };

              const index = userProgramCertificates?.findIndex(
                (item) => item.certificate?.certificateType === _certificate.certificate?.certificateType
              );

              if (index > -1) {
                userProgramCertificates.splice(index, 1, _certificate);
              } else {
                userProgramCertificates.push(_certificate);
              }
            }
          }
        }

        if (uploads.length === 0) {
          if (issueDates.length > 0) {
            for (let i = 0; i < issueDates.length; i++) {
              let issueDate = issueDates[i];

              const certificate: IUserProgramCertificate | undefined = userProgramCertificates?.find(
                (item) => item.certificate?.certificateType === issueDate.type
              );
              const index = userProgramCertificates?.findIndex(
                (item) => item.certificate?.certificateType === issueDate.type
              );

              if (certificate) {
                const _certificate: IUserProgramCertificate = {
                  ...certificate,
                  issueDate: issueDate.date.toISOString(),
                };

                if (index > -1) {
                  userProgramCertificates.splice(index, 1, _certificate);
                }
              }
            }
          }

          if (expiryDates.length > 0) {
            for (let i = 0; i < expiryDates.length; i++) {
              let expiryDate = expiryDates[i];

              const certificate: IUserProgramCertificate | undefined = userProgramCertificates?.find(
                (item) => item.certificate?.certificateType === expiryDate.type
              );
              const index = userProgramCertificates?.findIndex(
                (item) => item.certificate?.certificateType === expiryDate.type
              );

              if (certificate) {
                const _certificate: IUserProgramCertificate = {
                  ...certificate,
                  expiryDate: expiryDate.date.toISOString(),
                };

                if (index > -1) {
                  userProgramCertificates.splice(index, 1, _certificate);
                }
              }
            }
          }
        }

        userToSave.programCertificates = userProgramCertificates;

        const userRoles = userToSave.programRoles || [];

        let _userRoles: IUserProgramRoles = {
          programName: program.Name,
          programRoles: selectedRoles,
        };

        if (ProgramUtils.saveUserDetailsForEPRRProgram(program?.ID)) {
          const role = program.Roles?.find((r: any) => r.Name === selectedRoles[0])!;
          _userRoles.programRoles = [
            {
              roleID: role.ID,
              roleName: selectedRoles[0],
              optionalStandards: selectedStandards.map((standard) => ({
                id: standard.id,
                name: standard.name,
              })),
            },
          ];
          _userRoles.programID = program?.ID;
          _userRoles.area = selectedArea;
          if (ProgramUtils.showDeclarationOfObligationsTickbox(program.ID)) {
            _userRoles.declarationTickDate = new Date().toISOString();
          }

          FirebaseService.logEvent("optional_standards_confirmed", {
            standards: selectedStandards.map((standard) => standard.name),
          });
        }

        let index = userRoles?.findIndex((item) => item.programName === program.Name);

        if (index > -1) {
          userRoles.splice(index, 1, _userRoles);
        } else {
          userRoles.push(_userRoles);
        }

        userToSave.programRoles = userRoles;

        await DatabaseService.updateUserDetails(userToSave, {
          programRoles: userToSave.programRoles,
          programCertificates: userToSave.programCertificates,
        });

        if (selectedRoles.length > 0) {
          FirebaseService.logEvent("program_roles_updated", {
            program: program?.Name,
            roles: JSON.stringify(selectedRoles),
          });
        }

        setUser(userToSave);

        setTimeout(() => {
          history.goBack();
        }, 500);
      }
    } catch (error: any) {
      console.log(error);
      RemoteDataController.logError(error);
      setSaving(false);
      window.alert("An error occurred when saving details");
    }
  };

  const removeExistingCertificate = async (certificate: IUserProgramCertificate): Promise<void> => {
    const toDelete = _.cloneDeep(deletions);
    const _programCertificates = _.cloneDeep(existingCertificates);

    const index = toDelete.findIndex((item) => item.certificateType === certificate.certificate?.certificateType);
    const programsCertificateIndex = _programCertificates.findIndex((item) => {
      return item.certificate?.certificateType === certificate.certificate?.certificateType;
    });

    if (index > -1) {
      toDelete.splice(index, 1);
      toDelete.push(certificate.certificate!);
    } else {
      toDelete.push(certificate.certificate!);
    }

    if (programsCertificateIndex > -1) {
      _programCertificates.splice(programsCertificateIndex, 1);
    }

    setDeletions(toDelete);
    setExistingCertificates(_programCertificates);
  };

  const removeCertificateFromUpload = (type: string): void => {
    const toUpload = _.cloneDeep(uploads);
    const _issueDates = _.cloneDeep(issueDates);
    const _expiryDates = _.cloneDeep(expiryDates);

    const uploadsIndex = toUpload.findIndex((item) => item.certificateType === type);
    const issueDatesIndex = _issueDates.findIndex((item) => item.type === type);
    const expiryDatesIndex = _expiryDates.findIndex((item) => item.type === type);

    if (uploadsIndex > -1) {
      toUpload.splice(uploadsIndex, 1);
      setUploads(toUpload);
    }

    if (issueDatesIndex > -1) {
      _issueDates.splice(issueDatesIndex, 1);
      setIssueDates(_issueDates);
    }

    if (expiryDatesIndex > -1) {
      _expiryDates.splice(expiryDatesIndex, 1);
      setExpiryDates(_expiryDates);
    }
  };

  const openDatePicker = (pickerType: string, type: string, initialDate: Date): void => {
    setDatePickerVisible(true);
    setCertificateType(type);
    setDatePickerType(pickerType);

    if (initialDate && isValid(initialDate)) {
      setDatePickerValue(initialDate);
    }
  };

  const closeDatePicker = useCallback((): void => {
    setDatePickerVisible(false);
    setDatePickerValue(new Date());
  }, []);

  useEffect(() => {
    const updateIssueDate = (): void => {
      const _issueDates = _.cloneDeep(issueDates);
      const index = _issueDates.findIndex((item) => item.type === newDateInfo!.type);

      const dateInfo: OnboardingCertificateIssueDate = {
        date: newDateInfo!.date,
        type: newDateInfo!.type,
      };

      if (index > -1) {
        _issueDates.splice(index, 1, dateInfo);
      } else {
        _issueDates.push(dateInfo);
      }

      setIssueDates(_issueDates);
    };

    const updateExpiryDate = (): void => {
      const _expiryDates = _.cloneDeep(expiryDates);
      const index = _expiryDates.findIndex((item) => item.type === newDateInfo!.type);

      const dateInfo: OnboardingCertificateIssueDate = {
        date: newDateInfo!.date,
        type: newDateInfo!.type,
      };

      if (index > -1) {
        _expiryDates.splice(index, 1, dateInfo);
      } else {
        _expiryDates.push(dateInfo);
      }

      setExpiryDates(_expiryDates);
    };

    if (changingDate) {
      if (newDateInfo?.isIssueOrExpiry === IDatePickerType.ISSUE) {
        updateIssueDate();
        setChangingDate(false);
        setNewDateInfo(null);
      } else if (newDateInfo?.isIssueOrExpiry === IDatePickerType.EXPIRY) {
        updateExpiryDate();
        setChangingDate(false);
        setNewDateInfo(null);
      }
    }
  }, [allCertificates.length, changingDate, expiryDates, issueDates, newDateInfo]);

  const changeDate = useCallback(
    (event: any, pickerType: string, certificateType: string, initialDate: Date): void => {
      try {
        const isSameDate = isSameDay(new Date(event.detail.value), initialDate);

        if (newDateInfo === null && !isSameDate && !changingDate) {
          // console.log(event.detail.value, pickerType, certificateType);
          setChangingDate(true);
          setDatePickerValue(new Date(event.detail.value));
          setNewDateInfo({
            date: new Date(event.detail.value),
            isIssueOrExpiry: pickerType,
            type: certificateType,
          });
        }
      } catch (error) {
        console.log(error);
        window.alert("Failed to change issue date. Please try again");
      }
    },
    [changingDate, newDateInfo]
  );

  const getRoleIcon = (selected: boolean) => {
    if (selected) {
      if (ProgramUtils.useRadioRoleSelector(program?.ID)) {
        return Icon_Radio_Active;
      } else {
        return Button_Checkbox_Active;
      }
    } else {
      if (ProgramUtils.useRadioRoleSelector(program?.ID)) {
        return Icon_Radio_Grey;
      } else {
        return Button_Checkbox_Inactive;
      }
    }
  };

  const standardsForSelectedRole = getOptionalStandardsForSelectedRole();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref="/dashboard"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{"Your details"}</HeaderTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background whiteBackground">
        {/* <pre>{JSON.stringify(uploads, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(issueDates, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(allCertificates, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(expiryDates, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(existingCertificates, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(selectedStandards, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(user.contents.programRoles, null, 2)}</pre> */}
        <div className="editRolesContent" style={{ paddingTop: 16 }}>
          <div className="programAddCertificateTitle">{"Set up your details"}</div>
          {program?.DetailsDescription && <div className="accountCardSubTitle">{program.DetailsDescription}</div>}
          <IonPopover isOpen={datePickerVisible} onDidDismiss={() => closeDatePicker()}>
            <IonDatetime
              mode="md"
              size="cover"
              value={datePickerValue.toISOString()}
              onChange={(event) => console.log("DateTimeOnChange", event)}
              onIonChange={(event) =>
                changeDate(event, datePickerType, certificateType, new Date(datePickerValue) || null)
              }
              min={subYears(new Date(), 10).toISOString()}
              max={
                datePickerType === IDatePickerType.EXPIRY
                  ? addYears(new Date(), 10).toISOString()
                  : new Date().toISOString()
              }
              presentation="date"
              firstDayOfWeek={1}
            />
          </IonPopover>
          {!ProgramUtils.showCertsAfterRoleSelection(program?.ID) && (
            <CertificatesList
              program={program}
              certs={allCertificates}
              issueDates={issueDates}
              expiryDates={expiryDates}
              existingCertificates={existingCertificates}
              uploads={uploads}
              deletingCertificate={deletingCertificate}
              certificateType={certificateType}
              removeExistingCertificate={removeExistingCertificate}
              removeCertificateFromUpload={removeCertificateFromUpload}
              addCertificate={addCertificate}
              openDatePicker={openDatePicker}
            />
          )}
          {allRoles.length > 0 && (
            <div style={{ marginBottom: 32 }}>
              <div className="editRolesHeader" style={{ marginTop: program?.Certificates ? 32 : 16 }}>
                {ProgramUtils.getRolesLabel(program?.ID)}
              </div>
              {allRoles.map((item: ProgramRole) => {
                const selected = selectedRoles.includes(item.Name);

                const toggleValue = (value: string): void => {
                  let array = _.cloneDeep(selectedRoles);

                  if (!Array.isArray(array)) {
                    array = [array];
                  }

                  if (array.includes(value)) {
                    array = array.filter((_item) => _item !== value);
                  } else {
                    if (ProgramUtils.useRadioRoleSelector(program?.ID) && array.length === 1) {
                      array = [value];
                    } else {
                      array.push(value);
                    }
                  }

                  setSelectedRoles(array);
                  setSelectedStandards([]);
                };

                return (
                  <IonItem
                    button
                    detail={false}
                    key={item.ID}
                    className="detailsRoleCheckboxContainer"
                    onClick={() => toggleValue(item.Name)}
                  >
                    <div className="evidencePartsCheckboxContainerInner">
                      <div className="programRoleCheckboxContainerInner">
                        <div
                          className={`evidencePartsCheckboxLabel ${selected ? "evidencePartsCheckboxLabelSelected" : ""}`}
                        >
                          {item.Name}
                        </div>
                        {item.Description && <div className="programRoleDescription">{item.Description}</div>}
                      </div>
                      <img className="evidencePartsRadioCheckbox" src={getRoleIcon(selected)} />
                    </div>
                  </IonItem>
                );
              })}
            </div>
          )}
          {ProgramUtils.showCertsAfterRoleSelection(program?.ID) && (
            <div className="mb-4">
              <CertificatesList
                program={program}
                certs={allCertificates}
                issueDates={issueDates}
                expiryDates={expiryDates}
                existingCertificates={existingCertificates}
                uploads={uploads}
                deletingCertificate={deletingCertificate}
                certificateType={certificateType}
                removeExistingCertificate={removeExistingCertificate}
                removeCertificateFromUpload={removeCertificateFromUpload}
                addCertificate={addCertificate}
                openDatePicker={openDatePicker}
              />
            </div>
          )}
          {ProgramUtils.showProgramAreas(program?.ID) && (
            <>
              <div className="editRolesHeader" style={{ marginTop: 24, marginBottom: 6 }}>
                Area (Mandatory)
              </div>
              {program.Areas!.toSorted().map((area: string) => (
                <IonItem
                  button
                  detail={false}
                  key={area}
                  className="radioItem bg-white cursor-pointer"
                  onClick={() => {
                    if (selectedArea === area) {
                      setSelectedArea("");
                    } else {
                      setSelectedArea(area);
                    }
                  }}
                >
                  <div className="w-full flex flex-row items-center justify-between">
                    <div className="flex flex-col w-full">
                      <div
                        className={`text-acc-17px font-acc-400 leading-6 text-grey-90 w-3/4 whitespace-nowrap overflow-hidden overflow-ellipsis ${area === selectedArea ? "font-semibold" : ""}`}
                      >
                        {area}
                      </div>
                    </div>
                    <img
                      className="evidencePartsRadioCheckbox"
                      src={area === selectedArea ? Icon_Radio_Active : Icon_Radio_Grey}
                    />
                  </div>
                </IonItem>
              ))}
            </>
          )}
          {ProgramUtils.showOptionalStandardsInDetailsPage(program?.ID) && standardsForSelectedRole.length > 0 && (
            <div className="mb-6">
              <div className="editRolesHeader" style={{ marginTop: 24, marginBottom: 6 }}>
                Select optional standards (Mandatory)
              </div>
              <div className="text-grey-90 text-acc-13px font-acc-400 mb-3">
                You are required to select 2 optional standards that you would like to achieve (except for the Loggist
                role, which requires one optional standard).
              </div>
              {standardsForSelectedRole.map((standard) => {
                const itemInSelectedItemsState = selectedStandards.find((s) => s.name === standard.name);
                return (
                  <OptionalStandardCheckbox
                    key={standard.name}
                    standard={standard}
                    checked={itemInSelectedItemsState !== undefined}
                    handleClick={() => {
                      setSelectedStandards((prev) => {
                        if (itemInSelectedItemsState) {
                          // remove item
                          return prev.filter((it) => it.name !== itemInSelectedItemsState.name);
                        } else {
                          // add item
                          return [...prev, standard];
                        }
                      });
                    }}
                  />
                );
              })}
            </div>
          )}
          {ProgramUtils.showDeclarationOfObligationsTickbox(program.ID) && program.DeclarationOfObligationsText && (
            <div className="mb-6">
              <div className="editRolesHeader" style={{ marginTop: 24, marginBottom: 6 }}>
                Declaration of Obligations (Mandatory)
              </div>
              <div
                className="declarationText"
                dangerouslySetInnerHTML={{ __html: program.DeclarationOfObligationsText }}
              />
              <IonCheckbox
                onIonChange={() => setDeclarationChecked(!declarationChecked)}
                checked={declarationChecked}
                className={`text-acc-17px font-acc-400 text-grey-90`}
                labelPlacement="end"
              >
                I have read this declaration
              </IonCheckbox>
            </div>
          )}
        </div>
        {/* <pre>{JSON.stringify(selectedStandards, null, 2)}</pre> */}
      </IonContent>
      <IonFooter className="programDetailsFooter">
        <div className="programDetailsSaveContainer">
          <IonButton
            mode="ios"
            disabled={!canSaveDetails() || saving}
            className={`programDetailsSaveButton ${!canSaveDetails() && "reflectionsSaveButtonDisabled"}`}
            onClick={() => saveDetails()}
          >
            {saving ? <IonSpinner className="reflectionSaveSpinner" /> : <div>{"Confirm"}</div>}
          </IonButton>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default withRouter(ProgramDetails);
