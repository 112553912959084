import { AppInfo } from "@capacitor/app";
import { Device, DeviceInfo } from "@capacitor/device";
import { Preferences } from "@capacitor/preferences";
import { isPlatform } from "@ionic/react";
import {
  IAppAccess,
  IAppFeatures,
  IAppVersion,
  IClassSubscription,
  IEvidence,
  IEvidenceDraft,
  IExportType,
  ISubscriptionPurchase,
  IUserProgramCertificate,
  ProgramData,
} from "../Interfaces";
import _ from "lodash";
import { Capacitor } from "@capacitor/core";
import { chevronBack, arrowBackSharp } from "ionicons/icons";
import { addMonths, format, isAfter, isValid, subMonths, subWeeks } from "date-fns";
import moment from "moment";
import "moment-duration-format";
import { version } from "../environment/version";
import DOMPurify from "dompurify";
import * as ProgramUtils from "../utils/programUtils";

export class DataController {
  async saveEvidence(evidence: IEvidence[]): Promise<void> {
    try {
      await Preferences.set({
        key: "evidence",
        value: JSON.stringify(evidence),
      });
    } catch (error) {
      console.log(error);
    }
  }

  async deleteEvidence(id: string): Promise<IEvidence[] | null> {
    try {
      const object = await Preferences.get({ key: "evidence" });
      let array: IEvidence[] = [];

      if (object.value) {
        array = JSON.parse(object.value);
      }

      const index = array.findIndex((item) => item.id === id);

      if (index > -1) {
        array.splice(index, 1);
      }

      await Preferences.set({ key: "evidence", value: JSON.stringify(array) });

      return array;
    } catch (error) {
      console.log(error);

      return null;
    }
  }

  async saveDraft(draft: IEvidenceDraft): Promise<boolean> {
    try {
      const object = await Preferences.get({ key: "evidenceDrafts" });
      let array: IEvidenceDraft[] = [];

      if (object.value) {
        array = JSON.parse(object.value);
      }

      const index = array.findIndex((item) => item.evidence.id === draft.evidence.id);

      if (index > -1) {
        array.splice(index, 1, draft);
      } else {
        array.push(draft);
      }

      await Preferences.set({
        key: "evidenceDrafts",
        value: JSON.stringify(array),
      });

      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  }

  async deleteDraft(id: string): Promise<boolean> {
    try {
      const object = await Preferences.get({ key: "evidenceDrafts" });
      let array: IEvidenceDraft[] = [];

      if (object.value) {
        array = JSON.parse(object.value);
      }

      const index = array.findIndex((item) => item.evidence.id === id);

      if (index > -1) {
        array.splice(index, 1);
      }

      await Preferences.set({
        key: "evidenceDrafts",
        value: JSON.stringify(array),
      });

      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  }

  async saveUserPrograms(programs: ProgramData[] | any): Promise<void> {
    try {
      await Preferences.set({
        key: "userPrograms",
        value: JSON.stringify(programs),
      });
    } catch (error) {
      console.log(error);
    }
  }

  async saveJRCALCPlusTimelineData(data: any[]): Promise<void> {
    try {
      await Preferences.set({ key: "plusData", value: JSON.stringify(data) });
    } catch (error) {
      console.log(error);
    }
  }

  async saveParapassUsageData(data: any[]): Promise<void> {
    try {
      await Preferences.set({
        key: "parapassData",
        value: JSON.stringify(data),
      });
    } catch (error) {
      console.log(error);
    }
  }

  async saveProgressCheckData(data: any): Promise<void> {
    try {
      await Preferences.set({
        key: "progressCheckData",
        value: JSON.stringify(data),
      });
    } catch (error) {
      console.log(error);
    }
  }

  getParapassQuizType(type: string): string {
    switch (type) {
      case "topic":
        return "Topic Quiz";
      case "caseStudy":
        return "Case Study Quiz";
      case "issue":
        return "Standby CPD Quiz";
      case "section":
        return "JRCALC Section Quiz";
      case "guideline":
        return "JRCALC Guideline Quiz";
      default:
        return "Quiz";
    }
  }

  getJRCALCGuidelineType(GLID: string): string {
    if (GLID.indexOf("CNID:-") === 0) {
      return "Clinical Notice";
    } else if (GLID.indexOf("ALG-") === 0) {
      return "algorithm";
    } else if (GLID.indexOf("P0") === 0) {
      return "Page for Age";
    } else if (GLID.indexOf("D0") === 0) {
      return "medicine";
    } else {
      return "guideline";
    }
  }

  generateUniqueID(): string {
    return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
  }

  getIndefiniteArticle(role: string): string {
    switch (role) {
      case "Operational Commander":
      case "NILO / Tactical Advisor":
        return "an";
      default:
        return "a";
    }
  }

  // Returns all current Parafolio subscriptions
  getPortfolioSubscriptionIDs(): number[] {
    return [
      1531, // SWASFT HART ParaFolio Group Subscription
      1479, // SWASFT Commander ParaFolio Group Subscription
      1486, // ParaFolio Plus Group Subscription
      1517, // ParaFolio Individual Subscription
      1754, // ParaFolio Test Group Subscription
    ];
  }

  // Returns Parafolio subscriptions with programs
  // Currently used to stop first setup screen
  getSubscriptionIDsWithPrograms(): number[] {
    return [
      1531, // SWASFT HART ParaFolio Group Subscription
      1479, // SWASFT Commander ParaFolio Group Subscription
      1754, // ParaFolio Test Group Subscription
    ];
  }

  getAppTypeFromSubscriptionID(id: number): string {
    switch (id) {
      case 1479:
        return IAppVersion.SWAST;
      case 1486:
      case 1517:
        return IAppVersion.GENERAL;
      default:
        return IAppVersion.GENERAL;
    }
  }

  getAppFeaturesFromSubscriptionID(id: number): string {
    switch (id) {
      case 1479:
        return IAppFeatures.SWAST_MIC;
      case 1486:
      case 1517:
        return IAppFeatures.GENERAL;
      default:
        return IAppFeatures.GENERAL;
    }
  }

  getAccessTypeFromSubscriptionID(id: number): string {
    switch (id) {
      case 1479:
        return IAppAccess.SWAST;
      case 1486:
        return IAppAccess.GROUP;
      case 1517:
        return IAppAccess.INDIVIDUAL;
      default:
        return IAppAccess.INDIVIDUAL;
    }
  }

  supportEmailBody(data: any, deviceInfo: DeviceInfo | null, appInfo: AppInfo | null): string {
    const userData = _.cloneDeep(data) || {};

    let subscriptions: IClassSubscription[] = userData.subscriptions || [];
    subscriptions = subscriptions.filter((item) => this.getPortfolioSubscriptionIDs().includes(item.SubscriptionID));

    delete userData.token;
    delete userData.dbRecord;
    delete userData.subscriptions;
    delete userData.staffGroup;
    delete userData.CFRTrust;
    delete userData.TA;
    delete userData.TAFeatures;
    delete userData.Regions;
    delete userData.MatchUsersWithSubscription;
    delete userData.PersonalAccountExternalID;

    const body = `Type your query here
    
-- App and Device info --
App: ParaFolio
Bundle ID: N/A
${
  !this.isWeb() && appInfo
    ? `App version: ${appInfo.version}
Version: ${appInfo.build}`
    : `App version: Web version
Version: ${version}`
}

Platform: ${deviceInfo?.platform}
Device: ${deviceInfo?.manufacturer} - ${deviceInfo?.model}
OS Version: ${deviceInfo?.operatingSystem} ${deviceInfo?.osVersion}

-- User info --
${!_.isEmpty(userData) ? JSON.stringify(userData, null) : "None"}

-- Subscription info --
${subscriptions.length > 0 ? JSON.stringify(subscriptions, null) : "None"}`;

    return encodeURI(body);
  }

  isWeb(): boolean {
    return isPlatform("mobileweb") || isPlatform("desktop");
  }

  getHeaderHeight(): string {
    if (this.isWeb()) {
      return "44px";
    } else if (Capacitor.getPlatform() === "android") {
      return "54px";
    } else {
      return "unset";
    }
  }

  async isWebAsync(): Promise<boolean> {
    const info = await Device.getInfo();

    return info.platform === "web";
  }

  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  getWordCount = (value: string): number => {
    const cleanText = value.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/gim, "");
    return cleanText.length > 0 ? cleanText.trim().split(/\s+/).length : 0;
  };

  formatHTMLToPlaintext(input: string): string {
    const plaintext: string = input
      .replace(/&nbsp;/gm, " ")
      .replace(/<\/h2/gm, "\n\n</h2")
      .replace(/<\/span/gm, " </span")
      .replace(/<\/sup/gm, " </sup")
      .replace(/<br>/gm, "\n")
      .replace(/&#149;/gm, "•")
      .replace(/\n{3,}/gm, "\n")
      .replace(/\n{2,}/gm, "\n")
      .replace(/\s{3,}/gm, "\n    ")
      .replace(/<!--[\s\S]*?-->/gm, "")
      .replace(/&#145;/gm, "‘")
      .replace(/&#146;/gm, "’")
      .replace(/&#147;/gm, "“")
      .replace(/&#148;/gm, "”")
      .replace(/&#151;/gm, "-")
      .replace(/<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/gm, "")
      .replace(/<[^>]*>?/gm, "");

    return plaintext;
  }

  decodeHTML(html: string): string {
    const doc = new DOMParser().parseFromString(html, "text/html");
    let text = doc.body.innerHTML;

    text = DOMPurify.sanitize(text || "", { ALLOWED_ATTR: ["style"], FORBID_TAGS: ["img"] });

    // Fixes common styling issues caused by Microsoft Word classes
    text = text
      // Replace normal letter spacing with pixel value that react-pdf recognizes
      .replace(/letter-spacing: normal;/gim, "letter-spacing: 0px;")
      // Replaces caret-color with white - Otherwise displays black block instead of text
      .replace(/caret-color: rgb\(\d+, \d+, \d+\);/gim, "caret-color: #FFFFFF;")
      // Replaces caret-color with white - Otherwise displays black block instead of text
      .replace(
        /-webkit-tap-highlight-color: rgba\(\d+, \d+, \d+, \d+\.\d+\);/gim,
        "-webkit-tap-highlight-color: #FFFFFF;"
      );

    return text ?? "";
  }

  getFolderFromExportType(type: string): string {
    switch (type) {
      case IExportType.GENERAL:
        return "Evidence";
      case IExportType.HCPC:
        return "HCPC";
      case IExportType.MAJOR_INCIDENT:
        return "Major Incident";
      default:
        return "Evidence";
    }
  }

  mapGoogleSub(subscription: any): ISubscriptionPurchase {
    // console.log('mapGoogleSub', subscription);
    const endDate = new Date(subscription.expiryDate).toISOString();

    const dateString = subscription.originalStartDate;
    const dateSplit = dateString.split(" ");
    const date = dateSplit[0].split("-");
    const time = dateSplit[1].split(":");

    const startDate = new Date(date[2], date[1] - 1, date[0], time[0], time[1]).toISOString();

    const mappedSubscription: ISubscriptionPurchase = {
      productId: subscription.productIdentifier,
      transactionId: subscription.transactionId,
      startDate,
      endDate,
    };

    return mappedSubscription;
  }

  getLatestPortfolioSubscription(subscriptions: IClassSubscription[]): IClassSubscription | null {
    const subscriptionIDs = this.getPortfolioSubscriptionIDs();

    let portfolioSubscriptions = subscriptions.filter((subscription) =>
      subscriptionIDs.includes(subscription.SubscriptionID)
    );
    let latestSubscription = null;

    if (portfolioSubscriptions.length > 0) {
      portfolioSubscriptions = _.orderBy(portfolioSubscriptions, ["ExpiryDate"], ["desc"]);

      // Get non-expiring subscription
      latestSubscription = portfolioSubscriptions.find((item) => {
        const dateSeconds = parseInt(item.ExpiryDate.replace("/Date(", "").replace(")/", ""), 10);

        return dateSeconds === 0;
      });

      // No non-expiring subscription, get latest
      if (!latestSubscription) {
        latestSubscription = portfolioSubscriptions[0];
      }
    }

    return latestSubscription;
  }

  removePageFromDOM(id: string): void {
    const element = document.getElementById(id);

    setTimeout(() => {
      return element && element.remove();
    }, 1000);
  }

  getBackIconType(): string {
    return Capacitor.getPlatform() === "android" ? arrowBackSharp : chevronBack;
  }

  getBackIconText(): string {
    return Capacitor.getPlatform() === "android" ? "" : "Back";
  }

  convertToHoursMinutes(value: string): string {
    return moment.duration(value, "hour").format("hh:mm", { trim: false });
  }

  convertToNumberOfHours(value: string): string {
    return moment.duration(value, "hours").asHours().toFixed(2) + "";
  }

  getCertificatePillString(
    certificate: IUserProgramCertificate | undefined,
    duration: string,
    canBeBlank?: boolean
  ): string {
    const durationNumber = parseInt(duration, 10) || 12;

    if (certificate?.issueDate) {
      const expiry = addMonths(new Date(certificate.issueDate), durationNumber);
      return format(expiry, "d MMM yy");
    } else if (canBeBlank) {
      return "Add certificate";
    } else {
      return "No certificate";
    }
  }

  getCertificatePillContainer(
    certificate: IUserProgramCertificate | undefined,
    duration: string,
    canBeBlank?: boolean
  ): string {
    const durationNumber = parseInt(duration, 10) || 12;

    if (certificate?.issueDate) {
      const issueDate = new Date(certificate.issueDate);
      const today = new Date();

      // Expiry date is 1 year from issue date
      const expiry = addMonths(issueDate, durationNumber);
      // Red date is 6 weeks before expiry, amber date is 4 months before expiry
      const redDate = subWeeks(expiry, 6);
      const amberDate = subMonths(expiry, 4);

      if (isAfter(today, new Date(expiry))) {
        return "programCardCertificatePillExpired";
      } else if (isAfter(today, redDate)) {
        return "programCardCertificatePillExpired";
      } else if (isAfter(today, amberDate)) {
        return "programCardCertificatePillAmber";
      } else {
        return "programCardCertificatePillGreen";
      }
    } else if (canBeBlank) {
      return "programCardCertificatePillBlank";
    } else {
      return "programCardCertificatePillNone";
    }
  }

  getCertificatePillContainerFromExpiryDate(expiry: Date): string {
    const today = new Date();

    // Red date is 6 weeks before expiry, amber date is 4 months before expiry
    const redDate = subWeeks(expiry, 6);
    const amberDate = subMonths(expiry, 4);

    if (isAfter(today, new Date(expiry))) {
      return "programCardCertificatePillExpired";
    } else if (isAfter(today, redDate)) {
      return "programCardCertificatePillExpired";
    } else if (isAfter(today, amberDate)) {
      return "programCardCertificatePillAmber";
    } else {
      return "programCardCertificatePillGreen";
    }
  }

  getEvidenceCustomTagsKey(evidence: IEvidence): string | undefined {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);

    if (evidenceJSON) {
      switch (evidenceJSON.programInfo.program) {
        case "SWAST HART and EPRR Instructors":
          return "Custom tags";
        default:
          return;
      }
    }

    return;
  }

  checkEvidencePartIsFilled(type: string, value: any): boolean {
    if (!value) {
      return false;
    }

    if (type === "SingleLine text" || type === "TextBox") {
      const string: string = value;

      return string.trim().length !== 0;
    }

    if (type === "Pull down list") {
      const string: string = value;

      return string.length !== 0;
    }

    if (type === "Date") {
      try {
        const date: Date = new Date(value);

        return isValid(date);
      } catch (error) {
        console.log(error);

        return false;
      }
    }

    if (type === "Tick box list" || type === "SingleTick") {
      const array: any[] = value;

      return array.length > 0;
    }

    if (type === "Number") {
      const number: string = value;

      return number.length > 0;
    }

    if (type === "RadioButton" || type === "Scale 1-5") {
      const string: string = value;

      return string.length !== 0;
    }

    if (type === "Radio yes/no with comments") {
      const radioValue: string = value.radioValue;
      // let textValue: string = value.textValue;

      return radioValue.length !== 0;
    }

    if (type === "Attachments") {
      const arrays = _.cloneDeep(value);

      // console.log(arrays);

      return (
        arrays?.attachmentsToAdd?.length > 0 || arrays?.attachmentsToDelete?.length !== arrays?.attachments?.length
      );
    }

    return false;
  }

  getDefaultDateFromProgramEvidence(evidenceJSON: any): Date {
    let string;

    if (evidenceJSON["Date of reflection"]) {
      string = evidenceJSON["Date of reflection"];
    } else if (evidenceJSON["Date of Incident"]) {
      string = evidenceJSON["Date of Incident"];
    } else if (evidenceJSON["Date of activity"]) {
      string = evidenceJSON["Date of activity"];
    }

    return string ? new Date(string) : new Date();
  }

  isDefaultDate(partName: string, program: ProgramData | null): boolean {
    let parts: string[] = [];
    if (ProgramUtils.isEPRR(program?.ID)) {
      parts = ["Date of activity"];
    } else {
      parts = ["Date of reflection", "Date of Incident"];
    }

    return parts.includes(partName);
  }
}

export default new DataController();
