import React, { useEffect, useRef, useState } from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { Comment, CommentsDisclaimer, ContentContainer, HeaderTitle } from "parafolio-components";
import DataController from "../../controllers/DataController";
import { Capacitor } from "@capacitor/core";
import "../../theme/tailwind.css";
import { useRecoilValueLoadable } from "recoil";
import { progressCheckDataAtom, userAtom } from "../../state/State";
import { AllProgressCheckStatuses, IComment, IUser, ProgressCheck, ProgressCheckStatus } from "../../Interfaces";
import { useParams } from "react-router";
import { ProgressCheckService } from "../../controllers/ProgressCheckService";
import CommentWrapper from "../../components/programs/CommentWrapper";
import _ from "lodash";
import { Icon_Refresh } from "../../assets/images";
import { CommentHelpers } from "../../utils/commentHelpers";
import * as ProgramUtils from "../../utils/programUtils";
import CommentInput from "../../components/programs/CommentInput";
import { useProgramData } from "../../hooks/data/useProgramData";
import { useRefreshProgressCheckData } from "../../hooks/data/useRefreshProgressCheckData";
import { useHidingTabBar } from "../../hooks/tabs/useHidingTabBar";
import { useRefreshUserEvidence } from "../../hooks/data/useRefreshUserEvidence";
import { useRefreshUserDetails } from "../../hooks/data/useRefreshUserDetails";
import { StringUtils } from "../../utils/stringUtils";

const Comments: React.FC = (props) => {
  const bottom = useRef<HTMLDivElement>(null);

  const params = useParams<{ programId: string; progressCheckId: string }>();

  useHidingTabBar();

  const { refreshUserDetails } = useRefreshUserDetails();
  const { refreshProgressCheckData } = useRefreshProgressCheckData();
  const { refreshUserEvidence } = useRefreshUserEvidence();

  const { userPrograms } = useProgramData();
  const program = userPrograms.find((item) => item.ID === params.programId)!;

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const allProgressCheckData = useRecoilValueLoadable<AllProgressCheckStatuses[] | null>(progressCheckDataAtom);

  const [progressCheck, setProgressCheck] = useState<ProgressCheck | null>(null);
  const [progressCheckData, setProgressCheckData] = useState<ProgressCheckStatus | null>(null);
  const [comments, setComments] = useState<IComment[]>([]);
  const [refreshingData, setRefreshingData] = useState<boolean>(false);

  useEffect(() => {
    const getProgramData = (): void => {
      if (program) {
        const allProgressChecks: ProgressCheck[] = program?.ProgressChecks ?? [];
        const _progressCheck: ProgressCheck | undefined = allProgressChecks.find(
          (item: any): item is ProgressCheck => item.ID === params.progressCheckId
        );

        if (_progressCheck) {
          setProgressCheck(_progressCheck);
        }
      }
    };

    getProgramData();
  }, [program.version]);

  useEffect(() => {
    setTimeout(() => {
      bottom.current?.scrollIntoView({ behavior: "auto" });
    }, 100);
  }, []);

  useEffect(() => {
    const getProgressCheckData = (): void => {
      const dataForProgram: AllProgressCheckStatuses = allProgressCheckData.contents?.find(
        (item: any): item is AllProgressCheckStatuses => item.programID === program?.ID
      );

      const dataForProgressCheck = dataForProgram?.pCs.find((item) => item.pCId === progressCheck?.ID);

      if (dataForProgressCheck) {
        setProgressCheckData(dataForProgressCheck);
        setComments(_.cloneDeep(dataForProgressCheck.comments).reverse());
        bottom.current?.scrollIntoView({ behavior: "auto" });
      }
    };

    if (allProgressCheckData.state === "hasValue") {
      getProgressCheckData();
    }
  }, [allProgressCheckData, program, progressCheck]);

  useEffect(() => {
    const updateCommentReadTime = async (shouldUpdate?: boolean): Promise<void> => {
      let updated = await ProgressCheckService.markCommentsAsRead(user.contents, program?.ID, progressCheck!.ID);

      if (shouldUpdate && updated) {
        await refreshProgressCheckData(user.contents);
      }
    };

    if (user.state === "hasValue" && progressCheck?.ID && comments.length > 0) {
      updateCommentReadTime();
    }

    return () => {
      if (user.state === "hasValue" && progressCheck?.ID && comments.length > 0) {
        updateCommentReadTime(true);
      }
    };
  }, [comments.length, program, progressCheck, user]);

  const sendComment = async (comment: string): Promise<void> => {
    try {
      const added = await ProgressCheckService.addComment(user.contents, program.ID, progressCheck?.ID || "", comment);
      added && (await refreshProgressCheckData(user.contents));
    } catch (error) {
      console.log(error);
    }
  };

  const refreshData = async (): Promise<void> => {
    try {
      setRefreshingData(true);
      await refreshUserDetails(user.contents);
      await refreshUserEvidence(user.contents);
      await refreshProgressCheckData(user.contents);
      setRefreshingData(false);
    } catch (error) {
      console.log(error);
      setRefreshingData(false);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref="/dashboard"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{"Comments"}</HeaderTitle>
          <IonButtons slot="end">
            <IonButton disabled={refreshingData} className="header-button" mode="ios" onClick={() => refreshData()}>
              {refreshingData ? (
                <IonSpinner className="w-[44px] h-[20px] text-white" />
              ) : (
                <IonImg src={Icon_Refresh} className="headerIcon" />
              )}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="whiteBackground">
        <ContentContainer style={{ paddingTop: 0, paddingBottom: 0 }}>
          <CommentsDisclaimer
            isPreceptor={false}
            message={StringUtils.getCommentsDisclaimerText(program.ID, program.MentorTitle)}
          />
          {comments.map((item) => (
            <CommentWrapper key={item.date}>
              <Comment
                comment={CommentHelpers.mapServerCommentToComponent(item, program.MentorTitle)}
                showNew={CommentHelpers.showNewCommentLabel(user.contents, item, progressCheckData?.commentReadTimes)}
                showTime
              />
            </CommentWrapper>
          ))}
          <div ref={bottom} />
        </ContentContainer>
      </IonContent>
      <CommentInput
        commentsDisabled={ProgramUtils.isProgressCheckApproved(progressCheckData?.submissions)}
        sendComment={(comment) => sendComment(comment)}
      />
    </IonPage>
  );
};

export default Comments;
