import { useState } from "react";
import { EvidencePart } from "../../../Interfaces";
import { Text, View } from "@react-pdf/renderer";

type Props = {
  definition: EvidencePart;
  onValueChanged: (value: any) => void;
  data?: { email: string; name: string };
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue: { email: string; name: string };
};

const MentorInfo: React.FC<Props> = (props) => {
  const value: { email: string; name: string } = props.initialValue;

  if (props.isPDF) {
    return (
      <View>
        <Text style={props.pdfStyles.reflectionHeader}>
          {props.definition["Display Title"] || props.definition.Name}
        </Text>
        <View style={{ marginTop: 8 }}>
          <Text style={props.pdfStyles.boldText}>{"Mentor Name:"}</Text>
          <Text style={props.pdfStyles.normalText}>{props.data?.name ?? "N/A"}</Text>
        </View>
        <View style={{ marginTop: 8 }}>
          <Text style={props.pdfStyles.boldText}>{"Mentor Email:"}</Text>
          <Text style={props.pdfStyles.normalText}>{props.data?.email ?? "N/A"}</Text>
        </View>
      </View>
    );
  }

  if (props.isDisplay) {
    return (
      <div className="mt-[20px]">
        <div className="text-acc-13px font-bold leading-default tracking-[-0.1px] text-grey-90 mb-3">
          {props.definition["Display Title"] || props.definition.Name}
        </div>
        <div className="text-acc-13px font-bold tracking-default text-grey-90 mb-1">{"Mentor Name:"}</div>
        <div className="text-acc-17px tracking-default text-grey-90">{props.data?.name ?? "N/A"}</div>
        <div className="text-acc-13px font-bold tracking-default text-grey-90 mb-1 mt-2">{"Mentor Email:"}</div>
        <div className="text-acc-17px tracking-default text-grey-90">{props.data?.email ?? "N/A"}</div>
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return (
    <div className="mt-4">
      <div className="text-[15px] font-bold tracking-default text-grey-70 mb-1">{"Mentor Name:"}</div>
      <div className="text-[17px] tracking-default text-grey-90">{value?.name ?? "N/A"}</div>
      <div className="text-[15px] font-bold tracking-default text-grey-70 mt-2 mb-1">{"Mentor Email:"}</div>
      <div className="text-[17px] tracking-default text-grey-90">{value?.email ?? "N/A"}</div>
    </div>
  );
};

export default MentorInfo;
