import React from "react";
import "../../theme/tailwind.css";
import { StringUtils } from "../../utils/stringUtils";
import { IProgressCheckDescription } from "../../types/Components";

const ProgressCheckDescription: React.FC<IProgressCheckDescription> = (props) => {
  return (
    <div
      className="text-acc-13px leading[1.54] tracking-[-0.1px] text-grey-70 preceptor-description mb-1"
      dangerouslySetInnerHTML={StringUtils.sanitizeHTML(props.description)}
    />
  );
};

export default ProgressCheckDescription;
