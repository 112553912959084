import { IonItem } from "@ionic/react";
import { EvidencePart, LearningObjective } from "../../../Interfaces";
import {
  Button_Checkbox_Active,
  Button_Checkbox_Inactive,
  Button_Collapse,
  Button_Expand,
} from "../../../assets/images";
import { useEffect, useState } from "react";
import NOSSelectContainer from "../../common/NOSSelectContainer";
import Collapsible from "react-collapsible";
import { Text, View } from "@react-pdf/renderer";
import _ from "lodash";

type Props = {
  definition: EvidencePart;
  onValueChanged: (value: any) => void;
  toggleCollapsible: () => void;
  collapsibleOpen: boolean;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  learningOutcomes?: LearningObjective[];
  initialValue?: LearningObjective[];
  id: string;
};

// ATTENTION - MAKE SURE TO SAVE LEARNING OUTCOMES LIKE THIS: [{Name: string, ID: string}, {...}, {...}]
const LearningOutcomesList: React.FC<Props> = (props) => {
  const [selectedValues, setSelectedValues] = useState<any[]>(props.initialValue ?? []);

  const sectionsObject =
    props.learningOutcomes && props.learningOutcomes?.some((lo) => lo["Top Section"])
      ? _.groupBy(props.learningOutcomes, (lo) => lo["Top Section"] ?? lo.Name)
      : undefined;

  const toggleSelectedObjectives = (lo: any) => {
    let copy = [...selectedValues];
    const selectedItem = selectedValues.find((item) => item.ID === lo.ID);
    if (selectedItem) {
      copy = [...copy].filter((item) => item.ID !== lo.ID);
    } else {
      copy.push(lo);
    }

    setSelectedValues(copy.map((item: any) => ({ Name: item.Name, ID: item.ID })));
  };

  useEffect(() => {
    if (props.onValueChanged) {
      props.onValueChanged(selectedValues);
    }
  }, [selectedValues.length]);

  useEffect(() => {
    if (!props.initialValue?.length) {
      setSelectedValues([]);
    }
  }, [props.id]);

  if (props.isPDF) {
    return (
      <View>
        <Text style={props.pdfStyles.reflectionHeader}>
          {props.definition["Display Title"] || props.definition.Name}
        </Text>
        <Text style={props.pdfStyles.evidenceDate}>
          {Array.isArray(props.data) ? props.data?.map((item: any) => item.Name).join(", ") : props.data}
        </Text>
      </View>
    );
  }

  if (props.isDisplay) {
    return (
      <div className="readEvidenceBlock">
        <div className="readEvidenceHeader">{props.definition["Display Title"] || props.definition.Name}</div>
        <div className="readEvidenceText">
          {Array.isArray(props.data) ? props.data?.map((item: any) => item.Name).join(", ") : props.data}
        </div>
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  const areAllItemsSelectedInSection = (itemsInSection: LearningObjective[] | undefined) => {
    if (!itemsInSection) return false;

    return itemsInSection.every((sectionItem) => {
      const found = selectedValues.find((it) => it.ID === sectionItem.ID);
      return found;
    });
  };

  return (
    <NOSSelectContainer>
      <Collapsible
        trigger={
          <IonItem
            button
            detail={false}
            className="evidencePartsCheckboxContainer"
            onClick={() => props.toggleCollapsible()}
          >
            <div className="w-full flex flex-row items-center !justify-between">
              <div className="text-[15px] font-acc-600 leading-default tracking-[-0.3px] text-grey-90 whitespace-nowrap overflow-hidden text-ellipsis acc-underline decoration-current">
                {props.definition["Display Title"] ?? "Learning Outcomes"}
                <span className="text-cta-red">
                  {(props.definition.options === "Mandatory" || props.definition["option type"] === "Mandatory") &&
                    " (Mandatory)"}
                </span>
              </div>
              <button className="z-50" onClick={() => {}}>
                <img
                  alt=""
                  className="h-icon w-icon img-no-select"
                  src={props.collapsibleOpen ? Button_Collapse : Button_Expand}
                />
              </button>
            </div>
          </IonItem>
        }
        triggerDisabled
        transitionTime={250}
        transitionCloseTime={50}
        open={props.collapsibleOpen}
      >
        <div className="h-[2px] bg-grey-20" />
        {sectionsObject ? (
          <div className="mt-2 mb-1">
            {Object.keys(sectionsObject).map((k, i) => {
              const allItemsInSectionSelected = areAllItemsSelectedInSection(sectionsObject[k]);
              return (
                <div key={k}>
                  <div className="flex justify-between items-center">
                    <div className="text-acc-12px font-acc-600 leading-[1.333] text-grey-90">{k}</div>
                    <button
                      onClick={() => {
                        const copyWithItemsInSectionRemoved = selectedValues.filter(
                          (value) => !sectionsObject[k]?.map((it) => it.ID).includes(value.ID)
                        );
                        const copyWithItemsInSectionIncluded = [...selectedValues];
                        sectionsObject[k]?.forEach((sectionItem) => {
                          const found = selectedValues.find((it) => it.ID === sectionItem.ID);
                          if (!found)
                            copyWithItemsInSectionIncluded.push({ Name: sectionItem.Name, ID: sectionItem.ID });
                        });
                        allItemsInSectionSelected
                          ? setSelectedValues(copyWithItemsInSectionRemoved)
                          : setSelectedValues(copyWithItemsInSectionIncluded);
                      }}
                      className="text-[17px] leading-default tracking-[-0.3] text-cta-blue"
                    >
                      {allItemsInSectionSelected ? "Deselect all" : "Select all"}
                    </button>
                  </div>
                  {sectionsObject[k]?.map((it) => {
                    const selected = selectedValues.find((item) => item.ID === it.ID);
                    return (
                      <ListItem
                        key={it.Name}
                        lo={it}
                        selected={selected}
                        toggleSelectedObjectives={toggleSelectedObjectives}
                      />
                    );
                  })}
                  {i !== Object.keys(sectionsObject).length - 1 && <div className="h-[2px] bg-grey-20 mb-3" />}
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            <div className="flex flex-row items-center justify-between mt-2 mb-1">
              <div className="text-acc-12px font-acc-600 leading-[1.333] text-grey-90">
                {"Select the relevant Learning Outcomes for this competency"}
              </div>
              <button>
                <div
                  className="text-[17px] leading-default tracking-[-0.3] text-cta-blue"
                  onClick={() =>
                    props.learningOutcomes?.length === selectedValues.length
                      ? setSelectedValues([])
                      : setSelectedValues(props.learningOutcomes!.map((it) => ({ Name: it.Name, ID: it.ID })))
                  }
                >
                  {props.learningOutcomes?.length === selectedValues.length ? "Deselect all" : "Select all"}
                </div>
              </button>
            </div>
            {props.learningOutcomes?.map((lo) => {
              const selected = selectedValues.find((item) => item.ID === lo.ID);
              return (
                <ListItem key={lo.ID} lo={lo} selected={selected} toggleSelectedObjectives={toggleSelectedObjectives} />
              );
            })}
          </div>
        )}
      </Collapsible>
    </NOSSelectContainer>
  );
};

const ListItem = ({
  lo,
  selected,
  toggleSelectedObjectives,
}: {
  lo: LearningObjective;
  selected: boolean;
  toggleSelectedObjectives: (lo: any) => void;
}) => {
  return (
    <IonItem
      button
      detail={false}
      className="evidencePartsCheckboxContainer"
      onClick={() => toggleSelectedObjectives(lo)}
    >
      <>
        <div className="w-full flex flex-row items-center !justify-between">
          <div className="my-1 flex-1">
            <div className="text-[17px] font-acc-400 leading-default tracking-[-0.3px] text-grey-90 overflow-hidden text-ellipsis acc-underline decoration-current">
              {lo.Name}
            </div>
          </div>
          <button
            onClick={(event?: any) => {
              event?.stopPropagation();
              toggleSelectedObjectives(lo);
            }}
          >
            <img
              className="h-icon w-icon img-no-select"
              src={selected ? Button_Checkbox_Active : Button_Checkbox_Inactive}
            />
          </button>
        </div>
        {lo.Description && (
          <div className="ml-3 whitespace-pre-wrap text-acc-13px font-acc-400 leading-6 text-grey-90">
            {lo.Description}
          </div>
        )}
      </>
    </IonItem>
  );
};

export default LearningOutcomesList;
