import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { IWellbeingBarGraph } from "../../../types/Components";

ChartJS.register(
  CategoryScale,
  RadialLinearScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend,
  Filler
);

const WellbeingBarGraph: React.FC<IWellbeingBarGraph> = (props) => {
  const getBarColour = (currentValue: number, maxValue: number): string => {
    const percentage = Math.floor((currentValue / maxValue) * 100);

    if (percentage <= 30) {
      return "#ED4136";
    } else if (percentage <= 60) {
      return "#ED8936";
    } else {
      return "#48BB78";
    }
  };

  const getTotalColor = (count: number, total: number): string => {
    const percentage = Math.floor((count / total) * 100);

    if (count === 0 && total === 0) {
      return "text-grey-70";
    }

    if (percentage < 40) {
      return "text-[#ED4136]";
    } else if (percentage < 70) {
      return "text-[#ED8936]";
    } else {
      return "text-[#48BB78]";
    }
  };

  return (
    <>
      {props.initialValue.data.map((data, index) => {
        const count =
          data
            .map((data) => data.value)
            .filter((data) => data !== undefined)
            .reduce((a, b) => a + b, 0) ?? 0;
        const total =
          data
            .map((data) => data.maxValue)
            .filter((data) => data !== undefined)
            .reduce((a, b) => a + b, 0) ?? 0;

        return (
          <div className="flex justify-center mx-4 mt-2" key={data[0].header}>
            <div className="relative max-w-app-column w-full p-4 bg-white mb-2 pl-safe-area-l pr-safe-area-r rounded-md">
              <div className="flex items-center justify-between">
                <div className="text-[14px] font-acc-500 text-grey-90">{data[0].header}</div>
                <div className="flex items-center">
                  <span className={`text-[16px] font-extrabold ${getTotalColor(count, total)}`}>{count}</span>
                  <span className="text-[12px] font-acc-600 ml-[1px] mt-[2px]">{`/${total}`}</span>
                </div>
              </div>
              <div className="flex items-center justify-center !w-full max-h-[335px] h-full !px-0 pt-2">
                <Bar
                  data={{
                    labels: data.map((item) => item.label),
                    datasets: [
                      {
                        data: data.map((item) => item.value),
                        backgroundColor: (ctx) => getBarColour(ctx.raw as number, data[ctx.dataIndex].maxValue),
                        barPercentage: 0.3,
                        borderRadius: 4,
                      },
                    ],
                  }}
                  plugins={[ChartDataLabels]}
                  options={{
                    indexAxis: "y",
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      datalabels: {
                        display: true,
                        align: ["end"],
                        anchor: "start",
                        clamp: true,
                        // clamp: false,
                        textAlign: "center",
                        offset: 0,
                        padding: {
                          bottom: 10,
                        },
                        formatter: (value, ctx) => `${data[ctx.dataIndex].label}\n\n\n`,
                      },
                      tooltip: {
                        enabled: false,
                      },
                    },
                    scales: {
                      y: {
                        display: false,
                        max: data.length,
                      },
                      x: {
                        min: 0,
                        max: data[0].maxValue ?? 10,
                      },
                    },
                    maintainAspectRatio: false,
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
    // #ED4136
    // #ED8936
    // #48BB78
  );
};

export default WellbeingBarGraph;
