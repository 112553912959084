import { Capacitor } from "@capacitor/core";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonLoading,
  IonModal,
  IonToolbar,
} from "@ionic/react";
import DataController from "../../controllers/DataController";
import { HeaderTitle } from "parafolio-components";
import "../../theme/tailwind.css";
import { Button_Disclosure_Right } from "../../assets/images";
import ContentContainer from "../common/ContentContainer";

type Props = {
  page: string;
  visible: boolean;
  loginSSO: (trust: string) => Promise<void>;
  closeModal: (value?: boolean) => void;
  trusts: string[];
  loggingIn: boolean;
};

const getTrustDisplayName = (trust: string): string => {
  switch (trust) {
    case "SECAMB":
      return "SECAmb";
    case "WAS":
      return "WAST";
    case "SWAST":
      return "SWASFT";
    default:
      return trust;
  }
};

const SSOModal: React.FC<Props> = (props) => {
  return (
    <IonModal
      id="sso-modal"
      isOpen={props.visible}
      onDidDismiss={() => props.closeModal()}
      canDismiss
      className="fullscreen"
    >
      <IonHeader>
        <IonToolbar
          mode="ios"
          class="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonButton mode="ios" className="cancelButton" onClick={() => props.closeModal()}>
              {"Close"}
            </IonButton>
          </IonButtons>
          <HeaderTitle>{"Trust Portal"}</HeaderTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="onboardingLoginModalPage fullHeight">
        <IonLoading mode="ios" isOpen={props.loggingIn} message={"Logging in..."} />
        <div className="onboardingLoginContentNoPadding onboardingLearnMoreContent !pl-safe-area-l !pr-safe-area-r">
          <div className="onboardingLoginHeader">{"Select your Trust"}</div>
          <div className="text-acc-15px font-acc-normal leading-default tracking-default text-grey-90 mb-1">
            {"If your Trust is not listed below, please use the standard login"}
          </div>
          {props.trusts.map((trust) => (
            <IonItem
              button
              key={trust}
              onClick={() => props.loginSSO(trust)}
              className="flex justify-center !pr-0 sso-list-item"
              detail={false}
            >
              <div className="flex flex-row items-center justify-between w-full !pr-0 min-h-14">
                <div className="text-17px font-semibold tracking-default text-grey-90">
                  {getTrustDisplayName(trust)}
                </div>
                <IonImg src={Button_Disclosure_Right} class="w-11 h-11 min-w-11 min-h-11 flex flex-[-1]" />
              </div>
            </IonItem>
          ))}
        </div>
      </IonContent>
    </IonModal>
  );
};

export default SSOModal;
