import { useRecoilValueLoadable } from "recoil";
import { AllProgressCheckStatuses, IEvidence, ProgramData, ProgressCheck } from "../../Interfaces";
import { evidenceAtom, progressCheckDataAtom } from "../../state/State";
import { subYears, addWeeks, isBefore } from "date-fns";
import { Icon_PCs_Approved } from "../../assets/images";
import { max } from "date-fns";
import SegmentedProgressBar from "../common/SegmentedProgressBar";
import * as ProgramUtils from "../../utils/programUtils";
import * as EvidenceUtils from "../../utils/evidenceUtils";

const OverviewWithTick: React.FC<{ program: ProgramData }> = ({ program }) => {
  const evidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);

  const allProgressCheckData = useRecoilValueLoadable(progressCheckDataAtom);

  const programEvidence: IEvidence[] = getProgramEvidence();
  const draftCount = getDraftCount();
  const allApproved = program.ProgressChecks?.every((pc) => {
    return ProgramUtils.isProgressCheckApprovedBasedOnCompTrigger(programEvidence, pc["Competence/Activity"]);
  });

  function getProgramEvidence(): IEvidence[] {
    if (!evidence) return [];

    const filtered =
      evidence.contents?.filter((evidence: IEvidence) => {
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
        const programName = evidenceJSON?.programInfo?.program || "";

        const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

        return (
          programName === program.Name &&
          onHoldReset !== 1 &&
          evidence.draft !== true &&
          new Date(evidence.date) >= subYears(new Date(), 2)
        );
      }) ?? [];

    return filtered;
  }

  function getDraftCount(): number {
    if (!evidence) return 0;

    const filtered =
      evidence.contents?.filter((evidence: IEvidence) => {
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
        const programName = evidenceJSON?.programInfo?.program || "";

        const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

        return (
          programName === program.Name &&
          onHoldReset !== 1 &&
          evidence.draft === true &&
          new Date(evidence.date) >= subYears(new Date(), 2)
        );
      }) ?? [];

    return filtered.length;
  }

  function tickHasExpired(): boolean {
    let tickExpired = false;
    if (allApproved && allProgressCheckData.contents) {
      const programStatusObject = (allProgressCheckData.contents as AllProgressCheckStatuses[]).find(
        (pcStatus) => pcStatus.programID === program.ID
      );
      if (programStatusObject) {
        // get date of last pc completed
        const latestDate = max(programStatusObject.pCs.map((pc) => new Date(pc.submissions.history[0].date)));
        const oneWeekAgo = addWeeks(new Date(), -1);
        if (isBefore(latestDate, oneWeekAgo)) {
          tickExpired = true;
        }
      }
    }

    return tickExpired;
  }

  return (
    <div>
      {allApproved ? (
        tickHasExpired() ? (
          <div>
            <div className="text-acc-13px">{`You’ve completed your ${program.Title ?? "program"}. Well done!`}</div>
            <div
              className={`inline-block px-[8px] py-[2.25] h-20px rounded-[10px] mt-3`}
              style={{ backgroundColor: "#C6F7C1" }}
            >
              <div
                className={"h-[20px] leading-[20px] text-[13px] my-[2.25] font-semibold"}
                style={{ color: "#085333" }}
              >
                Approved
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="text-acc-13px">{`You’ve completed your ${program.Title ?? "program"}. Well done!`}</div>
            <img className="ml-auto mr-auto mt-4 mb-1" width={250} height={20} src={Icon_PCs_Approved} alt="" />
          </div>
        )
      ) : (
        <>
          {program.ProgressChecks?.map((pc) => {
            const num = EvidenceUtils.calculateCompsDone(programEvidence, pc);
            const den = pc["Competence/Activity"].filter((comp) => comp["Number Evidences required"] != 0).length;
            return (
              <div className="mt-4" key={pc.ID}>
                <SegmentedProgressBar
                  key={pc.ID}
                  title={`Mandatory ${pc.Name} completed`}
                  numerator={num}
                  denominator={den}
                />
              </div>
            );
          })}
          <div className="flex mt-4">
            <div className="flex-1 flex flex-col justify-between">
              <div className="text-grey-90 text-acc-13px font-normal mb-2">Evidence added</div>
              <div className="text-program-card-eprr-text text-acc-28px font-bold">{programEvidence.length}</div>
            </div>
            <div className="flex-1 flex flex-col justify-between items-start">
              <div className="mb-2 rounded-xl bg-draft-badge text-draft-badge-text px-2 py-1 text-acc-12px font-semibold">
                Draft
              </div>
              <div className="text-program-card-eprr-text text-acc-28px font-bold">{draftCount}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OverviewWithTick;
