import { useEffect } from "react";
import { EventRegister } from "react-native-event-listeners";

export const useHidingTabBar = (keepHidden: boolean = false) => {
  useEffect(() => {
    EventRegister.emit("tab-bar/visibility-changed", false);

    return () => {
      !keepHidden && EventRegister.emit("tab-bar/visibility-changed", true);
    };
  });
};
