import LearningOutcomesCount from "./LearningOutcomesCount";

type Props = {
  count: number;
  total: number;
  percentage: number;
};

const LearningOutcomesHeader: React.FC<Props> = (props) => {
  return (
    <div className="flex flex-col bg-white pt-4 pl-safe-area-l pr-safe-area-r">
      <div className="text-acc-17px font-extrabold tracking-default leading-acc-lh text-grey-90">
        {"Learning Outcomes"}
      </div>
      <div className="text-acc-13px font-acc-normal leading-default tracking-[-0.1px] text-grey-70">
        {"Overview of the LOs that you have met over your program."}
      </div>
      <div className="text-acc-13px font-acc-normal leading-acc-lh tracking-[-0.1px] text-grey-70 mt-3">
        {"Learning Outcomes met"}
      </div>
      <LearningOutcomesCount count={props.count} total={props.total} percentage={props.percentage} />
    </div>
  );
};

export default LearningOutcomesHeader;
