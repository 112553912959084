import ActivityDuration from "./ActivityDuration";
import AttachmentsInput from "./AttachmentsInput";
import Banner from "./Banner";
import CompMultiSelectList from "./CompMultiSelectList";
import ConfidenceScale from "./ConfidenceScale";
import CustomTags from "./CustomTags";
import DateInput from "./DateInput";
import DropDownList from "./DropDownList";
import LearningOutcomesList from "./LearningOutcomesList";
import MentorInfo from "./MentorInfo";
import NOSSelect from "./NOSSelect";
import NQPLearningObjectivesMultiSelect from "./NQPLearningObjectivesMultiSelect";
import NumberInput from "./NumberInput";
import RadioButton from "./RadioButton";
import RadioButtonWithComments from "./RadioButtonWithComments";
import RichTextBox from "./RichTextBox";
import RolesSelect from "./RolesSelect";
import TextBox from "./TextBox";
import SingleTickExpandable from "./SingleTickExpandable";
import TickBoxList from "./TickBoxList";
import TimeInput from "./TimeInput";
import DateRangeInput from "./DateRangeInput";

export const CreateEvidencePart: any = (type: string, additionalProps: any) => {
  if (type === "MultiLine text") {
    return <TextBox {...additionalProps} multiline={true} />;
  }
  if (type === "SingleLine text") {
    return <TextBox {...additionalProps} multiline={false} />;
  }
  if (type === "Pull down list") {
    return <DropDownList {...additionalProps} />;
  }
  if (type === "Date") {
    return <DateInput {...additionalProps} />;
  }
  if (type === "DateRange") {
    return <DateRangeInput {...additionalProps} />;
  }
  if (type === "TextBox") {
    return <RichTextBox {...additionalProps} />;
  }
  if (type === "Tick box list") {
    return <TickBoxList {...additionalProps} />;
  }
  if (type === "Number") {
    return <NumberInput {...additionalProps} />;
  }
  if (type === "Time") {
    return <TimeInput {...additionalProps} />;
  }
  if (type === "Radio yes/no with comments") {
    return <RadioButtonWithComments {...additionalProps} />;
  }
  if (type === "RadioButton") {
    return <RadioButton {...additionalProps} />;
  }
  if (type === "passedIN") {
    return <TextBox {...additionalProps} />;
  }
  if (type === "Activity duration") {
    return <ActivityDuration {...additionalProps} />;
  }
  if (type === "Custom tags") {
    return <CustomTags {...additionalProps} />;
  }
  if (type === "Attachments") {
    return <AttachmentsInput {...additionalProps} />;
  }
  if (type === "Roles select") {
    return <RolesSelect {...additionalProps} />;
  }
  if (type === "Scale 1-5") {
    return <ConfidenceScale {...additionalProps} />;
  }
  if (type === "SingleTick") {
    return <TickBoxList {...additionalProps} />;
  }
  if (type === "SingleTickExpandable") {
    return <SingleTickExpandable {...additionalProps} />;
  }
  if (type === "NOS Select") {
    return <NOSSelect {...additionalProps} />;
  }
  if (type === "Banner") {
    return <Banner {...additionalProps} />;
  }
  if (type === "LearningOutcomesList") {
    return <LearningOutcomesList {...additionalProps} />;
  }
  if (type === "CompMultiSelectForLOs") {
    return <CompMultiSelectList {...additionalProps} />;
  }
  if (type === "LOsMultiSelectForNQP") {
    return <NQPLearningObjectivesMultiSelect {...additionalProps} />;
  }
  if (type === "MentorInfo") {
    return <MentorInfo {...additionalProps} />;
  }
};
