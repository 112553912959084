import {
  AllProgressCheckStatuses,
  FastTrackLabel,
  FilledInBy,
  INOSSelectCompetence,
  INOSSelectLearningObjectives,
  IProgressCheckLabel,
  IUser,
  IUserProgramRoles,
  LearningObjective,
  Mentor,
  PauseLabel,
  ProgramDataVersionInfo,
  ProgramModuleStatus,
  ProgressCheckStatus,
  ProgressCheckSubmission,
  ProgressState,
  SequentiallyLocked,
  SubmissionButtonState,
  SubmissionState,
} from "./../Interfaces";
import {
  CompetenceActivityClass,
  ConfidenceLevelData,
  EvidencePart,
  FieldType,
  IClassSubscription,
  IEvidence,
  ProgramData,
  ProgramSkill,
  ProgressCheck,
  ProgressCheckApproval,
} from "../Interfaces";
import roles from "../assets/json/roles.json";
import standards from "../assets/json/standards.json";
import _ from "lodash";
import { format, subYears } from "date-fns";
import { IMandatoryStandard, IOptionalStandard } from "../Interfaces";
import { ProgramModule, PMUsageData } from "pf-support-lib";
import * as EvidenceUtils from "../utils/evidenceUtils";

/**
 * Checks if the Progress Check can be completed when the only piece of required Evidence in the Progress Check is completed
 * @param progressCheckCompetenceID
 * @returns
 */
export function autoCompleteProgressCheck(progressCheckCompetenceID: string): boolean {
  return [
    "LQZEW7C7CO6RU",
    "LVQSZILHMB8Q2",
    "LVQSO3MTY5R7X",
    "LYOBC707XLTKV",
    "M3G7O75XYWB7N",
    "M3SV71RXMQBZF",
    "M167IU5O1QKCF",
  ].includes(progressCheckCompetenceID);
}

/**
 *
 * @param submissionState - The ProgressCheckSubmission object for the current progress check
 * @returns If the user can edit the evidence added to their progress check based on its submission status
 */
export function canAddOrEditProgressCheckEvidence(submissionState: ProgressCheckSubmission | undefined): boolean {
  return (
    submissionState?.status !== SubmissionState.Approved &&
    submissionState?.status !== SubmissionState.Submitted &&
    submissionState?.status !== SubmissionState.Completed
  );
}

/**
 *
 * @param approval - The ProgressCheckApproval enum for the current progress check
 * @returns Whether the progress check requires approval
 */
export function canSubmitProgressCheck(approval: ProgressCheckApproval): boolean {
  const approvalRequired = [
    ProgressCheckApproval.ApprovalOO,
    ProgressCheckApproval.Required,
    ProgressCheckApproval.RequiredExtension,
  ];

  return approvalRequired.includes(approval);
}

/**
 *
 * @param selectedLearningObjectives - All the user's selected Learning Objectives
 * @param learningObjectives - All available Learning Objectives
 * @returns
 */
export function checkAllLearningObjectivesSelected(
  selectedLearningObjectives: INOSSelectLearningObjectives[],
  learningObjectives: LearningObjective[]
): boolean {
  if (learningObjectives.length > 0) {
    const ids = selectedLearningObjectives.map((learningObjective) => learningObjective.id);
    const array = learningObjectives.filter((learningObjective) => !ids.includes(learningObjective.ID));

    return array.length === 0;
  }

  return false;
}

/**
 * Utility function to check if the CompetenceActivity of a Program Module is populated
 * @param key - The key to check for
 * @param object - The object to check
 * @returns false if the object is a string, otherwise returns whether the key exists in the object
 */
function checkCompetenceActivityType<T extends object>(key: PropertyKey, object: T | string): key is keyof T {
  if (typeof object === "string") {
    return false;
  }

  return key in object;
}

export function progressCheckHasMentorAddedEvidence(progressCheck: ProgressCheck): boolean {
  const progressCheckCompetences = progressCheck["Competence/Activity"] ?? [];

  const competencesAddedByMentor = progressCheckCompetences.filter((competence) => {
    return competence.FilledInBy === FilledInBy.Mentor;
  });

  return competencesAddedByMentor.length > 0;
}

/**
 * Utility function to check if the mentor has added evidence to all the competences they are responsible for in a given progress check
 * @param progressCheck - The ProgressCheck object
 * @param allEvidence - All the user's evidence
 * @returns true if the mentor has added evidence to all the competences they are responsible for, false otherwise
 */
export function checkIfAllMentorEvidenceIsAdded(progressCheck: ProgressCheck, allEvidence: IEvidence[]): boolean {
  const progressCheckCompetences = progressCheck["Competence/Activity"] ?? [];

  const competencesAddedByMentor = progressCheckCompetences.filter((competence) => {
    return competence.FilledInBy === FilledInBy.Mentor;
  });

  const mappedIDs = competencesAddedByMentor.map((competence) => competence.ID);

  const evidenceAddedByMentor = allEvidence.filter((evidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const progressCheckID = evidenceJSON?.programInfo?.progressCheckID ?? "";
    const progressCheckCompetenceID = evidenceJSON?.programInfo?.progressCheckCompetenceID ?? "";

    const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

    return (
      progressCheckID === progressCheck.ID &&
      mappedIDs.includes(progressCheckCompetenceID) &&
      onHoldReset === 0 &&
      evidence.draft !== true
    );
  });

  if (mappedIDs.length > 0) {
    return mappedIDs.length !== evidenceAddedByMentor.length;
  }

  return false;
}

export function progressCheckAddNewEvidenceButtonDisabled(
  progressCheck: ProgressCheck,
  progressChecks: ProgressCheck[],
  programEvidence: IEvidence[],
  programProgressCheckData: AllProgressCheckStatuses | null,
  programID: string,
  fastTrackActive: boolean,
  pauseAndHold: boolean
): boolean {
  if (isNQPProgram(programID)) {
    if (pauseAndHold) {
      return true;
    }

    if (
      progressCheck.SequentiallyLocked !== undefined &&
      [`${SequentiallyLocked.Unlocked}`, `${SequentiallyLocked.UnlockedIgnore}`].includes(
        progressCheck.SequentiallyLocked
      )
    ) {
      return false;
    }

    const progressCheckIndex = progressChecks.findIndex((item) => item.ID === progressCheck.ID);

    let previousCheckIndex;

    if (progressCheck.UnlockedByID) {
      previousCheckIndex = progressChecks.findIndex((item) => item.ID === progressCheck.UnlockedByID);
    }

    let previousProgressCheck;

    if (previousCheckIndex) {
      previousProgressCheck = progressChecks[previousCheckIndex];
    } else if (progressCheckIndex > 0) {
      previousProgressCheck = progressChecks[progressCheckIndex - 1];
    }

    if (!previousProgressCheck) {
      if (fastTrackActive) {
        return false;
      }

      if (!progressCheckHasMentorAddedEvidence(progressCheck)) {
        const result = isAllEvidenceAddedForProgressCheck(programEvidence, progressCheck["Competence/Activity"], true);

        return result;
      }

      return false;
    }

    if (isProgressCheckApprovedBasedOnCompTrigger(programEvidence, previousProgressCheck["Competence/Activity"])) {
      return false;
    }

    if (previousProgressCheck.Approval === ProgressCheckApproval.NotRequired) {
      if (fastTrackActive) {
        return false;
      }

      const previousProgressCheckLocked = checkIfProgressCheckIsLocked(
        previousProgressCheck,
        progressChecks,
        programEvidence,
        programProgressCheckData,
        programID,
        fastTrackActive,
        pauseAndHold
      );

      // Check if previous progress check is locked - used when previous progress check is sequentially locked but doesn't require approval
      if (previousProgressCheckLocked) {
        return true;
      }

      const previousProgressCheckEvidence = getEvidenceForProgressCheck(programEvidence, previousProgressCheck.ID);

      const result = !isAllEvidenceAddedForProgressCheck(
        previousProgressCheckEvidence,
        previousProgressCheck["Competence/Activity"],
        true
      );

      return result;
    }

    let currentProgressCheckData = programProgressCheckData?.pCs.find((item) => item.pCId === progressCheck.ID);

    if (currentProgressCheckData) {
      if (
        currentProgressCheckData.submissions.status === SubmissionState.Approved ||
        currentProgressCheckData.submissions.status === SubmissionState.Completed ||
        currentProgressCheckData.submissions.status === SubmissionState.Submitted
      ) {
        return true;
      }
    }

    let previousProgressCheckData;

    if (programProgressCheckData) {
      previousProgressCheckData = programProgressCheckData.pCs.find((item) => item.pCId === previousProgressCheck.ID);
    }

    if (fastTrackActive) {
      return false;
    }

    if (previousProgressCheckData) {
      return previousProgressCheckData.submissions.status !== SubmissionState.Approved;
    }

    return true;
  } else {
    return checkIfProgressCheckIsLocked(
      progressCheck,
      progressChecks,
      programEvidence,
      programProgressCheckData,
      programID,
      fastTrackActive,
      pauseAndHold
    );
  }
}

/**
 *
 * @param progressCheck - The Progress Check to check
 * @param progressChecks - All Progress Checks in the Program Module
 * @param programEvidence - All the user's Evidence for the Program Module
 * @param programProgressCheckData - The user's Progress Check Data for the current Program Module
 * @returns
 */
export function checkIfProgressCheckIsLocked(
  progressCheck: ProgressCheck,
  progressChecks: ProgressCheck[],
  programEvidence: IEvidence[],
  programProgressCheckData: AllProgressCheckStatuses | null,
  programID: string,
  fastTrackActive: boolean,
  pauseAndHold: boolean
): boolean {
  if (isNQPProgram(programID)) {
    if (pauseAndHold) {
      return true;
    }

    let currentProgressCheckData = programProgressCheckData?.pCs.find((item) => item.pCId === progressCheck.ID);

    if (currentProgressCheckData) {
      if (
        currentProgressCheckData.submissions.status === SubmissionState.Approved ||
        currentProgressCheckData.submissions.status === SubmissionState.Completed ||
        currentProgressCheckData.submissions.status === SubmissionState.Submitted
      ) {
        return true;
      }
    }

    if (
      progressCheck.SequentiallyLocked !== undefined &&
      [`${SequentiallyLocked.Unlocked}`, `${SequentiallyLocked.UnlockedIgnore}`].includes(
        progressCheck.SequentiallyLocked
      )
    ) {
      return false;
    }

    const progressCheckIndex = progressChecks.findIndex((item) => item.ID === progressCheck.ID);

    let previousCheckIndex;

    if (progressCheck.UnlockedByID) {
      previousCheckIndex = progressChecks.findIndex((item) => item.ID === progressCheck.UnlockedByID);
    }

    let previousProgressCheck;

    if (previousCheckIndex) {
      previousProgressCheck = progressChecks[previousCheckIndex];
    } else if (progressCheckIndex > 0) {
      previousProgressCheck = progressChecks[progressCheckIndex - 1];
    }

    if (!previousProgressCheck) {
      if (fastTrackActive) {
        return false;
      }

      return false;
    }

    if (isProgressCheckApprovedBasedOnCompTrigger(programEvidence, previousProgressCheck["Competence/Activity"])) {
      return false;
    }

    if (previousProgressCheck.Approval === ProgressCheckApproval.NotRequired) {
      if (fastTrackActive) {
        return false;
      }

      const previousProgressCheckLocked = checkIfProgressCheckIsLocked(
        previousProgressCheck,
        progressChecks,
        programEvidence,
        programProgressCheckData,
        programID,
        fastTrackActive,
        pauseAndHold
      );

      // Check if previous progress check is locked - used when previous progress check is sequentially locked but doesn't require approval
      if (previousProgressCheckLocked) {
        return true;
      }

      const previousProgressCheckEvidence = getEvidenceForProgressCheck(programEvidence, previousProgressCheck.ID);

      const result = !isAllEvidenceAddedForProgressCheck(
        previousProgressCheckEvidence,
        previousProgressCheck["Competence/Activity"],
        true
      );

      return result;
    }

    let previousProgressCheckData;

    if (programProgressCheckData) {
      previousProgressCheckData = programProgressCheckData.pCs.find((item) => item.pCId === previousProgressCheck.ID);
    }

    if (fastTrackActive) {
      return false;
    }

    if (previousProgressCheckData) {
      return previousProgressCheckData.submissions.status !== SubmissionState.Approved;
    }

    return true;
  } else {
    if (
      progressCheck.SequentiallyLocked !== undefined &&
      [`${SequentiallyLocked.Unlocked}`, `${SequentiallyLocked.UnlockedIgnore}`].includes(
        progressCheck.SequentiallyLocked
      )
    ) {
      return false;
    }

    const progressCheckIndex = progressChecks.findIndex((item) => item.ID === progressCheck.ID);

    let previousCheckIndex;

    if (progressCheck.UnlockedByID) {
      previousCheckIndex = progressChecks.findIndex((item) => item.ID === progressCheck.UnlockedByID);
    }

    let previousProgressCheck;

    if (previousCheckIndex) {
      previousProgressCheck = progressChecks[previousCheckIndex];
    } else if (progressCheckIndex > 0) {
      previousProgressCheck = progressChecks[progressCheckIndex - 1];
    }

    if (!previousProgressCheck) {
      return false;
    }

    if (isProgressCheckApprovedBasedOnCompTrigger(programEvidence, previousProgressCheck["Competence/Activity"])) {
      return false;
    }

    if (previousProgressCheck.Approval === ProgressCheckApproval.NotRequired) {
      // Check if previous progress check is locked - used when previous progress check is sequentially locked but doesn't require approval
      if (
        checkIfProgressCheckIsLocked(
          previousProgressCheck,
          progressChecks,
          programEvidence,
          programProgressCheckData,
          programID,
          false,
          false
        )
      ) {
        return true;
      }

      const previousProgressCheckEvidence = getEvidenceForProgressCheck(programEvidence, previousProgressCheck.ID);

      const result = !isAllEvidenceAddedForProgressCheck(
        previousProgressCheckEvidence,
        previousProgressCheck["Competence/Activity"]
      );
      return result;
    }

    let previousProgressCheckData;

    if (programProgressCheckData) {
      previousProgressCheckData = programProgressCheckData.pCs.find((item) => item.pCId === previousProgressCheck.ID);
    }

    if (previousProgressCheckData) {
      return previousProgressCheckData.submissions.status !== SubmissionState.Approved;
    }

    return true;
  }
}

/**
 * Checks if the user has a ProgressCheckStatus for the current progress check
 * @param progressCheckData - The current ProgressCheckStatus object to check
 * @param progressCheckID - The ID of the progress check
 * @returns true if the object exists and its pCId is equal to the progressCheckID
 */
export function checkIfSubmissionExists(
  progressCheckData: ProgressCheckStatus | null,
  progressCheckID: string
): boolean {
  if (progressCheckData) {
    return progressCheckData.pCId === progressCheckID;
  }

  return false;
}

/**
 *
 * @param progressCheckCompetenceID - The ID of the current competence
 * @returns true if the ID is included in the list of competences for the confidence level entry
 */
export function competenceIsConfidenceLevel(progressCheckCompetenceID: string): boolean {
  return [
    // SWASFT Preceptorship
    "LNA36J9UUQHQ0",
    "LNA36J9UUQHQ1",
    "LNA36J9UUQHQ4",
    "LNA36J9UUQHQ1",
    "LNA36J9UUQHQ6",
    "LNA36J9UUQHQ1",
    "LNA37J9UUQHQ1",
    // NEAS NQP
    "M6Z8HDW2KEF3A", // 0-6 months
    "M6Z8IULAA8IJO", // 6-12 months
    "M6Z8JJ6E3JDOG", // 12-18 months
    "M6Z8JYY5VHR3L", // 18-24 months
    "M6Z8CKE5KO1BE", // General / Overall confidence level
    // YAS NQP
    "M7EPWG2P6KW3G", // 0-6 months
    "M7EPWG72QCNYG", // 6-12 months
    "M7EPWGCMJH70D", // 12-18 months,
    "M7EPWGIQCD92P", // 18-24 months
    "M7EPWFY69G8ZV", // General / Overall confidence level
  ].includes(progressCheckCompetenceID);
}

/**
 * Checks whether the button to add Program Module evidence should be disabled in the FAB popover
 * @param program
 * @param allEvidence
 * @param user
 * @returns
 */
export function disableFABProgramButton(
  program: ProgramData,
  allProgressCheckData: AllProgressCheckStatuses[] | undefined | null,
  allEvidence: IEvidence[],
  user: any,
  fastTrackActive: boolean,
  pauseAndHold: boolean
): boolean {
  if (isPreceptorship(program.ID) || isNQPProgram(program.ID)) {
    const progressChecks = program.ProgressChecks ?? [];

    return (
      isProgramCompleteNQP(progressChecks, allProgressCheckData, allEvidence, program.ID) ||
      removeProgressChecks(
        progressChecks,
        allEvidence,
        program,
        allProgressCheckData ?? [],
        fastTrackActive,
        pauseAndHold
      ).length === 0
    );
  } else if (isNwasEPRR(program.ID)) {
    return !isNwasEprrDetailsSet(user);
  } else if (isNeasEPRR(program.ID)) {
    return !isNeasEprrDetailsSet(user);
  } else if (isTestEPRR(program.ID)) {
    return !isTestEprrDetailsSet(user);
  } else if (isMTS(program.ID)) {
    return !isMtsDetailsSet(user);
  } else if (isCHUB(program.ID)) {
    return !isChubDetailsSet(user);
  } else if (isCSM(program.ID)) {
    return !isCsmDetailsSet(user);
  }

  return false;
}

/**
 * Checks whether to display the Progress Checks page in the PDF Export
 *
 * SWAST Preceptorship NQP1
 * @param programID - The ID of the current Program Module
 * @returns
 */
export function displayProgressChecksInPDF(programID: string = ""): boolean {
  return [
    "LNA4FTCKB012M", // SWASFT Preceptorship
    "LNA4FTCKB005M", // SWASFT NQP2
    "M167IR37RNSSQ", // NEAS NQP
    "LZCUV68I1O975", // NWAS NQP
    "M7EPWBZE8P2XK", // YAS NQP
  ].includes(programID);
}

/**
 * Checks whether to display the NOS Standards page in the PDF Export - NWAS EPRR
 *
 * NWAS EPRR Evidence
 * @param programID - The ID of the current Program Module
 * @returns
 */
export function displayStandardsInPDF(programID: string = ""): boolean {
  return ["LR0HYZYWY6JQK", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}

/**
 * Filters the input list of programIDs based on if they include progress checks
 * @param programIDs
 * @returns a filtered array of programIDs
 */
export function filterProgressCheckProgramIDs(programIDs: string[]): string[] {
  const progressCheckProgramIDs = [
    "LNA4FTCKB012M", // SWASFT Preceptorship
    "LNA4FTCKB003M", // LAS MTS
    "LNA4FTCKB004M", // LAS CHUB
    "LNA4FTCKB005M", // SWASFT NQP2
    "LQGK18YODQ6D2", // LAS CSM
    "M167IR37RNSSQ", // NEAS NQP
    "LZCUV68I1O975", // NWAS NQP
    "M7EPWBZE8P2XK", // YAS NQP
  ];
  return programIDs.filter((item) => progressCheckProgramIDs.includes(item));
}

/**
 *  Formats the approval text using the name of the mentor who approved the progress check and the time they approved it
 * @param input - The text to format
 * @param submissionStatus - The ProgressCheckSubmission object
 * @returns The formatted string - e.g. "Test User approved this progress check for you at 16:39 on 15 Mar 2024." | An empty string if no input or submissionStatus is present
 */
export function formatApprovalText(
  input: string | undefined,
  submissionStatus: ProgressCheckSubmission | undefined
): string {
  let result = "";

  if (input && submissionStatus) {
    const mentorInfo = submissionStatus.history[0];
    const mentorName = mentorInfo.displayName;
    const date = new Date(mentorInfo.date);

    result = input.replace("[MENTOR]", `<b>${mentorName}</b>`);
    result = result.replace("[DATE]", `<b>${format(date, "H:mm")} on ${format(date, "d MMM yyyy")}</b>`);
  }

  return result;
}

/**
 * Checks whether the currently selected program requires user setup
 * @param programID - The current program ID
 * @returns
 */
export function hasSetup(programID: string): boolean {
  return [
    "LNLT7JSYDT5PJ",
    "LR0HYZYWY6JQK",
    "LNA4FTCKB003M",
    "LNA4FTCKB004M",
    "LQGK18YODQ6D2",
    "M47YSAO1MFX4B",
    "M7N9K0Y7IOUMI",
  ].includes(programID);
}

// TODO check program module with LNA4FTCKB005M is still working with new evidence checking behaviour (see AllEvidenceAdded variable in ProgressCheckPage.tsx)
export function canHandleMultiplePiecesOfEvidencePerEvidenceType(programID: string): boolean {
  return [
    "LNA4FTCKB003M",
    "LNA4FTCKB004M",
    "LNA4FTCKB005M",
    "LZCUV68I1O975",
    "M167IR37RNSSQ",
    "LQGK18YODQ6D2",
    "M7EPWBZE8P2XK", // YAS NQP
  ].includes(programID);
}

export function hideDisclaimerText(programID: string): boolean {
  return ["LNA4FTCKB003M", "LNA4FTCKB004M", "LQGK18YODQ6D2"].includes(programID);
}

/**
 * Retrieves all the required skills from the current program list of competences
 * @param allCompetences - All competences for the program
 *
 */
export function getAllRequiredSkillsForProgram(allCompetences: CompetenceActivityClass[]): ProgramSkill[] {
  let skills = allCompetences.map((item) => item.Skills);
  skills = skills.filter((skill) => skill !== undefined);
  const flattenedSkills = _.flatten(skills);

  const required = flattenedSkills.filter(
    (skill: any): skill is ProgramSkill =>
      skill && skill.numberRequiredInDuration && parseInt(skill.numberRequiredInDuration, 10) > 0
  );

  return required;
}

export function getAllLearningObjectivesForProgram(program: ProgramData): LearningObjective[] {
  let allLearningObjectives: LearningObjective[] = [];

  const progressChecks = program.ProgressChecks ?? [];
  const ids = new Set<string>();

  for (let progressCheck of progressChecks) {
    for (let competence of progressCheck["Competence/Activity"]) {
      for (let learningObjective of competence["Learning Objective"]!) {
        if (!ids.has(learningObjective.ID)) {
          allLearningObjectives.push(learningObjective);
          ids.add(learningObjective.ID);
        }
      }
    }
  }

  allLearningObjectives.sort((a, b) => {
    const splitA = a.Name.split(".");
    const splitB = b.Name.split(".");

    const numA = parseInt(splitA[1], 10) ?? 0;
    const numB = parseInt(splitB[1], 10) ?? 0;
    const sectionA = splitA[2] ?? "";
    const sectionB = splitB[2] ?? "";

    if (numA < numB) return -1;
    if (numA > numB) return 1;
    if (numA === numB) {
      if (sectionA.localeCompare(sectionB) < 0) return -1;
      if (sectionA.localeCompare(sectionB) > 0) return 1;
    }

    return 0;
  });

  return allLearningObjectives;
}

export function getAllLearningObjectivesForProgramByGroup(
  program: ProgramData
): Partial<Record<string, LearningObjective[]>> {
  let allLearningObjectives: LearningObjective[] = [];

  const progressChecks = program.ProgressChecks ?? [];
  const ids = new Set<string>();

  for (let progressCheck of progressChecks) {
    for (let competence of progressCheck["Competence/Activity"]) {
      for (let learningObjective of competence["Learning Objective"]!) {
        if (!ids.has(learningObjective.ID)) {
          allLearningObjectives.push(learningObjective);
          ids.add(learningObjective.ID);
        }
      }
    }
  }

  allLearningObjectives.sort((a, b) => {
    const splitA = a.Name.split(".");
    const splitB = b.Name.split(".");

    const numA = parseInt(splitA[1], 10) ?? 0;
    const numB = parseInt(splitB[1], 10) ?? 0;
    const sectionA = splitA[2] ?? "";
    const sectionB = splitB[2] ?? "";

    if (numA < numB) return -1;
    if (numA > numB) return 1;
    if (numA === numB) {
      if (sectionA.localeCompare(sectionB) < 0) return -1;
      if (sectionA.localeCompare(sectionB) > 0) return 1;
    }

    return 0;
  });

  let learningObjectives = _.groupBy(allLearningObjectives, (item) => item.TopSection!);

  return learningObjectives;
}

/**
 * Calculates the user's NOS compliance
 * @param standards - A concatenated array of the Mandatory Standards for the user's role and their selected Optional Standards
 * @param programsData - The Program Module data
 * @param user - The current user object
 * @param programEvidence - The Program Module evidence
 * @returns The compliance percentage
 */
export function getCompliantCountForStandards(
  standards: IOptionalStandard[] | IMandatoryStandard[],
  programsData: any,
  user: IUser | null,
  programEvidence: IEvidence[],
  programId: string
) {
  if (!user || !programEvidence) return 0;

  let count = 0;
  standards.forEach((standard) => {
    const eprr: any = programsData.find((program: any) => program.ID === programId);
    const pm = new ProgramModule(eprr);
    const contactID = Number(user.userData.contactID);
    const roleID = getSelectedRoleIdForEPRRProgram(user, programId);
    const pMUsageData = new PMUsageData(eprr, []);
    const filteredEvidence = programEvidence
      .filter((ev) => !ev.draft)
      .filter((ev) => new Date(ev.date) >= subYears(new Date(), 2)) as [];
    const results = pMUsageData.getUsageDataByUser(pm, contactID, roleID, filteredEvidence);
    const resultForCurrentStandard = results.find((result: any) => result.compId === standard.id);

    if (resultForCurrentStandard) {
      if (Math.floor(Number(resultForCurrentStandard.percentage)) >= 90) {
        count += 1;
      }
    }
  });

  return count;
}

/**
 * @param evidence - All of the user's evidence
 * @param field - The key inside the evidenceJSON object that contains the graph label value
 * @returns
 */
export function getConfidenceGraphLabel(evidence: IEvidence[], field: string): string | undefined {
  const evidenceWithConfidenceLevels = evidence.find((evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const progressCheckCompetenceID = evidenceJSON?.programInfo?.progressCheckCompetenceID;

    const onHoldReset = evidenceJSON?.OnHoldReset;

    const programID = evidenceJSON?.programInfo?.programID;

    let confidenceLevelCompetenceID = "";

    if (programID === "M167IR37RNSSQ") {
      // NEAS NQP
      confidenceLevelCompetenceID = "M6Z8HDW2KEF3A";
    } else if (programID === "LNA4FTCKB012M") {
      // SWASFT Preceptorship
      confidenceLevelCompetenceID = "LNA36J9UUQHQ0";
    } else if (programID === "M7EPWBZE8P2XK") {
      // YAS NQP
      confidenceLevelCompetenceID = "M7EPWG2P6KW3G";
    }

    const value =
      progressCheckCompetenceID === confidenceLevelCompetenceID && onHoldReset !== 1 && evidence.draft !== true;

    return value;
  });

  if (!evidenceWithConfidenceLevels) {
    return undefined;
  }
  const evidenceJSON = JSON.parse(evidenceWithConfidenceLevels.evidenceJSON);

  return evidenceJSON[field];
}

/**
 * Retrieves all the evidence required to create the confidence level graph
 * @param programEvidence - All the evidence for the current program
 * @returns
 */
export function getConfidenceLevelEvidence(programEvidence: IEvidence[], program: string) {
  let evidenceWithConfidenceLevels: IEvidence[] = [];

  evidenceWithConfidenceLevels = programEvidence.filter((evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const progressCheckCompetenceID = evidenceJSON?.programInfo?.progressCheckCompetenceID;
    const programID = evidenceJSON?.programInfo?.programID;

    return competenceIsConfidenceLevel(progressCheckCompetenceID) && programID === program;
  });

  return evidenceWithConfidenceLevels;
}

/**
 * @param progressCheckID - The ID of the progress check
 * @returns The index to insert the confidence level data into
 */
export function getConfidenceLevelProgressCheckIndex(progressCheckID: string): number {
  switch (progressCheckID) {
    case "LNA4GMRKB031M":
    case "M167LOKBYZ1T9":
    case "M3SV5PZVW27GS": // NEAS NQP 0-6 months
    case "M7EPXK5EWRC2K": // YAS NQP 0-6 months
      return 0;
    case "LNA4GMRKB041M":
    case "M167LOX3P35BG": // NEAS NQP 6-12 months
    case "M7EPXKAEBRMTC": // YAS NQP 6-12 months
      return 1;
    case "LNA4GMRKB051M":
    case "M167LP2NL16ST": // NEAS NQP 12-18 months
    case "M7EPXKMQC1IRL": // YAS NQP 12-18 months
      return 2;
    case "LNA4GMRKB081M":
    case "M167LP736CQKK": // NEAS NQP 18-24 months
    case "M7EPXKRMLVURA": // YAS NQP 18-24 months
      return 3;
    default:
      return -1;
  }
}

/**
 * Gets the user's selected area (region)
 * @param programRoles - The user's Program Roles array
 * @returns The area if it exists, otherwise returns an empty string
 */
export function getEprrArea(programRoles: IUserProgramRoles[], programId: string) {
  if (!programRoles || programRoles.length === 0) return "";

  const eprrProgram = programRoles.find((program) => program.programID === programId);
  return eprrProgram ? eprrProgram.area : "";
}

/**
 * Gets the key of the evidence part that contains the activity type
 * @param evidence - The current piece of evidence
 * @returns The evidence parts key that contains the activity type
 */
export function getEvidenceActivityTypeKey(evidence: IEvidence): string | undefined {
  let evidenceJSON;

  if (typeof evidence.evidenceJSON === "string") {
    JSON.parse(evidence.evidenceJSON);
  } else if (typeof evidence.evidenceJSON === "object") {
    evidenceJSON = evidence.evidenceJSON;
  }

  if (evidenceJSON) {
    switch (evidenceJSON.programInfo.program) {
      case "SWAST HART and EPRR Instructors":
        return "CPD Activity - HART and EPRR";
      default:
        return;
    }
  }

  return;
}

/**
 * Gets the key of the attachments upload evidence part from an array of evidence parts
 * @param evidenceParts - The evidence parts to check
 * @returns The key of the attachments upload evidence part
 */
export function getEvidenceAttachmentsKey(evidenceParts: EvidencePart[]): string | undefined {
  const attachmentsPart = evidenceParts.find((item) => item["Field type"] === FieldType.Attachments);

  if (attachmentsPart) {
    const key = attachmentsPart.Name;

    return key;
  }

  return;
}

/**
 * @param programEvidence - The evidence for the current program
 * @param progressCheckID  - The ID of the progress check
 * @returns The number of pieces of evidence the user has created for the current progress check
 */
export function getEvidenceCountForProgressCheck(programEvidence: IEvidence[], progressCheckID: string): number {
  const progressCheckEvidence = programEvidence.filter((evidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const id = evidenceJSON?.programInfo?.progressCheckID || "";

    const onHoldReset = evidenceJSON.OnHoldReset;

    const value = id === progressCheckID && onHoldReset !== 1 && evidence.draft !== true;

    return value;
  });

  return progressCheckEvidence.length;
}

/**
 * Gets the key of the evidence part that contains the custom tags
 * @param evidence - The current piece of evidence
 * @returns The evidence parts key that contains the custom tags
 */
export function getEvidenceCustomTagsKey(evidence: IEvidence): string | undefined {
  let evidenceJSON;

  if (typeof evidence.evidenceJSON === "string") {
    JSON.parse(evidence.evidenceJSON);
  } else if (typeof evidence.evidenceJSON === "object") {
    evidenceJSON = evidence.evidenceJSON;
  }

  if (evidenceJSON) {
    switch (evidenceJSON.programInfo.program) {
      case "SWAST HART and EPRR Instructors":
        return "Custom tags";
      default:
        return;
    }
  }

  return;
}

/**
 * @param submissionState - The ProgressCheckSubmission object for the current progress check
 * @returns The correct disclaimer text if the submission status is Submitted or Approved, otherwise an empty string
 */
export function getEvidenceDisclaimerText(submissionState: ProgressCheckSubmission | undefined): string {
  switch (submissionState?.status) {
    case SubmissionState.Submitted:
      return "You can no longer add or edit evidence to this progress check now that you have submitted it for approval.";
    case SubmissionState.Approved:
      return "You can no longer add new evidence or edit existing evidence because this progress check is approved.";
    case SubmissionState.Completed:
      return "You can no longer add new evidence or edit existing evidence because this progress check is completed.";
    default:
      return "";
  }
}

/**
 * Retrieves all of the user's evidence for the current program
 * @param allEvidence - All of the current user's evidence
 * @param program - The current program name
 * @returns The filtered evidence for the program
 */
export function getEvidenceForProgramName(allEvidence: IEvidence[], programName: string) {
  let evidenceForProgram: IEvidence[] = [];

  evidenceForProgram = allEvidence?.filter((evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const program = evidenceJSON?.programInfo?.program || "";

    return program === programName;
  });

  evidenceForProgram = _.orderBy(evidenceForProgram, "date", "desc");

  return evidenceForProgram;
}

/**
 * Retrieves all of the user's Evidence for the current Program Module
 * @param allEvidence - All of the current user's Evidence
 * @param program - The current Program Module's name
 * @returns The filtered Evidence for the Program Module
 */
export function getEvidenceForProgramID(allEvidence: IEvidence[], programID: string) {
  let evidenceForProgram: IEvidence[] = [];

  evidenceForProgram = allEvidence?.filter((evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const id = evidenceJSON?.programInfo?.programID || "";

    return id === programID;
  });

  evidenceForProgram = _.orderBy(evidenceForProgram, "date", "desc");

  return evidenceForProgram;
}

/**
 * Retrieves all of the user's Evidence for the current Progress Check
 * @param programEvidence - All of the user's Evidence for the current Progress Check
 * @param progressCheckID - The current Progress Check ID
 * @returns The filtered Evidence for the Progress Check
 */
export function getEvidenceForProgressCheck(programEvidence: IEvidence[] = [], progressCheckID: string): IEvidence[] {
  let result: IEvidence[] = [];
  result = programEvidence?.filter((evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const id = evidenceJSON?.programInfo?.progressCheckID || "";

    const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

    return id === progressCheckID && onHoldReset !== 1 && evidence.draft !== true;
  });

  return result;
}

/**
 * @param evidencePart - The Evidence Part to get the value for
 * @param evidenceJSON
 * @param evidence - All of the user's Evidence
 * @param progressCheckCompetence - The current Progress Check Competence if needed (for ConfidenceLevelGraph)
 * @returns The initial value for the Evidence Part
 */
export function getEvidencePartInitialValue(
  evidencePart: EvidencePart,
  evidenceJSON: any,
  evidence: IEvidence[],
  progressCheckCompetence?: CompetenceActivityClass | null
): any {
  if (evidencePart["Field type"] === FieldType.NOSSelect) {
    return {
      Comps: evidenceJSON.Comps ?? [],
      LOs: evidenceJSON.LOs ?? [],
    };
  } else if (evidencePart["Field type"] === FieldType.CompMultiSelect) {
    return evidenceJSON["Comp Selector"];
  } else if (evidencePart["Field type"] === FieldType.LearningOutcomesList) {
    return evidenceJSON["LearningOutcomes"];
  } else if (isFieldLocked(progressCheckCompetence?.ID, evidencePart.ID)) {
    return getConfidenceGraphLabel(evidence, evidencePart.Name);
  } else {
    return evidenceJSON[evidencePart.Name];
  }
}

/**
 *
 * @param mandatoryStandards - All of the Mandatory Standards for the user's chosen Role
 * @param optionalStandards - The Optional Standards that the user has selected
 * @param selectedStandard - The Standard that the user has selected in the Evidence Modal
 * @param learningObjectives - All of the Learning Objective for the Program Module
 * @returns The user's selected Learning Objectives that correspond to the selected Standard
 */
export function getLearningObjectivesForStandard(
  mandatoryStandards: CompetenceActivityClass[] | undefined,
  optionalStandards: CompetenceActivityClass[] | undefined,
  selectedStandard: INOSSelectCompetence,
  learningObjectives: INOSSelectLearningObjectives[]
): INOSSelectLearningObjectives[] | undefined {
  const standards = mandatoryStandards?.concat(optionalStandards ?? []) ?? [];
  const standard = standards?.find((item) => item.ID === selectedStandard.id);
  const mappedLearningObjectives = standard?.["Learning Objective"]?.map((item) => item.ID) ?? [];

  const array = learningObjectives.filter((item) => mappedLearningObjectives.includes(item.id));

  return array ?? undefined;
}

/**
 *
 * @param user - Current user object
 * @param program - The current Program Module
 * @returns An array of the Mandatory Standards for the user's selected role if selected, otherwise an empty array
 */
export function getMandatoryStandards(user: IUser, program: ProgramData): CompetenceActivityClass[] {
  if (user && program) {
    const eprrProgram = user.programRoles?.find((role) => role.programID === program.ID);
    const selectedRoleID = eprrProgram?.programRoles[0].roleID;
    const role = program.Roles?.find((item) => item.ID === selectedRoleID);

    if (role?.CompetenceActivity?.length && checkCompetenceActivityType("Name", role.CompetenceActivity[0])) {
      const array: CompetenceActivityClass[] = role.CompetenceActivity as CompetenceActivityClass[];
      return array ?? [];
    }
  }

  return [];
}

/**
 * Retrieves the mandatory standards for a selected role
 * @param {any} program - the whole program json
 * @param {string} roleId - role id to obtain standards for
 * @returns An array of mandatory standards
 */
export function getMandatoryStandardsFromProgram(program: any, roleId: string): IMandatoryStandard[] {
  if (!roleId) {
    return [];
  }

  const mandatoryStandards: IMandatoryStandard[] = Array.from(
    program.Roles.find((r: any) => r.ID === roleId).CompetenceActivity
  ).map((item: any) => {
    const learningObjectives: LearningObjective[] = item["Learning Objective"].map((item: any) => item);
    return { id: item.ID, name: item.Name, description: item.Description, learningObjectives: learningObjectives };
  });

  return mandatoryStandards;
}

/**
 * Returns the standard tags for the user's SWAST IC role
 * @param role - The current user's SWAST IC role
 * @returns The mapped array of mandatory and optional standard tags
 */
export function getNOSStandardTagsForRole(role: string) {
  const roleObject = roles.find((item) => item.Name === role);

  const optional = roleObject?.OptionalRoles;
  const mandatory = roleObject?.MandatoryRoles;

  const mandatoryRoles = mandatory?.map((id) => standards.find((item) => item.id === id));
  const optionalRoles = optional?.map((id) => standards.find((item) => item.id === id));

  return {
    mandatory: _.orderBy(mandatoryRoles, ["Code"], ["asc"]) || [],
    optional: _.orderBy(optionalRoles, ["Code"], ["asc"]) || [],
  };
}

/**
 *
 * @param user - Current user object
 * @param program - The current Program Module
 * @returns An array of the Optional Standards for the user's selected role if selected, otherwise an empty array
 */
export function getOptionalStandards(user: IUser, program: ProgramData): CompetenceActivityClass[] {
  if (user && program) {
    const eprrProgram = user.programRoles?.find((role) => role.programID === program.ID);
    const selectedRoleID = eprrProgram?.programRoles[0].roleID;

    const optionalStandards = eprrProgram?.programRoles[0].optionalStandards ?? [];
    const optionalStandardsIDs = optionalStandards.map((item: any) => item.id);

    const role = program.Roles?.find((item) => item.ID === selectedRoleID);

    if (
      role?.OptionalCompetenceActivity?.length &&
      checkCompetenceActivityType("Name", role.OptionalCompetenceActivity[0])
    ) {
      const array: CompetenceActivityClass[] = role.OptionalCompetenceActivity as CompetenceActivityClass[];

      const selectedStandards = array.filter((item) => optionalStandardsIDs.includes(item.ID));

      return selectedStandards ?? [];
    }
  }

  return [];
}

/**
 * Retrieves the optional standards selected by a user
 * @param {any} user - user object
 * @returns An array of mandatory standards
 */
export function getOptionalStandardsFromUser(user: IUser | null, program: any) {
  if (!user) return [];

  const eprrProgram = user.programRoles?.find((role) => role.programID === program.ID);

  if (!eprrProgram) return [];

  const optionalStandards: IOptionalStandard[] = Array.from(eprrProgram?.programRoles[0].optionalStandards).map(
    (standard: any) => {
      let description = "";
      let learningObjectives: LearningObjective[] = [];
      const role = program.Roles.find((role: any) => role.ID === eprrProgram?.programRoles[0].roleID);
      const matchingCompetence = role.OptionalCompetenceActivity.find((comp: any) => comp.ID === standard.id);
      if (matchingCompetence) {
        description = matchingCompetence.Description;
        learningObjectives = matchingCompetence["Learning Objective"];
      }
      return {
        id: standard.id,
        name: standard.name,
        learningObjectives: learningObjectives,
        description: description,
      };
    }
  );

  return optionalStandards;
}

export function getPauseLabel(programModuleStatus: ProgramModuleStatus | undefined): PauseLabel | undefined {
  switch (programModuleStatus?.data.Pause) {
    case "Pause":
      return {
        label: "Pause",
        status: "Paused",
      };
    case "PauseAndHold":
      return {
        label: "Pause & hold",
        status: "Pause & Hold",
      };
    default:
      return undefined;
  }
}

export function getPauseDescription(
  programModuleStatus: ProgramModuleStatus | undefined,
  program?: ProgramData
): string | undefined {
  switch (programModuleStatus?.data.Pause) {
    case "Pause":
      return program?.PauseDescription;
    case "PauseAndHold":
      return program?.PauseAndHoldDescription;
    default:
      return undefined;
  }
}

export function getFastTrackLabel(programModuleStatus: ProgramModuleStatus | undefined): FastTrackLabel | undefined {
  switch (programModuleStatus?.data.FastTrack) {
    case "Enabled":
      return {
        label: "Fast Track",
        status: "Fast Track",
      };
    default:
      return undefined;
  }
}

export function getFastTrackDescription(
  programModuleStatus: ProgramModuleStatus | undefined,
  program?: ProgramData
): string | undefined {
  switch (programModuleStatus?.data.FastTrack) {
    case "Enabled":
      return program?.FastTrackDescription;
    default:
      return undefined;
  }
}

export function getPreceptorsText(mentorNamingConvention?: string, mentorTitle?: string): string | undefined {
  if (mentorNamingConvention) {
    return `Your ${mentorNamingConvention}`;
  } else if (mentorTitle) {
    return `Your ${mentorTitle}${makePlural(mentorTitle)}`;
  }

  return undefined;
}

function makePlural(input: string): string {
  if (input[input.length - 1] !== "s") {
    return "s";
  }

  return "";
}

/**
 * Finds the user's ParaFolio subscriptions and maps any ParaFolioPrograms fields withing them to the corresponding programID
 * @param subscriptions - All of the users Class subscriptions, not only ParaFolio subscriptions
 * @param programs - The full list of programs
 * @returns An array of ProgramIDs
 */
export function getProgramIDsFromSubscriptions(
  subscriptions: IClassSubscription[] | null,
  programs: ProgramData | any
): string[] {
  if (subscriptions) {
    const programSubscriptions: IClassSubscription[] = subscriptions.filter(
      (item: IClassSubscription) => item?.ParaFolioPrograms && item?.ParaFolioPrograms.length > 0
    );

    const subscriptionNameArrays = programSubscriptions.map((item) => item.ParaFolioPrograms);
    const uniqueProgramName = _.uniq(_.flatten(subscriptionNameArrays));

    let programIDs = uniqueProgramName.map((item) => {
      const program = programs.find((_item: any): _item is ProgramData => {
        return _item.Subscription?.includes(item) ?? undefined;
      });

      return program?.ID ?? undefined;
    });

    programIDs = programIDs.filter((item) => item !== undefined);

    return programIDs;
  }

  return [];
}

/**
 *
 * @param programName - The current Program Module's name
 * @returns The CSS class name for the ProgramCard background style
 */
export function getProgramCardBackground(programName: string): string {
  return "programCardBackground" + programName.replace(/\s/gim, "").replace(/:/, "");
}

/**
 * Returns the progress check for the current piece of evidence based on the progressCheckID
 * @param evidenceJSON
 * @param program
 * @returns The progress check if it exists or null if it doesn't
 */
export function getProgressCheckForEvidence(evidenceJSON: any, program: ProgramData | undefined): ProgressCheck | null {
  if (program) {
    const progressCheckID = evidenceJSON?.programInfo?.progressCheckID;
    const progressChecks = program.ProgressChecks || [];

    const progressCheck = progressChecks.find((item: ProgressCheck) => item.ID === progressCheckID);

    return progressCheck ? progressCheck : null;
  }

  return null;
}

export function getProgressCheckSubmissionLockedDisclaimerText(
  progressCheckData: ProgressCheckStatus | null
): string | undefined {
  switch (progressCheckData?.submissions.status) {
    case SubmissionState.Submitted:
      return "You can no longer add or edit evidence to this progress check now that you have submitted it for approval.";
    case SubmissionState.Approved:
      return "You can no longer add new evidence or edit existing evidence because this progress check is approved.";
    case SubmissionState.Completed:
      return "You can no longer add new evidence or edit existing evidence because this progress check is completed.";
    default:
      return undefined;
  }
}

/**
 *
 * @param progressCheckID
 * @returns The correct info string whether the previous Progress Check required approval or not
 */
export function getProgressCheckLockedDisclaimerText(progressCheckID: string): string {
  switch (progressCheckID) {
    case "LNA4GMRKB071M":
    case "LNA4GMRKB081M":
      return "You cannot add evidence to this progress check until you have completed the previous progress check.";
    default:
      return "You cannot add evidence to this progress check until your previous progress check has been approved.";
  }
}

export function getProgressCheckPauseAndHoldLockedText(pauseAndHold: boolean, isProgram?: boolean): string | undefined {
  if (pauseAndHold) {
    return `You cannot add evidence${isProgram ? " " : " to this progress check "}because your program has been paused.`;
  }
}

/**
 *
 * @param progressCheckID - The ID of the Progress Check to find
 * @param progressCheckData - The array of all Progress Check Status data
 * @param program
 * @returns The Progress Check Status if it exists or null if it doesn't
 */
export function getProgressCheckStatusFromEvidence(
  progressCheckID: string,
  progressCheckData: AllProgressCheckStatuses[] | null,
  program: ProgramData | undefined
): ProgressCheckStatus | null {
  if (program && progressCheckData) {
    const allProgressCheckStatuses = progressCheckData.find((item) => item.programID === program.ID);
    const progressCheckStatus = allProgressCheckStatuses?.pCs.find((item) => item.pCId === progressCheckID);

    return progressCheckStatus ?? null;
  }

  return null;
}

/**
 * Gets the correct information for the ProgressCheckButton label
 * @param progressCheck - The Progress Check to process
 * @param progressCheckStatus - The status object of the Progress Check
 * @param programEvidence - The Evidence for the Progress Check
 * @param progressChecks - All of the Progress Checks
 * @param allProgressCheckData - The status objects for all the Progress Checks
 * @returns an object with a label and status. e.g. { label: "Approved", status:"Completed" }
 */
export function getProgressCheckLabelInformationForTelTriage(
  currentProgressCheck: ProgressCheck,
  programEvidence: IEvidence[],
  previousProgressCheck?: ProgressCheck
) {
  const evidenceForProgressCheck = programEvidence.filter((e) => {
    const parsedEvidence = JSON.parse(e.evidenceJSON);
    if (!e.draft && parsedEvidence.programInfo.progressCheckID === currentProgressCheck.ID) {
      return true;
    }
  });

  let previousProgressCheckIsIncomplete = false;
  if (previousProgressCheck) {
    const evidenceForPreviousProgressCheck = programEvidence.filter((e) => {
      const parsedEvidence = JSON.parse(e.evidenceJSON);
      const onHoldReset = parsedEvidence?.OnHoldReset ?? 0;
      if (!e.draft && onHoldReset === 0 && parsedEvidence.programInfo.progressCheckID === previousProgressCheck.ID) {
        return true;
      }
    });
    const previousProgressCheckIsApproved = isProgressCheckApprovedBasedOnCompTrigger(
      evidenceForPreviousProgressCheck,
      previousProgressCheck["Competence/Activity"]
    );
    previousProgressCheckIsIncomplete = !previousProgressCheckIsApproved;
  }

  const approved = isProgressCheckApprovedBasedOnCompTrigger(
    evidenceForProgressCheck,
    currentProgressCheck["Competence/Activity"]
  );

  const onHoldResetExists = evidenceForProgressCheck.find((e) => {
    const parsed = JSON.parse(e.evidenceJSON);
    if (parsed && parsed.OnHoldReset && parsed.OnHoldReset == 1) {
      return true;
    }
  });

  if (
    !evidenceForProgressCheck.length &&
    (currentProgressCheck.SequentiallyLocked === SequentiallyLocked.Unlocked ||
      currentProgressCheck.SequentiallyLocked === SequentiallyLocked.UnlockedIgnore)
  ) {
    return {
      label: "Open",
      status: "Open",
    };
  }

  if (approved) {
    return {
      label: "Approved",
      status: "Completed",
    };
  } else if (!approved && onHoldResetExists) {
    return {
      label: "Reset",
      status: "Reset",
    };
  } else if (evidenceForProgressCheck.length > 0) {
    return {
      label: "In progress",
      status: "In progress",
    };
  } else if (previousProgressCheckIsIncomplete) {
    return {
      label: "Locked",
      status: "Locked",
    };
  } else {
    return {
      label: "Not started",
      status: "Not started",
    };
  }
}

export function getProgressCheckLabelInformation(
  progressCheck: ProgressCheck,
  progressCheckStatus: ProgressCheckStatus | undefined,
  programEvidence: IEvidence[],
  progressChecks: ProgressCheck[],
  allProgressCheckData: AllProgressCheckStatuses | null,
  programID: string,
  fastTrackActive: boolean,
  pauseAndHold: boolean
): IProgressCheckLabel | undefined {
  if (progressCheckStatus?.submissions.status === SubmissionState.Approved) {
    return {
      label: SubmissionState.Approved,
      status: "Completed",
    };
  } else if (progressCheckStatus?.submissions.status === SubmissionState.Completed) {
    return {
      label: SubmissionState.Completed,
      status: "Completed",
    };
  }

  const progressCheckEvidence = getEvidenceForProgressCheck(programEvidence, progressCheck.ID);

  if (progressCheckEvidence.length > 0) {
    const completed = isAllEvidenceAddedForProgressCheck(progressCheckEvidence, progressCheck["Competence/Activity"]);

    if (completed) {
      if (progressCheck.Approval === ProgressCheckApproval.NotRequired) {
        if (
          progressCheck.SequentiallyLocked === SequentiallyLocked.Unlocked ||
          progressCheck.SequentiallyLocked === SequentiallyLocked.UnlockedIgnore
        ) {
          return {
            label: "Open",
            status: "Open",
          };
        }

        return {
          label: "Completed",
          status: "Completed",
        };
      } else if (progressCheckStatus?.submissions.status === SubmissionState.Submitted) {
        return {
          label: ProgressState.Submitted,
          status: ProgressState.InProgress,
        };
      } else if (progressCheckStatus?.submissions.status === SubmissionState.OnHold) {
        return {
          label: "Submission held",
          status: "Attention",
        };
      } else if (progressCheckStatus?.submissions.status === SubmissionState.OnHoldReset) {
        return {
          label: "Submission held",
          status: "Attention",
        };
      } else if (showProgressCheckEvidenceCount(progressCheck.ID)) {
        return {
          label: `${progressCheckEvidence.length}/${progressCheck["Competence/Activity"].length}`,
          status: "Completed",
        };
      } else {
        return {
          label: "Ready for submission",
          status: ProgressState.InProgress,
        };
      }
    } else if (showProgressCheckEvidenceCount(progressCheck.ID)) {
      return {
        label: `${progressCheckEvidence.length}/${progressCheck["Competence/Activity"].length}`,
        status: "In progress",
      };
    } else if (progressCheckStatus?.submissions.status === SubmissionState.OnHoldReset) {
      return {
        label: "Submission held",
        status: "Attention",
      };
    } else if (progressCheckStatus?.submissions.status === SubmissionState.OnHold) {
      return {
        label: "Submission held",
        status: "Attention",
      };
    } else if (progressCheckStatus?.submissions.status === SubmissionState.Submitted) {
      return {
        label: ProgressState.Submitted,
        status: ProgressState.InProgress,
      };
    } else {
      return {
        label: "In progress",
        status: ProgressState.InProgress,
      };
    }
  }

  const progressCheckLocked = checkIfProgressCheckIsLocked(
    progressCheck,
    progressChecks,
    programEvidence,
    allProgressCheckData,
    programID,
    fastTrackActive,
    pauseAndHold
  );

  if (progressCheckLocked) {
    return {
      label: "Locked",
      status: "Not started",
    };
  }

  if (
    progressCheck.SequentiallyLocked === SequentiallyLocked.Unlocked ||
    progressCheck.SequentiallyLocked === SequentiallyLocked.UnlockedIgnore
  ) {
    return {
      label: "Open",
      status: "Open",
    };
  }

  return {
    label: "Not started",
    status: "Not started",
  };
}

/**
 * @param submissionState - The current ProgressCheckSubmission object
 * @returns The CSS class for the submission state button
 */
export function getProgressCheckSubmissionButtonClass(
  submissionState: ProgressCheckSubmission | undefined
): string | undefined {
  if (submissionState) {
    if (submissionState.status === SubmissionState.Submitted) {
      return "!program-card-button-submitted";
    }

    if (submissionState.status === SubmissionState.OnHold) {
      return "!program-card-button-cta";
    }

    if (submissionState.status === SubmissionState.OnHoldReset) {
      return "!program-card-button-cta";
    }

    if (submissionState.status === SubmissionState.Approved) {
      return "!program-card-button-approved";
    }
  }

  return undefined;
}

/**
 * Gets the submission button text for the current progress check submission state
 * @param submissionState
 * @returns Submitted | Waiting for approval | Resubmit
 */
export function getProgressCheckSubmissionButtonText(submissionState: ProgressCheckSubmission | undefined): string {
  if (submissionState) {
    if (submissionState.status === SubmissionState.Submitted) {
      return SubmissionButtonState.WaitingForApproval;
    }

    if (submissionState.status === SubmissionState.OnHold) {
      return SubmissionButtonState.Resubmit;
    }

    if (submissionState.status === SubmissionState.OnHoldReset) {
      return SubmissionButtonState.Resubmit;
    }

    if (submissionState.status === SubmissionState.Approved) {
      return SubmissionButtonState.Approved;
    }
  }

  return SubmissionButtonState.Submit;
}

/**
 * @param submissionState - The current ProgressCheckSubmission object
 * @returns The CSS class for the submission state button text
 */
export function getProgressCheckSubmissionButtonTextClass(
  submissionState: ProgressCheckSubmission | undefined
): string | undefined {
  if (submissionState) {
    if (submissionState.status === SubmissionState.Submitted) {
      return "!text-orange-text";
    }

    if (submissionState.status === SubmissionState.OnHold) {
      return "!text-white";
    }

    if (submissionState.status === SubmissionState.OnHoldReset) {
      return "!text-white";
    }

    if (submissionState.status === SubmissionState.Approved) {
      return "!text-green-text";
    }
  }

  return undefined;
}

/**
 * @param status - The current ProgressCheckSubmission state
 * @returns The Tailwind extended colour class name
 */
export function getProgressStatusBackgroundColor(status: string): string {
  if (status === ProgressState.NotStarted) {
    return "grey-30";
  }

  if (status === ProgressState.InProgress) {
    return "orange-20";
  }

  if (status === ProgressState.Submitted) {
    return "orange-20";
  }

  if (status === ProgressState.ReadyForSubmission) {
    return "orange-20";
  }

  return "grey-30";
}

/**
 * @param status - The current ProgressCheckSubmission state
 * @returns The Tailwind extended colour class name
 * @returns
 */
export function getProgressStatusTextColor(status: string): string {
  if (status === ProgressState.NotStarted) {
    return "text-grey-60";
  }

  if (status === ProgressState.InProgress) {
    return "text-orange-text";
  }

  if (status === ProgressState.Submitted) {
    return "text-orange-text";
  }

  if (status === ProgressState.ReadyForSubmission) {
    return "text-orange-text";
  }

  return "text-grey-60";
}

/**
 * Returns roles label for the program details component
 * @param programID - The current program ID
 * @returns
 */
export function getRolesLabel(programID: string): string {
  const programsWithMandatoryLabel = ["LR0HYZYWY6JQK", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"];
  if (programsWithMandatoryLabel.includes(programID)) {
    return "Your role (Mandatory)";
  }
  return "Your roles";
}

/**
 *
 * @param user - The current user object
 * @returns The selected role id if the user exists, otherwise returns an empty string
 */
export function getSelectedRoleIdForEPRRProgram(user: IUser | null, programId: string) {
  if (!user) return "";

  const prog = user.programRoles?.find((role) => role.programID === programId);
  const selectedRoleID = prog?.programRoles[0].roleID;

  return selectedRoleID;
}

/**
 * Checks whether the currently selected program requires user setup
 * @param programID - The current program ID
 * @returns
 */

/**
 * Checks whether the expiry date for a certificate input should be hidden.
 * @param programID - The current program ID
 * @returns
 */
export function hideExpiryDateCertInput(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}

/**
 * Checks whether the program card should show standards and evidence details (used by EPRR)
 * @param programID - The current program ID
 * @returns
 */
export function isNwasEPRR(programID: string): boolean {
  return ["LR0HYZYWY6JQK"].includes(programID);
}

export function isNeasEPRR(programID: string): boolean {
  return ["M47YSAO1MFX4B"].includes(programID);
}

export function isTestEPRR(programID: string): boolean {
  return ["M7N9K0Y7IOUMI"].includes(programID);
}

export function isEPRR(programID?: string) {
  if (!programID) return false;
  return ["LR0HYZYWY6JQK", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}

// TODO combine isDetailsSet functions into single function
/**
 * Checks whether the user details are set for the NWAS EPRR Program Module
 * @param user - The current user object
 * @returns
 */
export function isNwasEprrDetailsSet(user: any) {
  if (user && user.programRoles) {
    const eprrProgram = user.programRoles.find((program: any) => program.programID === "LR0HYZYWY6JQK");
    return eprrProgram ? true : false;
  }

  return false;
}

/**
 * Checks whether the user details are set for the NEAS EPRR Program Module
 * @param user - The current user object
 * @returns
 */
export function isNeasEprrDetailsSet(user: any) {
  if (user && user.programRoles) {
    const eprrProgram = user.programRoles.find((program: any) => program.programID === "M47YSAO1MFX4B");
    return eprrProgram ? true : false;
  }

  return false;
}

/**
 * Checks whether the user details are set for the TEST EPRR Program Module
 * @param user - The current user object
 * @returns
 */
export function isTestEprrDetailsSet(user: any) {
  if (user && user.programRoles) {
    const eprrProgram = user.programRoles.find((program: any) => program.programID === "M7N9K0Y7IOUMI");
    return eprrProgram ? true : false;
  }

  return false;
}

export function isMtsDetailsSet(user: any) {
  if (user && user.programRoles) {
    const mtsProgram = user.programRoles.find((program: any) => program.programID === "LNA4FTCKB003M");
    return mtsProgram ? true : false;
  }

  return false;
}

export function isChubDetailsSet(user: any) {
  if (user && user.programRoles) {
    const mtsProgram = user.programRoles.find((program: any) => program.programID === "LNA4FTCKB004M");
    return mtsProgram ? true : false;
  }

  return false;
}

export function isCsmDetailsSet(user: any) {
  if (user && user.programRoles) {
    const csmProgram = user.programRoles.find((program: any) => program.programID === "LQGK18YODQ6D2");
    return csmProgram ? true : false;
  }

  return false;
}

/**
 * Checks whether the details card should be displayed
 * @param programID - The current program ID
 * @returns
 */
export function isPreceptorship(programID: string): boolean {
  return ["LNA4FTCKB012M"].includes(programID);
}

export function isNQPProgram(programID: string): boolean {
  return [
    "LNA4FTCKB005M", // SWAST
    "M167IR37RNSSQ", // NEAS
    "LZCUV68I1O975", // NWAS
    "M7EPWBZE8P2XK", // YAS NQP
  ].includes(programID);
}

/**
 * Checks whether the chosen Progress Check is finished (Approved or Completed)
 * @param submissionState - The submission history for the chosen Progress Check
 * @returns
 */
export function isProgressCheckFinished(submissionState: ProgressCheckSubmission | undefined): boolean {
  return submissionState?.status === SubmissionState.Approved || submissionState?.status === SubmissionState.Completed;
}

/**
 * @param progressCheckEvidence - The Evidence for the current Progress Check
 * @param competences - All of the Progress Checks in the Program Module
 * @returns Whether all of the Evidence is added for the Progress Check
 */
export function isAllEvidenceAddedForProgressCheck(
  progressCheckEvidence: IEvidence[],
  competences: CompetenceActivityClass[],
  isNQP?: boolean
): boolean {
  let evidenceForProgressCheck: IEvidence[] = _.cloneDeep(progressCheckEvidence) ?? [];

  evidenceForProgressCheck = evidenceForProgressCheck?.filter((item) => {
    const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);

    const onHoldReset = evidenceJSON?.OnHoldReset === 1;

    return !onHoldReset && item.draft !== true;
  });

  const allCompetences = _.cloneDeep(competences);

  const progressCheckIDs = evidenceForProgressCheck
    .map((item: IEvidence) => {
      const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);
      return evidenceJSON?.programInfo?.progressCheckCompetenceID;
    })
    .sort();

  const requiredCompetences = allCompetences.filter((competence: CompetenceActivityClass) => {
    let isWellbeing = competence.isWellbeingActivity;

    if (isNQP) {
      return !isWellbeing;
    }

    return competence["Number Evidences required"] && competence["Number Evidences required"] > 0;
  });

  const competenceIDs = requiredCompetences.map((item) => item.ID).sort();

  let allEvidenceAdded = false;

  if (requiredCompetences.length !== 0) {
    allEvidenceAdded = competenceIDs.every((id) => progressCheckIDs.includes(id));
  }

  return allEvidenceAdded;
}

/**
 *
 * @param allEvidenceAdded
 * @param approval
 * @returns true if all Evidence is added for the Progress Check and it doesn't require approval
 */
export function isProgressCheckCompleted(
  allEvidenceAdded: boolean,
  approval: string,
  submissionState?: ProgressCheckSubmission | undefined
): boolean {
  if (submissionState?.status === SubmissionState.Completed) {
    return true;
  }

  return allEvidenceAdded && approval === ProgressCheckApproval.NotRequired;
}

// TODO should i replace this with checking progress check status?
// Checks if a progress check is completed based on it's evidence. This function should be used for program modules that are approved
// based on a competence trigger. For example, the data in the "End of Course Review" competence in MTS Telephone Triage acts as a trigger for approval.
export function isProgressCheckApprovedBasedOnCompTrigger(
  evidence: IEvidence[],
  progressCheckComps: CompetenceActivityClass[]
): boolean {
  if (!evidence) return false; // check for falsy evidence value because app can get into weird state where this is undefined

  let result = false;
  const compUsedForApproval = progressCheckComps.find((comp) => comp.isFinalReview);
  if (compUsedForApproval) {
    evidence
      .filter((e) => !e.draft)
      .forEach((e) => {
        if (e.evidenceJSON) {
          const parsedEvidence = JSON.parse(e.evidenceJSON);
          if (
            parsedEvidence.programInfo.progressCheckCompetenceID === compUsedForApproval.ID &&
            compUsedForApproval["Evidence Definitions"][0]
          ) {
            const evidenceDef = compUsedForApproval["Evidence Definitions"][0];
            const part = evidenceDef["Evidence parts"]?.find((part) => part["Progress Check Submission Trigger"]);
            if (part) {
              if (parsedEvidence[part?.Name] === part["Progress Check Submission Trigger"]) {
                result = true;
              }
            }
          }
        }
      });
  }

  return result;
}

/**
 * @param progressCheckCompetenceID
 * @param evidenceDefinitionID
 * @returns true if the field is locked in the Evidence Modal to prevent user input (ConfidenceLevelGraph)
 */
export function isFieldLocked(progressCheckCompetenceID: string | undefined, evidenceDefinitionID: string): boolean {
  if (!progressCheckCompetenceID) {
    return false;
  }

  const competenceIncluded = [
    "LNA36J9UUQHQ4",
    "LNA36J9UUQHQ6",
    "LNA37J9UUQHQ1", //
    "M6Z8IULAA8IJO", // NEAS NQP 6-12 months
    "M6Z8JJ6E3JDOG", // NEAS NQP 12-18 months
    "M6Z8JYY5VHR3L", // NEAS NQP 18-24 months
    "M7EPWG72QCNYG", // YAS NQP 6-12 months
    "M7EPWGCMJH70D", // YAS NQP 12-18 months
    "M7EPWGIQCD92P", // YAS NQP 18-24 months
  ].includes(progressCheckCompetenceID);

  const partCanBeLocked = [
    "LPMLMVO96BJBW",
    "LPMLTECLVT6U5",
    "M167J6D4HS385", // NEAS NQP - Area of practice 1 - pre-preceptorship
    "M167J6HL6QKWM", // NEAS NQP - Area of practice 2 - pre-preceptorship
    "M7EPWRBNVSNL1", // YAS NQP - Area of practice 1 - pre-preceptorship
    "M7EPWRG221NAI", // YAS NQP - Area of practice 2 - pre-preceptorship
  ].includes(evidenceDefinitionID);

  return competenceIncluded && partCanBeLocked;
}

export function autoCompleteProgressCheckDependsOnTrigger(programID: string) {
  return ["LNA4FTCKB003M", "LNA4FTCKB004M", "LQGK18YODQ6D2"].includes(programID);
}

export function includeSubtitleInProgressCheckEvidenceButton(programID: string): boolean {
  return [
    "LNA4FTCKB003M", // LAS MTS
    "LNA4FTCKB004M", // LAS CHUB
    "LNA4FTCKB005M", // SWASFT NQP
    "LZCUV68I1O975", // NWAS NQP
    "M167IR37RNSSQ", // NEAS NQP
    "LQGK18YODQ6D2", // LAS CSM
    "M7EPWBZE8P2XK", // YAS NQP
  ].includes(programID);
}

export function removeMandatoryCountInProgressCheckEvidenceButton(programID: string): boolean {
  return [
    "M167IR37RNSSQ", // NEAS NQP
    "LZCUV68I1O975", // NWAS NQP
    "LNA4FTCKB005M", // SWAST NQP
    "M7EPWBZE8P2XK", // YAS NQP
  ].includes(programID);
}

export function showApprovalStatusBannerOnProgressCheckPage(programID: string): boolean {
  return ["LNA4FTCKB003M", "LNA4FTCKB004M", "LQGK18YODQ6D2"].includes(programID);
}

/**
 * @param submissionState
 * @returns true if the submission state is approved
 */
export function isProgressCheckApproved(submissionState: ProgressCheckSubmission | undefined): boolean {
  return submissionState?.status === SubmissionState.Approved;
}

/**
 * @param submissionState
 * @param allEvidenceAdded
 * @returns true if the submission state is submitted or approved. Otherwise returns whether all the required evidence is not added
 */
export function isProgressCheckButtonDisabled(
  submissionState: ProgressCheckSubmission | undefined,
  allEvidenceAdded: boolean
): boolean {
  if (submissionState) {
    if (submissionState.status === SubmissionState.Submitted || submissionState.status === SubmissionState.Approved) {
      return true;
    }
  }

  return !allEvidenceAdded;
}

export function isProgressCheckSubmissionButtonDisabled(
  submissionState: ProgressCheckSubmission | undefined,
  progressCheckComps: CompetenceActivityClass[],
  evidence: IEvidence[],
  allEvidenceAdded: boolean,
  isNQP?: boolean
): boolean {
  if (submissionState) {
    if (submissionState.status === SubmissionState.Submitted || submissionState.status === SubmissionState.Approved) {
      return true;
    }
  }

  const compUsedForSubmission = progressCheckComps.find((comp) => comp.canUnlockSubmission);

  if (compUsedForSubmission) {
    let result = true;
    let evidenceAddedForCompUnlock = false;

    evidence
      .filter((e) => !e.draft)
      .forEach((e) => {
        if (e.evidenceJSON) {
          const parsedEvidence = JSON.parse(e.evidenceJSON);
          const onHoldReset = parsedEvidence?.OnHoldReset ?? 0;

          if (
            onHoldReset !== 1 &&
            parsedEvidence.programInfo.progressCheckCompetenceID === compUsedForSubmission.ID &&
            compUsedForSubmission["Evidence Definitions"][0]
          ) {
            const evidenceDef = compUsedForSubmission["Evidence Definitions"][0];
            const part = evidenceDef["Evidence parts"]?.find(
              (part) => part["Progress Check Submission Unlock Trigger"]
            );

            evidenceAddedForCompUnlock = true;

            if (part) {
              if (parsedEvidence[part?.Name] === part["Progress Check Submission Unlock Trigger"]) {
                result = false;
              } else {
                if (isNQP && result) {
                  result = !allEvidenceAdded;
                }
              }
            }
          }
        }
      });

    if (evidenceAddedForCompUnlock) {
      return result;
    }
  }

  return !allEvidenceAdded;
}

export function isWellbeingActivity(competenceID: string = "") {
  return ["LWKJR9KHM5VOO", "LZCUV746C9I8E"].includes(competenceID);
}

/**
 * Gets the evidence for generating the Preceptor confidence level graph and maps it to the ConfidenceLevelData object for the graph to use
 * @param confidenceLevelEvidence - The filtered evidence for the confidence level competences
 * @returns The mapped confidence level data if the first required evidence has been added, or null if it has not been added
 */
export function mapConfidenceLevelEvidence(
  confidenceLevelEvidence: IEvidence[],
  programID: string
): ConfidenceLevelData | null {
  if (programID === "M167IR37RNSSQ") {
    // NEAS NQP
    const evidenceID = "M6Z8HDW2KEF3A";

    const prePreceptorEvidence = confidenceLevelEvidence.find((evidence: IEvidence) => {
      const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
      const progressCheckCompetenceID = evidenceJSON?.programInfo?.progressCheckCompetenceID || "";

      const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

      return progressCheckCompetenceID === evidenceID && onHoldReset !== 1 && evidence.draft !== true;
    });

    if (prePreceptorEvidence) {
      const prePreceptorEvidenceJSON =
        prePreceptorEvidence.evidenceJSON && JSON.parse(prePreceptorEvidence.evidenceJSON);

      let area1Data = [0, 0, 0, 0];
      let area2Data = [0, 0, 0, 0];
      let generalData = [0, 0, 0, 0];

      for (let i = 0; i < confidenceLevelEvidence.length; i++) {
        const evidence = confidenceLevelEvidence[i];
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);

        const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

        // Don't include draft evidence or evidence on hold reset in the data
        if (onHoldReset === 1 || evidence.draft === true) {
          continue;
        }

        const index = getConfidenceLevelProgressCheckIndex(evidenceJSON.programInfo?.progressCheckID);

        if (index > -1) {
          if (evidenceJSON.programInfo.progressCheckCompetenceID === "M6Z8CKE5KO1BE") {
            generalData[index] = parseInt(evidenceJSON["Confidence level 1"], 10);
          } else if (evidenceJSON.programInfo.progressCheckCompetenceID === "M6Z8HDW2KEF3A") {
            area1Data[index] = parseInt(evidenceJSON["Confidence level 1"], 10);
            area2Data[index] = parseInt(evidenceJSON["Confidence level 2"], 10);
          } else {
            area1Data[index] = parseInt(evidenceJSON["Confidence level 1"], 10);
            area2Data[index] = parseInt(evidenceJSON["Confidence level 2"], 10);
          }
        }
      }

      return {
        area1: {
          areaName: prePreceptorEvidenceJSON["Area of practice 1 - pre-preceptorship"],
          data: area1Data,
        },
        area2: {
          areaName: prePreceptorEvidenceJSON["Area of practice 2 - pre-preceptorship"],
          data: area2Data,
        },
        general: {
          areaName: "General",
          data: generalData,
        },
      };
    }

    return null;
  } else if (programID === "M7EPWBZE8P2XK") {
    // YAS NQP
    const evidenceID = "M7EPWG2P6KW3G";

    const prePreceptorEvidence = confidenceLevelEvidence.find((evidence: IEvidence) => {
      const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
      const progressCheckCompetenceID = evidenceJSON?.programInfo?.progressCheckCompetenceID || "";

      const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

      return progressCheckCompetenceID === evidenceID && onHoldReset !== 1 && evidence.draft !== true;
    });

    if (prePreceptorEvidence) {
      const prePreceptorEvidenceJSON =
        prePreceptorEvidence.evidenceJSON && JSON.parse(prePreceptorEvidence.evidenceJSON);

      let area1Data = [0, 0, 0, 0];
      let area2Data = [0, 0, 0, 0];
      let generalData = [0, 0, 0, 0];

      for (let i = 0; i < confidenceLevelEvidence.length; i++) {
        const evidence = confidenceLevelEvidence[i];
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);

        const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

        // Don't include draft evidence or evidence on hold reset in the data
        if (onHoldReset === 1 || evidence.draft === true) {
          continue;
        }

        const index = getConfidenceLevelProgressCheckIndex(evidenceJSON.programInfo?.progressCheckID);

        if (index > -1) {
          if (evidenceJSON.programInfo.progressCheckCompetenceID === "M7EPWFY69G8ZV") {
            generalData[index] = parseInt(evidenceJSON["Confidence level 1"], 10);
          } else if (evidenceJSON.programInfo.progressCheckCompetenceID === "M7EPWG2P6KW3G") {
            area1Data[index] = parseInt(evidenceJSON["Confidence level 1"], 10);
            area2Data[index] = parseInt(evidenceJSON["Confidence level 2"], 10);
          } else {
            area1Data[index] = parseInt(evidenceJSON["Confidence level 1"], 10);
            area2Data[index] = parseInt(evidenceJSON["Confidence level 2"], 10);
          }
        }
      }

      return {
        area1: {
          areaName: prePreceptorEvidenceJSON["Area of practice 1 - pre-preceptorship"],
          data: area1Data,
        },
        area2: {
          areaName: prePreceptorEvidenceJSON["Area of practice 2 - pre-preceptorship"],
          data: area2Data,
        },
        general: {
          areaName: "General",
          data: generalData,
        },
      };
    }

    return null;
  } else if (programID === "LNA4FTCKB012M") {
    // SWASFT Preceptorship NQP1
    const prePreceptorEvidenceID = "LNA36J9UUQHQ0";

    const prePreceptorEvidence = confidenceLevelEvidence.find((evidence: IEvidence) => {
      const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
      const progressCheckCompetenceID = evidenceJSON?.programInfo?.progressCheckCompetenceID || "";

      const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

      return progressCheckCompetenceID === prePreceptorEvidenceID && onHoldReset !== 1 && evidence.draft !== true;
    });

    if (prePreceptorEvidence) {
      const prePreceptorEvidenceJSON =
        prePreceptorEvidence.evidenceJSON && JSON.parse(prePreceptorEvidence.evidenceJSON);

      let area1Data = [0, 0, 0, 0];
      let area2Data = [0, 0, 0, 0];
      let generalData = [0, 0, 0, 0];

      for (let i = 0; i < confidenceLevelEvidence.length; i++) {
        const evidence = confidenceLevelEvidence[i];
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);

        const onHoldReset = evidenceJSON.OnHoldReset ?? 0;

        if (onHoldReset === 1 || evidence.draft === true) {
          continue;
        }

        const index = getConfidenceLevelProgressCheckIndex(evidenceJSON.programInfo?.progressCheckID);

        if (index > -1) {
          if (evidenceJSON.programInfo?.progressCheckCompetenceID === "LNA36J9UUQHQ1") {
            generalData[index] = parseInt(evidenceJSON["Confidence level 1"]);
          } else if (evidenceJSON.programInfo?.progressCheckCompetenceID === "LNA37J9UUQHQ1") {
            area1Data[index] = parseInt(evidenceJSON["End of Preceptorship confidence levels 1"]);
            area2Data[index] = parseInt(evidenceJSON["End of Preceptorship confidence levels 2"]);
          } else {
            area1Data[index] = parseInt(evidenceJSON["Confidence level 1"]);
            area2Data[index] = parseInt(evidenceJSON["Confidence level 2"]);
          }
        }
      }

      return {
        area1: {
          areaName: prePreceptorEvidenceJSON["Area of practice 1 - pre-preceptorship"],
          data: area1Data,
        },
        area2: {
          areaName: prePreceptorEvidenceJSON["Area of practice 2 - pre-preceptorship"],
          data: area2Data,
        },
        general: {
          areaName: "General",
          data: generalData,
        },
      };
    }

    return null;
  }

  return null;
}

export function mapProgramDataVersionInfo(data: ProgramData[]): ProgramDataVersionInfo[] {
  let object = _.cloneDeep(data);

  return object.map((program) => ({
    Name: program.Name,
    Trust: program.Trust,
    ID: program.ID,
    version: program.version,
  }));
}

/**
 * Tries to find the user's ESR number from the subscription that corresponds to the Program Module passed in
 * @param program - The current Program Module
 * @param subscriptions - All of the user's Salesforce subscriptions
 * @returns The ESR number if it exists, otherwise undefined
 */
export function mapESRNumber(program: ProgramData, subscriptions: IClassSubscription[]): string | undefined {
  if (program && subscriptions) {
    const programSubscriptionIDs = program?.Subscription ?? [];

    const programSubscription: IClassSubscription | undefined = subscriptions?.find(
      (item: IClassSubscription | any) => {
        const subscriptionPrograms: string[] = item.ParaFolioPrograms;

        return (
          subscriptionPrograms?.some((_sub) => program?.Subscription?.includes(_sub)) ||
          programSubscriptionIDs.includes(`${item.SubscriptionID}`)
        );
      }
    );

    return programSubscription?.ESR ?? undefined;
  }

  return undefined;
}

/**
 * @param mentors
 * @returns A mapped string array of the mentors names
 */
export function mapPreceptorNames(mentors: Mentor[] | undefined, mentorTitle?: string): string[] | undefined {
  if (mentors) {
    return mentors.map((item) => {
      let role = item.role;

      if (role === "Mentor" && mentorTitle) {
        role = mentorTitle;
      }

      return `${item.name} - ${role}`;
    });
  }

  return undefined;
}

/**
 * @param regions
 * @returns A formatted string of the user's regions
 */
export function mapRegionNames(regions: string[] | undefined): string | undefined {
  if (regions) {
    return regions.join(", ");
  }

  return undefined;
}

/**
 * Checks whether the user has completed their Preceptorship to show congratulations card
 * @returns true if the Preceptorship is complete
 */
export function isProgramCompleteNQP(
  progressChecks: ProgressCheck[] | undefined,
  allProgressCheckData: AllProgressCheckStatuses[] | undefined | null,
  allEvidence: IEvidence[],
  programID: string | undefined
): boolean {
  if (!progressChecks || !allProgressCheckData || !programID) {
    return false;
  }

  const dataForProgram: AllProgressCheckStatuses | undefined = allProgressCheckData.find(
    (item: any): item is AllProgressCheckStatuses => item.programID === programID
  );

  for (let i = 0; i < progressChecks.length; i++) {
    const check = progressChecks[i];
    const progressCheckStatus = dataForProgram?.pCs.find((item) => item.pCId === check.ID);

    if (progressCheckStatus?.submissions.status === SubmissionState.Approved) {
      continue;
    }

    const progressCheckEvidence = getEvidenceForProgressCheck(allEvidence, check.ID);

    if (progressCheckEvidence?.length > 0) {
      const completed = isAllEvidenceAddedForProgressCheck(progressCheckEvidence, check["Competence/Activity"]);

      if (completed) {
        if (check.Approval === ProgressCheckApproval.NotRequired) {
          continue;
        } else if (progressCheckStatus?.submissions.status === SubmissionState.Submitted) {
          return false;
        } else {
          return false;
        }
      } else {
        if (
          [`${SequentiallyLocked.UnlockedIgnore}`, `${SequentiallyLocked.LockedIgnore}`].includes(
            check.SequentiallyLocked ?? ""
          )
        ) {
          continue;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  return true;
}

/**
 * Removes the progress check that the user has already added all required evidence for
 * @param progressChecks - The progress checks for the program module
 * @param competences - The progress check competences
 * @param program - The current program
 * @param programProgressCheckData - The array of progress check data for all programs
 * @returns The filtered progress check array
 */
export function removeProgressChecks(
  progressChecks: ProgressCheck[],
  allEvidence: IEvidence[],
  program: ProgramData | null,
  programProgressCheckData: AllProgressCheckStatuses[] | null,
  fastTrackActive: boolean,
  pauseAndHold: boolean,
  selectedProgressCheck?: ProgressCheck | null
): ProgressCheck[] {
  let result: ProgressCheck[] = [];

  const currentProgressCheckData =
    programProgressCheckData?.find((item: any): item is AllProgressCheckStatuses => item.programID === program?.ID) ??
    null;

  if (isMTS(program?.ID!) || isCHUB(program?.ID!)) {
    result = progressChecks?.filter((progressCheck: ProgressCheck) => {
      const evidenceForProgressCheck =
        allEvidence?.filter((evidence: IEvidence) => {
          const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
          const id = evidenceJSON?.programInfo?.progressCheckID || "";

          const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

          return progressCheck.ID === id && onHoldReset !== 1 && evidence.draft !== true;
        }) ?? [];

      return !isProgressCheckApprovedBasedOnCompTrigger(evidenceForProgressCheck, progressCheck["Competence/Activity"]);
    });

    // Remove locked progress checks - Either completed or approved
    result = result?.filter((progressCheck: ProgressCheck) => {
      const locked = checkIfProgressCheckIsLocked(
        progressCheck,
        progressChecks,
        allEvidence,
        currentProgressCheckData,
        program?.ID!,
        fastTrackActive,
        pauseAndHold
      );

      return !locked;
    });
    return result;
  } else {
    // Remove progress checks with all evidence added
    result = progressChecks?.filter((progressCheck: ProgressCheck) => {
      const progressCheckCompetenceIDs = progressCheck["Competence/Activity"].map((item) => item.ID).sort();

      const evidenceForProgressCheck =
        allEvidence?.filter((evidence: IEvidence) => {
          const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
          const id = evidenceJSON?.programInfo?.progressCheckID || "";

          const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

          return progressCheck.ID === id && onHoldReset !== 1 && evidence.draft !== true;
        }) ?? [];

      const competenceIDs = _.uniq(
        evidenceForProgressCheck
          .map((evidence: IEvidence) => {
            const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
            return evidenceJSON?.programInfo?.progressCheckCompetenceID;
          })
          .sort()
      );

      const isSelectedProgressCheck = progressCheck.ID === selectedProgressCheck?.ID;

      // console.log("isSelectedProgressCheck", isSelectedProgressCheck);
      return isSelectedProgressCheck || !_.isEqual(progressCheckCompetenceIDs, competenceIDs);
    });

    // Remove locked progress checks - Either completed or approved
    result = result?.filter((progressCheck: ProgressCheck) => {
      const locked = checkIfProgressCheckIsLocked(
        progressCheck,
        progressChecks,
        allEvidence,
        currentProgressCheckData,
        program?.ID!,
        fastTrackActive,
        pauseAndHold
      );

      const isSelectedProgressCheck = progressCheck.ID === selectedProgressCheck?.ID;

      return isSelectedProgressCheck || !locked;
    });

    result = result?.filter((progressCheck: ProgressCheck) => {
      let _competences = _.cloneDeep(progressCheck["Competence/Activity"]) ?? [];
      _competences = removeProgressCheckCompetences(_competences, progressCheck, allEvidence, program?.ID!, null);

      const isSelectedProgressCheck = progressCheck.ID === selectedProgressCheck?.ID;

      return isSelectedProgressCheck || _competences.length > 0;
    });

    return result;
  }
}

/**
 * Removes the progress check competences that the user has already added evidence for
 * @param competences - The progress check competences
 * @param progressCheckID - The ID of the current progress check
 * @param allEvidence - All of the user's evidence
 * @returns The filtered competence array
 */
export function removeProgressCheckCompetences(
  competences: CompetenceActivityClass[],
  progressCheck: ProgressCheck,
  allEvidence: IEvidence[],
  programId: string,
  selectedProgressCheckCompetence: CompetenceActivityClass | null
): CompetenceActivityClass[] {
  let result: CompetenceActivityClass[] = [];

  const evidenceForProgressCheck =
    allEvidence?.filter((evidence: IEvidence) => {
      const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
      const id = evidenceJSON?.programInfo?.progressCheckID ?? "";

      const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

      return progressCheck.ID === id && onHoldReset !== 1 && evidence.draft !== true;
    }) ?? [];

  const mappedCompetenceCheckIDs = evidenceForProgressCheck?.map((evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    const id = evidenceJSON?.programInfo?.progressCheckCompetenceID || "";

    return id;
  });

  result = competences?.filter((competence: CompetenceActivityClass) => {
    if (canHandleMultiplePiecesOfEvidencePerEvidenceType(programId)) {
      if (selectedProgressCheckCompetence && selectedProgressCheckCompetence.ID === competence.ID) return true;
      if (competence.isFinalReview && !isCSM(programId)) {
        const otherCompsDone = competences
          .filter((comp) => !comp.isFinalReview && comp["Number Evidences required"])
          .every((c) => {
            const evidenceStatus = EvidenceUtils.hasReachedEvidenceCount(evidenceForProgressCheck, c);
            const learningOutcomesStatus = EvidenceUtils.hasLearningOutcomes(evidenceForProgressCheck, c);
            // TODO replace with Airtable field
            if (progressCheck.ID === "LUK23YT16I5Q2" || progressCheck.ID === "LW99UWW8FT3WY") {
              if (
                (evidenceStatus === "COMPLETE" && learningOutcomesStatus === "COMPLETE_REQUIRED") ||
                (evidenceStatus === "COMPLETE" && learningOutcomesStatus === "COMPLETE_ALL") ||
                (evidenceStatus === "COMPLETE" && !c["Learning Objective"]?.length)
              )
                return true;
            } else {
              if (evidenceStatus === "COMPLETE") return true;
            }
            return false;
          });

        return otherCompsDone;
      }
      if (competence.isLockedByRequiredEvidence) {
        const otherCompsDone = competences
          .filter((comp) => !comp.isLockedByRequiredEvidence)
          .every((c) => {
            const evidenceStatus = EvidenceUtils.hasReachedEvidenceCount(evidenceForProgressCheck, c);

            if (evidenceStatus === "COMPLETE") return true;

            return false;
          });

        return otherCompsDone;
      }
      if (competence.FilledInBy === FilledInBy.Mentor) {
        return false;
      }

      const multipleAllowed = competence.MultipleAllowed;
      const requiredNumber = competence["Number Evidences required"] ?? 0;
      const evidenceForComp = evidenceForProgressCheck.filter((e) => {
        const evidenceJSON = e.evidenceJSON && JSON.parse(e.evidenceJSON);
        const id = evidenceJSON?.programInfo?.progressCheckCompetenceID || "";
        return id === competence.ID;
      });
      let includeComp = false;
      if (multipleAllowed) {
        includeComp = true;
      } else {
        if (requiredNumber === 0) {
          includeComp = evidenceForComp.length < 1;
        } else {
          includeComp = evidenceForComp.length < requiredNumber;
        }
      }

      return includeComp;
    } else {
      const wellbeingCompetenceIncluded = !mappedCompetenceCheckIDs?.includes(competence.ID);
      const isCurrentCompetence = competence.ID === selectedProgressCheckCompetence?.ID;

      return isCurrentCompetence || wellbeingCompetenceIncluded;
    }
  });

  result = result.filter((competence: CompetenceActivityClass) => !competence.isWellbeingActivity);

  return result;
}

/**
 * Checks whether user details saved are for the NWAS IC program module.
 * @param programID - The current program ID
 * @returns
 */
export function saveUserDetailsForEPRRProgram(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}

/**
 * Checks if the usage data should be calculated based on a competency and matching evidence
 * @param programID - The current program ID
 * @returns
 */
export function shouldCalculateUsageData(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}

/**
 * Checks whether the read evidence view should get the progress check information
 * @param programID - The current program ID
 * @returns
 */
export function shouldGetProgressCheckInfo(programID: string): boolean {
  return [
    "LNA4FTCKB012M",
    "LNA4FTCKB005M",
    "LNA4FTCKB003M",
    "LNA4FTCKB004M",
    "LZCUV68I1O975",
    "M167IR37RNSSQ",
    "LQGK18YODQ6D2",
    "M7EPWBZE8P2XK", // YAS NQP
  ].includes(programID);
}
/**
 * Checks whether the input date for supporting evidence should be mandatory.
 * @param programID - The current program ID
 * @returns
 */
export function shouldInputDateOnSupportingEvidenceBeMandatory(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}

/**
 * Checks whether the "BETA" label should be shown on the program card.
 * @param programID - The current program ID
 * @returns
 */
export function showBetaLabelForProgramCard(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "LNA4FTCKB012M", "LNA4FTCKB005M"].includes(programID);
}

/**
 * Checks whether the certificate inputs should be shown after (or before) role selection.
 * @param programID - The current program ID
 * @returns
 */
export function showCertsAfterRoleSelection(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}

export function showConfidenceLevelGraph(programID: string): boolean {
  return [
    "LNA4FTCKB012M", // SWASFT NQP1
    "M167IR37RNSSQ", // NEAS NQP
    "M7EPWBZE8P2XK", // YAS NQP
  ].includes(programID);
}

/**
 * Checks whether the certificate description should be shown under the certificate input
 * @param programID - The current program ID
 * @returns
 */
export function showDescriptionForCertificate(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}

export function showDeclarationOfObligationsTickbox(programID: string): boolean {
  return ["M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}

/**
 * Checks whether the evidence block should be displayed
 * @param programID - The current program ID
 * @param user - The user object
 * @returns true if the Program Module should have the evidence list displayed in the Program Home page
 */
export function showEvidenceBlock(programID: string, user: any): boolean {
  if (user) {
    if (isNwasEPRR(programID) && !isNwasEprrDetailsSet(user)) return false;
    if (isNeasEPRR(programID) && !isNeasEprrDetailsSet(user)) return false;
    if (isTestEPRR(programID) && !isTestEprrDetailsSet(user)) return false;
    if (isMTS(programID) && !isMtsDetailsSet(user)) return false;
    if (isCHUB(programID) && !isChubDetailsSet(user)) return false;
    if (isCSM(programID) && !isCsmDetailsSet(user)) return false;
  }
  return [
    "LNLT7JSYDT5PJ",
    "LNA4FTCKB012M",
    "LR0HYZYWY6JQK",
    "LNA4FTCKB005M",
    "LNA4FTCKB004M",
    "LNA4FTCKB003M",
    "M167IR37RNSSQ",
    "LZCUV68I1O975",
    "LQGK18YODQ6D2",
    "M47YSAO1MFX4B", // NEAS EPRR
    "M7EPWBZE8P2XK", // YAS NQP
    "M7N9K0Y7IOUMI", // TEST EPRR
  ].includes(programID);
}

/**
 * Checks whether the evidence count should be displayed
 * @param programID - The current program ID
 * @returns
 */
export function showEvidenceCount(programID: string): boolean {
  return ["LNLT7JSYDT5PJ"].includes(programID);
}

/**
 * Checks whether optional standards should be shown for a program in the program home page.
 * @param programID - The current program ID
 * @returns
 */
export function showMandatoryStandardsInProgramHomePage(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}

export function showNQPLearningObjectives(programID: string): boolean {
  return [
    "M167IR37RNSSQ", // NEAS NQP
    "LZCUV68I1O975", // NWAS NQP
    "M7EPWBZE8P2XK", // YAS NQP
  ].includes(programID);
}

/**
 * Checks whether optional standards should be shown for a program in the program details page.
 * @param programID - The current program ID
 * @returns
 */
export function showOptionalStandardsInDetailsPage(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}

/**
 * Checks whether the details card should be displayed
 * @param programID - The current program ID
 * @returns
 */
export function showPreceptorshipDetails(programID: string): boolean {
  return [
    "LNA4FTCKB012M",
    "LNA4FTCKB005M",
    "M167IR37RNSSQ",
    "LZCUV68I1O975",
    "M7EPWBZE8P2XK", // YAS NQP
  ].includes(programID);
}

/**
 * Checks whether areas should be shown for a program.
 * @param programID - The current program ID
 * @returns
 */
export function showProgramAreas(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}

/**
 * Checks whether the user's program certificates should be displayed
 * @param programID - The current program ID
 * @returns
 */
export function showProgramCertificates(programID: string): boolean {
  return ["LNLT7JSYDT5PJ"].includes(programID);
}

/**
 * Checks whether a trust label should be shown on the DetailsContentCollapsible component.
 * @param programID - The current program ID
 * @returns
 */
export function showTrustLabelForDetailsCollapsible(programID: string): boolean {
  return [
    "LR0HYZYWY6JQK",
    "LNA4FTCKB003M",
    "LNA4FTCKB004M",
    "LQGK18YODQ6D2",
    "M47YSAO1MFX4B",
    "M7N9K0Y7IOUMI",
  ].includes(programID);
}

/**
 * Checks whether the progress for the Progress Checks should be displayed on the Dashboard Card
 * @param programID - The current program ID
 * @returns
 */
export function showProgressCheckProgress(programID: string): boolean {
  return ["LNA4FTCKB012M", "LNA4FTCKB005M", "LNA4FTCKB003M"].includes(programID);
}

export function showProgressCheckProgressOnDashboard(programID: string): boolean {
  return [
    "LNA4FTCKB012M",
    "LNA4FTCKB005M",
    "M167IR37RNSSQ",
    "LZCUV68I1O975",
    "M7EPWBZE8P2XK", // YAS NQP
  ].includes(programID);
}

/**
 * Checks whether the standards label should be shown on the ProgramEvidenceCard component (e.g. for NWAS IC).
 * @param programID - The current program ID
 * @returns
 */
export function showStandardsLabelForEvidenceCard(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}

export function showPauseInfo(programModuleStatus: ProgramModuleStatus | undefined): boolean {
  switch (programModuleStatus?.data.Pause) {
    case "Pause":
    case "PauseAndHold":
      return true;
    default:
      return false;
  }
}

export function showFastTrackInfo(programModuleStaus: ProgramModuleStatus | undefined): boolean {
  return programModuleStaus?.data.FastTrack === "Enabled";
}

/**
 * Checks whether the Program Home page should display the old Details card (SWAST HART EPRR) or the new Details card (NWAS EPRR)
 * @param programID
 * @returns
 */
export function useDetailsCardInsteadOfProgramInfoCard(programID: string) {
  return [
    "LR0HYZYWY6JQK",
    "LNA4FTCKB003M",
    "LNA4FTCKB004M",
    "LQGK18YODQ6D2",
    "M47YSAO1MFX4B",
    "M7N9K0Y7IOUMI",
  ].includes(programID);
}

/**
 * Checks whether the details component should use radio buttons instead of checkboxes for selecting roles.
 * @param programID - The current program ID
 * @returns
 */
export function useRadioRoleSelector(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}

/**
 * Checks whether the certificate input components should use names as the labels.
 * @param programID - The current program ID
 * @returns
 */
export function useNameAsCertLabel(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}

export function showProgressCheckEvidenceCount(progressCheckID: string): boolean {
  return ["LNA4GMRKB061M", "LNA4GMRKB071M"].includes(progressCheckID);
}

export function getMtsRole(user: IUser, programID: string): string {
  const role = user.programRoles?.find((role) => role.programID === programID);
  if (role && role.programRoles.length) {
    return role.programRoles[0];
  }

  return "unset";
}

export function getMtsTeam(user: IUser, programID: string): string {
  const role = user.programRoles?.find((role) => role.programID === programID);
  if (role && role?.team) {
    return role.team;
  }

  return "unset";
}

/**
 * Before NWAS EPRR was introduced, programRoles in the IUserProgramRoles interface was just a string[].
 * Now it is a more descriptive array of objects, so this function checks if a particular program is
 * using the old format, or the new format.
 * @param programID - The current program ID
 * @returns
 */
export function usingDescriptiveProgramRolesFormat(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}

/**
 * Checks whether the program is a LAS TT program module (MTS, CHUB, or CSM)
 * @param programID - The current program ID
 * @returns
 */
export function isTelephoneTriageModule(programID: string): boolean {
  return ["LNA4FTCKB003M", "LNA4FTCKB004M", "LQGK18YODQ6D2"].includes(programID);
}

/**
 * Checks whether the program is LAS MTS
 * @param programID - The current program ID
 * @returns
 */
export function isMTS(programID: string): boolean {
  return ["LNA4FTCKB003M"].includes(programID);
}

/**
 * Checks whether the program is LAS CHUB
 * @param programID - The current program ID
 * @returns
 */
export function isCHUB(programID: string): boolean {
  return ["LNA4FTCKB004M"].includes(programID);
}

/**
 * Checks whether the program is LAS CSM
 * @param programID - The current program ID
 * @returns
 */
export function isCSM(programID: string): boolean {
  return ["LQGK18YODQ6D2"].includes(programID);
}

/**
 * TODO rename this func, it's not great
 * Checks the enabling the confirm button requires special/different validation. Specifically used by the NWAS IC program module.
 * @param programID - The current program ID
 * @returns
 */
export function validateDetails(programID: string): boolean {
  return ["LR0HYZYWY6JQK", "LNA4FTCKB003M", "M47YSAO1MFX4B", "M7N9K0Y7IOUMI"].includes(programID);
}
