import { useRecoilValueLoadable } from "recoil";
import { evidenceAtom } from "../../state/State";
import { IEvidence, ProgramData, ProgressCheck } from "../../Interfaces";
import { useProgramData } from "../../hooks/data/useProgramData";
import { subYears } from "date-fns";
import SegmentedProgressBar from "../common/SegmentedProgressBar";
import * as EvidenceUtils from "../../utils/evidenceUtils";

const ONGOING_DEVELOPMENT_PROGRESS_CHECK_ID = "LUK25EYB6DL4A";

function ChubOverview({ program }: { program: ProgramData }) {
  const evidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);

  const { userPrograms } = useProgramData();

  const programEvidence: IEvidence[] = getProgramEvidence();
  const draftEvidenceCount = getDraftCount();
  const progressChecksMapped = buildProgressCheckMap();

  function getProgramEvidence(): IEvidence[] {
    if (!evidence) return [];

    const result =
      evidence.contents?.filter((evidence: IEvidence) => {
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
        const programName = evidenceJSON?.programInfo?.program || "";

        const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

        return (
          programName === program.Name &&
          onHoldReset !== 1 &&
          evidence.draft !== true &&
          new Date(evidence.date) >= subYears(new Date(), 2)
        );
      }) ?? [];

    return result;
  }

  function buildProgressCheckMap(): { id: string; title: string; numerator: number; denominator: number }[] {
    const result: { id: string; title: string; numerator: number; denominator: number }[] = [];
    program.ProgressChecks?.map((pc, i) => {
      const id = pc.ID;
      let title = "";
      let numerator = 0;
      let denominator = 0;
      if (pc.ID === ONGOING_DEVELOPMENT_PROGRESS_CHECK_ID) {
        title = "Ongoing evidence this month";
        numerator = getCompsDoneForOngoingDevelopmentPC();
        denominator = 2;
      } else {
        title = `Mandatory ${pc.Name} competencies completed`;
        numerator = getCompsDoneForRegularPCs(pc);
        denominator = pc["Competence/Activity"].filter((comp) => comp["Number Evidences required"]).length;
      }

      result.push({ id, title, numerator, denominator });
    });

    return result;
  }

  function getDraftCount(): number {
    if (!evidence) return 0;

    const result: any[] =
      evidence.contents?.filter((evidence: IEvidence) => {
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
        const programName = evidenceJSON?.programInfo?.program || "";

        const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

        return (
          programName === program.Name &&
          onHoldReset !== 1 &&
          evidence.draft === true &&
          new Date(evidence.date) >= subYears(new Date(), 2)
        );
      }) ?? [];

    return result.length;
  }

  function getCompsDoneForOngoingDevelopmentPC() {
    const groupedEvidenceForOngoingDevelopment = programEvidence
      .filter((e) => {
        const parsedEvidence = JSON.parse(e.evidenceJSON);
        const peerReviewLoFound = parsedEvidence?.LearningOutcomes?.find((lo: any) => lo.ID === "LX4VATNYDQGT7");
        return (
          !parsedEvidence.OnHoldReset &&
          !e.draft &&
          (parsedEvidence.programInfo?.progressCheckCompetenceID == "LWAGQ8UP7YU3L" || peerReviewLoFound)
        );
      })
      .reduce((acc: { [key: string]: IEvidence[] }, current) => {
        const month = new Date(current.date).toLocaleString("default", { month: "long" });
        if (!acc[month]) {
          acc[month] = [];
        }
        acc[month].push(current);
        return acc;
      }, {});
    const currentMonth = new Date().toLocaleString("default", { month: "long" });
    const ongoingDevelopmentForThisMonth = groupedEvidenceForOngoingDevelopment[currentMonth];

    return ongoingDevelopmentForThisMonth?.length ?? 0;
  }

  function getCompsDoneForRegularPCs(pc: ProgressCheck) {
    let compsDone = 0;
    pc["Competence/Activity"].forEach((comp) => {
      const evidenceStatus = EvidenceUtils.hasReachedEvidenceCount(programEvidence, comp);
      const learningOutcomesStatus = EvidenceUtils.hasLearningOutcomes(programEvidence, comp);
      if (
        comp["Number Evidences required"] &&
        evidenceStatus === "COMPLETE" &&
        (learningOutcomesStatus === "COMPLETE_ALL" ||
          learningOutcomesStatus === "COMPLETE_REQUIRED" ||
          !comp["Learning Objective"]?.length)
      ) {
        compsDone += 1;
      }
    });

    return compsDone;
  }

  return (
    <div>
      {progressChecksMapped.map((item, i) => {
        return (
          <div className="mt-4" key={i}>
            {/* <SegmentedProgressBar title={item.title} numerator={item.numerator} denominator={item.denominator} /> */}
            {item.id === ONGOING_DEVELOPMENT_PROGRESS_CHECK_ID ? (
              <SegmentedProgressBar title={item.title} numerator={item.numerator} denominator={item.denominator} />
            ) : item.numerator < item.denominator ? (
              <SegmentedProgressBar title={item.title} numerator={item.numerator} denominator={item.denominator} />
            ) : (
              <div className={`text-grey-90 text-acc-13px`}>{item.title}</div>
            )}
          </div>
        );
      })}
      <div className="flex mt-4">
        {progressChecksMapped
          .filter((pc) => pc.id !== ONGOING_DEVELOPMENT_PROGRESS_CHECK_ID)
          .some((pc) => pc.numerator < pc.denominator) && (
          <div className="flex-1 flex flex-col justify-between">
            <div className="text-grey-90 text-acc-13px font-normal mb-2">Evidence added</div>
            <div className="text-program-card-eprr-text text-acc-28px font-bold">
              {programEvidence ? programEvidence.length : 0}
            </div>
          </div>
        )}
        <div className="flex-1 flex flex-col justify-between items-start">
          <div className="mb-2 rounded-xl bg-draft-badge text-draft-badge-text px-2 py-1 text-acc-12px font-semibold">
            Draft
          </div>
          <div className="text-program-card-eprr-text text-acc-28px font-bold">{draftEvidenceCount}</div>
        </div>
      </div>
    </div>
  );
}

export default ChubOverview;
