import { useEffect } from "react";
import DataController from "../../controllers/DataController";
import { FirebaseService } from "../../controllers/FirebaseService";

export const useFirebaseSetup = () => {
  useEffect(() => {
    const firebaseSetup = async (): Promise<void> => {
      if (await DataController.isWebAsync()) {
        await FirebaseService.initialize();
      }
    };

    firebaseSetup();
  }, []);
};
