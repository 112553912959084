import React, { useEffect, useState } from "react";
import { IonContent, IonModal, IonSpinner } from "@ionic/react";
import { IDeleteWellbeingActivityModal } from "../../types/Components";

const DeleteWellbeingActivityModal: React.FC<IDeleteWellbeingActivityModal> = (props) => {
  return (
    <IonModal
      id="delete-wellbeing-activity-modal"
      isOpen={props.visible}
      backdropDismiss={false}
      canDismiss={true}
      className="fullscreen"
    >
      <IonContent>
        <div className="flex flex-col justify-between h-full">
          <div className="mt-[16px] px-[16px]">
            <div className="text-[20px] font-bold leading-default tracking-[-0.1px] text-center text-grey-90">
              {"Are you sure you want to delete this wellbeing activity?"}
            </div>
            <div className="text-[15px] leading-[1.33] tracking-[-0.3px] text-center text-grey-90 mt-[8px]">
              {"If you delete this wellbeing activity, all the content you've added will be lost"}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="border-t border-t-grey-30">
              <button disabled={props.deleting} className="h-[48px] w-full" onClick={props.deletePressed}>
                {props.deleting ? (
                  <div className="flex justify-center items-center">
                    <IonSpinner class="w-[20px] h-[20px] text-cta-red" />
                  </div>
                ) : (
                  <div className="text-[16px] font-semibold leading-default tracking-default text-center text-cta-red">
                    {"Delete activity"}
                  </div>
                )}
              </button>
            </div>
            <div className="border-t border-t-grey-30">
              <button className="h-[48px] w-full" onClick={props.cancelPressed}>
                <div className="text-[16px] leading-default tracking-default text-center text-cta-blue">{"Cancel"}</div>
              </button>
            </div>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default DeleteWellbeingActivityModal;
