import { IonImg, IonItem } from "@ionic/react";
import { Button_Disclosure_Right_Grey } from "../../assets/images";

type Props = {
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
  subTitleIsError?: boolean;
  style?: React.CSSProperties;
  sideButton?: boolean;
  sideButtonText?: string;
  sidePill?: boolean;
  sidePillText?: string;
  topPill?: boolean;
  topPillText?: string;
  buttonClicked?: () => void;
};

const ProgramInfoCardButton: React.FC<Props> = (props) => {
  return (
    <div className="flex justify-center mb-2">
      <IonItem
        button
        detail={false}
        onClick={props.buttonClicked}
        className="program-info-card-button relative max-w-app-column w-full bg-white"
      >
        <div className="flex flex-row items-center justify-between w-full pl-safe-area-l pr-safe-area-r">
          <div className=" py-4">
            <div className="" style={props.style}>
              <div className="dashboardHeader">
                <div className="text-acc-17px font-extrabold tracking-default leading-acc-lh text-grey-80">
                  {props.title}
                </div>
              </div>
              <div className="programCardContent">{props.children}</div>
            </div>
          </div>
          <IonImg
            className="w-24px h-24px min-w-[24px] min-h-[24px] mr-[-5px] flex flex-[-1]"
            src={Button_Disclosure_Right_Grey}
          />
        </div>
      </IonItem>
    </div>
  );
};

export default ProgramInfoCardButton;
