import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import DataController from "../../controllers/DataController";
import { Capacitor } from "@capacitor/core";
import { EvidenceContainer, HeaderTitle } from "parafolio-components";
import { useHelpCentre } from "../../hooks/app/useHelpCentre";
import _ from "lodash";
import { Button_Disclosure_Right } from "../../assets/images";
import { HelpCentreContent, IUser } from "../../Interfaces";
import { useHistory } from "react-router";
import { FirebaseService } from "../../controllers/FirebaseService";
import { useCallback } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { DeviceInfo } from "@capacitor/device";
import { AppInfo } from "@capacitor/app";
import { appInfoAtom, deviceInfoAtom, userAtom } from "../../state/State";
import { useHidingTabBar } from "../../hooks/tabs/useHidingTabBar";

const HelpCentre: React.FC = (props) => {
  const history = useHistory();

  const { helpCentreContent } = useHelpCentre();
  useHidingTabBar();

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const deviceInfo = useRecoilValue<DeviceInfo | null>(deviceInfoAtom);
  const appInfo = useRecoilValue<AppInfo | null>(appInfoAtom);

  const sections = _.uniq(helpCentreContent.filter((item) => item.Enabled && item.Section).map((item) => item.Section));

  const topSection = helpCentreContent.find((item) => item.Section === "TOP");
  const bottomSection = helpCentreContent.find((item) => item.Section === "TAIL");

  const buttonClicked = (subSection: HelpCentreContent) => {
    history.push(`/dashboard/help-centre/${subSection.ID}`);
  };

  const sendSupportEmail = useCallback((): void => {
    if (user.state === "hasValue" && user.contents) {
      const url = "mailto:apps@class.co.uk";
      const subject = "ParaFolio - Help Centre Support";

      const body = DataController.supportEmailBody(user.contents.userData, deviceInfo, appInfo);

      window.open(`${url}?subject=${subject}&body=${body}`, "_blank");
    }
  }, []);

  const contactSupport = async (): Promise<void> => {
    await FirebaseService.logEvent("support_link_pressed", {
      link: "contact_support",
      type: "help_centre",
    });

    sendSupportEmail();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref="/dashboard"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{"Help Centre"}</HeaderTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background">
        <EvidenceContainer style={{ paddingTop: 0 }}>
          {topSection && (
            <div className="flex justify-center">
              <div className="relative max-w-app-column w-full py-4 bg-white pl-safe-area-l pr-safe-area-r">
                <div className="text-acc-20px font-acc-700 text-grey-90 mb-3">{topSection.Title}</div>
                <div
                  className="text-acc-16px tracking-default text-grey-90 leading-default"
                  dangerouslySetInnerHTML={{ __html: topSection.HTML }}
                />
              </div>
            </div>
          )}
          {sections.map((section, sectionIndex) => {
            const subSections = helpCentreContent.filter((item) => item.Section === section);

            return (
              <div key={section} className="flex justify-center self-center max-w-app-column w-full flex-col">
                <div className="relative pl-safe-area-l pr-safe-area-r">
                  <div className="text-acc-13px font-bold text-grey-70 py-1">{section.toUpperCase()}</div>
                </div>
                <div className="w-full max-w-app-column bg-white">
                  {subSections.map((subSection, subSectionIndex) => {
                    const hideSeparator =
                      subSectionIndex === subSections.length - 1 && sectionIndex !== sections.length - 1;

                    return (
                      <IonItem
                        button
                        className={`help-centre-button flex ${hideSeparator && "help-centre-button-no-border"}`}
                        detail={false}
                        onClick={() => buttonClicked(subSection)}
                        key={subSection.ID}
                      >
                        <div className="min-h-[50px] flex-1 pl-4 flex items-center justify-between">
                          <div className="text-acc-16px font-acc-600 leading-acc-lh tracking-default text-grey-90 pointer-events-none acc-underline">
                            {subSection.Title}
                          </div>
                          <IonImg src={Button_Disclosure_Right} className="h-11 w-11" />
                        </div>
                      </IonItem>
                    );
                  })}
                </div>
              </div>
            );
          })}
          {bottomSection && (
            <div className="flex justify-center">
              <div className="flex flex-col relative max-w-app-column w-full py-4 bg-white mb-2 pl-safe-area-l pr-safe-area-r">
                <div
                  className="text-acc-16px font-acc-600 tracking-default text-grey-90 leading-default mt-4 tailwind-anchor"
                  dangerouslySetInnerHTML={{ __html: bottomSection.HTML }}
                />
                <IonButton
                  mode="ios"
                  className="grey-button w-full h-[50px] max-w-button self-center mt-3"
                  onClick={() => history.push("/dashboard/help-centre/contact-us", { from: "help-centre" })}
                >
                  <div className="text-16px font-semibold tracking-default text-center text-cta-blue acc-underline">
                    {"Contact support"}
                  </div>
                </IonButton>
              </div>
            </div>
          )}
        </EvidenceContainer>
      </IonContent>
    </IonPage>
  );
};

export default HelpCentre;
