import { Capacitor } from "@capacitor/core";
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/react";
import { EvidenceContainer, HeaderTitle } from "parafolio-components";
import DataController from "../../controllers/DataController";
import { useHelpCentre } from "../../hooks/app/useHelpCentre";
import { useParams } from "react-router";
import { useCallback, useEffect } from "react";
import { HelpCentreContent, IUser } from "../../Interfaces";
import HelpCentreVideo from "../../components/helpCentre/HelpCentreVideo";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { DeviceInfo } from "@capacitor/device";
import { AppInfo } from "@capacitor/app";
import { appInfoAtom, deviceInfoAtom, userAtom } from "../../state/State";
import { FirebaseService } from "../../controllers/FirebaseService";
import { useHidingTabBar } from "../../hooks/tabs/useHidingTabBar";

const HelpCentreDetails: React.FC = () => {
  const params = useParams<{ pageId: string }>();
  const { helpCentreContent } = useHelpCentre();
  useHidingTabBar(true);

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const deviceInfo = useRecoilValue<DeviceInfo | null>(deviceInfoAtom);
  const appInfo = useRecoilValue<AppInfo | null>(appInfoAtom);

  const section = helpCentreContent.find((item) => item.ID === params.pageId)!;

  useEffect(() => {
    const logPageView = async (): Promise<void> => {
      await FirebaseService.logEvent("help_centre_page_view", {
        page: section.Title,
      });
    };

    logPageView();
  }, []);

  const sendSupportEmail = useCallback((): void => {
    if (user.state === "hasValue" && user.contents) {
      const url = "mailto:apps@class.co.uk";
      const subject = "ParaFolio - Help Centre Support";

      const body = DataController.supportEmailBody(user.contents.userData, deviceInfo, appInfo);

      window.open(`${url}?subject=${subject}&body=${body}`, "_blank");
    }
  }, []);

  const contactSupport = async (): Promise<void> => {
    await FirebaseService.logEvent("support_link_pressed", {
      link: "contact_support",
      type: "help_centre",
    });

    sendSupportEmail();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref="/dashboard/help-centre"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{"Help Centre"}</HeaderTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background whiteBackground">
        <EvidenceContainer>
          <div className="flex justify-center">
            <div className="relative max-w-app-column w-full pl-safe-area-l pr-safe-area-r text-acc-16px">
              <div className="text-acc-20px font-acc-700 text-grey-90 mb-3">{section.Title}</div>
              <HelpCentreVideo section={section} />
              <div>
                <div
                  className="text-acc-16px [&>p]:!text-acc-16px [&>p]:my-4"
                  dangerouslySetInnerHTML={{ __html: section?.HTML ?? "" }}
                />
                <div className="mt-3">
                  {"Didn't get the answer you needed? "}
                  <button type="button" onClick={() => contactSupport()}>
                    <div className="text-cta-blue font-acc-500 underline">{`Contact Support`}</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </EvidenceContainer>
      </IonContent>
    </IonPage>
  );
};

export default HelpCentreDetails;
