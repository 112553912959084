import { IProgramModuleEvidencePart } from "../../types/Components";
import _ from "lodash";
import EvidenceContentCard from "../common/EvidenceContentCard";
import { EvidencePart, IEvidence, IUser, LinkedPartType } from "../../Interfaces";
import * as EvidenceUtils from "../../utils/evidenceUtils";
import * as ProgramUtils from "../../utils/programUtils";
import { CreateEvidencePart } from "../programs/evidenceParts/ComponentWrapper";
import { useRecoilValueLoadable } from "recoil";
import { evidenceAtom, userAtom } from "../../state/State";
import Collapsible from "react-collapsible";
import { useState } from "react";

const ProgramModuleEvidencePart: React.FC<IProgramModuleEvidencePart> = (props) => {
  const evidence = useRecoilValueLoadable<IEvidence[]>(evidenceAtom);
  const user = useRecoilValueLoadable<IUser>(userAtom);

  const [collapsibleOpen, setCollapsibleOpen] = useState<boolean>(
    EvidenceUtils.isLearningOutcomesList(props.evidencePart)
  );

  const handleCollapsible = (value: boolean): void => setCollapsibleOpen(value);

  const toggleCollapsible = (): void => setCollapsibleOpen(!collapsibleOpen);

  if (!EvidenceUtils.checkIfEvidencePartIsRequired(props.evidencePart, props.evidenceJSON)) {
    return null;
  }

  if (props.evidencePart["Linked Part type"] === LinkedPartType.Child && !props.isChildPart) {
    return null;
  }

  const linkedChildParts = EvidenceUtils.getLinkedChildParts(props.evidencePart);
  const linkedSiblingParts = EvidenceUtils.getLinkedSiblingParts(props.evidencePart);

  let primaryContent = null;

  let id = "";

  if (props.competence) {
    id = `${props.competence?.ID}-${props.evidencePart.ID}-${props.index}`;
  } else if (props.progressCheckCompetence) {
    id = `${props.progressCheckCompetence?.ID}-${props.evidencePart.ID}-${props.index}`;
  } else {
    id = `${props.evidencePart.ID}-${props.index}`;
  }

  if (EvidenceUtils.isBanner(props.evidencePart)) {
    primaryContent = (
      <>
        {CreateEvidencePart(props.evidencePart["Field type"], {
          definition: props.evidencePart,
        })}
      </>
    );
  } else if (
    EvidenceUtils.isNOSSelect(props.evidencePart) ||
    EvidenceUtils.isLearningOutcomesList(props.evidencePart)
  ) {
    primaryContent = (
      <>
        {CreateEvidencePart(props.evidencePart["Field type"], {
          definition: props.evidencePart,
          onValueChanged: props.onValueChanged,
          onInputBlurred: props.onInputBlurred,
          setExistingTagModalVisible: props.setExistingTagModalVisible,
          setEmptyTagModalVisible: props.setEmptyTagModalVisible,
          focusRichText: props.focusRichText,
          blurRichText: props.blurRichText,
          setCollapsibleOpen: handleCollapsible,
          toggleCollapsible: toggleCollapsible,
          collapsibleOpen: collapsibleOpen,
          isLocked: ProgramUtils.isFieldLocked(props.progressCheckCompetence?.ID, props.evidencePart.ID),
          initialValue: ProgramUtils.getEvidencePartInitialValue(
            props.evidencePart,
            props.evidenceJSON,
            evidence.contents,
            props.progressCheckCompetence
          ),
          id: id,
          program: props.program,
          user: user.contents,
          mandatoryStandards: props.mandatoryStandards,
          optionalStandards: props.optionalStandards,
          learningOutcomes: props.learningOutcomes,
        })}
      </>
    );
  } else if (
    EvidenceUtils.isCompMultiSelect(props.evidencePart) ||
    EvidenceUtils.isNQPCompMultiSelect(props.evidencePart)
  ) {
    if (props.learningOutcomes?.length == 0) {
      primaryContent = null;
    } else {
      primaryContent = (
        <>
          {CreateEvidencePart(props.evidencePart["Field type"], {
            definition: props.evidencePart,
            onValueChanged: props.onValueChanged,
            onInputBlurred: props.onInputBlurred,
            setExistingTagModalVisible: props.setExistingTagModalVisible,
            setEmptyTagModalVisible: props.setEmptyTagModalVisible,
            focusRichText: props.focusRichText,
            blurRichText: props.blurRichText,
            setCollapsibleOpen: handleCollapsible,
            toggleCollapsible: toggleCollapsible,
            collapsibleOpen: collapsibleOpen,
            isLocked: ProgramUtils.isFieldLocked(props.progressCheckCompetence?.ID, props.evidencePart.ID),
            initialValue: ProgramUtils.getEvidencePartInitialValue(
              props.evidencePart,
              props.evidenceJSON,
              evidence.contents,
              props.progressCheckCompetence
            ),
            id: id,
            program: props.program,
            user: user.contents,
            learningOutcomes: props.learningOutcomes ?? [],
            progressCheckCompetence: props.progressCheckCompetence,
            currentProgressCheckId: props.currentProgressCheckId,
          })}
        </>
      );
    }
  } else {
    primaryContent = (
      <EvidenceContentCard
        mandatory={props.evidencePart.options === "Mandatory" || props.evidencePart["option type"] === "Mandatory"}
        title={props.evidencePart["Display Title"] || props.evidencePart.Name}
      >
        {CreateEvidencePart(props.evidencePart["Field type"], {
          definition: props.evidencePart,
          onValueChanged: props.onValueChanged,
          onInputBlurred: props.onInputBlurred,
          setExistingTagModalVisible: props.setExistingTagModalVisible,
          setEmptyTagModalVisible: props.setEmptyTagModalVisible,
          focusRichText: props.focusRichText,
          blurRichText: props.blurRichText,
          setCollapsibleOpen: handleCollapsible,
          toggleCollapsible: toggleCollapsible,
          collapsibleOpen: collapsibleOpen,
          isLocked: ProgramUtils.isFieldLocked(props.progressCheckCompetence?.ID, props.evidencePart.ID),
          initialValue: ProgramUtils.getEvidencePartInitialValue(
            props.evidencePart,
            props.evidenceJSON,
            evidence.contents,
            props.progressCheckCompetence
          ),
          id: id,
          program: props.program,
          user: user.contents,
        })}
        {linkedChildParts && (
          <Collapsible
            trigger={<div />}
            triggerDisabled
            transitionTime={200}
            transitionCloseTime={200}
            open={collapsibleOpen}
          >
            <div className="mt-[12px]">
              {linkedChildParts.map((evidencePart: EvidencePart, index) => (
                <ProgramModuleEvidencePart
                  evidencePart={evidencePart}
                  blurRichText={() => props.blurRichText?.()}
                  focusRichText={() => props.focusRichText?.()}
                  evidenceJSON={props.evidenceJSON}
                  index={index}
                  onInputBlurred={() => props.onInputBlurred?.()}
                  onValueChanged={(value: any, isInput?: boolean) =>
                    props.onChildValueChanged && props.onChildValueChanged(evidencePart, value, isInput)
                  }
                  key={`${props.competence?.Name}-${evidencePart.Name}-${index}`}
                  program={props.program}
                  competence={props.competence}
                  progressCheckCompetence={props.progressCheckCompetence}
                  setExistingTagModalVisible={() => props.setExistingTagModalVisible?.()}
                  setEmptyTagModalVisible={() => props.setEmptyTagModalVisible?.()}
                  isChildPart
                />
              ))}
            </div>
          </Collapsible>
        )}
      </EvidenceContentCard>
    );
  }

  return (
    <>
      {primaryContent}
      {linkedSiblingParts.length > 0 &&
        linkedSiblingParts.map((evidencePart: EvidencePart, index) => {
          const triggers = evidencePart["Linked Part trigger"] ? evidencePart["Linked Part trigger"].split("\n") : [];
          const selectedValue = props.evidenceJSON[props.evidencePart.Name];
          if (!triggers.includes(selectedValue)) {
            return null;
          }
          return (
            <ProgramModuleEvidencePart
              evidencePart={evidencePart}
              blurRichText={() => props.blurRichText?.()}
              focusRichText={() => props.focusRichText?.()}
              evidenceJSON={props.evidenceJSON}
              index={index}
              onInputBlurred={() => props.onInputBlurred?.()}
              onValueChanged={(value: any, isInput?: boolean) =>
                props.onChildValueChanged && props.onChildValueChanged(evidencePart, value, isInput)
              }
              key={`${props.competence?.Name}-${evidencePart.Name}-${index}`}
              program={props.program}
              competence={props.competence}
              progressCheckCompetence={props.progressCheckCompetence}
              setExistingTagModalVisible={() => props.setExistingTagModalVisible?.()}
              setEmptyTagModalVisible={() => props.setEmptyTagModalVisible?.()}
            />
          );
        })}
    </>
  );
};

export default ProgramModuleEvidencePart;
