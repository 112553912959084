import { useRecoilValueLoadable } from "recoil";
import { accessibilityAtom } from "../../state/State";
import { IAccessibilitySettings } from "../../Interfaces";
import { useEffect } from "react";

export const useAccessibilitySettings = () => {
  const accessibilitySettings = useRecoilValueLoadable<IAccessibilitySettings | null>(accessibilityAtom);

  useEffect(() => {
    if (accessibilitySettings.contents?.fontFamily) {
      document.documentElement.style.setProperty("--ion-font-family", accessibilitySettings.contents.fontFamily);
    } else {
      document.documentElement.style.setProperty("--ion-font-family", "Inter");
    }

    if (accessibilitySettings.contents?.boldText) {
      document.documentElement.style.setProperty("--accessibility-bold-text", "bold");
      document.documentElement.style.setProperty("--accessibility-bold-text-400", "bold");
      document.documentElement.style.setProperty("--accessibility-bold-text-500", "bold");
      document.documentElement.style.setProperty("--accessibility-bold-text-600", "bold");
    } else {
      document.documentElement.style.setProperty("--accessibility-bold-text", "normal");
      document.documentElement.style.setProperty("--accessibility-bold-text-400", "400");
      document.documentElement.style.setProperty("--accessibility-bold-text-500", "500");
      document.documentElement.style.setProperty("--accessibility-bold-text-600", "600");
    }

    if (accessibilitySettings.contents?.underlineButtons) {
      document.documentElement.style.setProperty("--accessibility-underline-links", "underline");
    } else {
      document.documentElement.style.setProperty("--accessibility-underline-links", "initial");
    }

    if (accessibilitySettings.contents?.lineHeightChange) {
      document.documentElement.style.setProperty(
        "--accessibility-line-height",
        accessibilitySettings.contents.lineHeightChange
      );
    } else {
      document.documentElement.style.setProperty("--accessibility-line-height", "1.5");
    }

    if (accessibilitySettings.contents?.fontSizeChange || accessibilitySettings.contents?.fontSizeChange === 0) {
      document.documentElement.style.setProperty(
        "--accessibility-font-size",
        `${accessibilitySettings.contents.fontSizeChange}px`
      );
    } else {
      document.documentElement.style.setProperty("--accessibility-font-size", "0px");
    }
  }, [accessibilitySettings.contents]);
};
