import { environment } from "../environment/environment";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { FirebaseCrashlytics } from "@capacitor-community/firebase-crashlytics";
import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from "@capacitor/push-notifications";

export namespace FirebaseService {
  /**
   * Subscribes the user to the chosen notification topic
   * @param topicID - The topic to subscribe to
   */
  export async function addTopic(topicID: string): Promise<any> {
    try {
      await FCM.subscribeTo({ topic: topicID });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Unsubscribes the user to the chosen notification topic
   * @param topicID - The topic to unsubscribe from
   */
  export async function removeTopic(topicID: string): Promise<any> {
    try {
      await FCM.unsubscribeFrom({ topic: topicID });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Initialize Firebase Analytics for the app with Firebase config credentials
   */
  export async function initialize(): Promise<void> {
    try {
      await FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);

      console.log("[FirebaseService] Firebase initialized");
    } catch (error) {
      console.log(error);
      console.log("[FirebaseService] Failed to initialize Firebase");
    }
  }

  /**
   * Check if the user has accepted Push Notification permissions and prompt a request if they haven't been rejected before
   */
  export async function requestPermissions(): Promise<any> {
    try {
      let permissionStatus = await PushNotifications.checkPermissions();

      if (permissionStatus.receive === "prompt") {
        permissionStatus = await PushNotifications.requestPermissions();
      }

      if (permissionStatus.receive === "granted") {
        return;
      }

      await PushNotifications.register();
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Gets the FCM (Firebase Cloud Messaging) token for the user - Used to send debug notifications
   * @returns The FCM token if it exists
   */
  export async function getFCMToken(): Promise<{ token: string } | undefined> {
    try {
      const token = await FCM.getToken();

      return token;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Sets the chosen user property for the user https://firebase.google.com/docs/analytics/user-properties
   * @param property - The key of the user property to be set
   * @param value - The value of the user property for this user
   */
  export async function setUserProperty(property: string, value: string): Promise<void> {
    try {
      await FirebaseAnalytics.setUserProperty({
        name: property,
        value,
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Sets the user ID for the user for Crashlytics https://firebase.google.com/docs/analytics/userid
   * @param contactID - The user's Salesforce ContactID
   */
  export async function setCrashlyticsUserID(contactID: string): Promise<void> {
    try {
      await FirebaseCrashlytics.setUserId({
        userId: contactID,
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Sets the user ID for the user for Analytics https://firebase.google.com/docs/analytics/userid
   * @param contactID - The user's Salesforce ContactID
   */
  export async function setAnalyticsUserID(contactID: string): Promise<void> {
    try {
      await FirebaseAnalytics.setUserId({
        userId: contactID,
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Sets the screen name for the current view that will be displayed in the Analytics events view
   * @param screenName
   */
  export async function setScreenName(screenName: string): Promise<void> {
    try {
      await FirebaseAnalytics.setScreenName({
        screenName,
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Logs a custom event to Firebase Analytics
   * @param event
   * @param params
   */
  export async function logEvent(event: string, params: Object = {}): Promise<void> {
    try {
      await FirebaseAnalytics.logEvent({
        name: event,
        params,
      });
    } catch (error) {
      console.log(error);
    }
  }
}
