import { IonButton, IonButtons, IonContent, IonHeader, IonImg, IonModal, IonToolbar } from "@ionic/react";
import DataController from "../../controllers/DataController";
import { Capacitor } from "@capacitor/core";
import { useTextToSpeech } from "../../hooks/accessibility/useTextToSpeech";
import ContentContainer from "../common/ContentContainer";
import { useRecoilValue } from "recoil";
import { IAccessibilitySettings } from "../../Interfaces";
import { accessibilityAtom } from "../../state/State";
import { Button_Audio_Play_White, Button_Audio_Stop } from "../../assets/images";
import { HeaderTitle } from "parafolio-components";

type Props = {
  visible: boolean;
  closeModal: () => void;
};

const AIDictationInfoModal: React.FC<Props> = (props) => {
  const accessibilitySettings = useRecoilValue<IAccessibilitySettings | null>(accessibilityAtom);

  const { handleTTS, playing, stopTTS } = useTextToSpeech("ai-dictation-info-modal-container");

  return (
    <IonModal
      id="ai-dictation-info-modal"
      isOpen={props.visible}
      onIonModalWillDismiss={() => {
        stopTTS();
        props.closeModal();
      }}
      backdropDismiss={true}
      className="fullscreen"
    >
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonButton
              className="cancelButton"
              mode="ios"
              onClick={() => {
                stopTTS();
                props.closeModal();
              }}
            >
              {"Close"}
            </IonButton>
          </IonButtons>
          <HeaderTitle>
            <div className="flex flex-row justify-center items-center">
              <div>{"AI Dictation"}</div>
              <div className="text-acc-13px font-semibold text-black bg-white px-1 ml-2 rounded-sm">{"BETA"}</div>
            </div>
          </HeaderTitle>
          {accessibilitySettings?.textToSpeech && (
            <IonButtons slot="end">
              <IonButton className="header-button" mode="ios" onClick={() => handleTTS()}>
                <IonImg src={playing ? Button_Audio_Stop : Button_Audio_Play_White} className="headerIcon" />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background whiteBackground">
        <ContentContainer>
          <div className="infoModalContainer" id="ai-dictation-info-modal-container">
            <h1>{"AI Dictation Information"}</h1>
            <p>{"TBC"}</p>
          </div>
        </ContentContainer>
      </IonContent>
    </IonModal>
  );
};

export default AIDictationInfoModal;
