import classNames from "classnames";

type Props = {
  children: React.ReactNode;
  tabBarDisabled?: boolean;
  style?: React.CSSProperties;
  id?: string;
};

const Container: React.FC<Props> = (props) => {
  return (
    <div
      id={props.id}
      className={classNames("flex flex-col mx-auto max-w-app-column pb-[72px]", {
        "pb-no-tabs": props.tabBarDisabled,
      })}
      style={props.style}
    >
      <div className="ml-safe-area-l-none mr-safe-area-r-none">{props.children}</div>
    </div>
  );
};

export default Container;
