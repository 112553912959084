import { IonContent } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React, { useRef, useState } from "react";
import { Route } from "react-router";
import Tabs from "./Tabs";
import { ISubscriptionModalType } from "./Interfaces";
import OnboardingRoutes from "./OnboardingRoutes";
import SubscriptionsModal from "./components/profile/SubscriptionsModal";
import { EventRegister } from "react-native-event-listeners";
import { Capacitor } from "@capacitor/core";
import Menu from "./pages/menu/Menu";
import DraftSavedToast from "./components/evidence/DraftSavedToast";
import { useProgramData } from "./hooks/data/useProgramData";
import ProgramDataUpdateToast from "./components/common/ProgramDataUpdateToast";
import DeepLinkingListener from "./components/app/DeepLinkingListener";
import { useRefreshProgressCheckData } from "./hooks/data/useRefreshProgressCheckData";
import AppEventListener from "./components/app/AppEventListener";
import { useUserAccessCheck } from "./hooks/subscriptions/useUserAccessCheck";
import { useSubscriptionRenewal } from "./hooks/subscriptions/useSubscriptionRenewal";
import { useAccessibilitySettings } from "./hooks/accessibility/useAccessibilitySettings";
import { useLoggedIn } from "./hooks/auth/useLoggedIn";
import { useRefreshUserDetails } from "./hooks/data/useRefreshUserDetails";
import { useDeviceAppInfo } from "./hooks/app/useDeviceAppInfo";
import { useRefreshParapassData } from "./hooks/data/useRefreshParapassData";
import { useRefreshJRCALCPlusData } from "./hooks/data/useRefreshJRCALCPlusData";
import { useRefreshUserEvidence } from "./hooks/data/useRefreshUserEvidence";
import { useHelpCentre } from "./hooks/app/useHelpCentre";

const ParaFolio: React.FC = () => {
  const routerRef = useRef(null);

  useProgramData(true);
  useHelpCentre(true);
  useSubscriptionRenewal();
  useUserAccessCheck();
  useAccessibilitySettings();
  useDeviceAppInfo();

  useRefreshJRCALCPlusData(true);
  useRefreshParapassData(true);
  useRefreshUserDetails(true);
  useRefreshUserEvidence(true);
  useRefreshProgressCheckData(true);

  const loggedIn = useLoggedIn();

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ISubscriptionModalType>(ISubscriptionModalType.ONBOARDING);

  const continueWithTrial = (): void => {
    EventRegister.emit("subscription/continue");
    setModalVisible(false);
  };

  if (Capacitor.getPlatform() === "ios") {
    return (
      <>
        <SubscriptionsModal
          visible={modalVisible}
          closeModal={() => setModalVisible(false)}
          type={modalType}
          continueWithTrial={() => continueWithTrial()}
        />
        <IonReactRouter ref={routerRef}>
          <DeepLinkingListener />
          <AppEventListener setModalVisible={setModalVisible} setModalType={setModalType} />
          {loggedIn && <Menu />}
          <Route path="/" render={() => (loggedIn ? <Tabs /> : <OnboardingRoutes />)} />
        </IonReactRouter>
      </>
    );
  }

  return (
    <IonContent>
      <SubscriptionsModal
        visible={modalVisible}
        closeModal={() => setModalVisible(false)}
        type={modalType}
        continueWithTrial={() => continueWithTrial()}
      />
      <ProgramDataUpdateToast />
      <DraftSavedToast />
      <IonReactRouter ref={routerRef}>
        <DeepLinkingListener />
        <AppEventListener setModalVisible={setModalVisible} setModalType={setModalType} />
        {loggedIn && <Menu />}
        <Route path="/" render={() => (loggedIn ? <Tabs /> : <OnboardingRoutes />)} />
      </IonReactRouter>
    </IonContent>
  );
};

export default ParaFolio;
