import { Icon_Tick } from "../../assets/images";
import { IEvidence, LearningObjective } from "../../Interfaces";
import classNames from "classnames";
import { getOccurrencesOfLearningOutcome } from "../../utils/nqpUtils";

type Props = {
  evidence: IEvidence[];
  learningOutcomes: {
    [x: string]: Partial<Record<string, LearningObjective[]>>;
  }[];
};

const LearningOutcomesList: React.FC<Props> = (props) => {
  return (
    <div className="bg-white">
      {props.learningOutcomes.map((item) => {
        const topSectionKey = Object.keys(item)[0];
        const topSection = item[topSectionKey];

        let object = Object.keys(topSection).map((key) => topSection[key]);
        const sections: LearningObjective[][] = object.filter((section) => section !== undefined);

        return (
          <div key={topSectionKey}>
            <div className="text-acc-17px font-acc-600 tracking-default text-grey-90 bg-white px-4 py-2">
              {topSectionKey}
            </div>
            {sections.map((section) => {
              return (
                <div key={section[0].Section}>
                  {section.map((learningOutcome, index) => {
                    const count = getOccurrencesOfLearningOutcome(learningOutcome, props.evidence);
                    const numberRequired = learningOutcome.NumberRequired ?? 0;
                    const loAchieved = numberRequired > 0 && count >= numberRequired;

                    return (
                      <div key={learningOutcome.ID}>
                        {index === 0 && (
                          <div className="text-acc-12px font-acc-600 text-grey-90 bg-grey-00 px-4 py-1.5 border-y border-grey-30">
                            {learningOutcome.Section}
                          </div>
                        )}
                        <div
                          className={classNames(
                            "px-4 py-1.5 bg-grey-00 border-b border-grey-30 flex flex-row justify-between items-center",
                            {
                              "bg-white": index % 2 === 0,
                              "mb-4": index === section.length - 1,
                            }
                          )}
                        >
                          <div className="text-acc-12px font-acc-normal text-grey-90 tracking-[-0.1] mr-4">
                            {learningOutcome.Description}
                          </div>
                          <div className="flex flex-shrink-0">
                            <div className="w-6 h-6 mr-1.5">
                              {loAchieved && <img className="w-6 h-6 img-no-select" src={Icon_Tick} />}
                            </div>
                            <div>
                              <span
                                className={classNames("text-acc-15px font-acc-700 tracking-default", {
                                  "text-grey-50": !loAchieved,
                                  "text-green-light-text": loAchieved,
                                })}
                              >{`${count} / ${learningOutcome.NumberRequired ?? 0}`}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default LearningOutcomesList;
