import { useSetRecoilState } from "recoil";
import DataController from "../../controllers/DataController";
import { Device, DeviceInfo } from "@capacitor/device";
import { App, AppInfo } from "@capacitor/app";
import { appInfoAtom, deviceInfoAtom } from "../../state/State";
import { useEffect } from "react";

export const useDeviceAppInfo = () => {
  const setDeviceInfo = useSetRecoilState<DeviceInfo | null>(deviceInfoAtom);
  const setAppInfo = useSetRecoilState<AppInfo | null>(appInfoAtom);

  useEffect(() => {
    const getDeviceInfo = async (): Promise<void> => {
      try {
        const info = await Device.getInfo();

        setDeviceInfo(info);
      } catch (error) {
        console.log(error);
      }
    };

    const getAppInfo = async (): Promise<void> => {
      try {
        if (!(await DataController.isWebAsync())) {
          const info = await App.getInfo();

          setAppInfo(info);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getDeviceInfo();
    getAppInfo();
  }, [setAppInfo, setDeviceInfo]);
};
