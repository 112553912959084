import { IonButton, IonImg, IonSpinner } from "@ionic/react";
import { Button_Info_White, Icon_Disclosure_Down_White, Icon_Evidence_AI, Icon_Microphone } from "../../assets/images";
import classNames from "classnames";
import { useWindowResized } from "../../hooks/app/useWindowResized";
import { forwardRef, useEffect, useRef, useState } from "react";
import AIDictationBannerHeader from "./AIDictationBannerHeader";
import Collapsible from "react-collapsible";
import Textarea from "@mui/joy/Textarea";
import DataController from "../../controllers/DataController";

type Props = {
  dictationState: "ready" | "listening" | "error";
  processingState: "ready" | "processing" | "processed" | "error";
  microphoneButtonPressed: () => void;
  createButtonPressed: (input: string) => Promise<boolean>;
  infoButtonPressed: () => void;
  errorMessage?: string;
};

const AIDictationBanner = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { windowWidth } = useWindowResized();

  const [input, setInput] = useState<string>("");
  const [processing, setProcessing] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<boolean>(false);

  useEffect(() => {
    if (showInput) {
      setTimeout(() => {
        textareaRef.current?.focus();
      }, 150);
    }
    // showInput && textareaRef.current?.focus();
  }, [showInput]);

  const createPressed = async () => {
    setProcessing(true);

    try {
      let success = await props.createButtonPressed(input);

      if (success) {
        setInput("");
      }

      setProcessing(false);
    } catch (error) {
      setProcessing(false);
    }
  };

  const getPlaceholderText = () => {
    switch (DataController.isWeb()) {
      case true:
        return "Type your evidence prompt here or use the microphone";
      case false:
        return "Type your evidence prompt here (Or use you device keyboard's built in dictation feature)";
    }
  };

  const inputIsEmpty = () => {
    return input.trim().length === 0;
  };

  return (
    <div ref={ref} id="ai-dictation-banner" className=" bg-ai-purple">
      <div
        className={classNames("flex mx-auto max-w-app-column py-4 items-center", {
          "pl-safe-area-l pr-safe-area-r": windowWidth <= 1012,
        })}
      >
        <IonButton
          color="light"
          fill="clear"
          className="flex flex-1 ai-dictation-button h-11 w-11 min-w-11 min-h-11 max-w-11 max-h-11"
          onClick={() => setShowInput(true)}
        >
          {props.dictationState === "listening" && (
            <div className="absolute top-0 left-0 w-full h-full bg-white opacity-35" />
          )}
          <IonImg src={Icon_Evidence_AI} className="h-[20.5px] w-[21px]" />
        </IonButton>
        <div className="flex flex-1 flex-col ml-3 overflow-hidden">
          <AIDictationBannerHeader />
        </div>
        <IonButton
          color="light"
          fill="clear"
          shape="round"
          slot="icon-only"
          className="flex flex-1 ai-dictation-info-button h-6 w-6 min-w-11 min-h-11 max-w-11 max-h-11"
          onClick={() => setShowInput(!showInput)}
        >
          <IonImg src={Icon_Disclosure_Down_White} className={classNames("h-8 w-h-8", { "rotate-180": showInput })} />
        </IonButton>
        <IonButton
          color="light"
          fill="clear"
          shape="round"
          slot="icon-only"
          className="flex flex-1 ai-dictation-info-button h-6 w-6 min-w-11 min-h-11 max-w-11 max-h-11"
          onClick={props.infoButtonPressed}
        >
          <IonImg src={Button_Info_White} className="h-11 w-11" />
        </IonButton>
      </div>
      <Collapsible trigger={<div />} triggerDisabled transitionTime={150} transitionCloseTime={150} open={showInput}>
        <div
          className={classNames("flex flex-col mx-auto max-w-app-column py-4 pt-0 pb-2 items-center", {
            "pl-safe-area-l pr-safe-area-r": windowWidth <= 1012,
          })}
        >
          <div className="flex flex-row w-full items-center">
            <IonButton
              color="light"
              fill="clear"
              className={classNames(
                "flex flex-1 ai-dictation-button h-11 w-11 min-w-11 min-h-11 max-w-11 max-h-11 m-0 ml-0.5 mr-2 self-start"
              )}
              disabled={props.processingState === "processing"}
              onClick={props.microphoneButtonPressed}
            >
              {props.dictationState === "listening" && (
                <div className="absolute top-0 left-0 w-full h-full bg-white opacity-35" />
              )}
              <IonImg src={Icon_Microphone} className="h-[32px] w-[15px]" />
            </IonButton>
            <Textarea
              maxRows={4}
              size="md"
              className="w-full bg-white rounded-md !pr-0"
              value={input}
              disabled={props.processingState === "processing"}
              onChange={(event) => setInput(event.target.value)}
              placeholder={getPlaceholderText()}
              slotProps={{
                textarea: {
                  ref: textareaRef,
                },
              }}
              variant="plain"
              sx={{
                "--Textarea-placeholderOpacity": 1,
                backgroundColor: "white",
                minHeight: "44px",
                color: input.length ? "black" : "var(--grey-60)", // different color for placeholder text
                paddingTop: "4px",
                paddingBottom: "4px",
                paddingLeft: "8px",
                paddingRight: "8px",
                fontSize: "17px",
                fontWeight: "600",
                ":hover": {
                  color: input.length ? "black" : "var(--grey-60)", // different color for placeholder text
                },
                "& .MuiTextarea-input": {
                  boxSizing: "border-box", // Apply box-sizing to the input
                  "&::placeholder": {
                    padding: "10px", // Set padding for the placeholder
                  },
                },
              }}
            />
          </div>
          <IonButton
            mode="ios"
            disabled={inputIsEmpty() || processing}
            className="flex w-full max-w-button mt-3 self-center white-button"
            onClick={createPressed}
            type="submit"
          >
            {processing ? (
              <div className="flex flex-row items-center justify-center">
                <IonSpinner className="h-4 w-h-4 text-grey-90 mr-2" />
                <div className="text-grey-90">{"Processing"}</div>
              </div>
            ) : (
              <div className="text-grey-90">{"Create"}</div>
            )}
          </IonButton>
        </div>
      </Collapsible>
    </div>
  );
});

export default AIDictationBanner;
