import { useEffect } from "react";
import { IClassSubscription, ISubscriptionModalType } from "../../Interfaces";
import { EventRegister } from "react-native-event-listeners";
import { addDays, isAfter, isSameDay, startOfDay } from "date-fns";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../state/State";
import { useLoggedIn } from "../auth/useLoggedIn";
import DataController from "../../controllers/DataController";

export const useUserAccessCheck = () => {
  const user = useRecoilValue(userAtom);
  const loggedIn = useLoggedIn();

  useEffect(() => {
    const checkHasAccess = async (): Promise<void> => {
      let latestSubscription: IClassSubscription | null = null;
      const subscriptions = user.userData.subscriptions;

      if (subscriptions && subscriptions.length > 0) {
        latestSubscription = DataController.getLatestPortfolioSubscription(subscriptions);

        if (!latestSubscription) {
          EventRegister.emit("subscriptions/show-modal", ISubscriptionModalType.PROFILE);
        } else {
          let valid = false;
          const dateSeconds = parseInt(latestSubscription.ExpiryDate.replace("/Date(", "").replace(")/", ""), 10);
          const withGrace = startOfDay(addDays(new Date(dateSeconds), 2));

          if (dateSeconds === 0) {
            valid = true;
          } else if (isAfter(withGrace, new Date())) {
            valid = true;
          } else if (isSameDay(new Date(), withGrace)) {
            valid = true;
          }

          if (!valid) {
            EventRegister.emit("subscriptions/show-modal", ISubscriptionModalType.PROFILE);
          }
        }
      } else {
        EventRegister.emit("subscriptions/show-modal", ISubscriptionModalType.PROFILE);
      }
    };

    if (loggedIn && user) {
      checkHasAccess();
    }
  }, [loggedIn, user]);
};
