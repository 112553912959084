import { useEffect, useState } from "react";
import RemoteDataController from "../../controllers/RemoteDataController";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import DataController from "../../controllers/DataController";
import { PF_SERVER_URL } from "../../Constants";
import { useRecoilValueLoadable } from "recoil";
import { userAtom } from "../../state/State";
import { EvidenceCustomTag, IEvidence } from "../../Interfaces";
import { useBetaFeatures } from "../app/useBetaFeatures";
import { EventRegister } from "react-native-event-listeners";

const TIMEOUT_DURATION = 15000;
const AI_DICTATION_URL = `${PF_SERVER_URL}/portfolio/AI/evidence-string2json`;
type DictationState = "ready" | "listening" | "error";
type ProcessingState = "ready" | "processing" | "processed" | "error";

export const useEvidenceDictation = () => {
  const { transcript, resetTranscript, isMicrophoneAvailable, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const { betaFeatures, canUseBetaFeature } = useBetaFeatures();

  const user = useRecoilValueLoadable(userAtom);

  const [state, setState] = useState<DictationState>("ready");
  const [processingState, setProcessingState] = useState<ProcessingState>("ready");
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");
  const [processedEvidence, setProcessedEvidence] = useState<IEvidence | undefined>(undefined);
  const [transcriptHistory, setTranscriptHistory] = useState<string[]>([]);

  useEffect(() => {
    const checkIfAvailable = async () => {
      if (!canUseBetaFeature("AI Dictation")) {
        return;
      }

      if (DataController.isWeb()) {
        setIsAvailable(browserSupportsSpeechRecognition);
      }
    };

    // checkIfAvailable();
  }, [user.contents, betaFeatures]);

  const processEvidenceGeneration = async (textInput: string): Promise<boolean> => {
    setProcessingState("processing");
    const abortController = new AbortController();
    const signal = abortController.signal;

    const timeout = setTimeout(() => {
      abortController.abort();
    }, TIMEOUT_DURATION);

    try {
      const input = textInput;

      if (input.length === 0) {
        setState("error");
        setErrorMessage("No dictation found. Try again...");
        return false;
      }

      const response: Response = await fetch(AI_DICTATION_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          string: input,
          token: user.contents?.token,
          contactID: user.contents?.userData.contactID,
        }),
        signal,
      });

      if (response) {
        const json = await response.json();

        if (json) {
          setProcessedEvidence(processEvidence(json.body));
          setTranscriptHistory((prev) => [...prev, input]);
          resetTranscript();
          setProcessingState("processed");
          return true;
        }
      } else {
        setState("error");
        setProcessingState("error");
        setErrorMessage("Failed to process dictation...");
        return false;
      }
    } catch (error: any) {
      handleError(error);
      return false;
    } finally {
      clearTimeout(timeout);
    }

    return false;
  };

  const resetProcessingState = (): void => {
    setProcessingState("ready");
  };

  const handleError = (error: any): void => {
    setState("error");
    setProcessingState("error");
    setErrorMessage(error?.name === "AbortError" ? "Request timed out. Try again..." : undefined);
    RemoteDataController.logError(error as never);
  };
  const processEvidence = (evidence: any) => {
    console.log("[processEvidence]", evidence);
    const newObject: any = {
      ...(evidence.activity && {
        activity: evidence.activity,
      }),
      ...(evidence.activityDate &&
        evidence.activityDate !== "." && {
          activityDate: evidence.activityDate,
        }),
      ...(evidence.activityDescription &&
        evidence.activityDescription !== "." && {
          activityDescription: evidence.activityDescription,
        }),
      ...(evidence.numberOfHours &&
        evidence.numberOfHours >= 0 && {
          numberOfHours: evidence.numberOfHours,
        }),
      ...(evidence.date && evidence.date !== "." && { date: evidence.date }),
      ...(evidence.benefits && evidence.benefits !== "." && { benefits: evidence.benefits }),
      ...(evidence.objectives && evidence.objectives !== "." && { objectives: evidence.objectives }),
      ...(evidence.reflections && evidence.reflections !== "." && { reflections: evidence.reflections }),
      ...(evidence.title && evidence.title !== "." && { title: evidence.title }),
      ...(evidence.customTags &&
        evidence.customTags.length > 0 && {
          customTags: evidence.customTags.filter((item: EvidenceCustomTag) => item.tag.length > 0),
        }),
    };

    return newObject;
  };

  const resetDictation = (): void => {
    setTranscriptHistory([]);
    setProcessedEvidence(undefined);
  };

  const handleAIDictation = async (): Promise<void> => {
    try {
      if (["ready", "processed", "error"].includes(state)) {
        setProcessedEvidence(undefined);
        EventRegister.emit("evidence/show-ai-feedback", false);

        if (DataController.isWeb()) {
          if (!isMicrophoneAvailable) {
            setState("error");
            setErrorMessage("User denied microphone permissions!");
          } else {
            setState("listening");

            await SpeechRecognition.startListening({
              language: "en-GB",
              continuous: true,
            });
          }
        }
      } else if (state === "listening") {
        if (DataController.isWeb()) {
          setState("ready");
          await SpeechRecognition.stopListening();
        }
      }
    } catch (error) {
      setState("error");
      setErrorMessage(undefined);
      RemoteDataController.logError(error as never);
    }
  };

  return {
    state,
    processingState,
    resetProcessingState,
    isAvailable: false,
    handleAIDictation,
    errorMessage,
    processEvidenceGeneration,
    transcript,
    processedEvidence,
    transcriptHistory,
    resetDictation,
  };
};
