import React, { useEffect, useState } from "react";
import { IonContent, IonModal } from "@ionic/react";
import { IUnsavedChangesModal } from "../../types/Components";

const UnsavedChangesModal: React.FC<IUnsavedChangesModal> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  return (
    <IonModal
      id="save-draft-modal"
      isOpen={visible}
      backdropDismiss={false}
      canDismiss={true}
      onDidDismiss={() => setVisible(false)}
      className="fullscreen"
    >
      <IonContent>
        <div className="flex flex-col justify-between h-full">
          <div className="mt-[16px] px-[16px]">
            <div className="text-[20px] font-bold leading-default tracking-[-0.1px] text-center text-grey-90">
              {"You have unsaved changes"}
            </div>
            <div className="text-[15px] leading-[1.33] tracking-[-0.3px] text-center text-grey-90">
              {"Are you sure you want to exit without saving your changes?"}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="border-t border-t-grey-30">
              <button className={`h-[48px] w-full`} onClick={props.savePressed}>
                <div className="text-[16px] font-semibold leading-default tracking-default text-center text-cta-blue">
                  {"Save changes"}
                </div>
              </button>
            </div>
            <div className="border-t border-t-grey-30">
              <button className="h-[48px] w-full" onClick={props.discardPressed}>
                <div className="text-[16px] font-semibold leading-default tracking-default text-center text-cta-red">
                  {"Discard changes"}
                </div>
              </button>
            </div>
            <div className="border-t border-t-grey-30">
              <button className="h-[48px] w-full" onClick={props.cancelPressed}>
                <div className="text-[16px] leading-default tracking-default text-center text-cta-blue">{"Cancel"}</div>
              </button>
            </div>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default UnsavedChangesModal;
