import { addMonths, format, isAfter, subMonths, subWeeks } from "date-fns";
import { ConfidenceLevelData, IUserProgramCertificate, ProgressCheckSubmission, SubmissionState } from "../Interfaces";
import DOMPurify from "dompurify";
import * as ProgramUtils from "./programUtils";

export namespace StringUtils {
  export function checkStringIsEmptyAfterTrimming(string: string): boolean {
    return string.trim().length === 0;
  }

  export function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  export function getCommentsDisclaimerText(
    programID: string,
    mentorNamingConvention: string | undefined
  ): string | undefined {
    switch (programID) {
      case "LNA4FTCKB005M":
        return `This is a private record. Every ${mentorNamingConvention?.toLowerCase()} you work with will see all your comments, as well as your other managers.`;
      default:
        return undefined;
    }
  }

  export function getProgressCheckSubmissionChangedByHeader(status: string | undefined): string {
    switch (status) {
      case SubmissionState.Approved:
        return "Approved by:";
      case SubmissionState.OnHoldReset:
        return "Put On Hold (Reset) by";
      case SubmissionState.OnHold:
        return "Put On Hold by:";
      default:
        return "";
    }
  }

  export function getProgressCheckStatusText(status: string | undefined): string {
    switch (status) {
      case SubmissionState.Approved:
        return SubmissionState.Approved;
      case SubmissionState.Completed:
        return SubmissionState.Completed;
      case SubmissionState.OnHoldReset:
        return "On Hold (Reset)";
      case SubmissionState.OnHold:
        return "On Hold";
      case SubmissionState.Submitted:
        return SubmissionState.Submitted;
      case SubmissionState.InProgress:
        return "In Progress";
      default:
        return "Not started";
    }
  }

  export function getProgressCheckPDFCompletedText(submissionState: ProgressCheckSubmission | undefined): string {
    switch (submissionState?.status) {
      case SubmissionState.Approved:
        return "Approved on:";
      case SubmissionState.Completed:
        return "Completed on:";
      case SubmissionState.OnHoldReset:
        return "Progress Check reset on:";
      case SubmissionState.OnHold:
        return "Progress Check held on:";
      case SubmissionState.Submitted:
        return "Submitted on:";
      default:
        return "Status updated on: ";
    }
  }

  export function getRoleDisplayString(role: string): string {
    switch (role) {
      case "Mentee":
        return "";
      case "Mentor":
        return " - Line Manager";
      case "OO":
        return " - OO (Operational Officer)";
      case "OO (Operational Officer)":
        return " - OO (Operational Officer)";
      case "EL (Educational Lead)":
        return " - EL (Educational Lead)";
      default:
        return "";
    }
  }

  export function getAuditTickboxTitle(): string {
    return "Mark this for audit";
  }

  export function getAuditTickboxText(): string {
    return "e.g. HCPC, clinical review, other";
  }

  /**
   * Retrieves the container class for the certificate expiry date pill
   * @param certificate - The program certificate
   * @param duration - How long the certificate is valid for in months
   * @param canBeBlank - Whether the certificate can have its issue date omitted
   * @returns The tailwind class string
   */
  export function getCertificatePillContainerClass(
    certificate: IUserProgramCertificate | undefined | null,
    duration: string | undefined,
    canBeBlank?: boolean
  ): string {
    const durationNumber = duration ? parseInt(duration, 10) : 12;

    if (certificate?.issueDate) {
      const issueDate = new Date(certificate.issueDate);
      const today = new Date();

      // Expiry date is 1 year from issue date
      const expiry = addMonths(issueDate, durationNumber);
      // Red date is 6 weeks before expiry, amber date is 4 months before expiry
      const redDate = subWeeks(expiry, 6);
      const amberDate = subMonths(expiry, 4);

      if (isAfter(today, new Date(expiry))) {
        return "shrink-0 h-[20px] py-0 px-[8px] bg-cta-red rounded-[10px] text-[13px] font-semibold leading-[1.54] tracking-[-0.05px] text-right text-white";
      } else if (isAfter(today, redDate)) {
        return "shrink-0 h-[20px] py-0 px-[8px] bg-cta-red rounded-[10px] text-[13px] font-semibold leading-[1.54] tracking-[-0.05px] text-right text-white";
      } else if (isAfter(today, amberDate)) {
        return "shrink-0 h-[20px] py-0 px-[8px] bg-[#FEEBCB] rounded-[10px] text-[13px] font-semibold leading-[1.54] tracking-[-0.05px] text-right text-[#9C4221]";
      } else {
        return "shrink-0 h-[20px] py-0 px-[8px] bg-[#C6F7C1] rounded-[10px] text-[13px] font-semibold leading-[1.54] tracking-[-0.05px] text-right text-[#085333]";
      }
    } else if (canBeBlank) {
      return "shrink-0 h-[20px] py-0 px-[8px] bg-transparent text-[13px] font-semibold leading-[1.54] tracking-[-0.05px] text-right text-cta-blue";
    } else {
      return "shrink-0 h-[20px] py-0 px-[8px] bg-[#FCBAAC] rounded-[10px] text-[13px] font-semibold leading-[1.54] tracking-[-0.05px] text-right text-[#890012]";
    }
  }

  /**
   * Retrieves the container class for the certificate expiry date pill
   * @param expiry - The expiry date of the certificate
   * @returns The tailwind class string
   */
  export function getCertificatePillContainerClassFromExpiryDate(expiry: Date): string {
    const today = new Date();

    if (isAfter(today, new Date(expiry))) {
      return "shrink-0 h-[20px] py-0 px-[8px] bg-cta-red rounded-[10px] text-[13px] font-semibold leading-[1.54] tracking-[-0.05px] text-right text-white";
    } else {
      return "shrink-0 h-[20px] py-0 px-[8px] bg-[#C6F7C1] rounded-[10px] text-[13px] font-semibold leading-[1.54] tracking-[-0.05px] text-right text-[#085333]";
    }
  }

  /**
   * Retrieves the text for the add certificate button
   * @param certificate - The program certificate
   * @param duration - How long the certificate is valid for in months
   * @param canBeBlank - Whether the certificate can have its issue date omitted
   * @returns
   */
  export function getCertificatePillString(
    certificate: IUserProgramCertificate | undefined | null,
    duration: string | undefined,
    canBeBlank?: boolean
  ): string {
    const durationNumber = duration ? parseInt(duration, 10) : 12;

    if (certificate?.issueDate) {
      const expiry = addMonths(new Date(certificate.issueDate), durationNumber);
      return format(expiry, "d MMM yy");
    } else if (canBeBlank) {
      return "Add certificate";
    } else {
      return "No certificate";
    }
  }

  /**
   * Returns a string representing the activity duration. If the duration is not present, return an empty string.
   * If the duration is present and has two dates, format it as "dd MMM yyyy to dd MMM yyyy". If the duration is present and has one date, format it as "dd MMM yyyy".
   * @param activityDate - The activity duration, which can be a string with one or two dates separated by a semicolon
   * @returns A string representing the activity duration
   */
  export function getActivityDateString(
    activityDate?: string | undefined,
    activityDateEnd?: string | undefined
  ): string {
    if (!activityDate) return "";

    if (activityDate && activityDateEnd) {
      return `${format(new Date(activityDate), "d MMM yyyy")} to ${format(new Date(activityDateEnd), "d MMM yyyy")}`;
    } else if (activityDate) {
      return format(new Date(activityDate), "d MMM yyyy");
    }

    return "";
  }

  export function getActivityDurationDisplayString(duration: string) {
    const [hours, minutes] = duration === "" ? ["0000", "00"] : duration.split(":");
    return `${hours} hr : ${minutes.padStart(2, "0")} min`;
  }

  /**
   * Returns the correct title string for the competence card header
   * @param {string} programID - The ID of the program
   * @returns The correct title string
   */
  export function getCompetenceCardTitle(programID: string): string {
    if (ProgramUtils.isMTS(programID) || ProgramUtils.isCHUB(programID)) {
      return "Evidence type";
    }

    return "Required evidence";
  }

  /**
   *
   * @param active - Whether the user has pieces of evidence with the confidence levels added
   * @returns The correct subtitle string for the Confidence Levels card header
   */
  export function getConfidenceLevelsHeader(
    confidenceLevelData: ConfidenceLevelData | null,
    programName?: string,
    programID?: string
  ): string {
    if (confidenceLevelData === null) {
      switch (programID) {
        case "LNA4FTCKB012M": // SWASFT Preceptorship NQP1
          return `During this ${programName} program, you are required to assess and record your confidence level 3 times. Choose 2 topics to track from the ‘Start-point’ Progress check onwards.`;
        case "M167IR37RNSSQ": // NEAS NQP
          return `During this ${programName} program, you are required to assess and record your confidence level 4 times. Choose 2 topics to track from the ‘0-6 months’ Progress check onwards.`;
        case "M7EPWBZE8P2XK": // YAS NQP
          return `During this ${programName} program, you are required to assess and record your confidence level 4 times. Choose 2 topics to track from the ‘0-6 months’ Progress check onwards.`;
        default:
          return `During this ${programName} program, you are required to assess and record your confidence level 3 times. Choose 2 topics to track from the ‘Start-point’ Progress check onwards.`;
      }
    }

    return `Your confidence levels throughout this ${programName} program.`;
  }

  /**
   * Sanitizes an input HTML string to use in dangerouslySetInnerHTML to prevent XSS
   * @param {string} html - The  string to sanitize
   * @returns An object { __html: string }
   */
  export function sanitizeHTML(html: string): { __html: string } {
    return { __html: DOMPurify.sanitize(html, { ADD_ATTR: ["target"] }) };
  }
}
