import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonMenuButton,
  IonModal,
  IonPage,
  IonPopover,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { format, isSameDay } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, withRouter } from "react-router";
import { useRecoilStateLoadable } from "recoil";
import {
  Button_Cancel,
  Button_Checkbox_Active,
  Button_Checkbox_Inactive,
  Button_Filter,
  Button_Filter_Active,
  Button_Menu,
  Button_Search,
  Button_Search_Active,
  Button_Tickbox_3_Inactive,
  Icon_Add_Evidence,
  Icon_ParaPass,
  Icon_Plus,
  Icon_Search_Small,
  Image_Evidence_Empty_State,
  Image_Timeline_Empty,
} from "../../assets/images";
import TimelineContainer from "../../components/timeline/TimelineContainer";
import { FirebaseService } from "../../controllers/FirebaseService";
import { IEvidence, INewTimelineEvidence, IParapassUsageData, IPlusUsageData, IUser } from "../../Interfaces";
import { evidenceAtom, parapassDataAtom, plusDataAtom, userAtom } from "../../state/State";
import _ from "lodash";
import DataController from "../../controllers/DataController";

import "./Timeline.css";
import { EventRegister } from "react-native-event-listeners";
import HeaderTitle from "../../components/common/HeaderTitle";
import { Capacitor } from "@capacitor/core";
import { Keyboard, KeyboardInfo } from "@capacitor/keyboard";

const Timeline: React.FC = () => {
  const history = useHistory();

  const [user, setUser] = useRecoilStateLoadable<IUser | null>(userAtom);
  const [evidence, setEvidence] = useRecoilStateLoadable<IEvidence[] | null>(evidenceAtom);
  const [plusData, setPlusData] = useRecoilStateLoadable<IPlusUsageData[] | null>(plusDataAtom);
  const [parapassData, setParapassData] = useRecoilStateLoadable<IParapassUsageData[] | null>(parapassDataAtom);

  const searchRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<HTMLIonModalElement>(null);
  const popoverRef = useRef<HTMLIonPopoverElement>(null);

  const [sortedEvidence, setSortedEvidence] = useState<IEvidence[]>([]);
  const [sortedPlusData, setSortedPlusData] = useState<IPlusUsageData[]>([]);
  const [sortedParapassData, setSortedParapassData] = useState<IParapassUsageData[]>([]);

  const [searchVisible, setSearchVisible] = useState<boolean>(false);
  const [searchPlaceholder, setSearchPlaceholder] = useState<string>(format(new Date(), "MMM yyyy"));
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [sortedUsageData, setSortedUsageData] = useState<Array<IPlusUsageData | IParapassUsageData>>([]);
  const [searchResults, setSearchResults] = useState<Array<IPlusUsageData | IParapassUsageData>>([]);

  const [plusFilters, setPlusFilters] = useState<string[]>([]);
  const [parapassFilters, setParapassFilters] = useState<string[]>([]);

  const [allFilterSelected, setAllFilterSelected] = useState<boolean>(true);
  const [allPlusSelected, setAllPlusSelected] = useState<boolean>(false);
  const [allParapassSelected, setAllParapassSelected] = useState<boolean>(false);

  const [selectedPlusFilters, setSelectedPlusFilters] = useState<any[]>([]);
  const [selectedParapassFilters, setSelectedParapassFilters] = useState<any[]>([]);

  const [popoverFilter, setPopoverFilter] = useState<boolean>(false);

  const [keyboardActive, setKeyboardActive] = useState<boolean>(false);
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);

  useEffect(() => {
    const windowResized = () => {
      setPopoverVisible(false);
      setFilterVisible(false);

      if (window.innerWidth >= 600) {
        setPopoverFilter(true);
      } else {
        setPopoverFilter(false);
      }
    };

    window.addEventListener("resize", windowResized);

    windowResized();

    return () => {
      window.removeEventListener("resize", windowResized);
    };
  }, []);

  useEffect(() => {
    const logSearch = async (): Promise<void> => {
      await FirebaseService.logEvent("evidence_search_pressed", {});
    };

    searchVisible && logSearch();
  }, [searchVisible]);

  useEffect(() => {
    const sortData = (): void => {
      let plusCopy = _.cloneDeep(plusData.contents) || [];
      let parapassCopy = _.cloneDeep(parapassData.contents) || [];
      plusCopy = plusCopy.map((item: IPlusUsageData) => ({
        ...item,
        filterType: DataController.getJRCALCGuidelineType(item.GLID),
        sortableDate: new Date(item.timestamp),
      }));
      parapassCopy = parapassCopy.map((item: IParapassUsageData) => ({
        ...item,
        filterType: DataController.getParapassQuizType(item.type),
        sortableDate: new Date(item.date),
      }));

      let uniquePlus: IPlusUsageData[] = [];

      for (let i = 0; i < plusCopy.length; i++) {
        let item: IPlusUsageData = plusCopy[i];

        let included = uniquePlus.findIndex(
          (value) => value.GLID === item.GLID && isSameDay(new Date(item.timestamp), new Date(value.timestamp))
        );

        if (included > -1) {
          const object = uniquePlus[included];
          let count = object.count || 1;
          uniquePlus.splice(included, 1, { ...object, count: count + 1 });
        } else {
          uniquePlus.push(item);
        }
      }

      const sorted = _.concat(uniquePlus, parapassCopy);

      parapassCopy.length > 0 &&
        setParapassFilters(_.uniq(parapassCopy.map((item: IParapassUsageData) => item.filterType)));
      plusCopy.length > 0 && setPlusFilters(_.uniq(plusCopy.map((item: IPlusUsageData) => item.filterType)));
      setSortedPlusData(_.orderBy(plusCopy, ["timestamp"], ["desc"]));
      setSortedParapassData(_.orderBy(parapassCopy, ["date"], ["desc"]));
      setSortedUsageData(_.orderBy(sorted, ["sortableDate"], ["desc"]));
      setSearchResults(_.orderBy(sorted, ["sortableDate"], ["desc"]));
    };

    plusData.state === "hasValue" && parapassData.state === "hasValue" && sortData();
  }, [parapassData.contents, parapassData.state, plusData.contents, plusData.state, user]);

  useEffect(() => {
    const setPageName = async (): Promise<void> => {
      await FirebaseService.setScreenName("timeline");
    };

    setPageName();
  }, []);

  useEffect(() => {
    if (Capacitor.getPlatform() === "ios") {
      Keyboard.addListener("keyboardWillShow", (info: KeyboardInfo) => {
        setKeyboardActive(true);
        setKeyboardHeight(info.keyboardHeight);
      });

      Keyboard.addListener("keyboardWillHide", () => {
        setKeyboardActive(false);
      });
    }
  }, []);

  const closeSearch = useCallback((): void => {
    setSearchText("");
    setSearchVisible(false);
  }, []);

  const toggleFilterAll = useCallback((): void => {
    if (!allFilterSelected) {
      setAllFilterSelected(true);
      setAllPlusSelected(false);
      setAllParapassSelected(false);
      setSelectedPlusFilters([]);
      setSelectedParapassFilters([]);
    }
  }, [allFilterSelected]);

  useEffect(() => {
    if (allPlusSelected && allParapassSelected) {
      toggleFilterAll();
    }
  }, [allParapassSelected, allPlusSelected, toggleFilterAll]);

  useEffect(() => {
    if (
      !allFilterSelected &&
      !allPlusSelected &&
      !allParapassSelected &&
      selectedParapassFilters.length === 0 &&
      selectedPlusFilters.length === 0
    ) {
      toggleFilterAll();
    }
  }, [
    allFilterSelected,
    allParapassSelected,
    allPlusSelected,
    selectedParapassFilters,
    selectedPlusFilters,
    toggleFilterAll,
  ]);

  useEffect(() => {
    if (searchVisible) {
      const searchPlaceholders: string[] = [
        format(new Date(), "MMM yyyy"),
        ...(sortedParapassData.length > 0 ? ["ParaPass"] : []),
        ...(sortedPlusData.length > 0 ? ["JRCALC"] : []),
        ...searchResults.map((item) => item.title),
      ];

      setSearchPlaceholder(searchPlaceholders[Math.floor(Math.random() * searchPlaceholders.length)]);

      try {
        setTimeout(() => {
          searchRef.current?.focus();
        }, 250);
      } catch (error) {
        console.log(error);
      }
    } else {
      closeSearch();
    }
  }, [closeSearch, searchResults, searchVisible, sortedParapassData.length, sortedPlusData.length]);

  useEffect(() => {
    if (searchText.length > 0) {
      let search = searchText.toLowerCase();
      let copy = _.cloneDeep(sortedUsageData) || [];

      copy = copy.filter((item) => {
        if ("quizSet" in item) {
          if (allFilterSelected || allParapassSelected) {
            return true;
          } else if (selectedParapassFilters.length > 0) {
            return selectedParapassFilters.includes(item.filterType);
          }
        } else if ("GLID" in item) {
          if (allFilterSelected || allPlusSelected) {
            return true;
          } else if (selectedPlusFilters.length > 0) {
            return selectedPlusFilters.includes(item.filterType);
          }
        }

        return false;
      });

      copy = _.filter(copy, (item) => {
        return (
          ("quizSet" in item && "ParaPass".toLowerCase().includes(search)) ||
          ("GLID" in item && "JRCALC".toLowerCase().includes(search)) ||
          item.title.toLowerCase().includes(search) ||
          format(item.sortableDate!, "dd MMM yyyy").toLowerCase().includes(search)
        );
      });
      setSearchResults(copy);
    } else {
      let copy = _.cloneDeep(sortedUsageData) || [];

      if (allFilterSelected || (allPlusSelected && allParapassSelected)) {
        setSearchResults(copy);
      } else {
        copy = copy.filter((item) => {
          if ("quizSet" in item) {
            if (allParapassSelected) {
              return true;
            } else if (selectedParapassFilters.length > 0) {
              return selectedParapassFilters.includes(item.filterType);
            }
          } else if ("GLID" in item) {
            if (allPlusSelected) {
              return true;
            } else if (selectedPlusFilters.length > 0) {
              return selectedPlusFilters.includes(item.filterType);
            }
          }

          return false;
        });

        setSearchResults(copy);
      }
    }
  }, [
    allFilterSelected,
    allParapassSelected,
    allPlusSelected,
    searchText,
    selectedParapassFilters,
    selectedParapassFilters.length,
    selectedPlusFilters,
    selectedPlusFilters.length,
    sortedUsageData,
  ]);

  const openFilter = (): void => {
    if (window.innerWidth >= 600) {
      setPopoverVisible(true);
    } else {
      setFilterVisible(true);
    }
  };

  const toggleSearch = (): void => {
    setSearchVisible(!searchVisible);
  };

  const searchTextChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };

  const searchFieldButtonClicked = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Escape") {
      closeSearch();
    }
  };

  const clearSearch = (): void => {
    setSearchText("");
    searchRef.current?.focus();
  };

  const readEvidence = (timelineReference: string): void => {
    if (evidence.state === "hasValue" && evidence.contents) {
      let found = evidence.contents.find((item) => item.timelineReference === timelineReference);
      let _evidence = _.pickBy(_.cloneDeep(found), _.identity);

      history.push(`/evidence/read/${_evidence.id}`);
    }
  };

  const addEvidence = async (item: IPlusUsageData | IParapassUsageData, timelineReference: string): Promise<void> => {
    let object: INewTimelineEvidence | {} = {};

    if ("quizSet" in item) {
      const totalScore = item.score.correct + item.score.incorrect + item.score.unanswered;

      object = _.extend(object, {
        title: item.title,
        date: new Date(),
        activityDate: new Date(item.sortableDate!),
        activityDescription: `Completed ParaPass “${item.title}” quiz and scored ${item.score.correct}/${totalScore} on ${format(item.sortableDate!, "dd MMM yyyy")}`,
        timelineReference,
        pdfData: {
          certificateFileName: `ParaPass_Certificate_${format(new Date(), "dd-MMM-yyyy_hh-mm-ss")}.pdf`,
        },
      });

      FirebaseService.logEvent("add_timeline_evidence_pressed", {
        type: "ParaPass",
        quizID: item.quizSet,
        title: item.title,
      });
    } else if ("GLID" in item) {
      object = _.extend({
        title: item.title,
        date: new Date(),
        activityDate: new Date(item.sortableDate!),
        activityDescription: `Viewed JRCALC ${DataController.getJRCALCGuidelineType(item.GLID)} “${item.title}” ${item.count ? `${item.count} time${item.count === 1 ? "" : "s"}` : ""} ${format(item.sortableDate!, "dd MMM yyyy")}`,
        timelineReference,
      });

      FirebaseService.logEvent("add_timeline_evidence_pressed", {
        type: "JRCALC",
        GLID: item.GLID,
        title: item.title,
      });
    }

    EventRegister.emit("evidence-modal/add-evidence-timeline", object);
  };

  const togglePlusFilter = (value: string): void => {
    let array = _.clone(selectedPlusFilters);

    if (array.includes(value)) {
      array = array.filter((item) => item !== value);
    } else {
      array.push(value);
    }

    const allSelected = array.length === plusFilters.length;

    allSelected ? setSelectedPlusFilters([]) : setSelectedPlusFilters(array);
    allSelected ? setAllPlusSelected(true) : setAllPlusSelected(false);
    setAllFilterSelected(false);
  };

  const toggleFilterPlus = (): void => {
    if (allPlusSelected) {
      setAllPlusSelected(false);
      setSelectedPlusFilters([]);
      setAllFilterSelected(false);
    } else {
      setAllPlusSelected(true);
      setSelectedPlusFilters([]);
      setAllFilterSelected(false);
    }
  };

  const toggleParapassFilter = (value: string): void => {
    let array = _.clone(selectedParapassFilters);

    if (array.includes(value)) {
      array = array.filter((item) => item !== value);
    } else {
      array.push(value);
    }

    const allSelected = array.length === parapassFilters.length;

    allSelected ? setSelectedParapassFilters([]) : setSelectedParapassFilters(array);
    allSelected ? setAllParapassSelected(true) : setAllParapassSelected(false);

    setAllFilterSelected(false);
  };

  const toggleFilterParapass = (): void => {
    if (allParapassSelected) {
      setAllParapassSelected(false);
      setSelectedParapassFilters([]);
      setAllFilterSelected(false);
    } else {
      setAllParapassSelected(true);
      setSelectedParapassFilters([]);
      setAllFilterSelected(false);
    }
  };

  const hasEvidence = (timelineReference: string): boolean => {
    let value = false;

    if (evidence.state === "hasValue" && evidence.contents) {
      value = evidence.contents.filter((item) => item.timelineReference === timelineReference).length > 0;
    }

    return value;
  };

  const showActiveFilterIcon = (): boolean => {
    return (
      !allFilterSelected ||
      allParapassSelected ||
      allPlusSelected ||
      selectedPlusFilters.length > 0 ||
      selectedParapassFilters.length > 0
    );
  };

  const hasData = sortedUsageData.length > 0;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonMenuButton>
              <IonImg src={Button_Menu} className="menuIcon" />
            </IonMenuButton>
          </IonButtons>
          <HeaderTitle>{"Timeline"}</HeaderTitle>
          {hasData && (
            <IonButtons slot="end">
              <IonButton id="filterButton" className="header-button" mode="ios" onClick={() => openFilter()}>
                <IonImg src={showActiveFilterIcon() ? Button_Filter_Active : Button_Filter} className="headerIcon" />
              </IonButton>
              <IonButton className="header-button" mode="ios" onClick={() => toggleSearch()}>
                <IonImg src={searchVisible ? Button_Search_Active : Button_Search} className="headerIcon" />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
        {hasData && !popoverFilter && (
          <IonModal
            id="timelineModal"
            ref={modalRef}
            // trigger="filterButton"
            isOpen={filterVisible}
            initialBreakpoint={0.7}
            breakpoints={[0, 0.7, 1]}
            onDidDismiss={() => setFilterVisible(false)}
            canDismiss={true}
            className="fullscreen"
          >
            <IonHeader>
              <IonButtons slot="start">
                <IonButton
                  mode="ios"
                  className="cancelButton"
                  onClick={() => toggleFilterAll()}
                  disabled={allFilterSelected}
                >
                  {"Reset"}
                </IonButton>
              </IonButtons>
              <IonToolbar mode="ios" className="navBar">
                <IonTitle>{"Filter"}</IonTitle>
                <IonButtons slot="end">
                  <IonButton mode="ios" className="cancelButton" onClick={() => setFilterVisible(false)}>
                    {"Done"}
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="filtersModal">
              <div>
                <IonItem
                  button
                  detail={false}
                  className="timelineFilterCheckboxContainer"
                  onClick={() => toggleFilterAll()}
                >
                  <div className="timelineCheckboxContainerInner">
                    <div className="timelineCheckboxLabel">{"All"}</div>
                    <img
                      alt=""
                      className="timelineRadioCheckbox"
                      src={allFilterSelected ? Button_Checkbox_Active : Button_Checkbox_Inactive}
                    />
                  </div>
                </IonItem>
              </div>
              {sortedPlusData.length > 0 && (
                <div>
                  <IonItem
                    button
                    detail={false}
                    className="timelineFilterCheckboxContainer"
                    onClick={() => toggleFilterPlus()}
                  >
                    <div className="timelineCheckboxContainerInner">
                      <div className="timelineCheckboxLabel">{"JRCALC"}</div>
                      <img
                        alt=""
                        className="timelineRadioCheckbox"
                        src={
                          allFilterSelected
                            ? Button_Tickbox_3_Inactive
                            : allPlusSelected
                              ? Button_Checkbox_Active
                              : Button_Checkbox_Inactive
                        }
                      />
                    </div>
                  </IonItem>
                  {plusFilters.map((item, index) => {
                    const selected = selectedPlusFilters.includes(item);
                    const allSelected = selectedPlusFilters.length === plusFilters.length;

                    return (
                      <IonItem
                        button
                        detail={false}
                        key={item}
                        className="timelineFilterCheckboxContainer"
                        onClick={() => togglePlusFilter(item)}
                      >
                        <div className="timelineCheckboxContainerInner">
                          <div className="timelineCheckboxLabelIndent">
                            {item === "Page for Age" ? item : DataController.capitalizeFirstLetter(item) + "s"}
                          </div>
                          <img
                            alt=""
                            className="timelineRadioCheckbox"
                            src={
                              allPlusSelected || allFilterSelected
                                ? Button_Tickbox_3_Inactive
                                : selected
                                  ? Button_Checkbox_Active
                                  : Button_Checkbox_Inactive
                            }
                          />
                        </div>
                      </IonItem>
                    );
                  })}
                </div>
              )}
              {sortedParapassData.length > 0 && (
                <div>
                  <IonItem
                    button
                    detail={false}
                    className="timelineFilterCheckboxContainer"
                    onClick={() => toggleFilterParapass()}
                  >
                    <div className="timelineCheckboxContainerInner">
                      <div className="timelineCheckboxLabel">{"ParaPass"}</div>
                      <img
                        alt=""
                        className="timelineRadioCheckbox"
                        src={
                          allFilterSelected
                            ? Button_Tickbox_3_Inactive
                            : allParapassSelected
                              ? Button_Checkbox_Active
                              : Button_Checkbox_Inactive
                        }
                      />
                    </div>
                  </IonItem>
                  {parapassFilters.map((item, index) => {
                    const selected = selectedParapassFilters.includes(item);
                    const allSelected = selectedParapassFilters.length === parapassFilters.length;

                    return (
                      <IonItem
                        button
                        detail={false}
                        key={item}
                        className="timelineFilterCheckboxContainer"
                        onClick={() => toggleParapassFilter(item)}
                      >
                        <div className="timelineCheckboxContainerInner">
                          <div className="timelineCheckboxLabelIndent">{item}</div>
                          <img
                            alt=""
                            className="timelineRadioCheckbox"
                            src={
                              allParapassSelected || allFilterSelected
                                ? Button_Tickbox_3_Inactive
                                : selected
                                  ? Button_Checkbox_Active
                                  : Button_Checkbox_Inactive
                            }
                          />
                        </div>
                      </IonItem>
                    );
                  })}
                </div>
              )}
            </IonContent>
          </IonModal>
        )}
        {hasData && popoverFilter && (
          <IonPopover
            id="timelinePopover"
            className="timelinePopover"
            ref={popoverRef}
            trigger={`${window.innerWidth >= 600 ? "filterButton" : undefined}`}
            mode="ios"
            isOpen={popoverVisible}
            onDidDismiss={() => setPopoverVisible(false)}
            animated={true}
            reference="trigger"
            side="bottom"
            backdropDismiss={true}
          >
            <div>
              <div slot="fixed">
                <IonHeader>
                  <IonToolbar mode="ios" className="navBar">
                    <IonButtons slot="start">
                      <IonButton
                        mode="ios"
                        className="cancelButton"
                        onClick={() => toggleFilterAll()}
                        disabled={allFilterSelected}
                      >
                        {"Reset"}
                      </IonButton>
                    </IonButtons>
                    <IonTitle>{"Filter"}</IonTitle>
                    <IonButtons slot="end">
                      <IonButton mode="ios" className="cancelButton" onClick={() => setPopoverVisible(false)}>
                        {"Done"}
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonHeader>
              </div>
              <div className="filtersModal">
                <div>
                  <IonItem
                    button
                    detail={false}
                    className="timelineFilterCheckboxContainer"
                    onClick={() => toggleFilterAll()}
                  >
                    <div className="timelineCheckboxContainerInner">
                      <div className="timelineCheckboxLabel">{"All"}</div>
                      <img
                        alt=""
                        className="timelineRadioCheckbox"
                        src={allFilterSelected ? Button_Checkbox_Active : Button_Checkbox_Inactive}
                      />
                    </div>
                  </IonItem>
                </div>
                {sortedPlusData.length > 0 && (
                  <div>
                    <IonItem
                      button
                      detail={false}
                      className="timelineFilterCheckboxContainer"
                      onClick={() => toggleFilterPlus()}
                    >
                      <div className="timelineCheckboxContainerInner">
                        <div className="timelineCheckboxLabel">{"JRCALC"}</div>
                        <img
                          alt=""
                          className="timelineRadioCheckbox"
                          src={
                            allFilterSelected
                              ? Button_Tickbox_3_Inactive
                              : allPlusSelected
                                ? Button_Checkbox_Active
                                : Button_Checkbox_Inactive
                          }
                        />
                      </div>
                    </IonItem>
                    {plusFilters.map((item, index) => {
                      const selected = selectedPlusFilters.includes(item);
                      const allSelected = selectedPlusFilters.length === plusFilters.length;

                      return (
                        <IonItem
                          button
                          detail={false}
                          key={item}
                          className="timelineFilterCheckboxContainer"
                          onClick={() => togglePlusFilter(item)}
                        >
                          <div className="timelineCheckboxContainerInner">
                            <div className="timelineCheckboxLabelIndent">
                              {item === "Page for Age" ? item : DataController.capitalizeFirstLetter(item) + "s"}
                            </div>
                            <img
                              alt=""
                              className="timelineRadioCheckbox"
                              src={
                                allPlusSelected || allFilterSelected
                                  ? Button_Tickbox_3_Inactive
                                  : selected
                                    ? Button_Checkbox_Active
                                    : Button_Checkbox_Inactive
                              }
                            />
                          </div>
                        </IonItem>
                      );
                    })}
                  </div>
                )}
                {sortedParapassData.length > 0 && (
                  <div>
                    <IonItem
                      button
                      detail={false}
                      className="timelineFilterCheckboxContainer"
                      onClick={() => toggleFilterParapass()}
                    >
                      <div className="timelineCheckboxContainerInner">
                        <div className="timelineCheckboxLabel">{"ParaPass"}</div>
                        <img
                          alt=""
                          className="timelineRadioCheckbox"
                          src={
                            allFilterSelected
                              ? Button_Tickbox_3_Inactive
                              : allParapassSelected
                                ? Button_Checkbox_Active
                                : Button_Checkbox_Inactive
                          }
                        />
                      </div>
                    </IonItem>
                    {parapassFilters.map((item, index) => {
                      const selected = selectedParapassFilters.includes(item);
                      const allSelected = selectedParapassFilters.length === parapassFilters.length;

                      return (
                        <IonItem
                          button
                          detail={false}
                          key={item}
                          className="timelineFilterCheckboxContainer"
                          onClick={() => toggleParapassFilter(item)}
                        >
                          <div className="timelineCheckboxContainerInner">
                            <div className="timelineCheckboxLabelIndent">{item}</div>
                            <img
                              alt=""
                              className="timelineRadioCheckbox"
                              src={
                                allParapassSelected || allFilterSelected
                                  ? Button_Tickbox_3_Inactive
                                  : selected
                                    ? Button_Checkbox_Active
                                    : Button_Checkbox_Inactive
                              }
                            />
                          </div>
                        </IonItem>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </IonPopover>
        )}
        {hasData && searchVisible && (
          <div className="evidenceSearchHeader">
            <div className="evidenceSearchInputContainer">
              <img alt="" src={Icon_Search_Small} className="evidenceSearchIcon" />
              <input
                value={searchText}
                className="searchInputField"
                placeholder={`Try searching “${searchPlaceholder}”`}
                onChange={searchTextChanged}
                ref={searchRef}
                onKeyDown={searchFieldButtonClicked}
              />
              {searchText.length > 0 && (
                <IonButton className="searchClearButton" mode="ios" onClick={clearSearch}>
                  <img alt="" src={Button_Cancel} className="icon-44" />
                </IonButton>
              )}
            </div>
            {searchText.length > 0 && (
              <div className="evidenceResultsText">
                {`${searchResults && searchResults.length} result${searchResults && searchResults.length !== 1 ? "s" : ""}`}
              </div>
            )}
          </div>
        )}
      </IonHeader>
      <IonContent className="page-background">
        <TimelineContainer
          id="timelineContentContainer"
          style={{
            paddingBottom: keyboardActive ? keyboardHeight : hasData ? "96px" : "40px",
          }}
        >
          {hasData ? (
            <div>
              <div className="timelineResultsContainer">
                <div className="timelineResultsInnerContainer">
                  <div className="timelineSearchResultsText">
                    {`${searchResults.length} result${searchResults && searchResults.length !== 1 ? "s" : ""}`}
                  </div>
                </div>
              </div>
              {searchResults &&
                searchResults.map((item, index) => {
                  if ("quizSet" in item) {
                    const totalScore = item.score.correct + item.score.incorrect + item.score.unanswered;
                    const searchInAppName =
                      searchText.trim().length > 0 && "ParaPass".toLowerCase().includes(searchText.toLowerCase());
                    const searchInTitle =
                      searchText.trim().length > 0 && item.title.toLowerCase().includes(searchText.toLowerCase());
                    const searchInDate =
                      searchText.trim().length > 0 &&
                      format(item.sortableDate!, "dd MMM yyyy").toLowerCase().includes(searchText.toLowerCase());

                    let splitAppName: string[] = [];
                    let splitTitle: string[] = [];
                    let splitDate: string[] = [];

                    if (searchInAppName) {
                      const regex = new RegExp(`(${DataController.escapeRegExp(searchText)})`, "gi");
                      splitAppName = "ParaPass".split(regex);
                      splitAppName = splitAppName.filter((item) => item.length > 0);
                    }

                    if (searchInTitle) {
                      const regex = new RegExp(`(${DataController.escapeRegExp(searchText)})`, "gi");
                      splitTitle = item.title.split(regex);
                      splitTitle = splitTitle.filter((item) => item.length > 0);
                    }

                    if (searchInDate) {
                      const regex = new RegExp(`(${DataController.escapeRegExp(searchText)})`, "gi");
                      splitDate = format(item.sortableDate!, "dd MMM yyyy").split(regex);
                      splitDate = splitDate.filter((item) => item.length > 0);
                    }

                    const timelineReference = `${item.quizSet}-${item.date}`;

                    return (
                      <div key={index} className="timelineBlock">
                        <div className="timelineBlockInner">
                          <div className="timelineBlockHeader">
                            <img src={Icon_ParaPass} alt="Parapass app icon" className="timelineBlockAppIcon" />
                            <div className="timelineBlockHeaderAppTitle parapassTitle">
                              {splitAppName.length > 0 ? (
                                <>
                                  {splitAppName.map((split: string, _index: number) => {
                                    const searchTerm = split.toLowerCase() === searchText.toLowerCase();

                                    if (searchTerm) {
                                      return (
                                        <span
                                          key={`parapass-appTitle-${split}-${_index}`}
                                          className="timelineBlockSearchHighlight"
                                        >
                                          {split}
                                        </span>
                                      );
                                    }

                                    return <span key={`parapass-appTitle-${split}-${_index}`}>{split}</span>;
                                  })}
                                </>
                              ) : (
                                <>{"ParaPass"}</>
                              )}
                            </div>
                            <div className="timelineBlockDot" />
                            <div className="timelineBlockHeaderType">
                              {DataController.getParapassQuizType(item.type)}
                            </div>
                          </div>
                          <span className="timelineBlockText">
                            {"Completed "}
                            {splitTitle.length > 0 ? (
                              <>
                                {splitTitle.map((split: string, _index: number) => {
                                  const searchTerm = split.toLowerCase() === searchText.toLowerCase();

                                  if (searchTerm) {
                                    return (
                                      <b key={`parapass-${split}-${_index}`} className="timelineBlockSearchHighlight">
                                        {split}
                                      </b>
                                    );
                                  }

                                  return <b key={`parapass-${split}-${_index}`}>{split}</b>;
                                })}
                              </>
                            ) : (
                              <b>{item.title}</b>
                            )}
                            {" quiz and scored "}
                            <b>{`${item.score.correct}/${totalScore}`}</b>
                          </span>
                          <div className="timelineDateText">
                            <span>
                              {"Taken "}
                              {splitDate.length > 0 ? (
                                <>
                                  {splitDate.map((split: string, _index: number) => {
                                    const searchTerm = split.toLowerCase() === searchText.toLowerCase();

                                    if (searchTerm) {
                                      return (
                                        <span
                                          key={`parapass-${split}-${_index}`}
                                          className="timelineBlockSearchHighlight"
                                        >
                                          {split}
                                        </span>
                                      );
                                    }

                                    return <span key={`parapass-${split}-${_index}`}>{split}</span>;
                                  })}
                                </>
                              ) : (
                                <>{`${format(item.sortableDate!, "dd MMM yyyy")}`}</>
                              )}
                            </span>
                          </div>
                          {hasEvidence(timelineReference) ? (
                            <IonButton
                              mode="ios"
                              className="timelineReadEvidenceButton"
                              onClick={() => readEvidence(timelineReference)}
                            >
                              <div>{"View"}</div>
                            </IonButton>
                          ) : (
                            <IonButton
                              mode="ios"
                              className="timelineAddEvidenceButton"
                              onClick={() => addEvidence(item, timelineReference)}
                            >
                              <div className="timelineAddEvidenceButtonContainer">
                                <div className="timelineAddEvidenceButtonIconContainer">
                                  <IonImg className="timelineAddEvidenceButtonIcon" src={Icon_Add_Evidence} />
                                </div>
                                <div>{"Add evidence"}</div>
                              </div>
                            </IonButton>
                          )}
                        </div>
                      </div>
                    );
                  } else if ("GLID" in item) {
                    const searchInAppName =
                      searchText.trim().length > 0 && "JRCALC".toLowerCase().includes(searchText.toLowerCase());
                    const searchInTitle =
                      searchText.trim().length > 0 && item.title.toLowerCase().includes(searchText.toLowerCase());
                    const searchInDate =
                      searchText.trim().length > 0 &&
                      format(item.sortableDate!, "dd MMM yyyy").toLowerCase().includes(searchText.toLowerCase());

                    let splitAppName: string[] = [];
                    let splitTitle: string[] = [];
                    let splitDate: string[] = [];

                    if (searchInAppName) {
                      const regex = new RegExp(`(${DataController.escapeRegExp(searchText)})`, "gi");
                      splitAppName = "JRCALC".split(regex);
                      splitAppName = splitAppName.filter((item) => item.length > 0);
                    }

                    if (searchInTitle) {
                      const regex = new RegExp(`(${DataController.escapeRegExp(searchText)})`, "gi");
                      splitTitle = item.title.split(regex);
                      splitTitle = splitTitle.filter((item) => item.length > 0);
                    }

                    if (searchInDate) {
                      const regex = new RegExp(`(${DataController.escapeRegExp(searchText)})`, "gi");
                      splitDate = format(item.sortableDate!, "dd MMM yyyy").split(regex);
                      splitDate = splitDate.filter((item) => item.length > 0);
                    }

                    const timelineReference = `${item.GLID}-${item.timestamp.split("T")[0]}`;

                    return (
                      <div key={index} className="timelineBlock">
                        <div className="timelineBlockInner">
                          <div className="timelineBlockHeader">
                            <img src={Icon_Plus} alt="JRCALC Plus app icon" className="timelineBlockAppIcon" />
                            <div className="timelineBlockHeaderAppTitle plusTitle">
                              {splitAppName.length > 0 ? (
                                <>
                                  {splitAppName.map((split: string, _index: number) => {
                                    const searchTerm = split.toLowerCase() === searchText.toLowerCase();

                                    if (searchTerm) {
                                      return (
                                        <span
                                          key={`plus-appTitle-${split}-${_index}`}
                                          className="timelineBlockSearchHighlight"
                                        >
                                          {split}
                                        </span>
                                      );
                                    }

                                    return <span key={`plus-appTitle-${split}-${_index}`}>{split}</span>;
                                  })}
                                </>
                              ) : (
                                <>{"JRCALC"}</>
                              )}
                            </div>
                          </div>
                          <span className="timelineBlockText">
                            {`Viewed ${DataController.getJRCALCGuidelineType(item.GLID)} `}
                            {splitTitle.length > 0 ? (
                              <>
                                {splitTitle.map((split: string, _index: number) => {
                                  const searchTerm = split.toLowerCase() === searchText.toLowerCase();

                                  if (searchTerm) {
                                    return (
                                      <b key={`plus-${split}-${_index}`} className="timelineBlockSearchHighlight">
                                        {split}
                                      </b>
                                    );
                                  }

                                  return <b key={`plus-${split}-${_index}`}>{split}</b>;
                                })}
                              </>
                            ) : (
                              <b>{item.title}</b>
                            )}
                          </span>
                          <div className="timelineDateText">
                            <span>
                              {`Viewed ${item.count ? `${item.count} time${item.count === 1 ? "" : "s"} on ` : ""}`}
                              {splitDate.length > 0 ? (
                                <>
                                  {splitDate.map((split: string, _index: number) => {
                                    const searchTerm = split.toLowerCase() === searchText.toLowerCase();

                                    if (searchTerm) {
                                      return (
                                        <span key={`plus-${split}-${_index}`} className="timelineBlockSearchHighlight">
                                          {split}
                                        </span>
                                      );
                                    }

                                    return <span key={`plus-${split}-${_index}`}>{split}</span>;
                                  })}
                                </>
                              ) : (
                                <>{`${format(item.sortableDate!, "dd MMM yyyy")}`}</>
                              )}
                            </span>
                          </div>
                          {hasEvidence(timelineReference) ? (
                            <IonButton
                              mode="ios"
                              className="timelineReadEvidenceButton"
                              onClick={() => readEvidence(timelineReference)}
                            >
                              <div>{"View"}</div>
                            </IonButton>
                          ) : (
                            <IonButton
                              mode="ios"
                              className="timelineAddEvidenceButton"
                              onClick={() => addEvidence(item, timelineReference)}
                            >
                              <div className="timelineAddEvidenceButtonContainer">
                                <div className="timelineAddEvidenceButtonIconContainer">
                                  <IonImg className="timelineAddEvidenceButtonIcon" src={Icon_Add_Evidence} />
                                </div>
                                <div>{"Add evidence"}</div>
                              </div>
                            </IonButton>
                          )}
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          ) : (
            <div className="evidenceEmptyContainer timelineEmptyContainer">
              <div className="timelineEmptyHeader">{"Reflect on your activity with Timeline"}</div>
              <img src={Image_Timeline_Empty} className="evidenceEmptyImage" alt="" />
              <div className="timelineEmptyText">
                {"Timeline activity is currently only viewable when you log in using the same account you use " +
                  "for your other Class apps (e.g. JRCALC Plus, ParaPass). If you want to reflect on your activity in other apps and add it as evidence, please log in using your associated login."}
              </div>
            </div>
          )}
        </TimelineContainer>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Timeline);
