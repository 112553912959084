import { IonItem } from "@ionic/react";
import { Button_Checkbox_Active, Button_Checkbox_Inactive } from "../../assets/images";
import { IAuditTickbox } from "../../types/Components";
import { StringUtils } from "../../utils/stringUtils";

const AuditTickbox: React.FC<IAuditTickbox> = (props) => {
  return (
    <div className="rounded-md mb-8 border-grey-40 border bg-white overflow-hidden">
      <IonItem button detail={false} onClick={props.toggleValue} className="audit-tickbox-button">
        <div
          className="p-3.5 pr-0.5 flex flex-row items-center w-full justify-between cursor-pointer"
          onClick={() => props.toggleValue()}
        >
          <div className="flex flex-col">
            <div className="reflectionContentCardTitle">{StringUtils.getAuditTickboxTitle()}</div>
            <div className="text-17px font-acc-normal leading-[1.53] tracking-[-0.3px] text-grey-90 acc-underline decoration-current">
              {StringUtils.getAuditTickboxText()}
            </div>
          </div>
          <img
            alt=""
            className="h-icon w-icon img-no-select"
            src={props.active ? Button_Checkbox_Active : Button_Checkbox_Inactive}
          />
        </div>
      </IonItem>
    </div>
  );
};

export default AuditTickbox;
