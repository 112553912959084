import { IonItem } from "@ionic/react";
import "../../App.css";
import { IEvidenceCard } from "../../types/Components";
import "./EvidenceComponents.css";

const EvidenceCard: React.FC<IEvidenceCard> = (props) => {
  return (
    <>
      <IonItem
        button
        detail={false}
        onClick={props.onClick}
        className="evidence-card-item w-full max-w-app-column flex-col relative pl-safe-area-l pr-safe-area-r bg-white shadow-evidence-card shadow-grey-40"
      >
        {props.children}
      </IonItem>
    </>
  );
};

export default EvidenceCard;
