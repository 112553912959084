import React, { useState } from "react";
import { ICustomTagInput } from "../../types/Components";

const CustomTagInput: React.FC<ICustomTagInput> = (props) => {
  const [newTag, setNewTag] = useState("");

  const filteredOldTags = filterOldTags();

  function filterOldTags() {
    if (newTag.length < 2) return []; // let user type in a couple of letters before showing results

    return props.oldTags.filter((tag) => {
      return tag.toLowerCase().startsWith(newTag.toLowerCase());
    });
  }

  function handleOnKeyUp(event: React.KeyboardEvent) {
    if (event.key === "Enter") {
      props.handleAddTag(newTag);
      setNewTag("");
    }
  }

  return (
    <div className="relative">
      <input
        className="reflectionTagsInput"
        placeholder="Hit return to add your custom tag..."
        id="reflectionStandardTagsInput"
        autoComplete="off"
        spellCheck
        value={newTag}
        onChange={(e) => setNewTag(e.target.value)}
        onBlur={() => setNewTag("")}
        onKeyUp={handleOnKeyUp}
      />
      {filteredOldTags.length > 0 && (
        <div id="oldTagsPopover">
          {filteredOldTags.map((tag, i) => (
            <React.Fragment key={i}>
              <div
                className="cursor-pointer font-semibold text-grey-90 p-3 border-b-red-500"
                style={{ fontSize: "17px" }}
                onMouseDown={(e) => e.preventDefault()} // Prevent input blur when clicking a tag
                onClick={(e) => {
                  props.handleAddTag(tag);
                  setNewTag("");
                }}
              >
                {tag}
              </div>
              {i < filteredOldTags.length - 1 && (
                <div className="mx-3" style={{ height: "1px", backgroundColor: "#ced4da" }} />
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomTagInput;
