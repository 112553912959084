import { FileOpener } from "@capacitor-community/file-opener";
import { AppInfo } from "@capacitor/app";
import { Clipboard } from "@capacitor/clipboard";
import { Capacitor } from "@capacitor/core";
import { DeviceInfo } from "@capacitor/device";
import { Directory } from "@capacitor/filesystem";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import WriteBlob from "capacitor-blob-writer";
import _ from "lodash";
import mime from "mime";
import { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router";
import {
  useRecoilStateLoadable,
  useRecoilValue,
  useRecoilValueLoadable,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { Button_Copy, Button_Logout, Button_Menu } from "../../assets/images";
import roleCertificates from "../../assets/json/role-certificates.json";
import roles from "../../assets/json/roles.json";
import ContentContainer from "../../components/common/ContentContainer";
import HeaderTitle from "../../components/common/HeaderTitle";
import DashboardCard from "../../components/home/DashboardCard";
import HCPCProfileCard from "../../components/profile/HCPCProfileCard";
import PersonalStatementModal from "../../components/profile/PersonalStatementModal";
import SummaryOfWorkModal from "../../components/profile/SummaryOfWorkModal";
import AuthenticationController from "../../controllers/AuthenticationController";
import AWSService from "../../controllers/AWSService";
import DataController from "../../controllers/DataController";
import { FirebaseService } from "../../controllers/FirebaseService";
import { useProgramData } from "../../hooks/data/useProgramData";
import { useRefreshJRCALCPlusData } from "../../hooks/data/useRefreshJRCALCPlusData";
import { useRefreshParapassData } from "../../hooks/data/useRefreshParapassData";
import { useRefreshProgressCheckData } from "../../hooks/data/useRefreshProgressCheckData";
import { useRefreshUserDetails } from "../../hooks/data/useRefreshUserDetails";
import { useRefreshUserEvidence } from "../../hooks/data/useRefreshUserEvidence";
import {
  AWSAccessParams,
  AllProgressCheckStatuses,
  IAccessibilitySettings,
  IAppFeatures,
  IEvidence,
  IEvidenceDraft,
  IParapassUsageData,
  IPlusUsageData,
  IUser,
  IUserCertificate,
} from "../../Interfaces";
import {
  accessibilityAtom,
  appFeaturesAtom,
  appInfoAtom,
  debugUserAtom,
  deviceInfoAtom,
  draftsHintDismissedAtom,
  evidenceAtom,
  evidenceDraftsAtom,
  guidelinesCardDismissedAtom,
  parapassDataAtom,
  plusDataAtom,
  programDataAtom,
  programDataVersionInfoAtom,
  programInfoDismissedAtom,
  progressCheckDataAtom,
  userAtom,
  userProgramsAtom,
  welcomeDismissedAtom,
} from "../../state/State";
import { loadableHasValue } from "../../utils/recoilUtils";
import "./Profile.css";

const Profile: React.FC = () => {
  const history = useHistory();

  const { userPrograms } = useProgramData();

  const [user, setUser] = useRecoilStateLoadable<IUser | null>(userAtom);
  const appFeatures = useRecoilValue<IAppFeatures[] | string[]>(appFeaturesAtom);

  const { refreshJRCALCPlusData } = useRefreshJRCALCPlusData();
  const { refreshParapassData } = useRefreshParapassData();
  const { refreshProgressCheckData } = useRefreshProgressCheckData();
  const { refreshUserDetails } = useRefreshUserDetails();
  const { refreshUserEvidence } = useRefreshUserEvidence();

  const deviceInfo = useRecoilValue<DeviceInfo | null>(deviceInfoAtom);
  const appInfo = useRecoilValue<AppInfo | null>(appInfoAtom);

  const setEvidence = useSetRecoilState<IEvidence[] | null>(evidenceAtom);
  const setGuidelinesCardDismissed = useSetRecoilState<boolean>(guidelinesCardDismissedAtom);
  const setWelcomeDismissed = useSetRecoilState<boolean>(welcomeDismissedAtom);
  const setPlusData = useSetRecoilState<IPlusUsageData[] | null>(plusDataAtom);
  const setParapassData = useSetRecoilState<IParapassUsageData[] | null>(parapassDataAtom);
  const setAccessibilitySettings = useSetRecoilState<IAccessibilitySettings | null>(accessibilityAtom);
  const setInfoDismissed = useSetRecoilState<any[]>(programInfoDismissedAtom);
  const setDraftsHintDismissed = useSetRecoilState<boolean>(draftsHintDismissedAtom);
  const setDrafts = useSetRecoilState<IEvidenceDraft[] | null>(evidenceDraftsAtom);
  const setAllProgressCheckData = useSetRecoilState<AllProgressCheckStatuses[]>(progressCheckDataAtom);

  const resetUser = useResetRecoilState(userAtom);
  const resetAllProgressCheckData = useResetRecoilState(progressCheckDataAtom);
  const resetUserPrograms = useResetRecoilState(userProgramsAtom);

  const debugUserLoadable = useRecoilValueLoadable(debugUserAtom);
  const setDebugUser = useSetRecoilState(debugUserAtom);
  const programDataLoadable = useRecoilValueLoadable(programDataAtom);
  const setProgramData = useSetRecoilState(programDataAtom);
  const programVersionInfoLoadable = useRecoilValueLoadable(programDataVersionInfoAtom);
  const setProgramVersionInfo = useSetRecoilState(programDataVersionInfoAtom);

  const [summaryOfWorkModalVisible, setSummaryOfWorkModalVisible] = useState<boolean>(false);
  const [personalStatementModalVisible, setPersonalStatementModalVisible] = useState<boolean>(false);

  const [viewingCertificate, setViewingCertificate] = useState<boolean>(false);
  const [viewingCertificateFilename, setViewingCertificateFilename] = useState<string>("");

  const [hasRoleCertificates, setHasRoleCertificates] = useState<boolean>(false);
  const [certificatesForRole, setCertificatesForRole] = useState<IUserCertificate[]>([]);

  const [syncingData, setSyncingData] = useState<boolean>(false);

  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    const setPageName = async (): Promise<void> => {
      await FirebaseService.setScreenName("profile");
    };

    setPageName();
  }, []);

  useEffect(() => {
    const checkCertificates = (): void => {
      const userRole = user && user.contents.role;
      let certificates = _.cloneDeep(user.contents.certificates) || [];
      let mappedCertificates = certificates.map((item: IUserCertificate) => item.certificate?.certificateType);

      let _role = roles.find((item) => item.Name === userRole);

      if (_role) {
        let _certificates = roleCertificates.filter((item) => item.Roles.includes(_role?.id || ""));
        _certificates = _certificates.filter((item) => mappedCertificates.includes(item.Name));

        let _mappedCertificates = _certificates.map((item) => item.Name);
        certificates = certificates.filter(
          (item: IUserCertificate) =>
            item.certificate && _mappedCertificates.includes(item.certificate?.certificateType)
        );

        setHasRoleCertificates(_certificates.length > 0);
        setCertificatesForRole(certificates);
      }
    };

    if (user.state === "hasValue" && user.contents) {
      checkCertificates();
    }
  }, [user]);

  const logout = async (): Promise<void> => {
    await AuthenticationController.logout();
    setEvidence(null);
    setPlusData(null);
    setParapassData(null);
    setAccessibilitySettings(null);
    setWelcomeDismissed(false);
    setDraftsHintDismissed(false);
    setGuidelinesCardDismissed(false);
    setInfoDismissed([]);
    setDrafts(null);
    resetUser();
    resetAllProgressCheckData();
    resetUserPrograms();
    loadableHasValue(debugUserLoadable) && setDebugUser(false);
    loadableHasValue(programDataLoadable) && setProgramData([]);
    loadableHasValue(programVersionInfoLoadable) && setProgramVersionInfo([]);

    history.replace("/onboarding/login");
  };

  const openCertificate = async (item: IUserCertificate): Promise<void> => {
    try {
      setViewingCertificate(true);
      setViewingCertificateFilename(item.certificate?.name || "");

      const accessParams: AWSAccessParams = await AWSService.generateSTSToken(user.contents);

      const file = await AWSService.openCertificateFromServer(user.contents, accessParams, item.certificate);

      if (await DataController.isWebAsync()) {
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(file.Body.data)], {
            type: file.ContentType,
          })
        );
        window.open(url, "_blank");
      } else {
        const blob = new Blob([new Uint8Array(file.Body.data)], {
          type: file.ContentType,
        });

        const filePath = await WriteBlob({
          path: `ParaFolio/Certificates/${item.certificate?.name}.${mime.getExtension(file.ContentType)}`,
          blob,
          directory: Directory.Library,
          recursive: true,
        });

        FileOpener.open({
          filePath,
        });
      }

      setViewingCertificate(false);
      setViewingCertificateFilename("");
    } catch (error) {
      console.log(error);
      window.alert("An error occurred when opening certificate");
      setViewingCertificate(false);
      setViewingCertificateFilename("");
    }
  };

  const navigateToPage = (page: string): void => {
    history.push(page);
  };

  const copyHCPCButton = async (): Promise<void> => {
    setCopied(true);

    if (await DataController.isWebAsync()) {
      navigator.clipboard.writeText(user.contents?.HCPCNumber);
    } else {
      Clipboard.write({
        string: user.contents?.HCPCNumber,
      });
    }

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const toggleSummaryOfWorkModalVisible = (value: boolean): void => {
    setSummaryOfWorkModalVisible(value);
  };

  const togglePersonalStatementModalVisible = (value: boolean): void => {
    setPersonalStatementModalVisible(value);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonMenuButton>
              <IonImg src={Button_Menu} className="menuIcon" />
            </IonMenuButton>
          </IonButtons>
          <HeaderTitle>{"Profile"}</HeaderTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background">
        <SummaryOfWorkModal
          visible={summaryOfWorkModalVisible}
          closeModal={(value) => toggleSummaryOfWorkModalVisible(value)}
        />
        <PersonalStatementModal
          visible={personalStatementModalVisible}
          closeModal={(value) => togglePersonalStatementModalVisible(value)}
        />
        <ContentContainer id="profileContentContainer">
          <DashboardCard
            title={user ? `${user.state === "hasValue" ? user.contents?.name || user.contents?.email : ""}` : "User"}
            subtitle="Your details"
            isProfile
            sideButton
            sideButtonText="Edit"
            buttonClicked={() => navigateToPage("/profile/edit")}
          >
            <div style={{ marginTop: -22 }}>
              <div className="homeCardUserInfoVertical">
                <div className="homeCardUserDetailsHeader">{"Email"}</div>
                <div className="homeCardUserDetailsText">{user.contents?.email || "Unknown"}</div>
              </div>
              {appFeatures.includes(IAppFeatures.SWAST_MIC) && user.state === "hasValue" && user.contents?.role && (
                <div className="homeCardUserInfoVertical">
                  <div className="homeCardUserDetailsHeader">{"Your roles"}</div>
                  <div className="homeCardUserDetailsText">{user.contents?.role || "Unknown"}</div>
                </div>
              )}
              {appFeatures.includes(IAppFeatures.SWAST_MIC) &&
                user.state === "hasValue" &&
                user.contents?.certificates &&
                hasRoleCertificates && (
                  <div className="homeCardUserInfoVertical">
                    <div className="homeCardUserDetailsHeader">{"Certificates"}</div>
                    {certificatesForRole.map((item, index) => {
                      const disabled = viewingCertificate && viewingCertificateFilename !== item.certificate?.name;
                      const viewing = viewingCertificate && viewingCertificateFilename === item.certificate?.name;

                      return (
                        <button
                          disabled={disabled}
                          key={item.certificate?.name || index}
                          className={`homeCardUserInfoButtonSlim ${viewing ? "homeCardUserInfoButtonSlimWait" : ""} ${disabled ? "homeCardUserInfoButtonSlimDisabled" : ""}`}
                          onClick={() => !(disabled || viewing) && openCertificate(item)}
                        >
                          <div className="homeCardCertificateTextGeneral">{item.certificate?.name || "Unknown"}</div>
                          {viewing && <IonSpinner className="homeCardCertificateSpinner" />}
                        </button>
                      );
                    })}
                  </div>
                )}
              {user.state === "hasValue" && user.contents?.HCPCNumber && (
                <div className="homeCardUserInfoVertical">
                  <div className="homeCardUserDetailsHeader">{"Registration number"}</div>
                  <div className="homeCardHCPCRegistrationNumberContainer">
                    <div className="homeCardHCPCRegistrationNumber">{user.contents?.HCPCNumber || "Unknown"}</div>
                    <button className="homeCardUserCopyButton" disabled={copied} onClick={copyHCPCButton}>
                      {copied ? (
                        <div className="homeCardUserCopyButtonText">{"Copied"}</div>
                      ) : (
                        <>
                          <div className="homeCardUserCopyButtonText">{"Copy"}</div>
                          <img src={Button_Copy} className="hcpcCopyIcon" alt="" />
                        </>
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-3 border-t" style={{ marginBottom: "-16px", marginLeft: "-14px", marginRight: "-14px" }}>
              <IonItem button={true} onClick={logout} className="profileSettingsButton" detail={false} lines="none">
                <div className="profileSettingsButtonContainer">
                  <div className="profileSettingsButtonInnerContainer">
                    <IonImg src={Button_Logout} className={`profileSettingIcon`} />
                    <IonLabel className={`profileSettingButtonTitle ${"profileSettingLogout"}`}>Sign out</IonLabel>
                  </div>
                </div>
              </IonItem>
            </div>
          </DashboardCard>
          <HCPCProfileCard
            personalStatementPressed={() => togglePersonalStatementModalVisible(true)}
            summaryOfWorkPressed={() => toggleSummaryOfWorkModalVisible(true)}
          />
        </ContentContainer>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Profile);
