import React, { useEffect, useState } from "react";
import "../../theme/tailwind.css";
import { IProgramCardPreceptorshipProgress } from "../../types/Components";
import {
  AllProgressCheckStatuses,
  IProgressCheckInfo,
  IUser,
  ProgressCheckApproval,
  ProgressState,
  SubmissionState,
} from "../../Interfaces";
import * as ProgramUtils from "../../utils/programUtils";
import { useRecoilValueLoadable } from "recoil";
import { userAtom } from "../../state/State";
import { CommentHelpers } from "../../utils/commentHelpers";
import { IonImg } from "@ionic/react";
import { Image_Completion } from "../../assets/images";

const ProgramCardPreceptorshipProgress: React.FC<IProgramCardPreceptorshipProgress> = (props) => {
  const user = useRecoilValueLoadable<IUser | null>(userAtom);

  const [currentProgressCheck, setCurrentProgressCheck] = useState<IProgressCheckInfo | null>();

  useEffect(() => {
    const getProgressCheckData = (): void => {
      const dataForProgram: AllProgressCheckStatuses | undefined = props.progressCheckData?.find(
        (item: any): item is AllProgressCheckStatuses => item.programID === props.program?.ID
      );

      let object: IProgressCheckInfo | null = null;

      for (let i = 0; i < props.progressChecks!.length; i++) {
        const check = props.progressChecks![i];
        const progressCheckStatus = dataForProgram?.pCs.find((item) => item.pCId === check.ID);

        if (progressCheckStatus?.submissions.status === SubmissionState.Approved) {
          continue;
        }

        const progressCheckEvidence = ProgramUtils.getEvidenceForProgressCheck(props.programEvidence, check.ID);

        // console.log(dataForProgram, check.ID, progressCheckStatus);

        let status = ProgressState.NotStarted;

        if (progressCheckEvidence?.length > 0) {
          const completed = ProgramUtils.isAllEvidenceAddedForProgressCheck(
            progressCheckEvidence,
            check["Competence/Activity"]
          );

          if (completed) {
            if (check.Approval === ProgressCheckApproval.NotRequired) {
              continue;
            } else if (progressCheckStatus?.submissions.status === SubmissionState.Submitted) {
              status = ProgressState.Submitted;
            } else {
              status = ProgressState.ReadyForSubmission;
            }
          } else {
            status = ProgressState.InProgress;
          }
        }

        object = {
          progressCheck: check,
          progressCheckStatus,
          progressCheckEvidence,
          status,
        };

        break;
      }

      setCurrentProgressCheck(object);
    };

    if (props.progressCheckData && props.progressChecks) {
      getProgressCheckData();
    }
  }, [props.progressCheckData, props.program, props.progressChecks, props.programEvidence]);

  if (
    ProgramUtils.isProgramCompleteNQP(
      props.progressChecks,
      props.progressCheckData,
      props.programEvidence,
      props.program?.ID
    )
  ) {
    return (
      <div className="flex flex-col">
        <div className="text-[13px] font-acc-normal leading-default tracking-[-0.05px] text-grey-90">
          {props.program?.CompletionText ?? `You've completed your ${props.program?.Title} program. Well done!`}
        </div>
        <IonImg className="self-center w-[160px] h-[112px] shrink-0 mt-[10px]" src={Image_Completion} />
      </div>
    );
  }

  if (!currentProgressCheck) {
    return null;
  }

  return (
    <div>
      <div className="text-[13px] leading-default tracking-[-0.1px] text-grey-70">{"Current progress check"}</div>
      <div className="flex flex-row justify-between items-center">
        <div className="text-[17px] leading-default tracking-[-0.3px] text-grey-90">
          {currentProgressCheck.progressCheck.Name}
        </div>
        <div
          className={`flex shrink-0 items-center px-[8px] justify-center h-20px rounded-[10px] bg-${ProgramUtils.getProgressStatusBackgroundColor(currentProgressCheck.status)}`}
        >
          <div
            className={`text-[13px] my-[2.25px] font-semibold ${ProgramUtils.getProgressStatusTextColor(currentProgressCheck.status)}`}
          >
            {currentProgressCheck.status}
          </div>
        </div>
      </div>
      {currentProgressCheck.progressCheck.CommentsSupported && (
        <div>
          <div className="mt-[12px] mb-[4px] text-[13px] leading-default tracking-[-0.1px] text-grey-70">
            {"Comments"}
          </div>
          {CommentHelpers.showNewCommentsCardLabel(
            user.contents,
            currentProgressCheck.progressCheckStatus?.comments,
            currentProgressCheck.progressCheckStatus?.commentReadTimes
          ) ? (
            <div className="inline-flex flex-row self-start grow-0 items-center px-[8px] justify-start h-20px rounded-[10px] bg-cta-red">
              <div className="text-[13px] my-[2.25px] font-semibold text-white">
                {CommentHelpers.getFormattedUnreadCommentCount(
                  user.contents,
                  currentProgressCheck.progressCheckStatus?.comments,
                  currentProgressCheck.progressCheckStatus?.commentReadTimes
                )}
              </div>
            </div>
          ) : (
            <div className="inline-flex flex-row self-start grow-0 items-center px-[8px] justify-start h-20px rounded-[10px] bg-green-background">
              <div className="text-[13px] my-[2.25px] font-semibold text-green-text">{"Up to date"}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProgramCardPreceptorshipProgress;
