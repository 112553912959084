import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonImg, IonPage, IonToolbar } from "@ionic/react";
import { EvidenceContainer, HeaderTitle } from "parafolio-components";
import DataController from "../../controllers/DataController";
import { Capacitor } from "@capacitor/core";
import { useParams } from "react-router";
import { useHidingTabBar } from "../../hooks/tabs/useHidingTabBar";
import { useRefreshProgramData } from "../../hooks/programs/useRefreshProgramData";
import { useProgramData } from "../../hooks/data/useProgramData";
import { Button_Audio_Play_White, Button_Audio_Stop } from "../../assets/images";
import WellbeingResultsContainer from "../../components/wellbeing/WellbeingResultsContainer";
import { useEffect, useState } from "react";
import DeleteWellbeingActivityModal from "../../components/programs/DeleteWellbeingActivityModal";
import { useRecoilStateLoadable, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { accessibilityAtom, evidenceAtom, userAtom } from "../../state/State";
import { IAccessibilitySettings, IEvidence, IUser } from "../../Interfaces";
import { useTextToSpeech } from "../../hooks/accessibility/useTextToSpeech";
import { DatabaseService } from "../../controllers/DatabaseService";
import { EventRegister } from "react-native-event-listeners";

const WellbeingResults: React.FC = () => {
  const params = useParams<{ programId: string; progressCheckId: string; competenceId: string; evidenceId: string }>();

  const { userPrograms } = useProgramData();

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const [evidence, setEvidence] = useRecoilStateLoadable<IEvidence[] | null>(evidenceAtom);
  const accessibilitySettings = useRecoilValue<IAccessibilitySettings | null>(accessibilityAtom);

  useHidingTabBar();
  const { handleTTS, playing, stopTTS } = useTextToSpeech("wellbeing-results");

  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [deletingActivity, setDeletingActivity] = useState<boolean>(false);
  const [deleteEvidenceModalVisible, setDeleteEvidenceModalVisible] = useState<boolean>(false);

  const wellbeingEvidence: IEvidence = evidence.contents?.find(
    (item: any): item is IEvidence => item.id === params.evidenceId
  );

  const program = userPrograms?.find((program) => program.ID === params.programId)!;

  const wellbeingActivity = program?.WellbeingModules?.find(
    (wellbeingModule) => wellbeingModule.CompetenceID === params.competenceId
  );

  useEffect(() => {
    return () => {
      stopTTS();
    };
  }, []);

  const deleteActivity = async (): Promise<void> => {
    try {
      if (wellbeingEvidence.id) {
        setDeletingActivity(true);
        const deletedFromDB = await DatabaseService.deleteEvidence(user.contents, wellbeingEvidence.id);

        if (deletedFromDB) {
          let allEvidence = [];
          allEvidence = await DatabaseService.getEvidence(user.contents);
          await DataController.saveEvidence(allEvidence);

          EventRegister.emit("evidence/evidence-deleted");
          setEvidence(allEvidence);
          setDeletingActivity(false);
          setDeleteEvidenceModalVisible(false);
        }
      }
    } catch (error) {
      setDeletingActivity(false);
      window.alert("An error occurred when deleting this wellbeing activity");
    }
  };

  const deleteClicked = async (): Promise<void> => {
    setDeleteEvidenceModalVisible(true);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons>
            <IonBackButton
              className="header-back-buttons"
              defaultHref={`/dashboard/program/${params.programId}/progress-check/${params.progressCheckId}`}
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{"Wellbeing Activity"}</HeaderTitle>
          <IonButtons slot="end">
            {accessibilitySettings?.textToSpeech && (
              <IonButton className="header-button" mode="ios" onClick={() => handleTTS()}>
                <IonImg src={playing ? Button_Audio_Stop : Button_Audio_Play_White} className="headerIcon" />
              </IonButton>
            )}
            {canDelete && (
              <IonButton mode="ios" className="cancelButton" onClick={() => deleteClicked()}>
                {"Delete"}
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="wellbeing-results-container" className="page-background !py-0 force-scroll-auto">
        <DeleteWellbeingActivityModal
          visible={deleteEvidenceModalVisible}
          deleting={deletingActivity}
          deletePressed={() => deleteActivity()}
          cancelPressed={() => setDeleteEvidenceModalVisible(false)}
        />
        {wellbeingEvidence && (
          <EvidenceContainer style={{ paddingTop: 0, alignSelf: "center", paddingBottom: 0 }}>
            <div className="!min-h-full" id="wellbeing-results">
              {wellbeingActivity && (
                <WellbeingResultsContainer program={program} wellbeingActivity={wellbeingActivity} />
              )}
            </div>
          </EvidenceContainer>
        )}
      </IonContent>
    </IonPage>
  );
};

export default WellbeingResults;
