import { Capacitor } from "@capacitor/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import _ from "lodash";
import { CenterContainer, CommentsCard, HeaderTitle } from "parafolio-components";
import React, { useEffect, useState } from "react";
import { EventRegister } from "react-native-event-listeners";
import { useHistory, useParams } from "react-router";
import { useRecoilValueLoadable } from "recoil";
import { Icon_Add_Evidence, Icon_Refresh } from "../../assets/images";
import ProgramInfoCard from "../../components/home/ProgramInfoCard";
import EvidenceDisclaimer from "../../components/programs/EvidenceDisclaimer";
import ProgramCardButton from "../../components/programs/ProgramCardButton";
import ProgramEvidenceCard from "../../components/programs/ProgramEvidenceCard";
import ProgressCheckDescription from "../../components/programs/ProgressCheckDescription";
import SubmitProgressCheckModal from "../../components/programs/SubmitProgressCheckModal";
import DataController from "../../controllers/DataController";
import { ProgressCheckService } from "../../controllers/ProgressCheckService";
import { useProgramData } from "../../hooks/data/useProgramData";
import {
  AllProgressCheckStatuses,
  CompetenceActivityClass,
  FilledInBy,
  IEvidence,
  IUser,
  LearningObjective,
  ProgressCheck,
  ProgressCheckApproval,
  ProgressCheckStatus,
  SubmissionState,
} from "../../Interfaces";
import { evidenceAtom, progressCheckDataAtom, userAtom } from "../../state/State";
import { CommentHelpers } from "../../utils/commentHelpers";
import * as EvidenceUtils from "../../utils/evidenceUtils";
import * as ProgramUtils from "../../utils/programUtils";
import ProgressCheckEvidenceButton, { CompetenceStatus } from "../../components/programs/ProgressCheckEvidenceButton";
import MonthlyEvidenceBreakdown from "../../components/evidence/MonthlyEvidenceBreakdown";
import { useRefreshProgressCheckData } from "../../hooks/data/useRefreshProgressCheckData";
import { useProgramModuleStatus } from "../../hooks/programs/useProgramModuleStatus";

const ProgressCheckPage: React.FC = () => {
  const history = useHistory();
  const params = useParams<{ programId: string; progressCheckId: string }>();
  const { userPrograms } = useProgramData();

  const { refreshProgressCheckData } = useRefreshProgressCheckData();
  const { programModuleStatus, fastTrackActive, pauseAndHold } = useProgramModuleStatus(params.programId);

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const evidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);
  const allProgressCheckData = useRecoilValueLoadable<AllProgressCheckStatuses[]>(progressCheckDataAtom);

  const program = userPrograms.find((item) => item.ID === params.programId)!;
  const [progressCheck, setProgressCheck] = useState<ProgressCheck | any | null>(null);
  const [progressCheckEvidence, setProgressCheckEvidence] = useState<IEvidence[]>([]);
  const [competences, setCompetences] = useState<CompetenceActivityClass[]>([]);
  const [allEvidenceAdded, setAllEvidenceAdded] = useState<boolean>(false);
  const [progressChecks, setProgressChecks] = useState<ProgressCheck[]>([]);

  const [currentProgressCheckData, setCurrentProgressCheckData] = useState<AllProgressCheckStatuses | null>(null);
  const [progressCheckData, setProgressCheckData] = useState<ProgressCheckStatus | null>(null);

  const [submitProgressCheckModalVisible, setSubmitProgressCheckModalVisible] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [refreshingData, setRefreshingData] = useState<boolean>(false);

  useEffect(() => {
    EventRegister.addEventListener("program/data-refreshed", () => setRefreshingData(false));

    return () => {
      EventRegister.removeEventListener("program/data-refreshed");
    };
  }, []);

  useEffect(() => {
    const getProgramData = (): void => {
      if (program) {
        const allProgressChecks: ProgressCheck[] = program.ProgressChecks ?? [];

        const _progressCheck: ProgressCheck | undefined = allProgressChecks.find(
          (item: any): item is ProgressCheck => item.ID === params.progressCheckId
        );

        setProgressChecks(allProgressChecks);

        if (_progressCheck) {
          setProgressCheck(_progressCheck);

          if (_progressCheck["Competence/Activity"]) {
            if (fastTrackActive) {
              setCompetences(_progressCheck["Competence/Activity"]);
            } else {
              const _competences = _progressCheck["Competence/Activity"].filter((item) => !item.FastTrackEvidence);
              setCompetences(_competences);
            }
          }
        }
      }
    };

    getProgramData();
  }, [program?.version, fastTrackActive]);

  useEffect(() => {
    const getProgressCheckData = (): void => {
      const dataForProgram: AllProgressCheckStatuses = allProgressCheckData.contents?.find(
        (item: any): item is AllProgressCheckStatuses => item.programID === program?.ID
      );

      const dataForProgressCheck = dataForProgram?.pCs.find((item) => item.pCId === progressCheck?.ID);

      dataForProgram && setCurrentProgressCheckData(dataForProgram);
      dataForProgressCheck && setProgressCheckData(dataForProgressCheck);
    };

    if (allProgressCheckData.state === "hasValue") {
      getProgressCheckData();
    }
  }, [allProgressCheckData, program, progressCheck]);

  useEffect(() => {
    const getEvidenceForProgressCheck = (): void => {
      let evidenceForProgressCheck: IEvidence[];

      evidenceForProgressCheck = evidence.contents?.filter((evidence: IEvidence) => {
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
        const id = evidenceJSON?.programInfo?.progressCheckID || "";

        return id === progressCheck?.ID;
      });

      evidenceForProgressCheck = _.orderBy(evidenceForProgressCheck, "date", "desc");

      setProgressCheckEvidence(evidenceForProgressCheck);
    };

    if (evidence.state === "hasValue" && progressCheck) {
      getEvidenceForProgressCheck();
    }
  }, [evidence, progressCheck]);

  useEffect(() => {
    const checkAllEvidenceAddedForProgressCheck = (): void => {
      let evidenceForProgressCheck: IEvidence[] = _.cloneDeep(progressCheckEvidence);

      evidenceForProgressCheck = evidenceForProgressCheck.filter((item) => {
        const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);

        const onHoldReset = evidenceJSON?.OnHoldReset === 1;

        return !onHoldReset && item.draft !== true;
      });

      const allCompetences = _.cloneDeep(competences);

      let allEvidenceAdded = false;

      if (ProgramUtils.canHandleMultiplePiecesOfEvidencePerEvidenceType(program.ID)) {
        if (ProgramUtils.canSubmitProgressCheck(progressCheck.Approval)) {
          const progressCheckIDs = evidenceForProgressCheck.map((item: IEvidence) => {
            const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);

            return evidenceJSON?.programInfo?.progressCheckCompetenceID;
          });

          const requiredCompetences = allCompetences.filter(
            (competence: CompetenceActivityClass) =>
              competence["Number Evidences required"] && competence["Number Evidences required"] > 0
          );
          const competenceIDs = requiredCompetences.map((item) => item.ID).sort();
          allEvidenceAdded = competenceIDs.every((id) => progressCheckIDs.includes(id));
        }
      } else {
        const progressCheckIDs = evidenceForProgressCheck.map((item: IEvidence) => {
          const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);

          return evidenceJSON?.programInfo?.progressCheckCompetenceID;
        });
        const competenceIDs = allCompetences.map((item) => item.ID).sort();
        allEvidenceAdded = competenceIDs.every((id) => progressCheckIDs.includes(id));
      }

      setAllEvidenceAdded(allEvidenceAdded);
    };

    if (progressCheckEvidence.length > 0) {
      checkAllEvidenceAddedForProgressCheck();
    }
  }, [competences, progressCheckEvidence]);

  const addEvidencePressed = (competence?: CompetenceActivityClass): void => {
    if (competence?.isWellbeingActivity) {
      const programID = program?.ID;
      const progressCheckID = progressCheck?.ID;
      const competenceID = competence?.ID;

      history.push(
        `/dashboard/program/${programID}/progress-check/${progressCheckID}/wellbeing-activity/${competenceID}`
      );
    } else {
      const progressChecks = program.ProgressChecks ?? [];
      let progressCompetence = competence;

      if (!progressCompetence && progressCheck?.["Competence/Activity"].length === 1) {
        progressCompetence = progressCheck?.["Competence/Activity"][0];
      }

      const data = {
        program: program,
        // progressCompetences: competences, // commented out because these were overwriting removeProgressCheckCompetences() behaviour
        ...(progressCompetence && { progressCompetence }),
        progressCheck: progressCheck,
        ...(progressChecks.length > 0 && { progressChecks }),
        learningOutcomes: competence?.["Learning Objective"] ?? [],
      };

      EventRegister.emit("evidence/add-progress-check-evidence", data);
    }
  };

  const goToComments = (): void => {
    const programID = program?.ID;
    const progressCheckID = progressCheck?.ID;

    history.push(`/dashboard/program/${programID}/progress-check/${progressCheckID}/comments`);
  };

  const evidenceCardPressed = (item: IEvidence): void => {
    let _evidence = _.pickBy(_.cloneDeep(item), _.identity);

    const evidenceJSON = _evidence.evidenceJSON && JSON.parse(_evidence.evidenceJSON);
    const progressCheckCompetenceID = evidenceJSON?.programInfo?.progressCheckCompetenceID;

    const programID = program?.ID;
    const progressCheckID = progressCheck?.ID;

    const competence = competences.find((item: CompetenceActivityClass) => item.ID === progressCheckCompetenceID);

    if (competence?.isWellbeingActivity) {
      history.push(
        `/dashboard/program/${programID}/progress-check/${progressCheckID}/wellbeing-activity/${progressCheckCompetenceID}/results/${_evidence.id}`
      );
    } else {
      history.push(`/dashboard/program/${programID}/progress-check/${progressCheckID}/read/${_evidence.id}`);
    }
  };

  const submitProgressCheck = async (): Promise<void> => {
    try {
      setSubmitting(true);
      let submitted = false;

      submitted = await ProgressCheckService.updateProgressCheck(user.contents, program?.ID, progressCheck?.ID);
      submitted && (await refreshProgressCheckData(user.contents));

      setSubmitting(false);
      setSubmitProgressCheckModalVisible(false);
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  };

  const submitButtonPressed = (): void => {
    setSubmitProgressCheckModalVisible(true);
  };

  const getSubmissionCardHeaderText = (progressCheck: ProgressCheck, data: ProgressCheckStatus | null): string => {
    if (data) {
      if (data.submissions.status === SubmissionState.Submitted) {
        return progressCheck.SubmissionWaitingText ?? "";
      } else if (data.submissions.status === SubmissionState.OnHoldReset) {
        return progressCheck.SubmissionRejectionResetText ?? "";
      } else if (data.submissions.status === SubmissionState.OnHold) {
        return progressCheck.SubmissionRejectionText ?? "";
      }
    }

    return progressCheck.SubmissionHeaderText ?? "";
  };

  const getSubmissionSidePillText = (data: ProgressCheckStatus | null): string | undefined => {
    if (data) {
      if (data.submissions.status === SubmissionState.OnHoldReset) {
        return "Submission reset";
      } else if (data.submissions.status === SubmissionState.OnHold) {
        return "Submission held";
      }
    }

    return undefined;
  };

  const submissionHasError = (data: ProgressCheckStatus | null): boolean | undefined => {
    if (data) {
      if (data.submissions.status === SubmissionState.OnHoldReset) {
        return true;
      } else if (data.submissions.status === SubmissionState.OnHold) {
        return true;
      }
    }

    return undefined;
  };

  const refreshUserProgramData = (): void => {
    setRefreshingData(true);

    EventRegister.emit("program/refresh-button", user.contents);
  };

  const getDateOfProgressCheckApproval = () => {
    let day = new Date().getDate();
    let month = new Date().toLocaleString("default", { month: "short" });
    let year = new Date().getFullYear();

    progressCheckEvidence
      .filter((e) => !e.draft)
      .forEach((evidence) => {
        const parsedEvidence = JSON.parse(evidence.evidenceJSON);
        const onHoldReset = parsedEvidence.onHoldReset ?? 0;
        if (onHoldReset === 0 && parsedEvidence.progressCheckID === progressCheck.ID) {
          day = new Date(evidence.date).getDate();
          month = new Date(evidence.date).toLocaleString("default", { month: "short" });
          year = new Date(evidence.date).getFullYear();
        }
      });

    return `${day} ${month} ${year}`;
  };

  const getEvidenceLabel = (comp: CompetenceActivityClass): string | undefined => {
    if (!ProgramUtils.includeSubtitleInProgressCheckEvidenceButton(program.ID)) return;

    let label;

    let evidenceUploaded = 0;
    progressCheckEvidence
      .filter((e) => !e.draft)
      .forEach((e) => {
        try {
          const parsedEvidence = JSON.parse(e.evidenceJSON);
          const onHoldReset = parsedEvidence.OnHoldReset ?? 0;
          if (onHoldReset !== 1) {
            if (parsedEvidence?.programInfo?.progressCheckCompetenceID === comp.ID) {
              evidenceUploaded += 1;
            }
          }
        } catch (error) {
          console.log(error);
        }
      });

    const evidenceRequired = comp["Number Evidences required"];

    if (evidenceUploaded === 0 && !evidenceRequired) {
      label = "Optional";
    } else {
      label = evidenceRequired ? `${evidenceUploaded}/${evidenceRequired} evidence` : `${evidenceUploaded} evidence`;
    }

    return label;
  };

  const getLearningOutcomesLabel = (comp: CompetenceActivityClass): string | undefined => {
    if (!ProgramUtils.includeSubtitleInProgressCheckEvidenceButton(program.ID)) return;

    let label;

    // add learning outcomes suffix // TODO change for CHUB -- NEW
    if (comp["Learning Objective"] && comp["Learning Objective"]?.length) {
      // get los required
      let losRequired: LearningObjective[] = [];
      comp["Learning Objective"].forEach((currentLearningOutcome) => {
        const loFound = losRequired.find((lo) => lo.ID === currentLearningOutcome.ID);
        if (currentLearningOutcome.NumberRequired && !loFound) {
          losRequired.push(currentLearningOutcome);
        }
      });

      // get los done
      const filteredEvidence = progressCheckEvidence.filter((e) => {
        const parsed = JSON.parse(e.evidenceJSON);
        return !e.draft && !parsed.OnHoldReset;
      });
      let losDone = 0;
      for (const lo of comp["Learning Objective"]) {
        const numRequired = !lo.NumberRequired || lo.NumberRequired === 0 ? 1 : lo.NumberRequired;
        let countOfLearningOutcomesDone = 0;
        for (const evidence of filteredEvidence) {
          const parsedEvidence = JSON.parse(evidence.evidenceJSON);
          // los can appear in the "Comp Selector" or the "LearningOutcomes" key, so check both
          if (parsedEvidence["Comp Selector"]) {
            Array.from(parsedEvidence["Comp Selector"]).forEach((it: any) => {
              it.selectedOutcomes.forEach((outcome: any) => {
                if (outcome.id === lo.ID) {
                  countOfLearningOutcomesDone += 1;
                }
              });
            });
          }
          if (parsedEvidence["LearningOutcomes"]) {
            Array.from(parsedEvidence["LearningOutcomes"]).forEach((outcome: any) => {
              if (outcome.ID === lo.ID) {
                countOfLearningOutcomesDone += 1;
              }
            });
          }
          if (parsedEvidence["LearningObjectivesTickboxes"]) {
            Array.from(parsedEvidence["LearningObjectivesTickboxes"].selectedOutcomes).forEach((outcome: any) => {
              if (outcome.id === lo.ID) {
                countOfLearningOutcomesDone += 1;
              }
            });
          }
        }

        if (countOfLearningOutcomesDone >= numRequired) losDone += 1;
      }

      if (ProgramUtils.removeMandatoryCountInProgressCheckEvidenceButton(params.programId)) {
        label = `${losDone}/${comp["Learning Objective"].length} Learning Outcomes`;
      } else {
        label = `${losDone}/${comp["Learning Objective"].length} Learning Outcomes (${losRequired.length} mandatory)`;
      }
    }

    return label;
  };

  // "In Progress" | "Added" | "Open" | "Locked" | "Mentor to Add" // TODO check when to return "Mentor to Add" status
  const getCompetenceStatus = (comp: CompetenceActivityClass): CompetenceStatus => {
    let status: CompetenceStatus = "Open";

    const progressCheckLocked = ProgramUtils.checkIfProgressCheckIsLocked(
      progressCheck,
      progressChecks,
      evidence.contents,
      currentProgressCheckData,
      program.ID,
      fastTrackActive,
      pauseAndHold
    );

    if (ProgramUtils.isMTS(program.ID) || ProgramUtils.isCHUB(program.ID)) {
      if (
        progressCheckLocked ||
        ProgramUtils.isProgressCheckApprovedBasedOnCompTrigger(progressCheckEvidence, competences)
      ) {
        status = "Locked";
        return status;
      }
    } else {
      if (ProgramUtils.isNQPProgram(program.ID)) {
        const NQPProgramComplete = ProgramUtils.isProgramCompleteNQP(
          program.ProgressChecks,
          allProgressCheckData.contents,
          evidence.contents,
          program.ID
        );

        if (NQPProgramComplete) {
          status = "Locked";
          return status;
        }
      }

      if (progressCheckLocked) {
        status = "Locked";
        return status;
      }
    }

    if (comp.isFinalReview) {
      const evidenceStatus = EvidenceUtils.hasReachedEvidenceCount(progressCheckEvidence, comp);
      if (evidenceStatus !== "NONE") {
        status = "Added";
      } else {
        // check all other comps
        const otherCompsDone = competences
          .filter((comp) => !comp.isFinalReview && comp["Number Evidences required"])
          .every((c) => {
            const evidenceStatus = EvidenceUtils.hasReachedEvidenceCount(progressCheckEvidence, c);
            const learningOutcomesStatus = EvidenceUtils.hasLearningOutcomes(progressCheckEvidence, c);

            // LUK23YT16I5Q2 = Mentoring progress check for MTS, LW99UWW8FT3WY = Mentoring progress check for CHUB
            if (params.progressCheckId === "LUK23YT16I5Q2" || params.progressCheckId === "LW99UWW8FT3WY") {
              const noLearningOutcomes = !c["Learning Objective"]?.length;
              let noRequiredLearningOutcomes = true;
              if (comp["Learning Objective"]?.length) {
                noRequiredLearningOutcomes = comp["Learning Objective"].every((lo) => !lo.NumberRequired);
              }
              if (
                (evidenceStatus === "COMPLETE" && learningOutcomesStatus === "COMPLETE_REQUIRED") ||
                (evidenceStatus === "COMPLETE" && learningOutcomesStatus === "COMPLETE_ALL") ||
                (evidenceStatus === "COMPLETE" && noLearningOutcomes) ||
                (evidenceStatus === "COMPLETE" && noRequiredLearningOutcomes)
              )
                return true;
            } else {
              if (evidenceStatus === "COMPLETE") return true;
            }
            return false;
          });
        if (!otherCompsDone && !ProgramUtils.isCSM(program.ID)) {
          status = "Locked";
        }
      }
    } else if (comp.isLockedByRequiredEvidence) {
      const evidenceStatus = EvidenceUtils.hasReachedEvidenceCount(progressCheckEvidence, comp);

      if (evidenceStatus !== "NONE") {
        status = "Added";
      } else {
        const otherCompsDone = competences
          .filter((comp) => !comp.isLockedByRequiredEvidence)
          .every((c) => {
            const evidenceStatus = EvidenceUtils.hasReachedEvidenceCount(progressCheckEvidence, c);

            if (evidenceStatus === "COMPLETE") return true;

            return false;
          });

        if (!otherCompsDone) {
          status = "Locked";
        }
      }
    } else {
      if (
        ProgramUtils.canSubmitProgressCheck(progressCheck.Approval) &&
        ProgramUtils.isProgressCheckApproved(progressCheckData?.submissions)
      ) {
        status = "Added";
      } else if (
        ProgramUtils.isNQPProgram(program.ID) &&
        ProgramUtils.isProgressCheckCompleted(allEvidenceAdded, progressCheck.Approval, progressCheckData?.submissions)
      ) {
        status = "Added";
      } else {
        // check the current competence
        const evidenceCountStatus = EvidenceUtils.hasReachedEvidenceCount(progressCheckEvidence, comp);
        const learningOutcomesStatus = EvidenceUtils.hasLearningOutcomes(progressCheckEvidence, comp);

        if (evidenceCountStatus === "NONE" && learningOutcomesStatus == "NONE") {
          if (comp.FilledInBy === FilledInBy.Mentor) {
            status = "Mentor to Add";
          } else {
            status = "Open";
          }
        } else if (evidenceCountStatus === "COMPLETE") {
          if (comp["Number Evidences required"]) {
            // handle mandatory evidence
            const noLearningOutcomes = !comp["Learning Objective"]?.length;
            let noRequiredLearningOutcomes = true;

            if (comp["Learning Objective"]?.length && !ProgramUtils.isNQPProgram(program.ID)) {
              noRequiredLearningOutcomes = comp["Learning Objective"].every((lo) => !lo.NumberRequired);
            }
            if (
              learningOutcomesStatus === "COMPLETE_ALL" ||
              learningOutcomesStatus === "COMPLETE_REQUIRED" ||
              noLearningOutcomes ||
              noRequiredLearningOutcomes
            ) {
              status = "Added";
            } else {
              if (ProgramUtils.isNQPProgram(program.ID)) {
                status = "Open";
              } else {
                status = "In Progress";
              }
            }
          } else {
            // handle optional evidence
            status = "Added";
          }
        } else {
          if (ProgramUtils.isNQPProgram(program.ID)) {
            status = "Open";
          } else {
            status = "In Progress";
          }
        }
      }
    }

    return status;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref={`/dashboard/program/${program?.ID}`}
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{progressCheck?.Name || ""}</HeaderTitle>
          <IonButtons slot="end">
            <IonButton className="header-button" mode="ios" onClick={() => refreshUserProgramData()}>
              {refreshingData ? (
                <IonSpinner className="w-[44px] h-[20px] text-white" />
              ) : (
                <IonImg src={Icon_Refresh} className="headerIcon" />
              )}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background">
        <SubmitProgressCheckModal
          cancelPressed={() => setSubmitProgressCheckModalVisible(false)}
          submitPressed={() => submitProgressCheck()}
          visible={submitProgressCheckModalVisible}
          submitting={submitting}
          headerText={progressCheck?.SubmissionModalHeader || ""}
          bodyText={progressCheck?.SubmissionModalBody || ""}
          expand={progressCheck?.Approval === ProgressCheckApproval.ApprovalOO}
        />
        <div className="pb-[96px]">
          {progressCheck && (
            <ProgramInfoCard title={progressCheck.Name}>
              {!ProgramUtils.isProgressCheckApproved(progressCheckData?.submissions) &&
                ProgramUtils.checkIfProgressCheckIsLocked(
                  progressCheck,
                  progressChecks,
                  evidence.contents,
                  currentProgressCheckData,
                  program.ID,
                  fastTrackActive,
                  pauseAndHold
                ) && (
                  <div className="mb-[8px]">
                    <EvidenceDisclaimer
                      message={
                        ProgramUtils.getProgressCheckPauseAndHoldLockedText(pauseAndHold) ??
                        ProgramUtils.getProgressCheckSubmissionLockedDisclaimerText(progressCheckData) ??
                        ProgramUtils.getProgressCheckLockedDisclaimerText(progressCheck.ID)
                      }
                    />
                  </div>
                )}
              {progressCheck.Description && !ProgramUtils.isProgressCheckApproved(progressCheckData?.submissions) && (
                <ProgressCheckDescription description={progressCheck.Description} />
              )}
              {ProgramUtils.isProgressCheckApproved(progressCheckData?.submissions) && (
                <>
                  <ProgressCheckDescription
                    description={ProgramUtils.formatApprovalText(
                      progressCheck.SubmissionApprovedText,
                      progressCheckData?.submissions
                    )}
                  />
                  <ProgramCardButton
                    callToAction
                    buttonText={ProgramUtils.getProgressCheckSubmissionButtonText(progressCheckData?.submissions)}
                    buttonType={ProgramUtils.getProgressCheckSubmissionButtonClass(progressCheckData?.submissions)}
                    buttonTextColor={ProgramUtils.getProgressCheckSubmissionButtonTextClass(
                      progressCheckData?.submissions
                    )}
                    buttonPressed={() => console.log("")}
                    disabled={ProgramUtils.isProgressCheckButtonDisabled(
                      progressCheckData?.submissions,
                      allEvidenceAdded
                    )}
                  />
                </>
              )}
              {ProgramUtils.showApprovalStatusBannerOnProgressCheckPage(program.ID) &&
                ProgramUtils.isProgressCheckApprovedBasedOnCompTrigger(progressCheckEvidence, competences) && (
                  <div>
                    <div className="mb-3 text-acc-13px">
                      Your {progressCheck.Name} were approved by your{" "}
                      {progressCheck.Name === "Mentoring" ? "manager" : "tutor"} on{" "}
                      <span className="font-acc-600">{getDateOfProgressCheckApproval()}</span>
                    </div>
                    <div className="bg-green-background text-green-text text-center p-3 font-acc-600 text-acc-16px rounded-lg">
                      Approved
                    </div>
                  </div>
                )}
              {ProgramUtils.showApprovalStatusBannerOnProgressCheckPage(program.ID) &&
                !ProgramUtils.isProgressCheckApprovedBasedOnCompTrigger(progressCheckEvidence, competences) &&
                progressCheckEvidence.some((e) => {
                  const parsed = JSON.parse(e.evidenceJSON);
                  return parsed.OnHoldReset && parsed.OnHoldReset === 1;
                }) && (
                  <div>
                    <div className="mb-3 text-acc-13px">
                      Your {progressCheck.Name} were reset by your{" "}
                      {progressCheck.Name === "Mentoring" ? "manager" : "tutor"}
                    </div>
                    <div className="bg-red-400 text-white text-center p-3 font-acc-600 text-acc-16px rounded-lg">
                      Reset
                    </div>
                  </div>
                )}
              {ProgramUtils.isProgressCheckCompleted(
                allEvidenceAdded,
                progressCheck.Approval,
                progressCheckData?.submissions
              ) &&
                !ProgramUtils.isMTS(program.ID) &&
                !ProgramUtils.isCHUB(program.ID) &&
                !ProgramUtils.isCSM(program.ID) && (
                  <ProgramCardButton
                    callToAction
                    buttonText="Completed"
                    buttonType="!program-card-button-approved"
                    buttonTextColor="!text-green-text"
                    buttonPressed={() => console.log("")}
                    disabled={true}
                  />
                )}
              {competences.length > 0 && (
                <div className="mt-[4px]">
                  {competences.map((competence) => (
                    <ProgressCheckEvidenceButton
                      key={competence.ID}
                      competence={competence}
                      evidenceLabel={getEvidenceLabel(competence)}
                      learningOutcomesLabel={getLearningOutcomesLabel(competence)}
                      competenceStatus={getCompetenceStatus(competence)}
                      programId={program.ID}
                      progressCheckData={progressCheckData}
                      onPress={() => {
                        const status = getCompetenceStatus(competence);

                        // addEvidencePressed(competence);
                        // return;

                        if (ProgramUtils.canHandleMultiplePiecesOfEvidencePerEvidenceType(program.ID)) {
                          if (
                            competence.isWellbeingActivity &&
                            status !== "Locked" &&
                            ProgramUtils.canAddOrEditProgressCheckEvidence(progressCheckData?.submissions)
                          ) {
                            const programID = program?.ID;
                            const progressCheckID = progressCheck?.ID;
                            history.push(
                              `/dashboard/program/${programID}/progress-check/${progressCheckID}/wellbeing-activity/${competence.ID}`
                            );
                          } else if (competence["Learning Objective"] && competence["Learning Objective"].length) {
                            const programID = program?.ID;
                            const progressCheckID = progressCheck?.ID;
                            history.push(
                              `/dashboard/program/${programID}/progress-check/${progressCheckID}/comp/${competence.ID}`
                            );
                          } else {
                            const status = getCompetenceStatus(competence);
                            if (competence.MultipleAllowed) {
                              if (status === "Open" || status === "In Progress" || status === "Added") {
                                addEvidencePressed(competence);
                              }
                            } else {
                              if (status === "Open" || status === "In Progress") {
                                addEvidencePressed(competence);
                              }
                            }
                          }
                        } else {
                          const status = getCompetenceStatus(competence);
                          if (status === "Open" || status === "In Progress") {
                            addEvidencePressed(competence);
                          }
                        }
                      }}
                    />
                  ))}
                </div>
              )}

              {ProgramUtils.isMTS(program.ID) || ProgramUtils.isCHUB(program.ID) ? (
                <ProgramCardButton
                  buttonPressed={() => addEvidencePressed()}
                  buttonText="Add new evidence"
                  icon={Icon_Add_Evidence}
                  disabled={
                    ProgramUtils.checkIfProgressCheckIsLocked(
                      progressCheck,
                      progressChecks,
                      evidence.contents,
                      currentProgressCheckData,
                      program.ID,
                      fastTrackActive,
                      pauseAndHold
                    ) || ProgramUtils.isProgressCheckApprovedBasedOnCompTrigger(progressCheckEvidence, competences)
                  }
                />
              ) : (
                <ProgramCardButton
                  buttonPressed={() => addEvidencePressed()}
                  buttonText="Add new evidence"
                  icon={Icon_Add_Evidence}
                  disabled={
                    ProgramUtils.checkIfAllMentorEvidenceIsAdded(progressCheck, evidence.contents) ||
                    ProgramUtils.progressCheckAddNewEvidenceButtonDisabled(
                      progressCheck,
                      progressChecks,
                      evidence.contents,
                      currentProgressCheckData,
                      program.ID,
                      fastTrackActive,
                      pauseAndHold
                    ) ||
                    ProgramUtils.isAllEvidenceAddedForProgressCheck(progressCheckEvidence, competences, true) ||
                    ProgramUtils.isProgressCheckCompleted(
                      allEvidenceAdded,
                      progressCheck.Approval,
                      progressCheckData?.submissions
                    ) ||
                    ProgramUtils.isProgressCheckApproved(progressCheckData?.submissions) ||
                    !ProgramUtils.canAddOrEditProgressCheckEvidence(progressCheckData?.submissions)
                  }
                />
              )}
            </ProgramInfoCard>
          )}
          {progressCheck?.CommentsSupported && (
            <CenterContainer>
              <CommentsCard
                buttonPressed={() => goToComments()}
                latestComment={CommentHelpers.getLatestComment(progressCheckData?.comments, program.MentorTitle)}
                showNew={CommentHelpers.showNewCommentsCardLabel(
                  user.contents,
                  progressCheckData?.comments,
                  progressCheckData?.commentReadTimes
                )}
                unreadCount={CommentHelpers.getUnreadCommentCount(
                  user.contents,
                  progressCheckData?.comments,
                  progressCheckData?.commentReadTimes
                )}
              />
            </CenterContainer>
          )}
          {progressCheck?.Approval &&
            ProgramUtils.canSubmitProgressCheck(progressCheck.Approval) &&
            !ProgramUtils.isProgressCheckApproved(progressCheckData?.submissions) && (
              <ProgramInfoCard
                title="Submit"
                subTitle={getSubmissionCardHeaderText(progressCheck, progressCheckData)}
                subTitleIsError={submissionHasError(progressCheckData)}
                sidePill={submissionHasError(progressCheckData)}
                sidePillText={getSubmissionSidePillText(progressCheckData)}
              >
                <ProgramCardButton
                  callToAction
                  buttonText={ProgramUtils.getProgressCheckSubmissionButtonText(progressCheckData?.submissions)}
                  buttonType={ProgramUtils.getProgressCheckSubmissionButtonClass(progressCheckData?.submissions)}
                  buttonTextColor={ProgramUtils.getProgressCheckSubmissionButtonTextClass(
                    progressCheckData?.submissions
                  )}
                  buttonPressed={() => submitButtonPressed()}
                  disabled={ProgramUtils.isProgressCheckSubmissionButtonDisabled(
                    progressCheckData?.submissions,
                    competences,
                    progressCheckEvidence,
                    allEvidenceAdded,
                    ProgramUtils.isNQPProgram(program.ID)
                  )}
                />
              </ProgramInfoCard>
            )}
          {ProgramUtils.isCHUB(program.ID) && progressCheck && progressCheck.ID === "LUK25EYB6DL4A" && (
            <ProgramInfoCard
              title={"Ongoing Development"}
              subTitle={"Upload a minimum of 2 pieces of any Ongoing Development competencies per month"}
            >
              <MonthlyEvidenceBreakdown
                evidence={progressCheckEvidence.filter((e) => {
                  // Only pass in relevant evidence that should be counted for ongoing development
                  // ('Peer Review' comp with Peer Review LO, and 'General' comp)
                  const parsedEvidence = JSON.parse(e.evidenceJSON);
                  const peerReviewLoFound = parsedEvidence?.LearningOutcomes.find(
                    (lo: any) => lo.ID === "LX4VATNYDQGT7"
                  ); // TODO Peer Review LO id (set in airtable id instead??)
                  return (
                    !parsedEvidence.OnHoldReset &&
                    !e.draft &&
                    (parsedEvidence.programInfo?.progressCheckCompetenceID == "LWAGQ8UP7YU3L" || peerReviewLoFound)
                  );
                })}
              />
            </ProgramInfoCard>
          )}
          <ProgramInfoCard
            title="Evidence"
            subTitle={
              progressCheckEvidence.length === 0
                ? "Any evidence added for this progress check will appear below."
                : undefined
            }
          >
            {!ProgramUtils.canAddOrEditProgressCheckEvidence(progressCheckData?.submissions) &&
              !ProgramUtils.hideDisclaimerText(program.ID) &&
              progressCheckData?.submissions.status !== SubmissionState.OnHoldReset && (
                <div>
                  <EvidenceDisclaimer
                    message={ProgramUtils.getEvidenceDisclaimerText(progressCheckData?.submissions)}
                  />
                </div>
              )}
            {progressCheckEvidence.length > 0 && (
              <div>
                {progressCheckEvidence.map((item) => (
                  <ProgramEvidenceCard
                    key={item.id}
                    buttonPressed={() => evidenceCardPressed(item)}
                    programEvidence={item}
                    program={program}
                  />
                ))}
              </div>
            )}
          </ProgramInfoCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ProgressCheckPage;
