type Props = {
  // state: "ready" | "listening" | "processing" | "error" | "processed";
  // errorMessage?: string;
  // stateChanged: boolean;
};

const AIDictationBannerHeader: React.FC<Props> = (props) => {
  return (
    <>
      <div className="flex flex-row items-center mb-0.5">
        <div className="text-acc-16px font-bold text-white">{"Try our new AI Dictation"}</div>
        <div className="text-acc-13px font-semibold text-black bg-white px-1 ml-2 rounded-sm">{"BETA"}</div>
      </div>
      <div className="text-acc-13px font-acc-600 text-white opacity-80">{"Tap the button to get started"}</div>
    </>
  );

  // return (
  //   <>
  //     {(props.state === "ready" || props.state === "processed") && (
  //       <>
  //         <div className="flex flex-row items-center mb-0.5">
  //           <div
  //             className={`text-acc-16px font-bold text-white ${props.stateChanged ? "animate-fade animate-once animate-duration-[150ms] animate-ease-in animate-normal animate-fill-both" : ""}`}
  //           >
  //             {"Try our new AI Dictation"}
  //           </div>
  //           <div className="text-acc-16px font-bold text-black bg-white px-1 ml-2 rounded-sm">{"BETA"}</div>
  //         </div>
  //         <div
  //           className={`text-acc-13px font-acc-600 text-white opacity-80 ${props.stateChanged ? "animate-fade-up animate-once animate-duration-[150ms] animate-ease-in animate-normal animate-fill-both" : ""}`}
  //         >
  //           {"Tap the wand to get started"}
  //         </div>
  //       </>
  //     )}
  //     {props.state === "listening" && (
  //       <>
  //         <div
  //           className={`text-acc-16px font-bold text-white ${props.stateChanged ? "animate-fade animate-once animate-duration-[150ms] animate-ease-in animate-normal animate-fill-both" : ""}`}
  //         >
  //           {"Start describing your CPD"}
  //         </div>
  //         <div
  //           className={`text-acc-13px font-acc-600 text-white opacity-80 ${props.stateChanged ? "animate-fade-up animate-once animate-duration-[150ms] animate-ease-in animate-normal animate-fill-both" : ""}`}
  //         >
  //           {"Tap the microphone again when you're done"}
  //         </div>
  //       </>
  //     )}
  //     {props.state === "processing" && (
  //       <>
  //         <div
  //           className={`text-acc-16px font-bold text-white ${props.stateChanged ? "animate-fade animate-once animate-duration-[150ms] animate-ease-in animate-normal animate-fill-both" : ""}`}
  //         >
  //           {"Populating your evidence form"}
  //         </div>
  //         <div
  //           className={`text-acc-13px font-acc-600 text-white opacity-80 ${props.stateChanged ? "animate-fade-up animate-once animate-duration-[150ms] animate-ease-in animate-normal animate-fill-both" : ""}`}
  //         >
  //           {"One moment, please..."}
  //         </div>
  //       </>
  //     )}
  //     {props.state === "error" && (
  //       <>
  //         <div
  //           className={`text-acc-16px font-bold text-white ${props.stateChanged ? "animate-fade animate-once animate-duration-[150ms] animate-ease-in animate-normal animate-fill-both" : ""}`}
  //         >
  //           {"An error has occurred"}
  //         </div>
  //         <div
  //           className={`text-acc-13px font-acc-600 text-white opacity-80 ${props.stateChanged ? "animate-fade-up animate-once animate-duration-[150ms] animate-ease-in animate-normal animate-fill-both" : ""}`}
  //         >
  //           {props.errorMessage ?? "Try again later..."}
  //         </div>
  //       </>
  //     )}
  //   </>
  // );
};

export default AIDictationBannerHeader;
