import "../../App.css";
import { IEvidenceCard, IEvidencePartWrapper } from "../../Interfaces";
import "./CommonComponents.css";

const EvidencePartWrapper: React.FC<IEvidencePartWrapper> = (props) => {
  return (
    <div
      className={`${props.isErrorBannerVisible && props.isPartIncomplete ? "mandatoryFieldIncomplete" : ""}`}
      ref={(ref) => {
        if (props.isMandatory) {
          props.handleElementReference(ref!);
        }
      }}
    >
      {props.children}
    </div>
  );
};

export default EvidencePartWrapper;
