import { IonContent, IonModal, IonSpinner } from "@ionic/react";
import { useState } from "react";
import { Button_Checkbox_Active, Button_Checkbox_Inactive } from "../../assets/images";

type Props = {
  disclaimerText: string;
  visible: boolean;
  saving: boolean;
  dismiss: () => void;
  confirm: () => void;
};

const WellbeingConfirmModal: React.FC<Props> = (props) => {
  const [confirm, setConfirm] = useState<boolean>(false);

  return (
    <IonModal
      id="wellbeing-confirm-modal"
      isOpen={props.visible}
      backdropDismiss={false}
      canDismiss={true}
      onDidDismiss={() => props.dismiss()}
      className="fullscreen"
    >
      <IonContent>
        <div className="flex flex-col justify-between h-full">
          <div className="mt-[16px] px-[16px]">
            <div className="text-[17px] font-bold leading-[1.41] tracking-default text-center text-grey-90">
              {"Confirmation"}
            </div>
            <div className="mt-[4px] text-[15px] leading-[1.33] tracking-[-0.3px] text-center text-grey-90">
              {props.disclaimerText}
            </div>
            <button className="mt-3 h-[48px] w-full" onClick={() => setConfirm(!confirm)}>
              <div className="flex justify-center items-center">
                <div
                  className={`text-[17px] leading-[1.41] tracking-default text-center text-grey-90 ${confirm && "font-semibold"}`}
                >
                  {"Acknowledge"}
                </div>
                <img
                  alt=""
                  className="w-icon h-icon img-no-select"
                  src={confirm ? Button_Checkbox_Active : Button_Checkbox_Inactive}
                />
              </div>
            </button>
          </div>
          <div className="flex flex-col">
            <div className="border-t border-t-grey-30">
              <button
                disabled={!confirm}
                className={`h-[48px] w-full ${!confirm && "opacity-50"}`}
                onClick={() => props.confirm()}
              >
                {props.saving ? (
                  <div className="flex justify-center items-center">
                    <IonSpinner className="w-[20px] h-[20px] text-cta-blue" />
                  </div>
                ) : (
                  <div className="text-[16px] font-semibold leading-default tracking-default text-center text-cta-blue">
                    {"Confirm"}
                  </div>
                )}
              </button>
            </div>
            <div className="border-t border-t-grey-30">
              <button className="h-[48px] w-full" onClick={() => props.dismiss()}>
                <div className="text-[16px] leading-default tracking-default text-center text-cta-blue ">
                  {"Cancel"}
                </div>
              </button>
            </div>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default WellbeingConfirmModal;
