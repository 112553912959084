import { useHistory, useParams } from "react-router";
import { useProgramData } from "../../hooks/data/useProgramData";
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { HeaderTitle } from "parafolio-components";
import DataController from "../../controllers/DataController";
import LearningOutcomesHeader from "../../components/programs/LearningOutcomesHeader";
import ContentContainer from "../../components/common/ContentContainer";
import * as ProgramUtils from "../../utils/programUtils";
import LearningOutcomesList from "../../components/programs/LearningOutcomesList";
import EvidenceContainer from "../../components/evidence/EvidenceContainer";
import Container from "../../components/common/Container";
import { useRecoilValueLoadable } from "recoil";
import { IEvidence } from "../../Interfaces";
import { evidenceAtom } from "../../state/State";
import * as NQPUtils from "../../utils/nqpUtils";

const NQPLearningOutcomesPage: React.FC = (props) => {
  const params = useParams<{ programId: string }>();
  const history = useHistory();

  const allEvidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);

  const { userPrograms } = useProgramData();

  const program = userPrograms.find((item) => item.ID === params.programId)!;

  const learningObjectives = ProgramUtils.getAllLearningObjectivesForProgram(program);
  const groupedLearningOutcomes = ProgramUtils.getAllLearningObjectivesForProgramByGroup(program);

  const mappedLearningOutcomes = Object.keys(groupedLearningOutcomes).map((key) => {
    return { [key]: Object.groupBy(groupedLearningOutcomes[key]!, (item) => item.Section!) };
  });

  let evidenceForProgram: IEvidence[] = [];

  if (allEvidence.state === "hasValue" && allEvidence.contents) {
    evidenceForProgram = allEvidence.contents
      .filter((evidence: IEvidence) => {
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
        const id = evidenceJSON?.programInfo?.programID || "";

        return id === program?.ID;
      })
      .map((evidence) => ({ ...evidence, evidenceJSON: JSON.parse(evidence.evidenceJSON) }));
  }

  const count = NQPUtils.getLearningOutcomesAchieved(learningObjectives, evidenceForProgram);
  const total = learningObjectives.length;
  const percentage = Math.trunc((count / total) * 100);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref={`/dashboard/program/${program?.ID}`}
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{"Learning Outcomes"}</HeaderTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background">
        <Container>
          <LearningOutcomesHeader count={count} total={total} percentage={percentage} />
          <LearningOutcomesList learningOutcomes={mappedLearningOutcomes} evidence={evidenceForProgram} />
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default NQPLearningOutcomesPage;
