import { useRecoilValue, useSetRecoilState } from "recoil";
import { progressCheckDataAtom, userAtom } from "../../state/State";
import { AllProgressCheckStatuses, IUser } from "../../Interfaces";
import { useCallback, useEffect } from "react";
import _ from "lodash";
import { DATA_REFRESH_INTERVAL } from "../../Constants";
import * as ProgramUtils from "../../utils/programUtils";
import { ProgressCheckService } from "../../controllers/ProgressCheckService";
import { useProgramData } from "./useProgramData";
import { useLoggedIn } from "../auth/useLoggedIn";

type RefreshProgressCheckData = {
  refreshProgressCheckData: (user: IUser) => Promise<void>;
};

export const useRefreshProgressCheckData = (shouldLoad?: boolean): RefreshProgressCheckData => {
  const user = useRecoilValue(userAtom);
  const setProgressCheckData = useSetRecoilState<AllProgressCheckStatuses[]>(progressCheckDataAtom);
  const loggedIn = useLoggedIn();

  const { userPrograms } = useProgramData();

  const refreshProgressCheckData = useCallback(
    async (_user: IUser): Promise<void> => {
      try {
        const programIDs = ProgramUtils.getProgramIDsFromSubscriptions(_user?.userData.subscriptions, userPrograms);
        const progressCheckProgramIDs = ProgramUtils.filterProgressCheckProgramIDs(programIDs);

        if (progressCheckProgramIDs?.length > 0) {
          let data = [];

          for (let i = 0; i < progressCheckProgramIDs.length; i++) {
            let result = await ProgressCheckService.getAllProgressCheckStatuses(_user, progressCheckProgramIDs[i]);
            data.push({ ...result, programID: progressCheckProgramIDs[i] });
          }

          setProgressCheckData(data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [setProgressCheckData]
  );

  useEffect(() => {
    const getProgressCheckData = async (): Promise<void> => {
      try {
        const programIDs = ProgramUtils.getProgramIDsFromSubscriptions(user?.userData.subscriptions, userPrograms);
        const progressCheckProgramIDs = ProgramUtils.filterProgressCheckProgramIDs(programIDs);

        let data = [];

        for (let i = 0; i < progressCheckProgramIDs.length; i++) {
          let result = await ProgressCheckService.getAllProgressCheckStatuses(user, progressCheckProgramIDs[i]);
          data.push({ ...result, programID: progressCheckProgramIDs[i] });
        }

        if (loggedIn && user) {
          setProgressCheckData(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const interval = setInterval(() => {
      if (loggedIn && user && shouldLoad) {
        getProgressCheckData();
      }
    }, DATA_REFRESH_INTERVAL);

    return () => {
      try {
        clearInterval(interval);
      } catch (error) {
        console.log(error);
      }
    };
  }, [loggedIn, setProgressCheckData, user, shouldLoad, userPrograms]);

  useEffect(() => {
    const getProgressCheckData = async (): Promise<void> => {
      try {
        const programIDs = ProgramUtils.getProgramIDsFromSubscriptions(user?.userData.subscriptions, userPrograms);
        const progressCheckProgramIDs = ProgramUtils.filterProgressCheckProgramIDs(programIDs);

        let data = [];

        for (let i = 0; i < progressCheckProgramIDs.length; i++) {
          let result = await ProgressCheckService.getAllProgressCheckStatuses(user, progressCheckProgramIDs[i]);
          data.push({ ...result, programID: progressCheckProgramIDs[i] });
        }

        setProgressCheckData(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (loggedIn && user && shouldLoad) {
      getProgressCheckData();
    }
  }, [loggedIn, setProgressCheckData, user, shouldLoad, userPrograms]);

  return {
    refreshProgressCheckData,
  };
};
