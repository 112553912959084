import React, { useEffect, useState } from "react";
import { IonContent, IonModal, IonSpinner } from "@ionic/react";
import { ISubmitProgressCheckModal } from "../../types/Components";

const SubmitProgressCheckModal: React.FC<ISubmitProgressCheckModal> = (props) => {
  return (
    <IonModal
      id={props.expand ? "submit-progress-check-modal-expand" : "submit-progress-check-modal"}
      isOpen={props.visible}
      backdropDismiss={!props.submitting}
      canDismiss={true}
      onDidDismiss={props.cancelPressed}
      className="fullscreen"
    >
      <IonContent>
        <div className="flex flex-col justify-between h-full">
          <div className="mt-[16px] px-[16px]">
            <div className="text-[17px] font-bold leading-[1.41] tracking-default text-center text-grey-90">
              {props.headerText}
            </div>
            <div className="mt-[4px] text-[15px] leading-[1.33] tracking-[-0.3px] text-center text-grey-90">
              {props.bodyText}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="border-t border-t-grey-30">
              <button className="h-[48px] w-full" onClick={props.submitPressed} disabled={props.submitting}>
                {props.submitting ? (
                  <div className="flex justify-center items-center">
                    <IonSpinner className="w-[20px] h-[20px] text-cta-blue" />
                  </div>
                ) : (
                  <div className="text-[16px] font-semibold leading-default tracking-default text-center text-cta-blue">
                    {"Submit"}
                  </div>
                )}
              </button>
            </div>
            <div className="border-t border-t-grey-30">
              <button className="h-[48px] w-full" disabled={props.submitting} onClick={props.cancelPressed}>
                <div className="text-[16px] leading-default tracking-default text-center text-cta-blue ">
                  {"Cancel submission"}
                </div>
              </button>
            </div>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default SubmitProgressCheckModal;
