import { Subscriptions } from "capacitor-subscriptions";
import { useEffect } from "react";
import { BUNDLE_ID, GOOGLE_VERIFY_ENDPOINT } from "../../Constants";

export const useGoogleVerification = () => {
  useEffect(() => {
    Subscriptions.setGoogleVerificationDetails({
      googleVerifyEndpoint: GOOGLE_VERIFY_ENDPOINT,
      bid: BUNDLE_ID,
    });
  }, []);
};
