import { Text, View } from "@react-pdf/renderer";
import { CompetenceActivityClass, EvidencePart, LearningObjective, ProgramData } from "../../../Interfaces";
import Collapsible from "react-collapsible";
import {
  Button_Checkbox_Active,
  Button_Checkbox_Inactive,
  Button_Collapse,
  Button_Expand,
} from "../../../assets/images";
import { IonItem } from "@ionic/react";
import { useState } from "react";
import _ from "lodash";

type Props = {
  definition: EvidencePart;
  onValueChanged?: (value: any) => void;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue: {
    selectedOutcomes: { id: string; name: string }[];
  };
  program?: ProgramData;
  learningOutcomes: LearningObjective[];
  progressCheckCompetence: CompetenceActivityClass;
  currentProgressCheckId: string;
};

const NQPLearningObjectivesMultiSelect: React.FC<Props> = (props) => {
  const [collapsibleOpen, setCollapsibleOpen] = useState<string[]>([]);
  const [selectedLearningObjectives, setSelectedLearningObjectives] = useState<{ id: string; name: string }[]>(
    props.initialValue?.selectedOutcomes ?? []
  );

  const groupedLearningOutcomes = Object.groupBy(props.learningOutcomes, (item) => item.TopSection!);

  const mappedLearningOutcomes = Object.keys(groupedLearningOutcomes).map((key) => {
    return { [key]: Object.groupBy(groupedLearningOutcomes[key]!, (item) => item.Section!) };
  });

  if (props.isPDF) {
    return (
      <View>
        {props.data.selectedOutcomes?.length > 0 && (
          <View>
            <Text style={props.pdfStyles.reflectionHeader}>
              {props.definition["Display Title"] || props.definition.Name}
            </Text>
            {props.data.selectedOutcomes.map((item: { id: string; name: string }, index: number) => {
              const learningObjective = props.learningOutcomes.find((lo) => lo.ID === item.id);

              return (
                <View key={item.id} style={{ marginTop: 8 }}>
                  <Text style={props.pdfStyles.boldText}>{learningObjective?.Name}</Text>
                  <Text style={props.pdfStyles.normalText}>{learningObjective?.TopSection}</Text>
                  <Text style={props.pdfStyles.normalText}>{learningObjective?.Section}</Text>
                  <Text style={props.pdfStyles.normalText}>{learningObjective?.Description}</Text>
                </View>
              );
            })}
          </View>
        )}
      </View>
    );
  }

  if (props.isDisplay) {
    return (
      <div>
        {props.data.selectedOutcomes?.length > 0 && (
          <div className="readEvidenceBlock">
            <div className="readEvidenceHeader mb-2">{props.definition["Display Title"] || props.definition.Name}</div>
            {props.data.selectedOutcomes.map((item: { id: string; name: string }, index: number) => {
              const learningObjective = props.learningOutcomes.find((lo) => lo.ID === item.id);

              return (
                <div key={item.id} className={`${index !== props.data.selectedOutcomes.length - 1 ? "mb-2" : ""}`}>
                  <div className="readEvidenceText font-acc-600">{learningObjective?.Name}</div>
                  <div className="readEvidenceText">{learningObjective?.Section}</div>
                  <div className="readEvidenceText">{learningObjective?.Description}</div>
                </div>
              );
            })}
            <div className="h-[1px] w-full bg-grey-30 mt-4" />
          </div>
        )}
      </div>
    );
  }

  const toggleLearningObjective = (learningObjective: LearningObjective): void => {
    let array = _.cloneDeep(selectedLearningObjectives);
    const index = array.findIndex((item) => item.id === learningObjective.ID);

    if (index > -1) {
      array.splice(index, 1);
    } else {
      array.push({ id: learningObjective.ID, name: learningObjective.Name });
    }

    const value = {
      selectedOutcomes: [...array],
    };

    props.onValueChanged?.(value);

    setSelectedLearningObjectives(array);
  };

  const handleCollapsibleToggle = (id: string, close?: boolean): void => {
    let array = _.cloneDeep(collapsibleOpen);
    const index = array.findIndex((item) => item === id);

    if (index > -1) {
      array.splice(index, 1);
    } else {
      !close && array.push(id);
    }

    setCollapsibleOpen(array);
  };

  return (
    <div>
      <div className="reflectionContentCard bg-grey-20">
        <div className="reflectionContentCardTitle !mb-0">
          {props.definition["Display Title"] ?? "Tick the LOs met"}
          <span className="text-cta-red">
            {(props.definition.options === "Mandatory" || props.definition["option type"] === "Mandatory") &&
              " (Mandatory)"}
          </span>
        </div>
      </div>
      {mappedLearningOutcomes.map((item) => {
        const topSectionKey = Object.keys(item)[0];
        const topSection = item[topSectionKey];

        let object = Object.keys(topSection).map((key) => topSection[key]);
        const sections: LearningObjective[][] = object.filter((section) => section !== undefined);
        const flattenedSectionIDs = sections.flat().map((section) => section.ID);

        const open = collapsibleOpen.includes(topSectionKey);

        const hasLOsSelected =
          selectedLearningObjectives.filter((lo) => flattenedSectionIDs.includes(lo.id)).length > 0;

        return (
          <div
            key={topSectionKey}
            className="rounded-md border-[1px] border-solid border-grey-40 bg-white mb-4 overflow-hidden"
          >
            <Collapsible
              key={topSectionKey}
              trigger={
                <IonItem
                  button
                  detail={false}
                  className="evidencePartsCheckboxContainer"
                  onClick={() => handleCollapsibleToggle(topSectionKey)}
                >
                  <div className="w-full flex flex-row justify-between items-center px-3.5 py-[5px]">
                    <div className="text-17px font-acc-600 leading-default tracking-default text-grey-90">
                      {topSectionKey}
                    </div>
                    <div className="flex flex-row">
                      <img
                        alt=""
                        className="h-icon w-icon img-no-select"
                        src={open ? Button_Collapse : Button_Expand}
                      />
                      <img
                        alt=""
                        className="h-icon w-icon img-no-select"
                        src={hasLOsSelected ? Button_Checkbox_Active : Button_Checkbox_Inactive}
                      />
                    </div>
                  </div>
                </IonItem>
              }
              transitionTime={250}
              transitionCloseTime={100}
              open={open}
            >
              <div className="px-4">
                {sections.map((section) => {
                  return (
                    <div key={section[0].Section}>
                      {section.map((learningOutcome, index) => {
                        const selected =
                          selectedLearningObjectives.findIndex((item) => item.id === learningOutcome.ID) > -1;

                        return (
                          <div key={learningOutcome.ID}>
                            {index === 0 && (
                              <>
                                <div className="h-[2px] bg-grey-20" />
                                <div className="mt-4 mb-6 text-[12px] font-acc-600 text-grey-90">
                                  {learningOutcome.Section}
                                </div>
                              </>
                            )}
                            <IonItem
                              button
                              detail={false}
                              className="evidencePartsCheckboxContainer mb-3"
                              onClick={() => toggleLearningObjective(learningOutcome)}
                            >
                              <div className="w-full flex flex-row justify-between items-center">
                                <div className="text-[12px] font-acc-400 text-grey-90">
                                  {learningOutcome.Description}
                                </div>
                                <img
                                  alt=""
                                  className="h-icon w-icon img-no-select"
                                  src={selected ? Button_Checkbox_Active : Button_Checkbox_Inactive}
                                />
                              </div>
                            </IonItem>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </Collapsible>
          </div>
        );
      })}
    </div>
  );
};

export default NQPLearningObjectivesMultiSelect;
