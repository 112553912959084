import { useEffect, useState } from "react";
import { useRecoilValueLoadable } from "recoil";
import { IClassSubscription, IUser } from "../../Interfaces";
import { userAtom } from "../../state/State";

export const useBetaFeatures = () => {
  const [betaFeatures, setBetaFeatures] = useState<string[]>([]);
  const userObject = useRecoilValueLoadable<IUser | null>(userAtom);

  useEffect(() => {
    const getBetaFeatures = (_user: IUser): void => {
      const allSubscriptions: IClassSubscription[] = _user?.userData?.subscriptions ?? [];

      const features = allSubscriptions.map((subscription) => subscription.BetaFeatures ?? []).flat();

      setBetaFeatures(Array.from(new Set(features)));
    };

    if (userObject.state === "hasValue" && userObject.contents) {
      getBetaFeatures(userObject.contents);
    }
  }, [userObject.contents]);

  const canUseBetaFeature = (featureName: string) => {
    return betaFeatures.includes(featureName);
  };

  return {
    betaFeatures,
    canUseBetaFeature,
  };
};
