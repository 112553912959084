import { Page, Text, View, Link, Image as ReactPDFImage } from "@react-pdf/renderer";
import DataController from "../../controllers/DataController";
import * as PDFStyles from "./PDFStyles";
import * as EvidenceUtils from "../../utils/evidenceUtils";
import * as ProgramUtils from "../../utils/programUtils";
import { StringUtils } from "../../utils/stringUtils";
import { IPDFEvidence } from "./PDFTypes";
import {
  CompetenceActivityClass,
  EvidenceAttachment,
  EvidencePart,
  EvidenceStandardTag,
  FieldType,
  ProgramData,
} from "../../Interfaces";
import _ from "lodash";
import { CreateEvidencePart } from "../programs/evidenceParts/ComponentWrapper";
import { format } from "date-fns";
import AWSService from "../../controllers/AWSService";
import { Html } from "react-pdf-html";

const PDFEvidence: React.FC<IPDFEvidence> = (props) => {
  return (
    <>
      {props.evidence.map((item, index) => {
        let attachments: EvidenceAttachment[] = typeof item.attachments === "object" ? item.attachments : [];

        let images = attachments.filter(
          (attachment) =>
            attachment.type === "image/jpg" || attachment.type === "image/jpeg" || attachment.type === "image/png"
        );

        let nonImageAttachments = attachments.filter(
          (attachment) =>
            attachment.type !== "image/jpg" && attachment.type !== "image/jpeg" && attachment.type !== "image/png"
        );

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
        const evidenceJSON: any =
          item.evidenceJSON && typeof item.evidenceJSON === "string" && JSON.parse(item.evidenceJSON);

        const sortedAttachments = _.concat(images, nonImageAttachments);
        const title: string = (item?.title || evidenceJSON?.Title) ?? "";

        let evidenceParts: EvidencePart[] = [];
        let _program: ProgramData | undefined;
        let progressCheckCompetence: CompetenceActivityClass | undefined;

        if (evidenceJSON) {
          const competenceName = evidenceJSON.programInfo?.competence;
          const programName = evidenceJSON.programInfo?.program;
          const progressCheckID = evidenceJSON.programInfo?.progressCheckID;
          const progressCheckCompetenceID = evidenceJSON.programInfo?.progressCheckCompetenceID;

          _program = props.userPrograms?.find((item: any): item is ProgramData => item && item.Name === programName);

          const _competences: any = _program?.CompetenceActivity ?? [];
          const _progressChecks: any[] = _program?.ProgressChecks ?? [];

          const competence = _competences?.find(
            (item: any): item is CompetenceActivityClass => item.Name === competenceName
          );

          const progressCheck = _progressChecks && _progressChecks?.find((item: any) => item.ID === progressCheckID);

          if (competence) {
            const evidenceDefinitions = competence["Evidence Definitions"][0];
            evidenceParts = evidenceDefinitions?.["Evidence parts"] ?? [];
          } else if (progressCheck) {
            const progressCheckCompetences = progressCheck["Competence/Activity"];
            progressCheckCompetence = progressCheckCompetences.find(
              (item: any): item is CompetenceActivityClass => item.ID === progressCheckCompetenceID
            );

            const evidenceDefinitions = progressCheckCompetence?.["Evidence Definitions"][0];
            evidenceParts = evidenceDefinitions ? EvidenceUtils.getEvidenceParts(evidenceDefinitions) : [];

            // console.log("evidenceDefinitions", evidenceDefinitions);
          } else {
            const evidenceDefinitions = _program?.["Evidence Definitions"]?.[0];
            evidenceParts = evidenceDefinitions?.["Evidence parts"] ?? [];
          }
        }

        return (
          <Page key={item.id} size="A4" style={PDFStyles.styles.page}>
            <View style={PDFStyles.styles.heading} fixed>
              <Text style={PDFStyles.styles.headingText}>{"ParaFolio"}</Text>
            </View>
            <View
              fixed
              style={PDFStyles.styles.pageHeader}
              render={({ pageNumber }) => (
                <>
                  <Text style={PDFStyles.styles.pageHeaderText}>{"Evidence"}</Text>
                  <Text style={PDFStyles.styles.pageNumber}>{pageNumber - 1}</Text>
                </>
              )}
            />
            <View style={PDFStyles.styles.pageInnerContainer}>
              {index === 0 && (
                <View>
                  <Text style={PDFStyles.styles.standardsHeaderOverview}>{"Evidence"}</Text>
                  <Text style={PDFStyles.styles.standardsHeaderDescription}>
                    {"Evidence is displayed by date, with the most recent evidence first."}
                  </Text>
                  <View style={PDFStyles.styles.separator} />
                </View>
              )}
              <Text style={PDFStyles.styles.evidenceTitle}>{title}</Text>
              {evidenceParts?.length > 0 ? (
                <View>
                  {item.date && (
                    <View>
                      <Text style={PDFStyles.styles.reflectionHeader}>{"Date:"}</Text>
                      <Text style={PDFStyles.styles.evidenceDate}>{format(new Date(item.date), "d MMMM yyyy")}</Text>
                    </View>
                  )}
                  {evidenceParts.map((part: any, partIndex: number) => {
                    let dataForPart;
                    let mandatoryStandards;
                    let optionalStandards;

                    if (part["Field type"] === FieldType.NOSSelect) {
                      dataForPart = {
                        Comps: evidenceJSON.Comps,
                        LOs: evidenceJSON.LOs,
                      };

                      mandatoryStandards = ProgramUtils.getMandatoryStandards(props.user, _program!);
                      optionalStandards = ProgramUtils.getOptionalStandards(props.user, _program!);
                    } else {
                      dataForPart = evidenceJSON[part.Name];
                    }

                    if (dataForPart) {
                      return (
                        <View key={part.Name}>
                          {CreateEvidencePart(part["Field type"], {
                            definition: part,
                            data: dataForPart,
                            isPDF: true,
                            pdfStyles: PDFStyles.styles,
                            evidenceID: item.id,
                            program: props.program,
                            user: props.user,
                            accessParams: props.accessParams,
                            mandatoryStandards: mandatoryStandards,
                            optionalStandards: optionalStandards,
                            learningOutcomes: progressCheckCompetence?.["Learning Objective"] ?? [],
                          })}
                        </View>
                      );
                    } else {
                      return null;
                    }
                  })}
                </View>
              ) : (
                <View>
                  <View style={PDFStyles.styles.evidenceDateRow}>
                    <Text style={PDFStyles.styles.evidenceDateBold}>{"Date of reflection:"}</Text>
                    <Text style={PDFStyles.styles.evidenceDate}>{format(new Date(item.date), "d MMMM yyyy")}</Text>
                  </View>
                  {item.activityDate && (
                    <View style={PDFStyles.styles.evidenceDateRow}>
                      <Text style={PDFStyles.styles.evidenceDateBold}>{"Date of activity:"}</Text>
                      <Text style={PDFStyles.styles.evidenceDate}>
                        {StringUtils.getActivityDateString(item.activityDate, item.activityDateEnd)}
                      </Text>
                    </View>
                  )}
                  {item.activity && (
                    <View style={PDFStyles.styles.evidenceDateRow}>
                      <Text style={PDFStyles.styles.evidenceDateBold}>{"Activity:"}</Text>
                      <Text style={PDFStyles.styles.evidenceDate}>{item.activity.type}</Text>
                    </View>
                  )}
                  {item.activityDescription && (
                    <View style={PDFStyles.styles.evidenceDateRow}>
                      <Text style={PDFStyles.styles.evidenceDateBold}>{"Activity description:"}</Text>
                      <Text style={PDFStyles.styles.evidenceDate}>{item.activityDescription}</Text>
                    </View>
                  )}
                  {item.numberOfHours && (
                    <View style={PDFStyles.styles.evidenceDateRow}>
                      <Text style={PDFStyles.styles.evidenceDateBold}>{"Number of hours:"}</Text>
                      <Text style={PDFStyles.styles.evidenceDate}>
                        {StringUtils.getActivityDurationDisplayString(
                          DataController.convertToHoursMinutes(item.numberOfHours)
                        )}
                      </Text>
                    </View>
                  )}
                  {!props.isHCPC && item.standardTags && item.standardTags.length > 0 && (
                    <View style={[PDFStyles.styles.evidenceDateRow, { marginBottom: 0 }]}>
                      <Text style={PDFStyles.styles.evidenceDateBold}>{"NOS met:"}</Text>
                      <Text style={PDFStyles.styles.evidenceDate}>
                        {item.standardTags && item.standardTags.length > 0
                          ? item.standardTags.map((tag: EvidenceStandardTag) => tag.tag).join(", ")
                          : "None"}
                      </Text>
                    </View>
                  )}
                </View>
              )}
              {props.accessParams && attachments.length > 0 && (
                <View>
                  <Text style={PDFStyles.styles.reflectionHeader}>{"Relevant documents:"}</Text>
                  {sortedAttachments.length > 0 &&
                    sortedAttachments.map((attachment: EvidenceAttachment, index) => {
                      const signedURL = AWSService.getAttachmentURL(
                        props.user,
                        props.accessParams,
                        item.id,
                        attachment
                      );

                      return (
                        <Link style={PDFStyles.styles.hyperlink} src={signedURL} key={attachment.name}>
                          {attachment.name}
                        </Link>
                      );
                    })}
                </View>
              )}
              <View>
                {item.reflections && (
                  <View>
                    <Text style={PDFStyles.styles.reflectionHeader}>{"Reflection:"}</Text>
                    <Html style={{ fontSize: 12 }} stylesheet={PDFStyles.htmlStyles}>
                      {DataController.decodeHTML(item.reflections)}
                    </Html>
                  </View>
                )}
                {item.benefits && (
                  <View>
                    <Text style={PDFStyles.styles.reflectionHeader}>{"Benefits:"}</Text>
                    <Html style={{ fontSize: 12 }} stylesheet={PDFStyles.htmlStyles}>
                      {DataController.decodeHTML(item.benefits)}
                    </Html>
                  </View>
                )}
                {item.objectives && (
                  <View>
                    <Text style={PDFStyles.styles.reflectionHeader}>{"Learning/ Exercise Objectives:"}</Text>
                    <Html style={{ fontSize: 12 }} stylesheet={PDFStyles.htmlStyles}>
                      {DataController.decodeHTML(item.objectives)}
                    </Html>
                  </View>
                )}
              </View>
            </View>
          </Page>
        );
      })}
    </>
  );
};

export default PDFEvidence;
