import { useState } from "react";
import { EvidencePart } from "../../../Interfaces";
import { Text, View } from "@react-pdf/renderer";
import { StringUtils } from "../../../utils/stringUtils";
import DataController from "../../../controllers/DataController";
import DurationPicker from "../../common/DurationPicker";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  definition: EvidencePart;
  onValueChanged?: (value: any, isInput?: boolean) => void;
  onInputBlurred: () => void;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue?: any;
};

const ActivityDuration: React.FC<Props> = ({
  children,
  style,
  onValueChanged,
  onInputBlurred,
  data,
  definition,
  isDisplay,
  isPDF,
  pdfStyles,
  initialValue,
}) => {
  const [value, setValue] = useState<string>(initialValue || "");

  const time = DataController.convertToHoursMinutes(data);

  if (isPDF) {
    return (
      <View>
        <Text style={pdfStyles.reflectionHeader}>{(definition["Display Title"] || definition.Name) + ":"}</Text>
        <Text style={pdfStyles.evidenceDate}>{StringUtils.getActivityDurationDisplayString(time)}</Text>
      </View>
    );
  }

  if (isDisplay) {
    return (
      <div className="readEvidenceBlock">
        <div className="readEvidenceHeader">{definition["Display Title"] || definition.Name}</div>
        <div className="readEvidenceText">{StringUtils.getActivityDurationDisplayString(time)}</div>
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return (
    <DurationPicker
      hours={value}
      onChange={(newTime) => {
        setValue(newTime);
        onValueChanged && onValueChanged(newTime, true);
      }}
    />
  );
};

export default ActivityDuration;
