import { useState } from "react";
import { EvidencePart, IDateRange } from "../../../Interfaces";
import { format, isToday, isBefore, isAfter } from "date-fns";
import { Button_Dropdown } from "../../../assets/images";
import { IonPopover } from "@ionic/react";
import { DayPicker, getDefaultClassNames, ClassNames, Modifiers } from "react-day-picker";
import { Text, View } from "@react-pdf/renderer";
import "react-day-picker/style.css";
import WeekDayRenderer from "./WeekdayRenderer";

const defaultClassNames = getDefaultClassNames();

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  definition: EvidencePart;
  onValueChanged?: (value: any) => void;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue?: any; // array of dates. if there is one value or no values, show "single" mode, if there are two values, show "range" mode
};

const DateRangeInput: React.FC<Props> = ({
  children,
  style,
  definition,
  onValueChanged,
  data,
  isDisplay,
  isPDF,
  pdfStyles,
  initialValue,
}) => {
  const [dateMode, setDateMode] = useState<"single" | "range">(getInitialValueForDateMode());
  const [singleDate, setSingleDate] = useState<Date>(getInitialValueForSingleDate());
  const [dateRange, setDateRange] = useState<IDateRange | undefined>(getInitialValueForDateRange());
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

  if (isPDF) {
    return (
      <View>
        <Text style={pdfStyles.reflectionHeader}>{(definition["Display Title"] || definition.Name) + ":"}</Text>
        {data &&
          data.length > 0 &&
          (data.length === 1 ? (
            <Text style={pdfStyles.evidenceDate}>{format(new Date(data[0]), "d MMMM yyyy")}</Text>
          ) : (
            <Text style={pdfStyles.evidenceDate}>
              {format(new Date(data[0]), "d MMMM yyyy")}
              {" - "}
              {format(new Date(data[1]), "d MMMM yyyy")}
            </Text>
          ))}
        {/* </Text> */}
      </View>
    );
  }

  if (isDisplay) {
    return (
      <div className="readEvidenceBlock">
        <div className="readEvidenceHeader">{definition["Display Title"] || definition.Name}</div>
        {/* <div className="readEvidenceText">{format(new Date(data), "d MMMM yyyy")}</div> */}
        {data &&
          data.length > 0 &&
          (data.length === 1 ? (
            <div className="readEvidenceText">{format(new Date(data[0]), "d MMMM yyyy")}</div>
          ) : (
            <div className="readEvidenceText flex">
              <div>{format(new Date(data[0]), "d MMMM yyyy")}</div>
              <div className="whitespace-pre-wrap">{" - "}</div>
              <div>{format(new Date(data[1]), "d MMMM yyyy")}</div>
            </div>
          ))}
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  function handleDateRangeChange(triggerDate: Date) {
    if (!dateRange) {
      setDateRange({ from: triggerDate });
      return;
    }

    const copy = { ...dateRange };
    const triggerIsBeforeFromDate = isBefore(triggerDate, copy.from);
    const triggerIsAfterFromDate = isAfter(triggerDate, copy.from);
    if (copy.to || triggerIsBeforeFromDate) {
      copy.from = triggerDate;
      copy.to = undefined;
    } else if (triggerIsAfterFromDate) {
      copy.to = triggerDate;
    }

    setDateRange(copy);
    onValueChanged && onValueChanged(copy.to ? [copy.from, copy.to] : [copy.from]);
  }

  function getInitialValueForDateMode(): "single" | "range" {
    if (!initialValue) return "single";
    return initialValue.length === 1 ? "single" : "range";
  }

  function getInitialValueForSingleDate(): Date {
    if (!initialValue) return new Date();
    return initialValue.length === 1 ? new Date(initialValue[0]) : new Date();
  }

  function getInitialValueForDateRange(): IDateRange | undefined {
    if (!initialValue) return undefined;
    return initialValue.length === 2 ? { from: new Date(initialValue[0]), to: new Date(initialValue[1]) } : undefined;
  }

  return (
    <div id={definition.id} className="reflectionDateContainer" onClick={() => setPopoverVisible(true)}>
      {dateMode === "single" ? (
        <div className="reflectionDateText">{format(singleDate, "dd / MM / yyyy")}</div>
      ) : (
        <div className="reflectionDateText flex">
          <div>{dateRange ? format(dateRange.from, "dd / MM / yyyy") : ""}</div>
          {dateRange && dateRange.to && <div className="whitespace-pre-wrap">{" - "}</div>}
          <div>{dateRange && dateRange.to ? format(dateRange.to, "dd / MM / yyyy") : ""}</div>
        </div>
      )}
      <img src={Button_Dropdown} className="reflectionsHeaderDropdown" />
      <IonPopover
        id="date-range-popover"
        trigger={definition.id}
        isOpen={popoverVisible}
        onDidDismiss={() => setPopoverVisible(false)}
      >
        <div className="p-5">
          <div className="flex gap-2 mb-3">
            <button
              className={`flex-1 border border-solid rounded-md py-2 ${dateMode === "single" ? "bg-cta-blue border-cta-blue text-white" : "bg-transparent border-[#D5D3D5] text-[#BEBEBE]"}`}
              onClick={() => setDateMode("single")}
            >
              Single Date
            </button>
            <button
              className={`flex-1 border border-solid rounded-md py-2 ${dateMode === "range" ? "bg-cta-blue border-cta-blue text-white" : "bg-transparent border-[#D5D3D5] text-[#BEBEBE]"}`}
              onClick={() => setDateMode("range")}
            >
              Date Range
            </button>
          </div>
          {dateMode === "single" ? (
            <DayPicker
              mode={"single"}
              weekStartsOn={1}
              selected={singleDate}
              defaultMonth={singleDate}
              onSelect={(data: any) => {
                setSingleDate(data);
                onValueChanged && onValueChanged([data]);
              }}
              endMonth={new Date()}
              disabled={{ after: new Date() }}
              classNames={{
                selected: `bg-cta-blue text-white rounded-[10px]`,
                today: isToday(singleDate) ? "text-white" : getDefaultClassNames().today,
                month_caption: `${defaultClassNames.month_caption} text-[#595959] font-normal`,
              }}
              components={{
                Weekday: (el) => <WeekDayRenderer element={el} />,
              }}
            />
          ) : (
            <DayPicker
              mode={"range"}
              selected={dateRange}
              defaultMonth={dateRange?.from}
              weekStartsOn={1}
              onSelect={(_: any, triggerDate: Date) => handleDateRangeChange(triggerDate)}
              endMonth={new Date()}
              disabled={{ after: new Date() }}
              classNames={{
                selected: dateRange?.from && !dateRange.to ? `bg-cta-blue text-white rounded-[10px]` : "",
                today: dateRange?.from && isToday(dateRange?.from) ? "text-white" : getDefaultClassNames().today,
                month_caption: `${defaultClassNames.month_caption} text-[#595959] font-normal`,
              }}
              components={{
                Weekday: (el) => <WeekDayRenderer element={el} />,
              }}
            />
          )}
        </div>
      </IonPopover>
    </div>
  );
};

export default DateRangeInput;
