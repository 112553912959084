import React from "react";
import { IEvidenceSearchCard } from "../../types/Components";
import * as ProgramUtils from "../../utils/programUtils";
import * as EvidenceUtils from "../../utils/evidenceUtils";
import {
  AllProgressCheckStatuses,
  CompetenceActivityClass,
  EvidenceCustomTag,
  EvidenceStandardTag,
  IUser,
  ProgressCheck,
} from "../../Interfaces";
import { format } from "date-fns";
import { StringUtils } from "../../utils/stringUtils";
import EvidenceDateHeader from "./EvidenceDateHeader";
import { EventRegister } from "react-native-event-listeners";
import { useProgramData } from "../../hooks/data/useProgramData";
import { useRecoilValueLoadable } from "recoil";
import { progressCheckDataAtom, userAtom } from "../../state/State";
import _ from "lodash";
import EvidenceCard from "./EvidenceCard";

const EvidenceSearchCard: React.FC<IEvidenceSearchCard> = (props) => {
  // Use new evidence type or old evidence type
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
  const evidenceJSON: any =
    props.evidence.evidenceJSON &&
    typeof props.evidence.evidenceJSON === "string" &&
    JSON.parse(props.evidence.evidenceJSON);

  const { userPrograms } = useProgramData();

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const allProgressCheckData = useRecoilValueLoadable<AllProgressCheckStatuses[] | null>(progressCheckDataAtom);

  const taskingType: string | undefined = evidenceJSON?.["Tasking type or training"];

  const programName = evidenceJSON?.programInfo?.program || "";
  const progressCheckName = evidenceJSON?.programInfo.progressCheck || "";
  const competenceName = evidenceJSON?.programInfo?.competence || "";
  const title = props.evidence?.title || evidenceJSON?.Title || "";

  const hasSearchText = props.searchText.trim().length > 0;

  const customTagsKey = ProgramUtils.getEvidenceCustomTagsKey(props.evidence);
  const customTags: EvidenceCustomTag[] = customTagsKey ? evidenceJSON?.[customTagsKey] : props.evidence.customTags;

  const searchInTitle = hasSearchText && title?.toLowerCase().includes(props.searchText.toLowerCase());
  const searchInActivityType =
    hasSearchText &&
    props.evidence.activity !== null &&
    props.evidence.activity?.type.toLowerCase().includes(props.searchText.toLowerCase());
  const searchInDate =
    hasSearchText &&
    format(new Date(props.evidence.date), "d MMM yyyy").toLowerCase().includes(props.searchText.toLowerCase());
  const searchInProgram = hasSearchText && programName?.toLowerCase().includes(props.searchText.toLowerCase());
  const searchInProgressCheck =
    hasSearchText && progressCheckName?.toLowerCase().includes(props.searchText.toLowerCase());
  const searchInCompetence = hasSearchText && competenceName?.toLowerCase().includes(props.searchText.toLowerCase());
  const searchInTaskingType = hasSearchText && taskingType?.toLowerCase().includes(props.searchText.toLowerCase());

  let splitTitle: string[] = [];
  let splitActivityType: string[] = [];
  let splitDate: string[] = [];
  let splitProgram: string[] = [];
  let splitProgressCheck: string[] = [];
  let splitCompetence: string[] = [];
  let splitTaskingType: string[] = [];

  const regex = new RegExp(`(${StringUtils.escapeRegExp(props.searchText)})`, "gi");

  if (searchInTitle) {
    splitTitle = title?.split(regex) || [];
    splitTitle = splitTitle.filter((item) => item.length > 0);
  }

  if (searchInActivityType) {
    splitActivityType = props.evidence.activity?.type.split(regex)!;
    splitActivityType = splitActivityType.filter((item) => item.length > 0);
  }

  if (searchInDate) {
    splitDate = format(new Date(props.evidence.date), "d MMM yyyy").split(regex);
    splitDate = splitDate.filter((item) => item.length > 0);
  }

  if (searchInProgram) {
    splitProgram = programName?.split(regex) || [];
    splitProgram = splitProgram.filter((item) => item.length > 0);
  }

  if (searchInProgressCheck) {
    splitProgressCheck = progressCheckName?.split(regex) || [];
    splitProgressCheck = splitProgressCheck.filter((item) => item.length > 0);
  }

  if (searchInCompetence) {
    splitCompetence = competenceName?.split(regex) || [];
    splitCompetence = splitCompetence.filter((item) => item.length > 0);
  }

  if (searchInTaskingType) {
    splitTaskingType = taskingType?.split(regex) || [];
    splitTaskingType = splitTaskingType.filter((item) => item.length > 0);
  }

  const hasSeparator = programName.length > 0 || competenceName.length > 0 || props.evidence.activity?.type;
  const hasSecondarySeparator = programName.length > 0 && (competenceName.length > 0 || progressCheckName.length > 0);

  const onHoldReset = evidenceJSON?.OnHoldReset === 1;

  const editEvidence = (event: any) => {
    event.stopPropagation();

    let _evidence = _.pickBy(_.cloneDeep(props.evidence), _.identity);

    if (_evidence.evidenceJSON) {
      const parsedEvidence = JSON.parse(props.evidence.evidenceJSON);
      const program = userPrograms.find((program) => program.ID === parsedEvidence.programInfo.programID);

      const evidenceDefinitions = program?.["Evidence Definitions"] || [];
      const mandatoryStandards = ProgramUtils.getMandatoryStandards(user.contents, program!);
      const optionalStandards = ProgramUtils.getOptionalStandards(user.contents, program!);

      const competenceName = parsedEvidence.programInfo?.competence;
      const competence = program?.CompetenceActivity?.find(
        (item: any): item is CompetenceActivityClass => item.Name === competenceName
      );

      const progressCheckID = parsedEvidence.programInfo?.progressCheckID;
      const progressCheckCompetenceID = parsedEvidence.programInfo?.progressCheckCompetenceID;

      const progressCheck = program?.ProgressChecks?.find(
        (item: any): item is ProgressCheck => item.ID === progressCheckID
      );

      const progressCheckCompetence = progressCheck?.["Competence/Activity"]?.find(
        (item: any) => item.ID === progressCheckCompetenceID
      );

      const data = {
        program: program,
        ...(program?.CompetenceActivity &&
          program?.CompetenceActivity.length > 0 && { competences: program?.CompetenceActivity }),
        ...(evidenceDefinitions.length > 0 && {
          evidenceDefinitions: evidenceDefinitions[0],
        }),
        competence: competence,
        evidence: _evidence,
        ...(progressCheckCompetence && {
          progressCompetence: progressCheckCompetence,
        }),
        progressCheck: progressCheck,
        ...(program?.ProgressChecks &&
          program?.ProgressChecks.length > 0 && { progressChecks: program.ProgressChecks }),
        ...(mandatoryStandards.length > 0 && { mandatoryStandards }),
        ...(optionalStandards.length > 0 && { optionalStandards }),
        evidenceJSON: JSON.parse(_evidence.evidenceJSON),
        skill: null,
        id: _evidence.id,
        learningOutcomes: progressCheckCompetence?.["Learning Objective"] ?? [],
        progressCheckCompetence: progressCheckCompetence,
        currentProgressCheckId: progressCheck?.ID ?? "",
      };

      EventRegister.emit("evidence/add-program-evidence", data);
    } else {
      EventRegister.emit("evidence/evidence-card-clicked", _evidence);
    }
  };

  return (
    <div>
      {props.showHeader && (
        <div className="flex justify-center">
          <EvidenceDateHeader date={new Date(props.evidence.date)} />
        </div>
      )}
      <div className="timelineBlock">
        <EvidenceCard onClick={props.buttonPressed}>
          <div className="flex flex-col w-full py-3.5 pl-safe-area-l pr-safe-area-r">
            <div className="flex justify-between">
              <div>
                <div className="evidenceCardDateContainer">
                  {props.evidence.private && (
                    <div className="flex items-center justify-center self-start h-[16px] w-[52px] px-[6px] rounded-[13px] mr-[8px] bg-[#DEE2E6] ">
                      <div className="text-[12px] font-semibold text-[#868E96]">{"Private"}</div>
                    </div>
                  )}
                  {props.evidence.draft && (
                    <div className="flex items-center justify-center self-start h-[16px] w-[42px] px-[6px] rounded-[13px] mr-[8px] bg-orange-30 ">
                      <div className="text-[12px] font-semibold text-[#9C4221]">{"Draft"}</div>
                    </div>
                  )}
                  <div className="evidenceCardDate">
                    {splitDate.length > 0 ? (
                      <>
                        {splitDate.map((split: string, _index: number) => {
                          const searchTerm = split.toLowerCase() === props.searchText.toLowerCase();

                          if (searchTerm) {
                            return (
                              <span key={`evidence-date-${split}-${_index}`} className="timelineBlockSearchHighlight">
                                {split}
                              </span>
                            );
                          }

                          return <span key={`evidence-date-${split}-${_index}`}>{split}</span>;
                        })}
                      </>
                    ) : (
                      <>{format(new Date(props.evidence.date), "d MMM yyyy") || ""}</>
                    )}
                  </div>
                  {hasSeparator && <div className="evidenceCardDotSeparator" />}
                  {props.evidence.activity?.type && (
                    <div className="evidenceCardActivity">
                      {splitActivityType.length > 0 ? (
                        <>
                          {splitActivityType.map((split: string, _index: number) => {
                            const searchTerm = split.toLowerCase() === props.searchText.toLowerCase();

                            if (searchTerm) {
                              return (
                                <span
                                  key={`evidence-activity-${split}-${_index}`}
                                  className="timelineBlockSearchHighlight"
                                >
                                  {split}
                                </span>
                              );
                            }

                            return <span key={`evidence-activity-${split}-${_index}`}>{split}</span>;
                          })}
                        </>
                      ) : (
                        <>{props.evidence.activity?.type}</>
                      )}
                    </div>
                  )}
                  {programName.length > 0 && (
                    <div className="evidenceCardActivity">
                      {splitProgram.length > 0 ? (
                        <>
                          {splitProgram.map((split: string, _index: number) => {
                            const searchTerm = split.toLowerCase() === props.searchText.toLowerCase();

                            if (searchTerm) {
                              return (
                                <span
                                  key={`evidence-activity-${split}-${_index}`}
                                  className="timelineBlockSearchHighlight"
                                >
                                  {split}
                                </span>
                              );
                            }

                            return <span key={`evidence-activity-${split}-${_index}`}>{split}</span>;
                          })}
                        </>
                      ) : (
                        <>{programName}</>
                      )}
                    </div>
                  )}
                  {hasSecondarySeparator && <div className="evidenceCardDotSeparator" />}
                  {competenceName.length > 0 && (
                    <div className="evidenceCardActivity">
                      {splitCompetence.length > 0 ? (
                        <>
                          {splitCompetence.map((split: string, _index: number) => {
                            const searchTerm = split.toLowerCase() === props.searchText.toLowerCase();

                            if (searchTerm) {
                              return (
                                <span
                                  key={`evidence-activity-${split}-${_index}`}
                                  className="timelineBlockSearchHighlight"
                                >
                                  {split}
                                </span>
                              );
                            }

                            return <span key={`evidence-activity-${split}-${_index}`}>{split}</span>;
                          })}
                        </>
                      ) : (
                        <>{competenceName}</>
                      )}
                    </div>
                  )}
                  {progressCheckName.length > 0 && (
                    <div className="evidenceCardActivity">
                      {splitProgressCheck.length > 0 ? (
                        <>
                          {splitProgressCheck.map((split: string, _index: number) => {
                            const searchTerm = split.toLowerCase() === props.searchText.toLowerCase();

                            if (searchTerm) {
                              return (
                                <span
                                  key={`evidence-activity-${split}-${_index}`}
                                  className="timelineBlockSearchHighlight"
                                >
                                  {split}
                                </span>
                              );
                            }

                            return <span key={`evidence-activity-${split}-${_index}`}>{split}</span>;
                          })}
                        </>
                      ) : (
                        <>{progressCheckName}</>
                      )}
                    </div>
                  )}
                  {onHoldReset && (
                    <div className="flex flex-row">
                      <div className="evidenceCardDotSeparator" />
                      <div className="text-acc-12px font-acc-500 leading-[1.33] tracking-[-0.1px] text-cta-red whitespace-break-spaces">
                        {"Submission Reset"}
                      </div>
                    </div>
                  )}
                </div>
                <div className="evidenceCardTitle">
                  {splitTitle.length > 0 ? (
                    <>
                      {splitTitle.map((split: string, _index: number) => {
                        const searchTerm = split.toLowerCase() === props.searchText.toLowerCase();

                        if (searchTerm) {
                          return (
                            <span key={`evidence-title-${split}-${_index}`} className="timelineBlockSearchHighlight">
                              {split}
                            </span>
                          );
                        }

                        return <span key={`evidence-title-${split}-${_index}`}>{split}</span>;
                      })}
                    </>
                  ) : (
                    <>{title}</>
                  )}
                </div>
                <span className="evidenceTagsContainer">
                  {taskingType && (
                    <>
                      {splitTaskingType.length > 0 ? (
                        <span className="evidenceCustomTags">
                          {splitTaskingType.map((split: string, _index: number) => {
                            const searchTerm = split.toLowerCase() === props.searchText.toLowerCase();

                            if (searchTerm) {
                              return (
                                <span
                                  key={`evidence-tasking-type-${split}-${_index}`}
                                  className="timelineBlockSearchHighlight"
                                >
                                  {split}
                                </span>
                              );
                            }

                            return <span key={`evidence-tasking-type-${split}-${_index}`}>{split}</span>;
                          })}
                        </span>
                      ) : (
                        <span className="evidenceCustomTags">{taskingType}</span>
                      )}
                    </>
                  )}
                  {props.evidence.standardTags && props.evidence.standardTags?.length > 0 && (
                    <span className="evidenceStandardTags">
                      {props.evidence.standardTags.map((tag: EvidenceStandardTag) => tag.tag).join(", ")}
                    </span>
                  )}
                  {props.evidence.standardTags &&
                    props.evidence.standardTags.length > 0 &&
                    props.evidence.customTags &&
                    props.evidence.customTags.length > 0 && <span className="evidenceCardDotSeparator" />}
                  {customTags && customTags.length > 0 && (
                    <span className="evidenceCustomTags">
                      {customTags.map((tag: EvidenceCustomTag) => tag.tag).join(", ")}
                    </span>
                  )}
                </span>
                {props.evidence.addToHCPCAudit && <div className="evidenceHCPCPill">{"Audit"}</div>}
              </div>
              {EvidenceUtils.canEdit(props.evidence, userPrograms, allProgressCheckData) && (
                <button onClick={(e) => editEvidence(e)} className="text-cta-blue text-acc-14px p-1 self-center">
                  Edit
                </button>
              )}
            </div>
          </div>
        </EvidenceCard>
      </div>
    </div>
  );
};

export default EvidenceSearchCard;
