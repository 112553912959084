import { HelpCentreContent } from "../../Interfaces";

type HelpCentreVideoProps = {
  section: HelpCentreContent;
};

const HelpCentreVideo: React.FC<HelpCentreVideoProps> = (props) => {
  const videoID = props.section.VideoID;
  const showVideo = videoID != null && videoID !== "";
  const image = props.section.Image;

  if (showVideo) {
    const videoSrc = `https://player.vimeo.com/video/${videoID}${videoID.includes("?") ? "&" : "?"}?portrait=0&autoplay=0`;

    return (
      <div className="bg-black">
        <iframe src={videoSrc} className="help-centre-video" allowFullScreen />
      </div>
    );
  } else if (image) {
    return (
      <div className="flex justify-center items-center max-h-[50vh]">
        <img className="max-h-[50vh]" src={image.url} />
      </div>
    );
  }

  return <div></div>;
};

export default HelpCentreVideo;
