import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar } from "@capacitor/status-bar";
import { NavigationBar } from "@hugotomazi/capacitor-navigation-bar";
import { useEffect } from "react";
import DataController from "../../controllers/DataController";
export const useAppLaunchSetup = () => {
  useEffect(() => {
    const setNavbarColors = async (): Promise<void> => {
      if (!(await DataController.isWebAsync())) {
        try {
          StatusBar.setOverlaysWebView({ overlay: false });
          StatusBar.setBackgroundColor({ color: "#026F8A" });
          NavigationBar.setColor({ color: "#026F8A" });
        } catch (error) {}
      }
    };

    const hideSplashScreen = (): void => {
      try {
        SplashScreen.hide();
      } catch (error) {
        console.log(error);
      }
    };

    hideSplashScreen();
    setNavbarColors();
  }, []);
};
