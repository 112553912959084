import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Filler,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import * as GraphingUtils from "../../../utils/graphingUtils";
import { IWellbeingRadarGraph } from "../../../types/Components";

ChartJS.register(
  CategoryScale,
  RadialLinearScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const WellbeingRadarGraph: React.FC<IWellbeingRadarGraph> = (props) => {
  const getBackgroundColour = (index: number): string => {
    if (index % 2 === 0) {
      return "rgba(251, 92, 121, 0.3)";
    } else {
      return "rgba(54, 151, 227, 0.3)";
    }
  };

  const getBorderColour = (index: number): string => {
    if (index % 2 === 0) {
      return "#FB5C79";
    } else {
      return "#3697E3";
    }
  };

  const getTextColour = (index: number): string => {
    if (index % 2 === 0) {
      return "text-[#FB5C79]";
    } else {
      return "text-[#3697E3]";
    }
  };

  const getSquareColour = (index: number): string => {
    if (index % 2 === 0) {
      return "bg-[#FB5C79]";
    } else {
      return "bg-[#3697E3]";
    }
  };

  const getMaxValue = (): number => {
    const maxValues = props.initialValue.data.flatMap((data) => data.map((data) => data.maxValue));

    return Math.max(...maxValues) ?? 10;
  };

  return (
    <div className="flex justify-center mx-4 mt-2">
      <div className="relative max-w-app-column w-full p-4 bg-white mb-2 pl-safe-area-l pr-safe-area-r rounded-md">
        <div className="flex items-center justify-center w-285px">
          <Radar
            data={{
              labels: props.initialValue.data.flatMap((data) => data.map((data) => data.label)),
              datasets: props.initialValue.data.map((data, index) => {
                return {
                  data: GraphingUtils.padRadarGraphData(data, props.initialValue.data, index === 0 ? "start" : "end"),
                  backgroundColor: getBackgroundColour(index),
                  borderColor: getBorderColour(index),
                  fill: true,
                };
              }),
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
                filler: {},
                tooltip: {
                  enabled: true,
                },
                datalabels: {
                  display: false,
                },
              },
              scales: {
                r: {
                  min: 0,
                  max: getMaxValue(),
                },
              },
              animation: false,
            }}
            height={704}
            width={704}
          />
        </div>
        {props.initialValue.data.map((data, index) => {
          const total = data.map((data) => data.value).reduce((a, b) => a + b, 0);

          return (
            <div key={data[0].header}>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className={`h-wellbeing-square w-wellbeing-square rounded-sm ${getSquareColour(index)} mr-3`} />
                  <div className="text-[14px] font-acc-500 text-grey-90">{data[0].header}</div>
                </div>
                <div className={`text-[14px] font-black ${getTextColour(index)}`}>{total}</div>
              </div>
              {index !== props.initialValue.data.length - 1 && <div className="my-3 h-[1px] bg-grey-40" />}
            </div>
          );
        })}
      </div>
    </div>
    // #FB5C79
    // #3697E3
  );
};

export default WellbeingRadarGraph;
