import { useEffect, useState } from "react";

type SSOAvailableResponse = {
  enabled: boolean;
  ssoTrusts: string[];
};

export const useSSO = (load?: boolean) => {
  const [available, setAvailable] = useState<boolean>(false);
  const [ssoTrusts, setSSOTrusts] = useState<string[]>([]);

  useEffect(() => {
    const checkIfSSOAvailable = async (): Promise<void> => {
      try {
        const response: Response = await fetch(
          "https://parafolio-config.s3.eu-west-2.amazonaws.com/sso/ssoConfig.json",
          {
            headers: {
              "Cache-Control": "no-cache",
            },
          }
        );

        const result: SSOAvailableResponse = await response.json();

        if (result != null) {
          setAvailable(result.enabled);
          setSSOTrusts(result.ssoTrusts);
        }
      } catch (error) {
        setAvailable(false);
      }
    };

    if (load) {
      checkIfSSOAvailable();
    }
  }, [load]);

  return {
    enabled: available,
    trusts: ssoTrusts,
  };
};
