import { IonToast } from "@ionic/react";
import React, { useEffect } from "react";
import { Icon_Thumb_Down_Outline, Icon_Thumb_Up_Outline } from "../../assets/images";
import { EventRegister } from "react-native-event-listeners";
import { FirebaseService } from "../../controllers/FirebaseService";

const AIFeedbackToast: React.FC = () => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [feedback, setFeedback] = React.useState<"positive" | "negative" | undefined>();

  useEffect(() => {
    EventRegister.addEventListener("evidence/show-ai-feedback", () => {
      setVisible(true);

      const toast = document.getElementById("ai-feedback-toast");
      const toastRoot = toast?.shadowRoot;

      const positiveFeedbackButton = toastRoot?.querySelector("#positive-feedback");
      const negativeFeedbackButton = toastRoot?.querySelector("#negative-feedback");

      positiveFeedbackButton?.setAttribute("part", "button positive-feedback");
      negativeFeedbackButton?.setAttribute("part", "button negative-feedback");
    });

    return () => {
      EventRegister.removeEventListener("evidence/show-ai-feedback");
    };
  }, []);

  useEffect(() => {
    const toast = document.getElementById("ai-feedback-toast");
    const toastRoot = toast?.shadowRoot;

    const positiveFeedbackButton = toastRoot?.querySelector("#positive-feedback");
    const negativeFeedbackButton = toastRoot?.querySelector("#negative-feedback");

    if (feedback === "positive") {
      positiveFeedbackButton?.setAttribute("part", "button positive-feedback-selected");
      positiveFeedbackButton?.setAttribute("disabled", "");
      negativeFeedbackButton?.setAttribute("part", "button negative-feedback");
    } else if (feedback === "negative") {
      positiveFeedbackButton?.setAttribute("part", "button positive-feedback");
      negativeFeedbackButton?.setAttribute("part", "button negative-feedback-selected");
    } else {
      positiveFeedbackButton?.setAttribute("part", "button positive-feedback");
      negativeFeedbackButton?.setAttribute("part", "button negative-feedback");
    }
  }, [feedback]);

  const positiveFeedback = async () => {
    if (!feedback) {
      setFeedback("positive");
      FirebaseService.logEvent("ai_dictation_feedback", { type: "positive" });
      closeToast();
    }

    return false;
  };

  const negativeFeedback = async () => {
    if (!feedback) {
      setFeedback("negative");
      FirebaseService.logEvent("ai_dictation_feedback", { type: "negative" });
      closeToast();
    }

    return false;
  };

  const closeToast = () => {
    setTimeout(() => {
      setVisible(false);
    }, 1000);
  };

  const resetToast = () => {
    setFeedback(undefined);
  };

  const getMessageText = () => {
    switch (feedback) {
      case "positive":
      case "negative":
        return "Thanks for your feedback!";
      default:
        return "Tell us how we did";
    }
  };

  return (
    <IonToast
      buttons={[
        {
          handler: positiveFeedback,
          icon: Icon_Thumb_Up_Outline,
          role: "positive-feedback",
          htmlAttributes: {
            id: "positive-feedback",
          },
        },
        {
          handler: negativeFeedback,
          icon: Icon_Thumb_Down_Outline,
          role: "negative-feedback",
          cssClass: "toast-negative-feedback",
          htmlAttributes: {
            id: "negative-feedback",
          },
        },
      ]}
      cssClass="ai-feedback-toast"
      id="ai-feedback-toast"
      isOpen={visible}
      message={getMessageText()}
      onDidDismiss={resetToast}
      mode="ios"
      position="bottom"
    />
  );
};

export default AIFeedbackToast;
