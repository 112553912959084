import "./ProfileComponents.css";

type Props = {
  title: string;
  children?: React.ReactNode;
  showResetButton?: boolean;
  resetButtonClicked?: () => void;
};

const AccessibilitySection: React.FC<Props> = (props) => {
  return (
    <div className="accessibilitySection">
      <div className="accessibilitySectionInner">
        <div className="accessibilitySectionTitleContainer flex flex-row justify-between items-center">
          <div className="accessibilitySectionTitle">{props.title.toUpperCase()}</div>
          {props.showResetButton && (
            <button onClick={props.resetButtonClicked} className="h-5 flex items-center justify-center">
              <div className="text-acc-16px text-cta-blue font-acc-500">{"Reset"}</div>
            </button>
          )}
        </div>
        <div className="accessibilitySectionContent">{props.children}</div>
      </div>
    </div>
  );
};

export default AccessibilitySection;
