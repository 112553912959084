import { useEffect, useState } from "react";

export const useWindowResized = () => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

  useEffect(() => {
    const windowResized = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", windowResized);

    windowResized();

    return () => {
      window.removeEventListener("resize", windowResized);
    };
  }, []);

  return {
    windowWidth,
    windowHeight,
  };
};
