import classNames from "classnames";
import { EvidencePart } from "../../../Interfaces";
import { shouldInvertBannerTextColor } from "./helpers/evidencePartUtils";

type Props = {
  definition: EvidencePart;
  isPDF: boolean;
  isDisplay: boolean;
};

const Banner: React.FC<Props> = (props) => {
  const bannerColourVariants: any = {
    Red: "bg-red-banner",
    Yellow: "bg-yellow-banner",
    Grey: "bg-grey-20",
    Blue: "bg-blue-banner",
    Green: "bg-green-banner",
  };

  if (props.isDisplay || props.isPDF) {
    return null;
  }

  const bannerColor = props.definition["Banner colour"] ?? props.definition["Banner color"] ?? "Grey";

  return (
    <div className={`reflectionContentCard ${bannerColourVariants[bannerColor]}`}>
      <div
        className={classNames("reflectionContentCardTitle !mb-0", {
          "!text-white": shouldInvertBannerTextColor(bannerColor),
        })}
      >
        {props.definition["Display Title"]}
      </div>
    </div>
  );
};

export default Banner;
