import { useCallback, useEffect, useRef, useState } from "react";
import { IUser } from "../../Interfaces";
import { useRecoilState } from "recoil";
import { userAtom } from "../../state/State";
import { DatabaseService } from "../../controllers/DatabaseService";
import AuthenticationController from "../../controllers/AuthenticationController";
import _ from "lodash";
import { useLoggedIn } from "../auth/useLoggedIn";
import { USER_DATA_REFRESH_INTERVAL } from "../../Constants";

type RefreshUserDetails = {
  refreshUserDetails: (_user: IUser) => Promise<void>;
};

export const useRefreshUserDetails = (shouldLoad?: boolean): RefreshUserDetails => {
  const [user, setUser] = useRecoilState(userAtom);
  const loggedIn = useLoggedIn();

  const refreshUserDetails = useCallback(
    async (_user: IUser): Promise<void> => {
      try {
        if (_user) {
          let userDetails = await DatabaseService.getUserDetails(_user);

          if (userDetails && userDetails.contactID === `${_user.userData.contactID}`) {
            let userToSave: IUser = {
              ..._user,
              certificates: userDetails.certificates ? JSON.parse(userDetails.certificates) : undefined,
              usageData: userDetails.dataSharing === 1,
              role: userDetails.role,
              ...(userDetails.personalStatement && {
                personalStatement: userDetails.personalStatement,
              }),
              ...(userDetails.summaryOfWork && {
                summaryOfWork: userDetails.summaryOfWork,
              }),
              ...(userDetails.personalStatementTimestamp && {
                personalStatementTimestamp: userDetails.personalStatementTimestamp,
              }),
              ...(userDetails.summaryOfWorkTimestamp && {
                summaryOfWorkTimestamp: userDetails.summaryOfWorkTimestamp,
              }),
              ...(userDetails.HCPCNumber && {
                HCPCNumber: userDetails.HCPCNumber,
              }),
              programCertificates: userDetails.programCertificates
                ? JSON.parse(userDetails.programCertificates)
                : undefined,
              programRoles: userDetails.programRoles ? JSON.parse(userDetails.programRoles) : undefined,
            };

            userToSave = await AuthenticationController.getUserData(userToSave);

            setUser(userToSave);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [setUser]
  );

  useEffect(() => {
    const getUserDetails = async (): Promise<void> => {
      try {
        let userDetails = await DatabaseService.getUserDetails(user);

        if (userDetails && userDetails.contactID === `${user.userData.contactID}`) {
          let userToSave: IUser = {
            ...user,
            certificates: userDetails.certificates ? JSON.parse(userDetails.certificates) : undefined,
            usageData: userDetails.dataSharing === 1,
            role: userDetails.role,
            ...(userDetails.personalStatement && {
              personalStatement: userDetails.personalStatement,
            }),
            ...(userDetails.summaryOfWork && {
              summaryOfWork: userDetails.summaryOfWork,
            }),
            ...(userDetails.personalStatementTimestamp && {
              personalStatementTimestamp: userDetails.personalStatementTimestamp,
            }),
            ...(userDetails.summaryOfWorkTimestamp && {
              summaryOfWorkTimestamp: userDetails.summaryOfWorkTimestamp,
            }),
            ...(userDetails.HCPCNumber && {
              HCPCNumber: userDetails.HCPCNumber,
            }),
            programCertificates: userDetails.programCertificates
              ? JSON.parse(userDetails.programCertificates)
              : undefined,
            programRoles: userDetails.programRoles ? JSON.parse(userDetails.programRoles) : undefined,
          };

          userToSave = await AuthenticationController.getUserData(userToSave);

          setUser(userToSave);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const interval = setInterval(() => {
      if (loggedIn && user && shouldLoad) {
        getUserDetails();
      }
    }, USER_DATA_REFRESH_INTERVAL);

    return () => {
      try {
        clearInterval(interval);
      } catch (error) {
        console.log(error);
      }
    };
  }, [loggedIn, user, shouldLoad, setUser]);

  // Get user details from server and update user object
  useEffect(() => {
    const getUserDetails = async (): Promise<void> => {
      try {
        let userDetails = await DatabaseService.getUserDetails(user);

        if (userDetails && userDetails.contactID === `${user.userData.contactID}`) {
          let userToSave: IUser = {
            ...user,
            certificates: userDetails.certificates ? JSON.parse(userDetails.certificates) : undefined,
            usageData: userDetails.dataSharing === 1,
            role: userDetails.role,
            ...(userDetails.personalStatement && {
              personalStatement: userDetails.personalStatement,
            }),
            ...(userDetails.summaryOfWork && {
              summaryOfWork: userDetails.summaryOfWork,
            }),
            ...(userDetails.personalStatementTimestamp && {
              personalStatementTimestamp: userDetails.personalStatementTimestamp,
            }),
            ...(userDetails.summaryOfWorkTimestamp && {
              summaryOfWorkTimestamp: userDetails.summaryOfWorkTimestamp,
            }),
            ...(userDetails.HCPCNumber && {
              HCPCNumber: userDetails.HCPCNumber,
            }),
            programCertificates: userDetails.programCertificates
              ? JSON.parse(userDetails.programCertificates)
              : undefined,
            programRoles: userDetails.programRoles ? JSON.parse(userDetails.programRoles) : undefined,
          };

          userToSave = await AuthenticationController.getUserData(userToSave);

          setUser(userToSave);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (loggedIn && user && shouldLoad) {
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    refreshUserDetails,
  };
};
