import { useRecoilState } from "recoil";
import { HelpCentreContent, HelpCentreTimestamp } from "../../Interfaces";
import { helpCentreContentAtom, helpCentreTimestampAtom } from "../../state/State";
import { useEffect } from "react";

export interface UseHelpCentreData {
  helpCentreContent: HelpCentreContent[];
}

export const useHelpCentre = (loadData?: boolean): UseHelpCentreData => {
  const [helpCentreTimestamp, setHelpCentreTimestamp] = useRecoilState<HelpCentreTimestamp>(helpCentreTimestampAtom);
  const [helpCentreContent, setHelpCentreContent] = useRecoilState<HelpCentreContent[]>(helpCentreContentAtom);

  useEffect(() => {
    const checkIfDataNeedsUpdating = async (): Promise<void> => {
      try {
        const timestampResponse: Response = await fetch(
          "https://parafolio-config.s3.eu-west-2.amazonaws.com/help-centre/timestamp.json",
          {
            headers: {
              "Cache-Control": "no-cache",
            },
          }
        );

        const timestamp = await timestampResponse.json();

        if (timestamp.lastUpdatedTime) {
          if (timestamp.lastUpdatedTime > helpCentreTimestamp.lastUpdatedTime) {
            const response: Response = await fetch(
              "https://parafolio-config.s3.eu-west-2.amazonaws.com/help-centre/helpCentre.json",
              {
                headers: {
                  "Cache-Control": "no-cache",
                },
              }
            );

            const content = await response.json();

            if (content) {
              setHelpCentreContent(content);
              setHelpCentreTimestamp(timestamp);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (loadData) {
      checkIfDataNeedsUpdating();
    }
  }, []);

  return {
    helpCentreContent,
  };
};
