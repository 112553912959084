import { IonContent, IonFooter, IonHeader, IonMenu, IonTitle, IonToolbar } from "@ionic/react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { appInfoAtom, deviceInfoAtom, userAtom } from "../../state/State";
import { useRef, useState } from "react";
import { IUser } from "../../Interfaces";
import MenuButton from "../../components/menu/MenuButton";
import {
  Button_Menu_Account,
  Button_Menu_Contact_Support,
  Button_Menu_Help_Centre,
  Button_Menu_Info,
  Button_Menu_Settings,
  Button_Menu_Sync,
  Button_Take_Survey,
  Icon_Bulb,
} from "../../assets/images";
import { useHistory } from "react-router";
import { FEEDBACK_FORM_URL } from "../../Constants";
import { DeviceInfo } from "@capacitor/device";
import { AppInfo } from "@capacitor/app";
import DataController from "../../controllers/DataController";
import { version } from "../../environment/version";
import { Capacitor } from "@capacitor/core";
import { FirebaseService } from "../../controllers/FirebaseService";
import "./Menu.css";
import { useDebugUser } from "../../hooks/auth/useDebugUser";
import { useProgramData } from "../../hooks/data/useProgramData";
import { useRefreshJRCALCPlusData } from "../../hooks/data/useRefreshJRCALCPlusData";
import { useRefreshParapassData } from "../../hooks/data/useRefreshParapassData";
import { useRefreshProgressCheckData } from "../../hooks/data/useRefreshProgressCheckData";
import { useRefreshUserDetails } from "../../hooks/data/useRefreshUserDetails";
import { useRefreshUserEvidence } from "../../hooks/data/useRefreshUserEvidence";

const Menu: React.FC = () => {
  const history = useHistory();

  const menuRef = useRef<HTMLIonMenuElement>(null);

  const { isDebugUser, setIsDebugUser, debugHiddenButtonPressed } = useDebugUser();
  const { userPrograms } = useProgramData();

  const { refreshJRCALCPlusData } = useRefreshJRCALCPlusData();
  const { refreshParapassData } = useRefreshParapassData();
  const { refreshProgressCheckData } = useRefreshProgressCheckData();
  const { refreshUserDetails } = useRefreshUserDetails();
  const { refreshUserEvidence } = useRefreshUserEvidence();

  const user = useRecoilValueLoadable<IUser | null>(userAtom);

  const [syncingData, setSyncingData] = useState<boolean>(false);

  const deviceInfo = useRecoilValue<DeviceInfo | null>(deviceInfoAtom);
  const appInfo = useRecoilValue<AppInfo | null>(appInfoAtom);

  const syncAccountData = async (): Promise<void> => {
    try {
      setSyncingData(true);

      if (user.state === "hasValue" && user.contents) {
        await refreshUserDetails(user.contents);
        await refreshUserEvidence(user.contents);
        await refreshProgressCheckData(user.contents);
        await refreshJRCALCPlusData(user.contents);
        await refreshParapassData(user.contents);
      }

      setTimeout(() => {
        setSyncingData(false);
      }, 1500);
    } catch (error) {
      console.log(error);
      setSyncingData(false);
    }
  };

  const logButtonPressed = async (event: string, params: object): Promise<void> => {
    try {
      await FirebaseService.logEvent(event, params);
    } catch (error) {
      console.log(error);
    }
  };

  const navigateToPage = (page: string): void => {
    menuRef.current?.close();
    history.push(page);
  };

  const contactSupport = (): void => {
    if (user.state === "hasValue" && user.contents) {
      const url = "mailto:apps@class.co.uk";
      const subject = "ParaFolio - General support";

      const body = DataController.supportEmailBody(user.contents.userData, deviceInfo, appInfo);

      window.open(`${url}?subject=${subject}&body=${body}`, "_blank");
    }
  };

  const openExternalLink = (url: string): void => {
    menuRef.current?.close();
    window.open(url, "_blank");
  };

  return (
    <IonMenu
      ref={menuRef}
      side="start"
      type="push"
      // swipeGesture={Capacitor.getPlatform() !== 'web'}
      swipeGesture={false}
      maxEdgeStart={150}
      hidden={false}
      className="mainMenu"
      contentId="contentMain"
    >
      <IonHeader
        style={{
          backgroundColor: "#005F70",
          height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
        }}
      >
        <IonToolbar mode="ios" className="navBarMenu">
          <IonTitle>{""}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="menuContent pl-menu-safe-area-l">
        <MenuButton
          icon={Button_Menu_Account}
          title={user.contents?.email || "Unknown"}
          buttonClicked={() => {
            logButtonPressed("menu_button_pressed", { button: "Account" });
            navigateToPage("/profile/account");
          }}
        />
        <MenuButton
          icon={Button_Menu_Help_Centre}
          title="Help centre"
          buttonClicked={() => {
            logButtonPressed("menu_button_pressed", { button: "Help centre" });
            navigateToPage("/dashboard/help-centre");
          }}
        />
        <MenuButton
          icon={Button_Menu_Settings}
          title="Settings"
          buttonClicked={() => {
            logButtonPressed("menu_button_pressed", {
              button: "Accessibility settings",
            });
            navigateToPage("/profile/accessibility");
          }}
        />
        <MenuButton
          icon={Button_Menu_Contact_Support}
          title="Contact us"
          buttonClicked={() => {
            logButtonPressed("menu_button_pressed", {
              button: "Contact support",
            });
            // contactSupport();
            navigateToPage("/dashboard/contact-us");
          }}
        />
        <MenuButton
          icon={Button_Menu_Info}
          title="App information"
          buttonClicked={() => {
            logButtonPressed("menu_button_pressed", {
              button: "App information",
            });
            navigateToPage("/profile/app-info");
          }}
        />
        <MenuButton
          icon={Button_Take_Survey}
          title="Take our survey"
          buttonClicked={() => {
            logButtonPressed("menu_button_pressed", { button: "Feedback" });
            openExternalLink(FEEDBACK_FORM_URL);
          }}
        />
        <MenuButton
          icon={Button_Menu_Sync}
          title="Sync account data"
          syncingData={syncingData}
          buttonClicked={() => {
            logButtonPressed("menu_button_pressed", {
              button: "Sync account data",
            });
            syncAccountData();
          }}
        />
        {isDebugUser && (
          <MenuButton
            icon={Icon_Bulb}
            title="Debug options"
            buttonClicked={() => navigateToPage("/dashboard/program-data-info")}
          />
        )}
      </IonContent>
      <div className="menuShadow" />
      <IonFooter className="menuFooter" onClick={() => debugHiddenButtonPressed()}>
        <div className="menuFooterText">
          {Capacitor.getPlatform() === "web" ? (
            <>{`Version ${version}`}</>
          ) : (
            <>{`Version ${appInfo?.version} (${appInfo?.build})`}</>
          )}
        </div>
      </IonFooter>
    </IonMenu>
  );
};

export default Menu;
